import React, {useContext, useEffect, useState} from 'react';
import "./Devices.scss"
import {useNavigate} from "react-router-dom";
import {Form, Formik, useFormikContext} from "formik";
import FormikControl from "../FormTemplate/FormikControl";
import mqttRequests from "../../Utils/mqttRequests";
import {MqttClientContext} from "../../context/MqttClientContext";
import * as Yup from "yup";

//page for the user settings
function ParametersModifier(props) {

    //the device affected
    const device = JSON.parse(localStorage.getItem("deviceParam"));


    //set the color on the menu
    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('deviceList');

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //to navigate through the app
    let navigate = useNavigate();

    const [device1, setDevice1] = useState("0");
    const [device2, setDevice2] = useState("0");
    const [device3, setDevice3] = useState("0");
    const [device4, setDevice4] = useState("0");
    const [modifyParam, setModifyParam] = useState("");

    const selectOptions = [
        {key:"select", value: ""},
        {key:"g", value: "g"},
        {key:"o/t", value: "o"},
        {key:"o ct", value: "oct"},
        {key:"o co2", value: "oco"},
        {key:"o voc", value: "ovoc"},
    ]

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/deviceList")
        }, false);
    }, [navigate, setMenuClicked]);

    useEffect(() => {
        if(mqttClient !== null){
            let operation = mqttRequests.generateOperationCode("dataAsked");
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "identifier":"' + device.identifier + '", "from":"' + Math.round(new Date().getTime()/1000) + '", "to":"' + Math.round(new Date().getTime()/1000) + '", "idModel":"' + device.idModel + '", "dataType":["20221222161657076001"], "sortType":0}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/dataAsked");
            mqttRequests.publish(mqttClient, "data/dataAsked", data);

            //read incoming message
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //response for modifyParam
                if(jsonParsed.operation === "modifyParam"){
                    setModifyParam(jsonParsed.data);
                    console.log(jsonParsed.data)
                }else if(jsonParsed.data && jsonParsed.operation.includes("dataAsked")){
                    handleSubDevices(jsonParsed.data);
                }
            });

            mqttRequests.unSubscribe(mqttClient, "data/modifyParam");
            mqttRequests.subscribe(mqttClient, "data/modifyParam");
        }
    }, [mqttClient, source]);

    const initialValues = {
        device1: device1,
        device2: device2,
        device3: device3,
        device4: device4,
        preset1: "",
        preset2: "",
        preset3: "",
        preset4: "",
    };

    const validationSchema = Yup.object().shape({
        device1: Yup.string().required('please enter a correct MAC id')
            .test( 'len', 'please enter a correct MAC id',
                (val) => {
                    if (val === "0"){
                        return true
                    }
                    return val.length === 12 && /^[0-9A-F]+$/.test(val)
                }
            ),
        device2: Yup.string().required('please enter a correct MAC id')
            .test( 'len', 'please enter a correct MAC id',
                (val) => {
                    if (val === "0"){
                        return true
                    }
                    return val.length === 12 && /^[0-9A-F]+$/.test(val)
                }
            ),
        device3: Yup.string().required('please enter a correct MAC id')
            .test( 'len', 'please enter a correct MAC id',
                (val) => {
                    if (val === "0"){
                        return true
                    }
                    return val.length === 12 && /^[0-9A-F]+$/.test(val)
                }
            ),
        device4: Yup.string().required('please enter a correct MAC id')
            .test( 'len', 'please enter a correct MAC id',
                (val) => {
                    if (val === "0"){
                        return true
                    }
                    return val.length === 12 && /^[0-9A-F]+$/.test(val)
                }
            ),
        preset1: Yup.string().required('please select a device model'),
        preset2: Yup.string().required('please select a device model'),
        preset3: Yup.string().required('please select a device model'),
        preset4: Yup.string().required('please select a device model'),
    });

    const handleSubDevices = (data) =>{
        Object.keys(data).filter(name => name.includes("Temperature")).forEach((element, id)=> {
            switch (id){
                case 0:
                    setDevice1(element.substring(13));
                    break;
                case 1:
                    setDevice2(element.substring(13));
                    break;
                case 2:
                    setDevice3(element.substring(13));
                    break;
                case 3:
                    setDevice4(element.substring(13));
                    break;
                default:
                    break;
            }
        })
    }

    const submitDevice1 = (value) => {
        if(value.device1 === "0" || (value.device1.length === 12 && /^[0-9A-F]+$/.test(value.device1))){
            if(modifyParam === "" || modifyParam === null){
                let data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 1202:' + value.device1 + '"]}}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }else{
                let tempModifyParam = modifyParam;
                if(Object.keys(modifyParam).includes(device.identifier)){
                    let tempValue = modifyParam[device.identifier];
                    let arrayValue = [...tempValue];
                    //chercher dans le tableau si le paramètre sse trouve déjà là
                    arrayValue.forEach((element, index)=> {
                        //si on trouve un "setparam"
                        if(element.includes("setparam")){
                            let findParam = false;
                            let elementToModify = element.split(" ");
                            let contentToModify = elementToModify[1].split(";");
                            let tempModifiedParameter = 0;
                            //si le paramètre devrais déjà être modifier, on le cherche et on le modifie
                            contentToModify.forEach((parameter, index)=> {
                                if(parameter.substring(0,4) === "1202"){
                                    findParam = true;
                                    tempModifiedParameter = index;
                                }
                            });
                            //sinon on l'ajoute à la chaîne
                            if(findParam === false){
                                arrayValue[index] = element + ";1202:" + value.device1;
                            }else{
                                //s'il a été modifié, on refais l'array complet
                                arrayValue[index] = elementToModify[0] + " " + contentToModify.map((para,id)=>{
                                    if(id === tempModifiedParameter){
                                        return "1202:" + value.device1;
                                    }else {
                                        return para;
                                    }});
                            }
                            //si on n'a pas encore de setparam, on en ajoute un
                        }else {
                            arrayValue.push("setparam 1202:" + value.device1);
                        }
                    })
                    tempModifyParam[device.identifier] = arrayValue;
                }else {
                    tempModifyParam[device.identifier] = ["setparam 1202:" + value.device1]
                }
                let tempModifyParamString = JSON.stringify(tempModifyParam).replaceAll(",", ";");
                //remet les , au lieu des ; à chaque changement de tableaux
                tempModifyParamString = tempModifyParamString.replaceAll("];", "],");
                let data = '{"operation":"modifyParam", "data":' + tempModifyParamString + '}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }
        }
    }

    const submitDevice2 = (value) => {
        if(value.device2 === "0" || (value.device2.length === 12 && /^[0-9A-F]+$/.test(value.device2))){
            if(modifyParam === "" || modifyParam === null){
                let data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 1252:' + value.device2 + '"]}}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }else{
                let tempModifyParam = modifyParam;
                if(Object.keys(modifyParam).includes(device.identifier)){
                    let tempValue = modifyParam[device.identifier];
                    let arrayValue = [...tempValue];
                    //chercher dans le tableau si le paramètre sse trouve déjà là
                    arrayValue.forEach((element, index)=> {
                        //si on trouve un "setparam"
                        if(element.includes("setparam")){
                            let findParam = false;
                            let elementToModify = element.split(" ");
                            let contentToModify = elementToModify[1].split(";");
                            let tempModifiedParameter = 0;
                            //si le paramètre devrais déjà être modifier, on le cherche et on le modifie
                            contentToModify.forEach((parameter, index)=> {
                                if(parameter.substring(0,4) === "1252"){
                                    findParam = true;
                                    tempModifiedParameter = index;
                                }
                            });
                            //sinon on l'ajoute à la chaîne
                            if(findParam === false){
                                arrayValue[index] = element + ";1252:" + value.device2;
                            }else {
                                //s'il a été modifié, on refais l'array complet
                                arrayValue[index] = elementToModify[0] + " " + contentToModify.map((para, id)=>{
                                    if(id === tempModifiedParameter){
                                        return "1252:" + value.device2;
                                    }else {
                                        return para;
                                    }
                                });
                            }
                            //si on n'a pas encore de setparam, on en ajoute un
                        }else {
                            arrayValue.push("setparam 1252:" + value.device2);
                        }
                    })
                    tempModifyParam[device.identifier] = arrayValue;
                }else {
                    tempModifyParam[device.identifier] = ["setparam 1252:" + value.device2]
                }
                let tempModifyParamString = JSON.stringify(tempModifyParam).replaceAll(",", ";");
                //remet les , au lieu des ; à chaque changement de tableaux
                tempModifyParamString = tempModifyParamString.replaceAll("];", "],");
                let data = '{"operation":"modifyParam", "data":' + tempModifyParamString + '}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }
        }
    }

    const submitDevice3 = (value) => {
        if(value.device3 === "0" || (value.device3.length === 12 && /^[0-9A-F]+$/.test(value.device3))){
            if(modifyParam === "" || modifyParam === null){
                let data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 1302:' + value.device3 + '"]}}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }else{
                let tempModifyParam = modifyParam;
                if(Object.keys(modifyParam).includes(device.identifier)){
                    let tempValue = modifyParam[device.identifier];
                    let arrayValue = [...tempValue];
                    //chercher dans le tableau si le paramètre sse trouve déjà là
                    arrayValue.forEach((element, index)=> {
                        //si on trouve un "setparam"
                        if(element.includes("setparam")){
                            let findParam = false;
                            let elementToModify = element.split(" ");
                            let contentToModify = elementToModify[1].split(";");
                            let tempModifiedParameter = 0;
                            //si le paramètre devrais déjà être modifier, on le cherche et on le modifie
                            contentToModify.forEach((parameter, index)=> {
                                if(parameter.substring(0,4) === "1302"){
                                    findParam = true;
                                    tempModifiedParameter = index;
                                }
                            });
                            //sinon on l'ajoute à la chaîne
                            if(findParam === false){
                                arrayValue[index] = element + ";1302:" + value.device3;
                            }else {
                                //s'il a été modifié, on refais l'array complet
                                arrayValue[index] = elementToModify[0] + " " + contentToModify.map((para, id)=>{
                                    if(id === tempModifiedParameter){
                                        return "1302:" + value.device3;
                                    }else {
                                        return para;
                                    }
                                });
                            }
                            //si on n'a pas encore de setparam, on en ajoute un
                        }else {
                            arrayValue.push("setparam 1302:" + value.device3);
                        }
                    })
                    tempModifyParam[device.identifier] = arrayValue;
                }else {
                    tempModifyParam[device.identifier] = ["setparam 1302:" + value.device3]
                }
                let tempModifyParamString = JSON.stringify(tempModifyParam).replaceAll(",", ";");
                //remet les , au lieu des ; à chaque changement de tableaux
                tempModifyParamString = tempModifyParamString.replaceAll("];", "],");
                let data = '{"operation":"modifyParam", "data":' + tempModifyParamString + '}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }
        }

    }
    const submitDevice4 = (value) => {
        if(value.device4 === "0" || (value.device4.length === 12 && /^[0-9A-F]+$/.test(value.device4))){
            if(modifyParam === "" || modifyParam === null){
                let data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 1352:' + value.device4 + '"]}}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }else{
                let tempModifyParam = modifyParam;
                if(Object.keys(modifyParam).includes(device.identifier)){
                    let tempValue = modifyParam[device.identifier];
                    let arrayValue = [...tempValue];
                    //chercher dans le tableau si le paramètre sse trouve déjà là
                    arrayValue.forEach((element, index)=> {
                        //si on trouve un "setparam"
                        if(element.includes("setparam")){
                            let findParam = false;
                            let elementToModify = element.split(" ");
                            let contentToModify = elementToModify[1].split(";");
                            let tempModifiedParameter = 0;
                            //si le paramètre devrais déjà être modifier, on le cherche et on le modifie
                            contentToModify.forEach((parameter, index)=> {
                                if(parameter.substring(0,4) === "1352"){
                                    findParam = true;
                                    tempModifiedParameter = index;
                                }
                            });
                            //sinon on l'ajoute à la chaîne
                            if(findParam === false){
                                arrayValue[index] = element + ";1352:" + value.device4;
                            }else {
                                //s'il a été modifié, on refais l'array complet
                                arrayValue[index] = elementToModify[0] + " " + contentToModify.map((para, id)=>{
                                    if(id === tempModifiedParameter){
                                        return "1352:" + value.device4;
                                    }else {
                                        return para;
                                    }
                                });
                            }
                            //si on n'a pas encore de setparam, on en ajoute un
                        }else {
                            arrayValue.push("setparam 1352:" + value.device4);
                        }
                    })
                    tempModifyParam[device.identifier] = arrayValue;
                }else {
                    tempModifyParam[device.identifier] = ["setparam 1352:" + value.device4]
                }
                let tempModifyParamString = JSON.stringify(tempModifyParam).replaceAll(",", ";");
                //remet les , au lieu des ; à chaque changement de tableaux
                tempModifyParamString = tempModifyParamString.replaceAll("];", "],");
                let data = '{"operation":"modifyParam", "data":' + tempModifyParamString + '}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }
        }
    }

    const submitPreset1 = (value) => {
        if(value.preset1 !== ""){
            if(modifyParam === "" || modifyParam === null){
                let data = "";
                if(value.preset1 === "g"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:FE;14008:FE;14009:;14010:8;14011:10;14012:12;14013:20;14014:22;14015:25;14016:14;14017:16;14018:18;14019:0;14020:2;14021:2;14022:2;14023:2;14024:1;14025:6;14026:2;14027:2;14028:2;14029:0;14030:1;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:1;14038:1;14039:0;14040:1;14041:3;14042:10;14043:9;14044:8;14045:4;14046:11;14047:5;14048:7;14049:0;14050:;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:0.1;14073:0.03125;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:5000;14083:1600;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0"]}}';
                }
                if(value.preset1 === "o"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:0;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:3;14025:6;14026:0;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:0;14037:0;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:10;14045:4;14046:0;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:0.001953;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0"]}}';
                }
                if(value.preset1 === "oct"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:;14006:;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:40;14014:8;14015:0;14016:0;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:2;14024:6;14025:0;14026:0;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:0;14036:0;14037:0;14038:0;14039:0;14040:0;14041:2;14042:8;14043:10;14044:4;14045:0;14046:0;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:1;14065:0;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:1;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0"]}}';
                }
                if(value.preset1 === "oco"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:FE;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:48;14017:4;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:3;14025:6;14026:2;14027:1;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:1;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:10;14045:7;14046:11;14047:5;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:0.001953;14075:1;14076:0.5;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0"]}}';
                }
                if(value.preset1 === "ovoc"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:3;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:2;14025:6;14026:1;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:0;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:9;14045:7;14046:5;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0"]}}';
                }
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }else{
                let tempModifyParam = modifyParam;
                if(Object.keys(modifyParam).includes(device.identifier)){
                    let tempValue = modifyParam[device.identifier];
                    let arrayValue = [...tempValue];
                    //chercher dans le tableau si le paramètre sse trouve déjà là
                    arrayValue.forEach((element, index)=> {
                        //si on trouve un "setparam"
                        if(element.includes("setparam")){
                            let findParam = false;
                            let elementToModify = element.split(" ");
                            let contentToModify = elementToModify[1].split(";");
                            let tempModifiedParameter = 0;
                            //si le paramètre devrais déjà être modifier, on le cherche et on le modifie
                            contentToModify.forEach((parameter, index)=> {
                                if(parameter.substring(0,5) === "14000"){
                                    findParam = true;
                                    tempModifiedParameter = index;
                                }
                            });
                            //sinon on l'ajoute à la chaîne
                            if(findParam === false){
                                if(value.preset1 === "g"){
                                    arrayValue[index] = element + ";14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:FE;14008:FE;14009:;14010:8;14011:10;14012:12;14013:20;14014:22;14015:25;14016:14;14017:16;14018:18;14019:0;14020:2;14021:2;14022:2;14023:2;14024:1;14025:6;14026:2;14027:2;14028:2;14029:0;14030:1;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:1;14038:1;14039:0;14040:1;14041:3;14042:10;14043:9;14044:8;14045:4;14046:11;14047:5;14048:7;14049:0;14050:;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:0.1;14073:0.03125;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:5000;14083:1600;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0";
                                }
                                if(value.preset1 === "o"){
                                    arrayValue[index] = element + ";14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:0;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:3;14025:6;14026:0;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:0;14037:0;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:10;14045:4;14046:0;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:0.001953;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0";
                                }
                                if(value.preset1 === "oct"){
                                    arrayValue[index] = element + ";14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:;14006:;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:40;14014:8;14015:0;14016:0;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:2;14024:6;14025:0;14026:0;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:0;14036:0;14037:0;14038:0;14039:0;14040:0;14041:2;14042:8;14043:10;14044:4;14045:0;14046:0;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:1;14065:0;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:1;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0";
                                }
                                if(value.preset1 === "oco"){
                                    arrayValue[index] = element + ";14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:FE;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:48;14017:4;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:3;14025:6;14026:2;14027:1;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:1;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:10;14045:7;14046:11;14047:5;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:0.001953;14075:1;14076:0.5;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0";
                                }
                                if(value.preset1 === "ovoc"){
                                    arrayValue[index] = element + ";14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:3;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:2;14025:6;14026:1;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:0;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:9;14045:7;14046:5;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0";
                                }
                            }else {
                                //s'il a été modifié, on refais l'array complet
                                arrayValue[index] = elementToModify[0] + " " + contentToModify.map((para, id)=>{
                                    if(id === tempModifiedParameter){
                                        if(value.preset1 === "g"){
                                            return "14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:FE;14008:FE;14009:;14010:8;14011:10;14012:12;14013:20;14014:22;14015:25;14016:14;14017:16;14018:18;14019:0;14020:2;14021:2;14022:2;14023:2;14024:1;14025:6;14026:2;14027:2;14028:2;14029:0;14030:1;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:1;14038:1;14039:0;14040:1;14041:3;14042:10;14043:9;14044:8;14045:4;14046:11;14047:5;14048:7;14049:0;14050:;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:0.1;14073:0.03125;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:5000;14083:1600;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0";
                                        }
                                        if(value.preset1 === "o"){
                                            return "14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:0;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:3;14025:6;14026:0;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:0;14037:0;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:10;14045:4;14046:0;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:0.001953;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0";
                                        }
                                        if(value.preset1 === "oct"){
                                            return "14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:;14006:;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:40;14014:8;14015:0;14016:0;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:2;14024:6;14025:0;14026:0;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:0;14036:0;14037:0;14038:0;14039:0;14040:0;14041:2;14042:8;14043:10;14044:4;14045:0;14046:0;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:1;14065:0;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:1;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0";
                                        }
                                        if(value.preset1 === "oco"){
                                            return "14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:FE;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:48;14017:4;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:3;14025:6;14026:2;14027:1;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:1;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:10;14045:7;14046:11;14047:5;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:0.001953;14075:1;14076:0.5;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0";
                                        }
                                        if(value.preset1 === "ovoc"){
                                            return "14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:3;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:2;14025:6;14026:1;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:0;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:9;14045:7;14046:5;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0";
                                        }
                                    }
                                    return para;
                                });
                            }
                            //si on n'a pas encore de setparam, on en ajoute un
                        }else {
                            if(value.preset1 === "g"){
                                arrayValue.push("setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:FE;14008:FE;14009:;14010:8;14011:10;14012:12;14013:20;14014:22;14015:25;14016:14;14017:16;14018:18;14019:0;14020:2;14021:2;14022:2;14023:2;14024:1;14025:6;14026:2;14027:2;14028:2;14029:0;14030:1;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:1;14038:1;14039:0;14040:1;14041:3;14042:10;14043:9;14044:8;14045:4;14046:11;14047:5;14048:7;14049:0;14050:;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:0.1;14073:0.03125;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:5000;14083:1600;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0");
                            }
                            if(value.preset1 === "o"){
                                arrayValue.push("setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:0;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:3;14025:6;14026:0;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:0;14037:0;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:10;14045:4;14046:0;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:0.001953;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0");
                            }
                            if(value.preset1 === "oct"){
                                arrayValue.push("setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:;14006:;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:40;14014:8;14015:0;14016:0;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:2;14024:6;14025:0;14026:0;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:0;14036:0;14037:0;14038:0;14039:0;14040:0;14041:2;14042:8;14043:10;14044:4;14045:0;14046:0;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:1;14065:0;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:1;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0");
                            }
                            if(value.preset1 === "oco"){
                                arrayValue.push("setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:FE;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:48;14017:4;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:3;14025:6;14026:2;14027:1;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:1;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:10;14045:7;14046:11;14047:5;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:0.001953;14075:1;14076:0.5;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0");
                            }
                            if(value.preset1 === "ovoc"){
                                arrayValue.push("setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:3;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:2;14025:6;14026:1;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:0;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:9;14045:7;14046:5;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0");
                            }
                        }
                    })
                    tempModifyParam[device.identifier] = arrayValue;
                }else {
                    if(value.preset1 === "g"){
                        tempModifyParam[device.identifier] = ["setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:FE;14008:FE;14009:;14010:8;14011:10;14012:12;14013:20;14014:22;14015:25;14016:14;14017:16;14018:18;14019:0;14020:2;14021:2;14022:2;14023:2;14024:1;14025:6;14026:2;14027:2;14028:2;14029:0;14030:1;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:1;14038:1;14039:0;14040:1;14041:3;14042:10;14043:9;14044:8;14045:4;14046:11;14047:5;14048:7;14049:0;14050:;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:0.1;14073:0.03125;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:5000;14083:1600;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0"];
                    }
                    if(value.preset1 === "o"){
                        tempModifyParam[device.identifier] = ["setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:0;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:3;14025:6;14026:0;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:0;14037:0;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:10;14045:4;14046:0;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:0.001953;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0"];
                    }
                    if(value.preset1 === "oct"){
                        tempModifyParam[device.identifier] = ["setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:;14006:;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:40;14014:8;14015:0;14016:0;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:2;14024:6;14025:0;14026:0;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:0;14036:0;14037:0;14038:0;14039:0;14040:0;14041:2;14042:8;14043:10;14044:4;14045:0;14046:0;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:1;14065:0;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:1;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0"];
                    }
                    if(value.preset1 === "oco"){
                        tempModifyParam[device.identifier] = ["setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:FE;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:48;14017:4;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:3;14025:6;14026:2;14027:1;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:1;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:10;14045:7;14046:11;14047:5;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:0.001953;14075:1;14076:0.5;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0"];
                    }
                    if(value.preset1 === "ovoc"){
                        tempModifyParam[device.identifier] = ["setparam 14000:FE;14001:FE;14002:FE;14003:FE;14004:FE;14005:FE;14006:FE;14007:;14008:;14009:;14010:0;14011:16;14012:36;14013:41;14014:44;14015:8;14016:3;14017:0;14018:0;14019:0;14020:2;14021:1;14022:2;14023:1;14024:2;14025:6;14026:1;14027:0;14028:0;14029:0;14030:0;14031:1;14032:1;14033:1;14034:1;14035:1;14036:1;14037:0;14038:0;14039:0;14040:0;14041:2;14042:1;14043:3;14044:9;14045:7;14046:5;14047:0;14048:0;14049:0;14050:0201;14060:0;14061:0;14062:0;14063:0;14064:0;14065:1;14066:0;14067:0;14068:0;14069:0;14070:1;14071:1;14072:1;14073:50;14074:1;14075:1;14076:1;14077:1;14078:1;14079:1;14080:0;14081:0;14082:0;14083:0;14084:0;14085:0;14086:0;14087:0;14088:0;14089:0"];
                    }
                }
                let tempModifyParamString = JSON.stringify(tempModifyParam).replaceAll(",", ";");
                //remet les , au lieu des ; à chaque changement de tableaux
                tempModifyParamString = tempModifyParamString.replaceAll("];", "],");
                let data = '{"operation":"modifyParam", "data":' + tempModifyParamString + '}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }
        }
    }

    const submitPreset2 = (value) => {
        if(value.preset2 !== ""){
            if(modifyParam === "" || modifyParam === null){
                let data = "";
                if(value.preset2 === "g"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:FE;14208:FE;14209:;14210:8;14211:10;14212:12;14213:20;14214:22;14215:25;14216:14;14217:16;14218:18;14219:0;14220:2;14221:2;14222:2;14223:2;14224:1;14225:6;14226:2;14227:2;14228:2;14229:0;14230:1;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:1;14238:1;14239:0;14240:1;14241:3;14242:10;14243:9;14244:8;14245:4;14246:11;14247:5;14248:7;14249:0;14250:;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:0.1;14273:0.03125;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:5000;14283:1600;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0"]}}';
                }
                if(value.preset2 === "o"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:0;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:3;14225:6;14226:0;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:0;14237:0;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:10;14245:4;14246:0;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:0.001953;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0"]}}';
                }
                if(value.preset2 === "oct"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:;14206:;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:40;14214:8;14215:0;14216:0;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:2;14224:6;14225:0;14226:0;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:0;14236:0;14237:0;14238:0;14239:0;14240:0;14241:2;14242:8;14243:10;14244:4;14245:0;14246:0;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:1;14265:0;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:1;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0"]}}';
                }
                if(value.preset2 === "oco"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:FE;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:48;14217:4;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:3;14225:6;14226:2;14227:1;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:1;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:10;14245:7;14246:11;14247:5;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:0.001953;14275:1;14276:0.5;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0"]}}';
                }
                if(value.preset2 === "ovoc"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:3;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:2;14225:6;14226:1;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:0;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:9;14245:7;14246:5;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0"]}}';
                }
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }else{
                let tempModifyParam = modifyParam;
                if(Object.keys(modifyParam).includes(device.identifier)){
                    let tempValue = modifyParam[device.identifier];
                    let arrayValue = [...tempValue];
                    //chercher dans le tableau si le paramètre sse trouve déjà là
                    arrayValue.forEach((element, index)=> {
                        //si on trouve un "setparam"
                        if(element.includes("setparam")){
                            let findParam = false;
                            let elementToModify = element.split(" ");
                            let contentToModify = elementToModify[1].split(";");
                            let tempModifiedParameter = 0;
                            //si le paramètre devrais déjà être modifier, on le cherche et on le modifie
                            contentToModify.forEach((parameter, index)=> {
                                if(parameter.substring(0,5) === "14200"){
                                    findParam = true;
                                    tempModifiedParameter = index;
                                }
                            });
                            //sinon on l'ajoute à la chaîne
                            if(findParam === false){
                                if(value.preset2 === "g"){
                                    arrayValue[index] = element + ";14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:FE;14208:FE;14209:;14210:8;14211:10;14212:12;14213:20;14214:22;14215:25;14216:14;14217:16;14218:18;14219:0;14220:2;14221:2;14222:2;14223:2;14224:1;14225:6;14226:2;14227:2;14228:2;14229:0;14230:1;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:1;14238:1;14239:0;14240:1;14241:3;14242:10;14243:9;14244:8;14245:4;14246:11;14247:5;14248:7;14249:0;14250:;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:0.1;14273:0.03125;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:5000;14283:1600;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0";
                                }
                                if(value.preset2 === "o"){
                                    arrayValue[index] = element + ";14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:0;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:3;14225:6;14226:0;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:0;14237:0;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:10;14245:4;14246:0;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:0.001953;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0";
                                }
                                if(value.preset2 === "oct"){
                                    arrayValue[index] = element + ";14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:;14206:;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:40;14214:8;14215:0;14216:0;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:2;14224:6;14225:0;14226:0;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:0;14236:0;14237:0;14238:0;14239:0;14240:0;14241:2;14242:8;14243:10;14244:4;14245:0;14246:0;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:1;14265:0;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:1;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0";
                                }
                                if(value.preset2 === "oco"){
                                    arrayValue[index] = element + ";14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:FE;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:48;14217:4;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:3;14225:6;14226:2;14227:1;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:1;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:10;14245:7;14246:11;14247:5;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:0.001953;14275:1;14276:0.5;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0";
                                }
                                if(value.preset2 === "ovoc"){
                                    arrayValue[index] = element + ";14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:3;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:2;14225:6;14226:1;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:0;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:9;14245:7;14246:5;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0";
                                }
                            }else {
                                //s'il a été modifié, on refais l'array complet
                                arrayValue[index] = elementToModify[0] + " " + contentToModify.map((para, id)=>{
                                    if(id === tempModifiedParameter){
                                        if(value.preset2 === "g"){
                                            return "14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:FE;14208:FE;14209:;14210:8;14211:10;14212:12;14213:20;14214:22;14215:25;14216:14;14217:16;14218:18;14219:0;14220:2;14221:2;14222:2;14223:2;14224:1;14225:6;14226:2;14227:2;14228:2;14229:0;14230:1;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:1;14238:1;14239:0;14240:1;14241:3;14242:10;14243:9;14244:8;14245:4;14246:11;14247:5;14248:7;14249:0;14250:;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:0.1;14273:0.03125;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:5000;14283:1600;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0";
                                        }
                                        if(value.preset2 === "o"){
                                            return "14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:0;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:3;14225:6;14226:0;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:0;14237:0;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:10;14245:4;14246:0;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:0.001953;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0";
                                        }
                                        if(value.preset2 === "oct"){
                                            return "14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:;14206:;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:40;14214:8;14215:0;14216:0;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:2;14224:6;14225:0;14226:0;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:0;14236:0;14237:0;14238:0;14239:0;14240:0;14241:2;14242:8;14243:10;14244:4;14245:0;14246:0;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:1;14265:0;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:1;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0";
                                        }
                                        if(value.preset2 === "oco"){
                                            return "14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:FE;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:48;14217:4;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:3;14225:6;14226:2;14227:1;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:1;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:10;14245:7;14246:11;14247:5;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:0.001953;14275:1;14276:0.5;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0";
                                        }
                                        if(value.preset2 === "ovoc"){
                                            return "14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:3;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:2;14225:6;14226:1;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:0;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:9;14245:7;14246:5;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0";
                                        }
                                    }
                                    return para;
                                });
                            }
                            //si on n'a pas encore de setparam, on en ajoute un
                        }else {
                            if(value.preset2 === "g"){
                                arrayValue.push("setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:FE;14208:FE;14209:;14210:8;14211:10;14212:12;14213:20;14214:22;14215:25;14216:14;14217:16;14218:18;14219:0;14220:2;14221:2;14222:2;14223:2;14224:1;14225:6;14226:2;14227:2;14228:2;14229:0;14230:1;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:1;14238:1;14239:0;14240:1;14241:3;14242:10;14243:9;14244:8;14245:4;14246:11;14247:5;14248:7;14249:0;14250:;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:0.1;14273:0.03125;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:5000;14283:1600;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0");
                            }
                            if(value.preset2 === "o"){
                                arrayValue.push("setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:0;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:3;14225:6;14226:0;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:0;14237:0;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:10;14245:4;14246:0;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:0.001953;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0");
                            }
                            if(value.preset2 === "oct"){
                                arrayValue.push("setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:;14206:;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:40;14214:8;14215:0;14216:0;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:2;14224:6;14225:0;14226:0;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:0;14236:0;14237:0;14238:0;14239:0;14240:0;14241:2;14242:8;14243:10;14244:4;14245:0;14246:0;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:1;14265:0;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:1;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0");
                            }
                            if(value.preset2 === "oco"){
                                arrayValue.push("setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:FE;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:48;14217:4;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:3;14225:6;14226:2;14227:1;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:1;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:10;14245:7;14246:11;14247:5;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:0.001953;14275:1;14276:0.5;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0");
                            }
                            if(value.preset2 === "ovoc"){
                                arrayValue.push("setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:3;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:2;14225:6;14226:1;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:0;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:9;14245:7;14246:5;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0");
                            }
                        }
                    })
                    tempModifyParam[device.identifier] = arrayValue;
                }else {
                    if(value.preset2 === "g"){
                        tempModifyParam[device.identifier] = ["setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:FE;14208:FE;14209:;14210:8;14211:10;14212:12;14213:20;14214:22;14215:25;14216:14;14217:16;14218:18;14219:0;14220:2;14221:2;14222:2;14223:2;14224:1;14225:6;14226:2;14227:2;14228:2;14229:0;14230:1;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:1;14238:1;14239:0;14240:1;14241:3;14242:10;14243:9;14244:8;14245:4;14246:11;14247:5;14248:7;14249:0;14250:;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:0.1;14273:0.03125;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:5000;14283:1600;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0"];
                    }
                    if(value.preset2 === "o"){
                        tempModifyParam[device.identifier] = ["setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:0;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:3;14225:6;14226:0;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:0;14237:0;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:10;14245:4;14246:0;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:0.001953;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0"];
                    }
                    if(value.preset2 === "oct"){
                        tempModifyParam[device.identifier] = ["setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:;14206:;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:40;14214:8;14215:0;14216:0;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:2;14224:6;14225:0;14226:0;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:0;14236:0;14237:0;14238:0;14239:0;14240:0;14241:2;14242:8;14243:10;14244:4;14245:0;14246:0;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:1;14265:0;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:1;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0"];
                    }
                    if(value.preset2 === "oco"){
                        tempModifyParam[device.identifier] = ["setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:FE;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:48;14217:4;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:3;14225:6;14226:2;14227:1;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:1;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:10;14245:7;14246:11;14247:5;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:0.001953;14275:1;14276:0.5;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0"];
                    }
                    if(value.preset2 === "ovoc"){
                        tempModifyParam[device.identifier] = ["setparam 14200:FE;14201:FE;14202:FE;14203:FE;14204:FE;14205:FE;14206:FE;14207:;14208:;14209:;14210:0;14211:16;14212:36;14213:41;14214:44;14215:8;14216:3;14217:0;14218:0;14219:0;14220:2;14221:1;14222:2;14223:1;14224:2;14225:6;14226:1;14227:0;14228:0;14229:0;14230:0;14231:1;14232:1;14233:1;14234:1;14235:1;14236:1;14237:0;14238:0;14239:0;14240:0;14241:2;14242:1;14243:3;14244:9;14245:7;14246:5;14247:0;14248:0;14249:0;14250:0201;14260:0;14261:0;14262:0;14263:0;14264:0;14265:1;14266:0;14267:0;14268:0;14269:0;14270:1;14271:1;14272:1;14273:50;14274:1;14275:1;14276:1;14277:1;14278:1;14279:1;14280:0;14281:0;14282:0;14283:0;14284:0;14285:0;14286:0;14287:0;14288:0;14289:0"];
                    }
                }
                let tempModifyParamString = JSON.stringify(tempModifyParam).replaceAll(",", ";");
                //remet les , au lieu des ; à chaque changement de tableaux
                tempModifyParamString = tempModifyParamString.replaceAll("];", "],");
                let data = '{"operation":"modifyParam", "data":' + tempModifyParamString + '}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }
        }
    }

    const submitPreset3 = (value) => {
        if(value.preset3 !== ""){
            if(modifyParam === "" || modifyParam === null){
                let data = "";
                if(value.preset3 === "g"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:FE;14408:FE;14409:;14410:8;14411:10;14412:12;14413:20;14414:22;14415:25;14416:14;14417:16;14418:18;14419:0;14420:2;14421:2;14422:2;14423:2;14424:1;14425:6;14426:2;14427:2;14428:2;14429:0;14430:1;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:1;14438:1;14439:0;14440:1;14441:3;14442:10;14443:9;14444:8;14445:4;14446:11;14447:5;14448:7;14449:0;14450:;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:0.1;14473:0.03125;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:5000;14483:1600;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0"]}}';
                }
                if(value.preset3 === "o"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:0;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:3;14425:6;14426:0;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:0;14437:0;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:10;14445:4;14446:0;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:0.001953;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0"]}}';
                }
                if(value.preset3 === "oct"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:;14406:;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:40;14414:8;14415:0;14416:0;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:2;14424:6;14425:0;14426:0;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:0;14436:0;14437:0;14438:0;14439:0;14440:0;14441:2;14442:8;14443:10;14444:4;14445:0;14446:0;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:1;14465:0;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:1;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0"]}}';
                }
                if(value.preset3 === "oco"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:FE;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:48;14417:4;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:3;14425:6;14426:2;14427:1;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:1;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:10;14445:7;14446:11;14447:5;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:0.001953;14475:1;14476:0.5;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0"]}}';
                }
                if(value.preset3 === "ovoc"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:3;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:2;14425:6;14426:1;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:0;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:9;14445:7;14446:5;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0"]}}';
                }
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }else{
                let tempModifyParam = modifyParam;
                if(Object.keys(modifyParam).includes(device.identifier)){
                    let tempValue = modifyParam[device.identifier];
                    let arrayValue = [...tempValue];
                    //chercher dans le tableau si le paramètre sse trouve déjà là
                    arrayValue.forEach((element, index)=> {
                        //si on trouve un "setparam"
                        if(element.includes("setparam")){
                            let findParam = false;
                            let elementToModify = element.split(" ");
                            let contentToModify = elementToModify[1].split(";");
                            let tempModifiedParameter = 0;
                            //si le paramètre devrais déjà être modifier, on le cherche et on le modifie
                            contentToModify.forEach((parameter, index)=> {
                                if(parameter.substring(0,5) === "14400"){
                                    findParam = true;
                                    tempModifiedParameter = index;
                                }
                            });
                            //sinon on l'ajoute à la chaîne
                            if(findParam === false){
                                if(value.preset3 === "g"){
                                    arrayValue[index] = element + ";14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:FE;14408:FE;14409:;14410:8;14411:10;14412:12;14413:20;14414:22;14415:25;14416:14;14417:16;14418:18;14419:0;14420:2;14421:2;14422:2;14423:2;14424:1;14425:6;14426:2;14427:2;14428:2;14429:0;14430:1;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:1;14438:1;14439:0;14440:1;14441:3;14442:10;14443:9;14444:8;14445:4;14446:11;14447:5;14448:7;14449:0;14450:;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:0.1;14473:0.03125;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:5000;14483:1600;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0";
                                }
                                if(value.preset3 === "o"){
                                    arrayValue[index] = element + ";14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:0;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:3;14425:6;14426:0;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:0;14437:0;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:10;14445:4;14446:0;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:0.001953;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0";
                                }
                                if(value.preset3 === "oct"){
                                    arrayValue[index] = element + ";14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:;14406:;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:40;14414:8;14415:0;14416:0;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:2;14424:6;14425:0;14426:0;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:0;14436:0;14437:0;14438:0;14439:0;14440:0;14441:2;14442:8;14443:10;14444:4;14445:0;14446:0;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:1;14465:0;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:1;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0";
                                }
                                if(value.preset3 === "oco"){
                                    arrayValue[index] = element + ";14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:FE;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:48;14417:4;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:3;14425:6;14426:2;14427:1;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:1;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:10;14445:7;14446:11;14447:5;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:0.001953;14475:1;14476:0.5;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0";
                                }
                                if(value.preset3 === "ovoc"){
                                    arrayValue[index] = element + ";14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:3;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:2;14425:6;14426:1;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:0;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:9;14445:7;14446:5;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0";
                                }
                            }else {
                                //s'il a été modifié, on refais l'array complet
                                arrayValue[index] = elementToModify[0] + " " + contentToModify.map((para, id)=>{
                                    if(id === tempModifiedParameter){
                                        if(value.preset3 === "g"){
                                            return "14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:FE;14408:FE;14409:;14410:8;14411:10;14412:12;14413:20;14414:22;14415:25;14416:14;14417:16;14418:18;14419:0;14420:2;14421:2;14422:2;14423:2;14424:1;14425:6;14426:2;14427:2;14428:2;14429:0;14430:1;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:1;14438:1;14439:0;14440:1;14441:3;14442:10;14443:9;14444:8;14445:4;14446:11;14447:5;14448:7;14449:0;14450:;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:0.1;14473:0.03125;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:5000;14483:1600;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0";
                                        }
                                        if(value.preset3 === "o"){
                                            return "14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:0;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:3;14425:6;14426:0;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:0;14437:0;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:10;14445:4;14446:0;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:0.001953;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0";
                                        }
                                        if(value.preset3 === "oct"){
                                            return "14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:;14406:;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:40;14414:8;14415:0;14416:0;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:2;14424:6;14425:0;14426:0;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:0;14436:0;14437:0;14438:0;14439:0;14440:0;14441:2;14442:8;14443:10;14444:4;14445:0;14446:0;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:1;14465:0;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:1;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0";
                                        }
                                        if(value.preset3 === "oco"){
                                            return "14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:FE;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:48;14417:4;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:3;14425:6;14426:2;14427:1;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:1;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:10;14445:7;14446:11;14447:5;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:0.001953;14475:1;14476:0.5;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0";
                                        }
                                        if(value.preset3 === "ovoc"){
                                            return "14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:3;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:2;14425:6;14426:1;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:0;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:9;14445:7;14446:5;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0";
                                        }
                                    }
                                    return para;
                                });
                            }
                            //si on n'a pas encore de setparam, on en ajoute un
                        }else {
                            if(value.preset3 === "g"){
                                arrayValue.push("setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:FE;14408:FE;14409:;14410:8;14411:10;14412:12;14413:20;14414:22;14415:25;14416:14;14417:16;14418:18;14419:0;14420:2;14421:2;14422:2;14423:2;14424:1;14425:6;14426:2;14427:2;14428:2;14429:0;14430:1;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:1;14438:1;14439:0;14440:1;14441:3;14442:10;14443:9;14444:8;14445:4;14446:11;14447:5;14448:7;14449:0;14450:;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:0.1;14473:0.03125;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:5000;14483:1600;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0");
                            }
                            if(value.preset3 === "o"){
                                arrayValue.push("setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:0;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:3;14425:6;14426:0;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:0;14437:0;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:10;14445:4;14446:0;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:0.001953;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0");
                            }
                            if(value.preset3 === "oct"){
                                arrayValue.push("setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:;14406:;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:40;14414:8;14415:0;14416:0;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:2;14424:6;14425:0;14426:0;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:0;14436:0;14437:0;14438:0;14439:0;14440:0;14441:2;14442:8;14443:10;14444:4;14445:0;14446:0;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:1;14465:0;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:1;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0");
                            }
                            if(value.preset3 === "oco"){
                                arrayValue.push("setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:FE;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:48;14417:4;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:3;14425:6;14426:2;14427:1;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:1;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:10;14445:7;14446:11;14447:5;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:0.001953;14475:1;14476:0.5;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0");
                            }
                            if(value.preset3 === "ovoc"){
                                arrayValue.push("setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:3;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:2;14425:6;14426:1;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:0;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:9;14445:7;14446:5;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0");
                            }
                        }
                    })
                    tempModifyParam[device.identifier] = arrayValue;
                }else {
                    if(value.preset3 === "g"){
                        tempModifyParam[device.identifier] = ["setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:FE;14408:FE;14409:;14410:8;14411:10;14412:12;14413:20;14414:22;14415:25;14416:14;14417:16;14418:18;14419:0;14420:2;14421:2;14422:2;14423:2;14424:1;14425:6;14426:2;14427:2;14428:2;14429:0;14430:1;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:1;14438:1;14439:0;14440:1;14441:3;14442:10;14443:9;14444:8;14445:4;14446:11;14447:5;14448:7;14449:0;14450:;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:0.1;14473:0.03125;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:5000;14483:1600;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0"];
                    }
                    if(value.preset3 === "o"){
                        tempModifyParam[device.identifier] = ["setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:0;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:3;14425:6;14426:0;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:0;14437:0;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:10;14445:4;14446:0;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:0.001953;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0"];
                    }
                    if(value.preset3 === "oct"){
                        tempModifyParam[device.identifier] = ["setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:;14406:;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:40;14414:8;14415:0;14416:0;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:2;14424:6;14425:0;14426:0;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:0;14436:0;14437:0;14438:0;14439:0;14440:0;14441:2;14442:8;14443:10;14444:4;14445:0;14446:0;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:1;14465:0;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:1;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0"];
                    }
                    if(value.preset3 === "oco"){
                        tempModifyParam[device.identifier] = ["setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:FE;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:48;14417:4;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:3;14425:6;14426:2;14427:1;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:1;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:10;14445:7;14446:11;14447:5;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:0.001953;14475:1;14476:0.5;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0"];
                    }
                    if(value.preset3 === "ovoc"){
                        tempModifyParam[device.identifier] = ["setparam 14400:FE;14401:FE;14402:FE;14403:FE;14404:FE;14405:FE;14406:FE;14407:;14408:;14409:;14410:0;14411:16;14412:36;14413:41;14414:44;14415:8;14416:3;14417:0;14418:0;14419:0;14420:2;14421:1;14422:2;14423:1;14424:2;14425:6;14426:1;14427:0;14428:0;14429:0;14430:0;14431:1;14432:1;14433:1;14434:1;14435:1;14436:1;14437:0;14438:0;14439:0;14440:0;14441:2;14442:1;14443:3;14444:9;14445:7;14446:5;14447:0;14448:0;14449:0;14450:0201;14460:0;14461:0;14462:0;14463:0;14464:0;14465:1;14466:0;14467:0;14468:0;14469:0;14470:1;14471:1;14472:1;14473:50;14474:1;14475:1;14476:1;14477:1;14478:1;14479:1;14480:0;14481:0;14482:0;14483:0;14484:0;14485:0;14486:0;14487:0;14488:0;14489:0"];
                    }
                }
                let tempModifyParamString = JSON.stringify(tempModifyParam).replaceAll(",", ";");
                //remet les , au lieu des ; à chaque changement de tableaux
                tempModifyParamString = tempModifyParamString.replaceAll("];", "],");
                let data = '{"operation":"modifyParam", "data":' + tempModifyParamString + '}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }
        }
    }

    const submitPreset4 = (value) => {
        if(value.preset4 !== ""){
            if(modifyParam === "" || modifyParam === null){
                let data = "";
                if(value.preset4 === "g"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:FE;14608:FE;14609:;14610:8;14611:10;14612:12;14613:20;14614:22;14615:25;14616:14;14617:16;14618:18;14619:0;14620:2;14621:2;14622:2;14623:2;14624:1;14625:6;14626:2;14627:2;14628:2;14629:0;14630:1;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:1;14638:1;14639:0;14640:1;14641:3;14642:10;14643:9;14644:8;14645:4;14646:11;14647:5;14648:7;14649:0;14650:;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:0.1;14673:0.03125;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:5000;14683:1600;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0"]}}';
                }
                if(value.preset4 === "o"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:0;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:3;14625:6;14626:0;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:0;14637:0;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:10;14645:4;14646:0;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:0.001953;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0"]}}';
                }
                if(value.preset4 === "oct"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:;14606:;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:40;14614:8;14615:0;14616:0;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:2;14624:6;14625:0;14626:0;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:0;14636:0;14637:0;14638:0;14639:0;14640:0;14641:2;14642:8;14643:10;14644:4;14645:0;14646:0;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:1;14665:0;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:1;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0"]}}';
                }
                if(value.preset4 === "oco"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:FE;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:48;14617:4;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:3;14625:6;14626:2;14627:1;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:1;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:10;14645:7;14646:11;14647:5;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:0.001953;14675:1;14676:0.5;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0"]}}';
                }
                if(value.preset4 === "ovoc"){
                    data = '{"operation":"modifyParam", "data":{"'+ device.identifier +'":["setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:3;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:2;14625:6;14626:1;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:0;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:9;14645:7;14646:5;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0"]}}';
                }
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }else{
                let tempModifyParam = modifyParam;
                if(Object.keys(modifyParam).includes(device.identifier)){
                    let tempValue = modifyParam[device.identifier];
                    let arrayValue = [...tempValue];
                    //chercher dans le tableau si le paramètre sse trouve déjà là
                    arrayValue.forEach((element, index)=> {
                        //si on trouve un "setparam"
                        if(element.includes("setparam")){
                            let findParam = false;
                            let elementToModify = element.split(" ");
                            let contentToModify = elementToModify[1].split(";");
                            let tempModifiedParameter = 0;
                            //si le paramètre devrais déjà être modifier, on le cherche et on le modifie
                            contentToModify.forEach((parameter, index)=> {
                                if(parameter.substring(0,5) === "14600"){
                                    findParam = true;
                                    tempModifiedParameter = index;
                                }
                            });
                            //sinon on l'ajoute à la chaîne
                            if(findParam === false){
                                if(value.preset4 === "g"){
                                    arrayValue[index] = element + ";14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:FE;14608:FE;14609:;14610:8;14611:10;14612:12;14613:20;14614:22;14615:25;14616:14;14617:16;14618:18;14619:0;14620:2;14621:2;14622:2;14623:2;14624:1;14625:6;14626:2;14627:2;14628:2;14629:0;14630:1;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:1;14638:1;14639:0;14640:1;14641:3;14642:10;14643:9;14644:8;14645:4;14646:11;14647:5;14648:7;14649:0;14650:;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:0.1;14673:0.03125;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:5000;14683:1600;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0";
                                }
                                if(value.preset4 === "o"){
                                    arrayValue[index] = element + ";14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:0;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:3;14625:6;14626:0;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:0;14637:0;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:10;14645:4;14646:0;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:0.001953;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0";
                                }
                                if(value.preset4 === "oct"){
                                    arrayValue[index] = element + ";14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:;14606:;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:40;14614:8;14615:0;14616:0;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:2;14624:6;14625:0;14626:0;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:0;14636:0;14637:0;14638:0;14639:0;14640:0;14641:2;14642:8;14643:10;14644:4;14645:0;14646:0;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:1;14665:0;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:1;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0";
                                }
                                if(value.preset4 === "oco"){
                                    arrayValue[index] = element + ";14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:FE;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:48;14617:4;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:3;14625:6;14626:2;14627:1;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:1;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:10;14645:7;14646:11;14647:5;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:0.001953;14675:1;14676:0.5;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0";
                                }
                                if(value.preset4 === "ovoc"){
                                    arrayValue[index] = element + ";14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:3;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:2;14625:6;14626:1;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:0;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:9;14645:7;14646:5;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0";
                                }
                            }else {
                                //s'il a été modifié, on refais l'array complet
                                arrayValue[index] = elementToModify[0] + " " + contentToModify.map((para, id)=>{
                                    if(id === tempModifiedParameter){
                                        if(value.preset4 === "g"){
                                            return "14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:FE;14608:FE;14609:;14610:8;14611:10;14612:12;14613:20;14614:22;14615:25;14616:14;14617:16;14618:18;14619:0;14620:2;14621:2;14622:2;14623:2;14624:1;14625:6;14626:2;14627:2;14628:2;14629:0;14630:1;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:1;14638:1;14639:0;14640:1;14641:3;14642:10;14643:9;14644:8;14645:4;14646:11;14647:5;14648:7;14649:0;14650:;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:0.1;14673:0.03125;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:5000;14683:1600;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0";
                                        }
                                        if(value.preset4 === "o"){
                                            return "14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:0;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:3;14625:6;14626:0;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:0;14637:0;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:10;14645:4;14646:0;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:0.001953;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0";
                                        }
                                        if(value.preset4 === "oct"){
                                            return "14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:;14606:;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:40;14614:8;14615:0;14616:0;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:2;14624:6;14625:0;14626:0;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:0;14636:0;14637:0;14638:0;14639:0;14640:0;14641:2;14642:8;14643:10;14644:4;14645:0;14646:0;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:1;14665:0;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:1;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0";
                                        }
                                        if(value.preset4 === "oco"){
                                            return "14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:FE;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:48;14617:4;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:3;14625:6;14626:2;14627:1;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:1;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:10;14645:7;14646:11;14647:5;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:0.001953;14675:1;14676:0.5;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0";
                                        }
                                        if(value.preset4 === "ovoc"){
                                            return "14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:3;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:2;14625:6;14626:1;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:0;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:9;14645:7;14646:5;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0";
                                        }
                                    }
                                    return para;
                                });
                            }
                            //si on n'a pas encore de setparam, on en ajoute un
                        }else {
                            if(value.preset4 === "g"){
                                arrayValue.push("setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:FE;14608:FE;14609:;14610:8;14611:10;14612:12;14613:20;14614:22;14615:25;14616:14;14617:16;14618:18;14619:0;14620:2;14621:2;14622:2;14623:2;14624:1;14625:6;14626:2;14627:2;14628:2;14629:0;14630:1;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:1;14638:1;14639:0;14640:1;14641:3;14642:10;14643:9;14644:8;14645:4;14646:11;14647:5;14648:7;14649:0;14650:;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:0.1;14673:0.03125;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:5000;14683:1600;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0");
                            }
                            if(value.preset4 === "o"){
                                arrayValue.push("setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:0;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:3;14625:6;14626:0;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:0;14637:0;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:10;14645:4;14646:0;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:0.001953;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0");
                            }
                            if(value.preset4 === "oct"){
                                arrayValue.push("setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:;14606:;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:40;14614:8;14615:0;14616:0;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:2;14624:6;14625:0;14626:0;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:0;14636:0;14637:0;14638:0;14639:0;14640:0;14641:2;14642:8;14643:10;14644:4;14645:0;14646:0;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:1;14665:0;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:1;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0");
                            }
                            if(value.preset4 === "oco"){
                                arrayValue.push("setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:FE;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:48;14617:4;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:3;14625:6;14626:2;14627:1;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:1;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:10;14645:7;14646:11;14647:5;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:0.001953;14675:1;14676:0.5;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0");
                            }
                            if(value.preset4 === "ovoc"){
                                arrayValue.push("setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:3;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:2;14625:6;14626:1;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:0;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:9;14645:7;14646:5;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0");
                            }
                        }
                    })
                    tempModifyParam[device.identifier] = arrayValue;
                }else {
                    if(value.preset4 === "g"){
                        tempModifyParam[device.identifier] = ["setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:FE;14608:FE;14609:;14610:8;14611:10;14612:12;14613:20;14614:22;14615:25;14616:14;14617:16;14618:18;14619:0;14620:2;14621:2;14622:2;14623:2;14624:1;14625:6;14626:2;14627:2;14628:2;14629:0;14630:1;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:1;14638:1;14639:0;14640:1;14641:3;14642:10;14643:9;14644:8;14645:4;14646:11;14647:5;14648:7;14649:0;14650:;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:0.1;14673:0.03125;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:5000;14683:1600;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0"];
                    }
                    if(value.preset4 === "o"){
                        tempModifyParam[device.identifier] = ["setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:0;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:3;14625:6;14626:0;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:0;14637:0;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:10;14645:4;14646:0;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:0.001953;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0"];
                    }
                    if(value.preset4 === "oct"){
                        tempModifyParam[device.identifier] = ["setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:;14606:;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:40;14614:8;14615:0;14616:0;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:2;14624:6;14625:0;14626:0;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:0;14636:0;14637:0;14638:0;14639:0;14640:0;14641:2;14642:8;14643:10;14644:4;14645:0;14646:0;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:1;14665:0;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:1;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0"];
                    }
                    if(value.preset4 === "oco"){
                        tempModifyParam[device.identifier] = ["setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:FE;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:48;14617:4;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:3;14625:6;14626:2;14627:1;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:1;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:10;14645:7;14646:11;14647:5;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:0.001953;14675:1;14676:0.5;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0"];
                    }
                    if(value.preset4 === "ovoc"){
                        tempModifyParam[device.identifier] = ["setparam 14600:FE;14601:FE;14602:FE;14603:FE;14604:FE;14605:FE;14606:FE;14607:;14608:;14609:;14610:0;14611:16;14612:36;14613:41;14614:44;14615:8;14616:3;14617:0;14618:0;14619:0;14620:2;14621:1;14622:2;14623:1;14624:2;14625:6;14626:1;14627:0;14628:0;14629:0;14630:0;14631:1;14632:1;14633:1;14634:1;14635:1;14636:1;14637:0;14638:0;14639:0;14640:0;14641:2;14642:1;14643:3;14644:9;14645:7;14646:5;14647:0;14648:0;14649:0;14650:0201;14660:0;14661:0;14662:0;14663:0;14664:0;14665:1;14666:0;14667:0;14668:0;14669:0;14670:1;14671:1;14672:1;14673:50;14674:1;14675:1;14676:1;14677:1;14678:1;14679:1;14680:0;14681:0;14682:0;14683:0;14684:0;14685:0;14686:0;14687:0;14688:0;14689:0"];
                    }
                }
                let tempModifyParamString = JSON.stringify(tempModifyParam).replaceAll(",", ";");
                //remet les , au lieu des ; à chaque changement de tableaux
                tempModifyParamString = tempModifyParamString.replaceAll("];", "],");
                let data = '{"operation":"modifyParam", "data":' + tempModifyParamString + '}';
                mqttRequests.publish(mqttClient, "data/modifyParam", data, 1, true);
            }
        }
    }

    const mustDisplayModifyValue = (param) => {
        let response = false;
        if(Object.keys(modifyParam).includes(device.identifier)){
            modifyParam[device.identifier].forEach((value) => {
                if(value.includes(param + ":")){
                    response = true;
                }
            });
        }
        return response;
    }

    const displayModifyValue = (param) => {
        let response = "";
        let tempValue = modifyParam[device.identifier];
        let arrayValue = [...tempValue];
        //chercher dans le tableau où le paramètre se trouve
        arrayValue.forEach((element, index)=> {
            //si on trouve un "setparam"
            if (element.includes("setparam")) {
                let elementToModify = element.split(" ");
                let contentToModify = elementToModify[1].split(";");
                //si le paramètre devrais déjà être modifier, on le cherche et on le modifie
                contentToModify.forEach((parameter) => {
                    if (parameter.substring(0, 4) === param) {
                        response = parameter.substring(5);
                    }
                });
            }
        });
        return response ;
    }

    const displayModifyValuePreset = (param, param2) => {
        let response = "";
        let alreadyFound = false;
        let tempValue = modifyParam[device.identifier];
        let arrayValue = [...tempValue];
        //chercher dans le tableau où le paramètre se trouve
        arrayValue.forEach((element, index)=> {
            //si on trouve un "setparam"
            if (element.includes("setparam")) {
                let elementToModify = element.split(" ");
                let contentToModify = elementToModify[1].split(";");
                //si le paramètre devrais déjà être modifier, on le cherche et on le modifie
                contentToModify.forEach((parameter) => {
                    if (parameter.substring(0, 5) === param) {
                        if(parameter.substring(6) === "25" && alreadyFound === false){
                            response = "g";
                            alreadyFound = true;
                        } else
                        if(parameter.substring(6) === "8" && alreadyFound === false){
                            response = "o/t";
                            alreadyFound = true;
                        } else
                        if(parameter.substring(6) === "0" && alreadyFound === false){
                            response = "o ct";
                            alreadyFound = true;
                        }
                    }
                    if (parameter.substring(0, 5) === param2) {
                        if(parameter.substring(6) === "48"){
                            response = "o co2";
                            alreadyFound = true;
                        } else
                        if(parameter.substring(6) === "3"){
                            response = "o voc";
                            alreadyFound = true;
                        }
                    }
                });
            }
        });
        return response ;
    }

    //observer to trigger things when the form changes
    const FormObserver: React.FC = () => {
        const { values, setFieldValue } = useFormikContext();
        useEffect(() => {
            setFieldValue('device1', device1);
            setFieldValue('device2', device2);
            setFieldValue('device3', device3);
            setFieldValue('device4', device4);

        }, [device1, device2, device3, device4]);
        return null;
    }

    return (
        <div className="baseMainDiv">
            <h2 className="baseTitle">Parameters for {device.customName}({device.identifier})</h2>
            <div className="deviceDivWarning">
                <p className="devicesTextWaring">Please note that any changes you make to a data logger will only be taken into account the next time the device sends points.</p>
                <p className="devicesTextWaring">Changes may take another 1 or 2 points sends before appearing on this page.</p>
                <p className="devicesTextWaring">If you want to let a device empty, enter "0" for it.</p>
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {}}
            >
                {({values}) => {
                    return(
                        <Form>
                            <FormObserver/>
                            <div className="devicesFlexDivLeft">
                                <FormikControl control="input" type="text" label="First device: " name="device1" style={{border:"2px black solid"}}/>
                                <button type="button" className="devicesButtonNoMargin" onClick={() => submitDevice1(values)}>Modify</button>
                                {mustDisplayModifyValue("1202") ?
                                    <p className="deviceText">Will be modified to "{displayModifyValue("1202")}"</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="devicesFlexDivLeft">
                                <FormikControl control="input" type="text" label="Second device: " name="device2" style={{border:"2px black solid"}}/>
                                <button type="button" className="devicesButtonNoMargin" onClick={() => submitDevice2(values)}>Modify</button>
                                {mustDisplayModifyValue("1252") ?
                                    <p className="deviceText">Will be modified to "{displayModifyValue("1252")}"</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="devicesFlexDivLeft">
                                <FormikControl control="input" type="text" label="Third device: " name="device3" style={{border:"2px black solid"}}/>
                                <button type="button" className="devicesButtonNoMargin" onClick={() => submitDevice3(values)}>Modify</button>
                                {mustDisplayModifyValue("1302") ?
                                    <p className="deviceText">Will be modified to "{displayModifyValue("1302")}"</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="devicesFlexDivLeft">
                                <FormikControl control="input" type="text" label="Fourth device: " name="device4" style={{border:"2px black solid"}}/>
                                <button type="button" className="devicesButtonNoMargin" onClick={() => submitDevice4(values)}>Modify</button>
                                {mustDisplayModifyValue("1352") ?
                                    <p className="deviceText">Will be modified to "{displayModifyValue("1352")}"</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="devicesFlexDivLeft">
                                <FormikControl control="select" label="First preset: " name="preset1" options={selectOptions} style={{border:"2px black solid"}}/>
                                <button type="button" className="devicesButtonNoMargin" onClick={() => submitPreset1(values)}>Modify</button>
                                {mustDisplayModifyValue("14000") ?
                                    <p className="deviceText">Will be modified to "{displayModifyValuePreset("14015", "14016")}"</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="devicesFlexDivLeft">
                                <FormikControl control="select" label="Second preset: " name="preset2" options={selectOptions} style={{border:"2px black solid"}}/>
                                <button type="button" className="devicesButtonNoMargin" onClick={() => submitPreset2(values)}>Modify</button>
                                {mustDisplayModifyValue("14200") ?
                                    <p className="deviceText">Will be modified to "{displayModifyValuePreset("14215", "14216")}"</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="devicesFlexDivLeft">
                                <FormikControl control="select" label="Third preset: " name="preset3" options={selectOptions} style={{border:"2px black solid"}}/>
                                <button type="button" className="devicesButtonNoMargin" onClick={() => submitPreset3(values)}>Modify</button>
                                {mustDisplayModifyValue("14400") ?
                                    <p className="deviceText">Will be modified to "{displayModifyValuePreset("14415", "14416")}"</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="devicesFlexDivLeft">
                                <FormikControl control="select" label="Fourth preset: " name="preset4" options={selectOptions} style={{border:"2px black solid"}}/>
                                <button type="button" className="devicesButtonNoMargin" onClick={() => submitPreset4(values)}>Modify</button>
                                {mustDisplayModifyValue("14600") ?
                                    <p className="deviceText">Will be modified to "{displayModifyValuePreset("14615", "14616")}"</p>
                                    :
                                    null
                                }
                            </div>
                        </Form>
                    )
                }}
            </Formik>


        </div>
    );
}

export default ParametersModifier;
