import React, {FormEvent, useContext, useEffect, useState} from 'react';
import './Mission.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {Alert, Dialog, DialogActions, DialogTitle, IconButton, Snackbar} from "@mui/material";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import FormikControl from "../FormTemplate/FormikControl";
import {red} from "@mui/material/colors";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {SettingsContext} from "../../context/SettingsContext";
import Loading from '../../assets/Loading.png';

//the page to manage missions
function ManageMissions(props) {

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('missions');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/missions");
        }, false);
    }, [navigate]);

    //the idUser and idAccount for mqtt
    const {idUser, idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the temperature unit
    const {temperatureUnit} = useContext(SettingsContext);

    //the mission to manage
    const idMission = localStorage.getItem("missionManagement");
    //the previous mission
    const [previousMission, setPreviousMission] = useState([]);

    //variables to set the mission, groupMissions, the groupDeviceMissions and the KineticsMissions, missionSupervision
    const [mission, setMission] = useState([])
    const [groupMission, setGroupMission] = useState([]);
    const [groupDeviceMission, setGroupDeviceMission] = useState([]);
    const [kineticsMission, setKineticsMission] = useState([]);
    const [missionSupervisions, setMissionSupervisions] = useState([]);
    const [allGroupDevicesInMission, setAllGroupDevicesInMission] = useState([]);
    const [calcKinetics, setCalcKinetics] = useState([]);

    //variable to get the groupUsers, the groupDevices and the kinetics, groupNotification, dataType
    const [groupUsers, setGroupUsers] = useState([]);
    const [groupUsersSelect, setGroupUsersSelect] = useState([]);
    const [groupDevice, setGroupDevice] = useState([]);
    const [groupDeviceSelect, setGroupDeviceSelect] = useState([]);
    const [devices, setDevices] = useState([]);
    const [devicesSelect, setDevicesSelect] = useState([]);
    const [kinetics, setKinetics] = useState([]);
    const [kineticsSelect, setKineticsSelect] = useState([]);
    const [groupNotification, setGroupNotification] = useState([]);
    const [dataType, setDataType] = useState([]);

    //variable to open popups
    const [startSuccessOpen, setStartSuccessOpen] = useState(false);
    const [errorNoDeviceOpen, setErrorNoDeviceOpen] = useState(false);
    const [errorTwoOpen, setErrorTwoOpen] = useState(false);
    const [allGroupInMissionError, setAllGroupInMissionError] = useState(false);
    const [allKineticsInMissionError, setAllKineticsInMissionError] = useState(false);
    const [addSupervisionOpen, setAddSupervisionOpen] = useState(false);
    const [groupNotificationDontExistError, setGroupNotificationDontExistError] = useState(false);
    const [noDeviceInMissionError, setNoDeviceInMissionError] = useState(false);
    //message to display popup
    const [messagePopupDialog, setMessagePopupDialog] = useState("");

    //variable to open the dialogs
    const [dialogOpen, setDialogOpen] = useState(false);
    const [addGroupOpen, setAddGroupOpen] = useState(false);
    const [addDeviceOpen, setAddDeviceOpen] = useState(false);
    const [addKineticOpen, setAddKineticOpen] = useState(false);

    //value for the state of the radiobutton
    const [deviceChoiceRadio, setDeviceChoiceRadio] = useState("groupDevice");

    const [loadingOn, setLoadingOn] = useState(true);

    //variable to show again the data after a deletion
    const [reshow, setReshow] = useState(false);

    //if the alphaaeging2 field is displayed or not
    const [alphaaeging2Display, setAlphaaeging2Display] = useState(false);

    //the initial values for formik to add groupMission
    const addGroupMissionInitialValues = {
        groupUser: "",
        typeAuthorization: 1,
    };

    //the yup validation schema to add groupMission
    const addGroupMissionValidationSchema = Yup.object({
        groupUser: Yup.string().required("Required")
    });

    //values for the select type authorization
    const selectTypeAuthorization = [
        {key: "Read + write", value: 1},
        {key: "Read", value: 2}
    ]

    //initial values for formik to add groupDeviceMission
    const addGroupDeviceMissionInitialValues = {
        idGroupDevice: "",
        idDevice: "",
        idProfile: "",
        dialogRadioValue: "groupDevice",
    }

    //the yup validation schema to add groupDeviceMission
    const addGroupDeviceMissionValidationSchema = Yup.object({
        dialogRadioValue: Yup.string(),
        idGroupDevice: Yup.string().when("dialogRadioValue", {
            is: "groupDevice",
            then: () => Yup.string().required("Required"),
            otherwise: () => Yup.string().notRequired(),
        }),
        idDevice: Yup.string().when("dialogRadioValue", {
            is: "device",
            then: () => Yup.string().required("Required"),
            otherwise: () => Yup.string().notRequired(),
        }),
        idProfile: Yup.string().when("dialogRadioValue", {
            is: "profile",
            then: () => Yup.string().required("Required"),
            otherwise: () => Yup.string().notRequired(),
        }),
    });

    //initial values for formik to add kinetics
    const addKineticInitialValues = {
        kinetic: "",
        description: "",
        alphaAeging1: 0,
        alphaAeging2: 0,
    };

    //yup validation schema to add kinetics
    const addKineticValidationSchema = Yup.object({
        kinetic: Yup.number().required("Required"),
        alphaAeging1: Yup.number().min(0, "Min : 0").max(0.9999999999999, "Max: 1 (not include)").required("Required"),
        alphaAeging2: Yup.number().min(0, "Min : 0").max(0.9999999999999, "Max: 1 (not include)").required("Required"),
    });

    //the 3 options that you can add in the device part
    const dialogRadioOptions = [
        {key: "Group device", value: "groupDevice"},
        {key: "Device", value: "device"},
    ];

    //display popup if missionSupervision is added
    //select which groups to display in the select group users
    useEffect(() => {
        if(localStorage.getItem("missionSupervisionAdded") === "true"){
            setAddSupervisionOpen(true);
            localStorage.setItem("missionSupervisionAdded", "false");
        }
    },[]);

    //method to get all things needed from mqtt
    useEffect(() => {
        if(source !==null){

            //read incoming message and set the mission variable
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());

                //if it's a response for getMission
                if(jsonParsed.mission && jsonParsed.result === 1){
                    jsonParsed.mission.forEach((element) => {
                        //select the last mission
                        if(element.idMission === mission.previousMission){
                            setPreviousMission(element);
                        }
                        //select the actual mission
                        if(element.idMission === idMission){
                            setMission(element);
                        }
                    })
                //if it's a response for getGroupMission
                }else if (jsonParsed.groupMission && jsonParsed.result === 1){
                    setGroupMission(jsonParsed.groupMission);
                //if it's a response for getGroupDeviceMission
                }else if (jsonParsed.groupDeviceMission && jsonParsed.result === 1){
                    setGroupDeviceMission(jsonParsed.groupDeviceMission);
                //if it's a response for getKineticsMission
                }else if (jsonParsed.kineticsMission && jsonParsed.result === 1){
                    setKineticsMission(jsonParsed.kineticsMission);
                //if it's a response for getCalcKineticsMissions
                }else if (jsonParsed.kineticsMissions && jsonParsed.result === 1){
                    console.log(jsonParsed)
                    setCalcKinetics(jsonParsed.kineticsMissions);
                    setLoadingOn(false);
                //if it's a response for getUserGroup
                }else if(jsonParsed.UserGroupName){
                    if(jsonParsed.result===1){
                        refactorDataUserGroup(jsonParsed);
                    }
                //if it's a response for get devices
                }else if(jsonParsed.devices) {
                    setDevices(jsonParsed.devices);
                //if it's a response for get groupDevice
                }else if(jsonParsed.deviceGroupName){
                    if(jsonParsed.result===1){
                        refactorDataDeviceGroup(jsonParsed);
                    }
                //if it's a response for getKinetics
                }else if(jsonParsed.kinetics){
                    setKinetics(jsonParsed.kinetics);
                //if it's a response for an add or delete
                }else if (jsonParsed.missionSupervision) {
                    setMissionSupervisions(jsonParsed.missionSupervision);
                } else if(jsonParsed.groupNotification){
                    setGroupNotification(jsonParsed.groupNotification);
                }else if (jsonParsed.idDataType){
                    refactorDataType(jsonParsed);
                }else if (jsonParsed.groupDevices){
                    setAllGroupDevicesInMission(jsonParsed.groupDevices);
                }else if (jsonParsed.result === 1){
                    setStartSuccessOpen(true);
                    setReshow(!reshow);
                }else if (jsonParsed.result === -2){
                    setErrorTwoOpen(true);
                }
            });

            //demand to get missions
            //generate operation code
            let operation = mqttRequests.generateOperationCode("getMissions");
            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idUser":"' + idUser + '"}';
            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getMissions");
            //publish a demand to get all the missions
            mqttRequests.publish(mqttClient, "mission/getMissions", data);

            //demand to get groupMission
            operation = mqttRequests.generateOperationCode("getGroupMissions");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idMission":"' + idMission + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupMissions");
            mqttRequests.publish(mqttClient, "mission/getGroupMissions", data);

            //demand to get groupDeviceMission
            operation = mqttRequests.generateOperationCode("getGroupDeviceMissions");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idMission":"' + idMission + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupDeviceMissions");
            mqttRequests.publish(mqttClient, "mission/getGroupDeviceMissions", data);

            //demand to get kineticsMission
            operation = mqttRequests.generateOperationCode("getKineticsMissions");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idMission":"' + idMission + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getKineticsMissions");
            mqttRequests.publish(mqttClient, "mission/getKineticsMissions", data);

            //demand to get the groupUsers
            operation = mqttRequests.generateOperationCode("getGroupUser");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupUser");
            mqttRequests.publish(mqttClient, "user/getGroupUser", data);

            //demand to get the groupDevices
            operation = mqttRequests.generateOperationCode("getGroupDevice");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupDevice");
            mqttRequests.publish(mqttClient, "device/getGroupDevice", data);

            //demand to get the devices
            operation = mqttRequests.generateOperationCode("getAllDevices");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getAllDevices");
            mqttRequests.publish(mqttClient, "device/getAllDevices", data);

            //demand to get the kinetics
            operation = mqttRequests.generateOperationCode("getKinetic");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getKinetic");
            mqttRequests.publish(mqttClient, "kinetic/getKinetic", data);

            //demand to get mission supervisions
            operation = mqttRequests.generateOperationCode("getMissionSupervisions");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idMission":"' + idMission + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getMissionSupervisions");
            mqttRequests.publish(mqttClient, "alarm/getMissionSupervisions", data);

            //demand to get groupNotifications
            operation = mqttRequests.generateOperationCode("getGroupNotification");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupNotification");
            mqttRequests.publish(mqttClient, "alarm/getGroupNotification", data);

            //demand to get datatypes
            operation = mqttRequests.generateOperationCode("getDataType");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getDataType");
            mqttRequests.publish(mqttClient, "device/getDataType", data);

            //ask for kinetics results
            operation = mqttRequests.generateOperationCode("getCalcKineticsMissions");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idMission":"' + idMission + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getCalcKineticsMissions");
            mqttRequests.publish(mqttClient, "mission/getCalcKineticsMissions", data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[source, idUser, mqttClient, reshow]);

    //select which groups to display in the select group users
    useEffect(() => {
        let tempGroupUsers = [];
        if(groupUsers.length!==0){
            tempGroupUsers = groupUsers.map((element) => {
                //if the group is visible and don't have rights yet
                if(element.hide===0 && !checkUserGroupHasRights(element.id)){
                    return {
                        key: element.name,
                        value: element.id,
                    }
                }
                return undefined;
            });
        }
        let tempObject = [{key: "Select", value: ""}]
        setGroupUsersSelect([...tempObject, ...tempGroupUsers.filter(element => element !== undefined)]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[groupUsers]);

    //select which group device to display in the select groupDevices
    useEffect(() => {
        let tempGroupDevices = [] ;
        let groupDevicesInMission = [] ;
        if(groupDevice.length!==0){
            tempGroupDevices = groupDevice.map((element) => {
                //if the group device is visible and is not in the mission yet
                if(element.hide===0 && !checkGroupDeviceIsIn(element.id)){
                    return {
                        key: element.name,
                        value: element.id,
                    }
                }
                if(checkGroupDeviceIsIn(element.id)){
                    groupDevicesInMission.push(element.id);
                }
                return undefined;
            });
        }
        let tempObject = [{key: "Select", value: ""}]
        setGroupDeviceSelect([...tempObject, ...tempGroupDevices.filter(element => element !== undefined)]);
        if(groupDevicesInMission.length !== 0){
            //demand to get all groupDeviceDevice
            let operation = mqttRequests.generateOperationCode("getAllGroupDeviceDevice");
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idGroupDevice":' + JSON.stringify(groupDevicesInMission) + '}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getAllGroupDeviceDevice");
            mqttRequests.publish(mqttClient, "device/getAllGroupDeviceDevice", data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[groupDevice]);

    //select which device to display in the select device
    useEffect(() => {
        let tempDevices = [];
        if(devices.length!==0){
            tempDevices = devices.map((element) => {
                //if the group is not in the mission
                if(!checkDeviceIsIn(element.idDevice)){
                    return {
                        key: element.identifier,
                        value: element.idDevice,
                    }
                }
                return undefined;
            });
        }
        let tempObject = [{key: "Select", value: ""}]
        setDevicesSelect([...tempObject, ...tempDevices.filter(element => element !== undefined)]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[devices]);

    //select which kinetics to display in the select kinetics
    useEffect(() => {
        let tempKinetics = [];
        if(kinetics.length!==0){
            tempKinetics = kinetics.map((element) => {
                //if the kinetic is not in the group and is visible
                if(element.hideKinetics===0 && !checkKineticsInMission(element.idKinetics)){
                    return {
                        key: element.nameKinetics,
                        value: element.idKinetics,
                    }
                }
                return undefined;
            });
        }
        let tempObject = [{key: "Select", value: ""}]
        setKineticsSelect([...tempObject, ...tempKinetics.filter(element => element !== undefined)]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[kinetics]);

    //function which return true if the user group has already rights in the mission
    const checkUserGroupHasRights = (id) => {
        let result = false;
        if(groupMission.length !==0){
            groupMission.forEach((element) => {
                if(element.idGroupUser === id){
                    result = true;
                }
            })
        }
        return result;
    }

    //function which return true if the device group is already in the mission
    const checkGroupDeviceIsIn = (id) => {
        let result = false;
        if(groupDeviceMission.length !==0){
            groupDeviceMission.forEach((element) => {
                if(element.idGroupDevice === id){
                    result = true;
                }
            })
        }
        return result;
    }

    //function which return true if the device is already in the mission
    const checkDeviceIsIn = (id) => {
        let result = false;
        if(groupDeviceMission.length !==0){
            groupDeviceMission.forEach((element) => {
                if(element.idDevice === id){
                    result = true;
                }
            })
        }
        return result;
    }

    //function which return true if the kinetic is already in the mission
    const checkKineticsInMission = (id) => {
        let result = false;
        if(kineticsMission.length !== 0){
            kineticsMission.forEach((element) => {
                if(element.idKinetics === id){
                    result = true;
                }
            })
        }
        return result;
    }

    //refactor data to set a variable with the group user
    const refactorDataUserGroup = (data) => {
        //store all the GroupUsers in a variable
        setGroupUsers(data.idGroupUser.map((id, index_value) =>{
            return {
                name: data.UserGroupName[index_value],
                id: id,
                hide: data.UserGroupHide[index_value],
            }
        }));
    }

    //refactor data to set a variable with the group device
    const refactorDataDeviceGroup = (data) => {
        //store all the groupDevices in a variable
        setGroupDevice(data.idGroupDevice.map((id, index_value) =>{
            return {
                name: data.deviceGroupName[index_value],
                id: id,
                hide: data.deviceGroupHide[index_value],
            }
        }));
    }

    //refactor datatype to set it in a variable
    const refactorDataType = (data) => {
        setDataType(data.idDataType.map((idDataType, index) =>{
            return {
                id: idDataType,
                name : data.description[index]
            }
        }))
    }

    //display the correct status (the status 4 is not displayed)
    const displayState = (state) => {
        if(state === 1){
            return "Planned";
        }
        if(state === 2){
            return "in progress";
        }
        if(state === 3){
            return "finished";
        }
        return "archived";
    }

    //open the dialog "are you sure to act with the mission" with the correct word to start stop or archive the mission
    const manageDialog = (state) => {
        if(groupDeviceMission.length !== 0){
            switch (state){
                case 1:
                    setMessagePopupDialog("start");
                    break;
                case 2:
                    setMessagePopupDialog("stop");
                    break;
                case 3:
                    setMessagePopupDialog("archive");
                    break;
                default:
                    break;
            }
            setDialogOpen(true);
        }else
            setErrorNoDeviceOpen(true);
    }

    //the mission to really change state of the mission
    const manageStateMission = () => {

        //close the dialog
        setDialogOpen(false);

        //set the state of the mission and prepare the success popup
        let newState = 2;
        switch (messagePopupDialog){
            case "start":
                setMessagePopupDialog("Mission successfully started");
                break;
            case "stop":
                setMessagePopupDialog("Mission successfully stopped");
                newState = 3;
                break;
            case "archive":
                setMessagePopupDialog("Mission successfully archived");
                newState = 4;
                break;
            default:
                break;
        }

        //set and adjust the start and stop date
        let startDate = mission.dateStart;
        let stopDate = mission.dateStop;

        let lastCheck = 0;

        //if the mission start now, set the start date and the last check at now
        if(mission.stateMission === 1){
            startDate = Math.round(new Date().getTime()/1000);
            lastCheck = startDate;
        }
        // if the mission finish now, set the stop date at now
        if(mission.stateMission === 2){
            stopDate = Math.round(new Date().getTime()/1000);
        }

        //modify in the db
        //generate operation code
        const operation = mqttRequests.generateOperationCode("editMission");
        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "idMission":"'
            + mission.idMission + '", "oldNameMission":"' + mission.nameMission + '", "newNameMission":"' + mission.nameMission + '", "descrMission":"' + mission.descrMission
            + '", "dateStart":"' + startDate + '", "dateStop":"' + stopDate + '", "stateMission":"' + newState + '", "lastCheck":"' + lastCheck + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/editMission");
        //publish a demand to edit the mission
        mqttRequests.publish(mqttClient, "mission/editMission", data);
    }

    //function to add a groupMission to the mission
    const addGroupMission = (value) => {
        //close the dialog
        setAddGroupOpen(false);

        //generate operation code
        const operation = mqttRequests.generateOperationCode("addGroupMission");
        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idGroupUser":"' + value.groupUser + '", "idMission":"'
            + mission.idMission + '", "typeAuthorization":"' + value.typeAuthorization + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/addGroupMission");
        //publish a demand to edit the mission
        mqttRequests.publish(mqttClient, "mission/addGroupMission", data);

        //display the success popup and reshow data
        setMessagePopupDialog("Right successfully added");
        setReshow(!reshow);
    }

    //function to send the name depending on the id to display
    const displayGroupUserName = (id) => {
        let nameToDisplay = "";
        groupUsers.forEach((element) => {
            if(element.id === id){
                nameToDisplay = element.name;
            }
        });
        return nameToDisplay;
    }

    //function to send the name depending on the id to display
    const displayKineticsName = (id) => {
        let nameToDisplay = "";
        kinetics.forEach((element) => {
            if(element.idKinetics === id){
                nameToDisplay = element.nameKinetics;
            }
        });
        return nameToDisplay;
    }

    //manage to open the dialog to add group users or display the warning popup
    const manageOpenAddGroup = () => {
        if(groupUsersSelect.length !== 1){
            setAddGroupOpen(true);
        }else {
            setAllGroupInMissionError(true);
        }
    }

    //really delete groupMission
    const handleDeleteGroup = (idGroupUser) => {
        //generate operation code
        const operation = mqttRequests.generateOperationCode("deleteGroupMission");
        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idGroupUser":"' + idGroupUser + '", "idMission":"' +
            idMission + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/deleteGroupMission");
        //publish a demand to add a user
        mqttRequests.publish(mqttClient, "mission/deleteGroupMission", data);

        //rerender data, if not, we don't see the deletion
        setReshow(!reshow);
        //set the message for the popup
        setMessagePopupDialog("Right successfully removed from the mission");
    }

    //manage the cancel button for the dialogs
    const handleCancelAddGroupDevice = () => {
        setDeviceChoiceRadio("groupDevice");
        setAddDeviceOpen(false);
    }

    //function to add group device in the mission
    const addGroupDeviceMission = (value) => {
        //close the dialog
        setAddDeviceOpen(false);

        //generate operation code
        const operation = mqttRequests.generateOperationCode("addGroupDeviceMission");

        //prepare the data
        let idGroupDevice = null;
        let idDevice = null;
        let idProfile = null;

        switch (deviceChoiceRadio){
            case "groupDevice":
                idGroupDevice = value.idGroupDevice;
                break;
            case "device":
                idDevice = value.idDevice;
                break;
            case "profile":
                idProfile = value.idProfile;
                break;
            default:
                break;
        }

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idMission":"' + mission.idMission
            + '", "idGroupDevice":"' + idGroupDevice + '", "idDevice":"' + idDevice + '", "idProfile":"' + idProfile
            + '", "startdate":"' + 0 + '", "stopdate":"' + 0 + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/addGroupDeviceMission");
        //publish a demand to edit the mission
        mqttRequests.publish(mqttClient, "mission/addGroupDeviceMission", data);

        //prepare the popup
        setMessagePopupDialog(deviceChoiceRadio + " successfully added");
        //reset the form
        setDeviceChoiceRadio("groupDevice");
        //reshow the data
        setReshow(!reshow);
    }

    //really delete the groupDevices
    const handleDeleteGroupDevice = (id) => {
        //generate operation code
        const operation = mqttRequests.generateOperationCode("deleteGroupDeviceMission");
        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idDeviceMission":"' + id + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/deleteGroupDeviceMission");
        //publish a demand to add a user
        mqttRequests.publish(mqttClient, "mission/deleteGroupDeviceMission", data);

        //rerender data, if not, we don't see the deletion
        setReshow(!reshow);
        //set the message for the popup
        setMessagePopupDialog("Device successfully removed from the mission");
    }

    //go to the page "view kinetics"
    const NavigateViewKinetics = (device) => {
        if(typeof(device) === "string"){
            devices.forEach((element) => {
                if(element.identifier === device){
                    device = element;
                }
            })
        }
        localStorage.setItem("KinCalcDevice", JSON.stringify(device));
        localStorage.setItem("KinCalcKinetics", JSON.stringify(calcKinetics));
        localStorage.setItem("MissionFirstPointTime", mission.dateStart.toString());
        navigate("/kineticsData");
    }

    //display the name of the group device based on the id
    const displayGroupDevice = (idGroupToDisplay) => {
        let groupToDisplay = {};
        let devicesInGroup = [];
        groupDevice.forEach((element) => {
            if(element.id === idGroupToDisplay){
                groupToDisplay = element;
            }
        })
        allGroupDevicesInMission.forEach((element)=>{
            if(element.idGroupDevice === idGroupToDisplay){
                devicesInGroup = element.identifier ;
            }
        })
        return(
            <div>
                <h4 className="missionText">Group device: {groupToDisplay.name}</h4>
                <ul>
                    {devicesInGroup ? devicesInGroup.map((device, id) => {
                        return(
                            <div key={id} className="devicesFlexDivLeft">
                                <li className="devicesTextDevice">{device}</li>
                                <button className="devicesButton" onClick={() => NavigateViewData(device)}>View data</button>
                                {calcKinetics.length !== 0 && mission.stateMission !== 1 ?
                                    <button className="missionButton" onClick={() => NavigateViewKinetics(device)}>View kinetics</button>
                                    :
                                    loadingOn ?
                                        <img src={Loading} className="missionimgLoading"/>
                                        :
                                        null
                                }
                            </div>
                        )
                    })
                    :
                        null
                    }
                </ul>
            </div>
        )
    }

    //display the name and the identifier of the device based on the id
    const displayDevice = (idDeviceToDisplay) => {
        let deviceToDisplay = {};
        devices.forEach((element) => {
            if (element.idDevice === idDeviceToDisplay) {
                deviceToDisplay = element;
            }
        });
        return (
            <div className="missionFlexboxDiv">
                <div>
                    <h4 className="missionTextDevices">Device: {deviceToDisplay.identifier}</h4>
                    <p className="missionTextDevices">Name: {deviceToDisplay.customName}</p>
                </div>

                <button className="missionButton" onClick={() => NavigateViewData(deviceToDisplay)}>View data</button>
                {calcKinetics.length !== 0 && mission.stateMission !== 1 ?
                    displayViewKinetics ?
                        <button className="missionButton" onClick={() => NavigateViewKinetics(deviceToDisplay)}>View kinetics</button>
                        :
                        null
                    :
                    loadingOn ?
                        <img src={Loading} className="missionimgLoading" style={{ userSelect: "none" }}/>
                        :
                        null
                }
            </div>
        )
    }

    const displayViewKinetics = () => {
        let response = false;
        calcKinetics[0].device.forEach((element) => {
            if(element.time.length !== 0){
                response = true;
            }
        })
        return response ;
    }

    //go to profile view data page
    const NavigateViewDataProfile = (profile) => {
        localStorage.setItem("profile", JSON.stringify(profile));
        navigate("/profileData");
    }

    //go to device view data page
    const NavigateViewData = (device) => {

        if(typeof(device) === "string"){
            devices.forEach((element) => {
                if(element.identifier === device){
                    device = element;
                }
            })
        }
        localStorage.setItem("device", JSON.stringify(device));
        if(mission.stateMission === 2){
            localStorage.setItem("startDate", moment(new Date(mission.dateStart*1000)).format('YYYY-MM-DD'));
        }else {
            localStorage.setItem("startDate", moment(new Date(device.lastPoint * 1000)).format('YYYY-MM-DD'));
        }
        localStorage.setItem("endDate", moment(new Date(device.lastPoint * 1000)).format('YYYY-MM-DD'));
        navigate("/deviceData");
    }

    //sent the name of the device from the id
    const displayNameDevice = (id) => {
        let name = "";
        devices.forEach((element) => {
            if(element.idDevice === id){
                name = element.customName + ' (' + element.identifier + ')';
            }
        })
        return name;
    }

    //sent the name of the device from the identifier
    const displayNameDevicefromIdentifier = (id) => {
        let name = "";
        devices.forEach((element) => {
            if(element.identifier === id){
                name = element.customName + ' (' + element.identifier + ')';
            }
        })
        return name;
    }

    //open the dialog to add kinetics or display the warning popup if all kinetics are in the mission
    const manageOpenAddKinetic = () => {
        setAlphaaeging2Display(false);
        if(kineticsSelect.length !== 1){
            setAddKineticOpen(true);
        }else {
            setAllKineticsInMissionError(true);
        }
    }

    //function to really add kinetics in the missions
    const addKineticMission = (value) => {
        //close the dialog
        setAddKineticOpen(false);

        //generate operation code
        const operation = mqttRequests.generateOperationCode("addKineticsMission");


        //manage the alphaaeging
        let alphaaeging1 = 0.0000000001;
        let alphaaeging2 = 0.0000000001;

        if(!alphaaeging2Display){
            alphaaeging2 = 0;
        }else if(value.alphaAeging2 !== 0) {
            alphaaeging2 = value.alphaAeging2;
        }

        if(value.alphaAeging1 !== 0) {
            alphaaeging1 = value.alphaAeging1;
        }

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idKinetics":"' + value.kinetic
            + '", "idMission":"' + mission.idMission + '", "descrKineticsMission":"' + value.description + '", "alphaAeging1":"'
            + alphaaeging1 + '", "alphaAeging2":"' + alphaaeging2 + '", "startdate":"' + 0 + '", "stopdate":"' + 0 + '", "pathFileKin":""}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/addKineticsMission");

        //publish a demand to edit the mission
        mqttRequests.publish(mqttClient, "mission/addKineticsMission", data);

        //prepare the popup and reshow the data
        setMessagePopupDialog("Kinetic successfully added");
        setReshow(!reshow);
    }

    //really remove a kinetic from a mission
    const handleDeleteKinetics = (id) => {
        //generate operation code
        const operation = mqttRequests.generateOperationCode("deleteKineticsMission");
        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idKineticsMission":"' + id + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/deleteKineticsMission");
        //publish a demand to add a user
        mqttRequests.publish(mqttClient, "mission/deleteKineticsMission", data);

        //rerender data, if not, we don't see the deletion
        setReshow(!reshow);

        //set the message for the popup
        setMessagePopupDialog("Kinetic successfully removed from the mission");
    }

    //listen to the changes on the form to display or not the field alphaaeging 2
    const handleOnChange = (event: FormEvent) => {
        if(event.target.id === "kinetic"){
            setAlphaaeging2Display(false);
            if(event.target.value !== ""){
                kinetics.forEach((kin) => {
                    if(event.target.value === kin.idKinetics && kin.typeKinetics === 4){
                        kin.kinParameterKinetics.forEach((parameter)=>{
                            if(parameter.idKinParameter === "20230210153627928034" && parameter.arrayValueInteger === "4"){
                                setAlphaaeging2Display(true);
                            }
                        })
                    }
                })
            }
        }
    };

    //send the user to the addSupervision page
    const handleAddSupervisions = () => {
        if(groupNotification.length === 0){
            setGroupNotificationDontExistError(true);
        }else if(groupDeviceMission.length === 0){
            setNoDeviceInMissionError(true);
        }else{
            localStorage.setItem("groupDeviceMission", JSON.stringify(groupDeviceMission));
            localStorage.setItem("kineticsMission", JSON.stringify(kineticsMission));
            localStorage.setItem("idMission", mission.idMission);
            localStorage.setItem("devices", JSON.stringify(devices));
            localStorage.setItem("kinetics", JSON.stringify(kinetics));
            navigate("/addSupervision");
        }
    }

    //delete the mission supervision selected
    const handleDeleteMissionSupervision = (id) => {
        //generate operation code
        const operation = mqttRequests.generateOperationCode("deleteMissionSupervision");

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idMissionSupervision":"' + id + '"}';
        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/deleteMissionSupervision");

        //publish a demand to delete a mission supervision
        mqttRequests.publish(mqttClient, "alarm/deleteMissionSupervision", data);

        //rerender data, if not, we don't see the deletion
        setReshow(!reshow);

        //set the message for the popup
        setMessagePopupDialog("Supervision successfully removed from the mission");
    }

    //get the name of the group notification from the id
    const getGroupNotifNameFromId = (id) => {
        let name = "";
        groupNotification.forEach((element) => {
            if(element.idGroupNotification === id){
                name = element.nameGroupNotif;
            }
        })
        return name ;
    }

    //create the text to display after the "when" (the datatype, the limitType and the limit)
    const DefineWhenAlarm = (idDataType, limiteType, limit, gi) => {
        if(idDataType === '20230126103350301008'){
            return "there is a crash"
        }else {
            let name = "";
            dataType.forEach((element) => {
                if(element.id === idDataType){
                    name = element.name;
                }
            })

            if(gi === "external"){
                name = name + " external"
            }else if(gi === "mv"){
                name = name + " mV"
            }else if(gi === "gateway" && idDataType === "20230126103350301001"){
                name = name + " %"
            }
            //display the temperature corresponding to the unit defined in the settings
            if(name === "Temperature" && (limiteType === 1 || limiteType === 2)){
                let limitCorrectUnit = limit;
                if(temperatureUnit === "F"){
                    limitCorrectUnit = Math.round(((limit*9/5)+32) *100)/100;
                }
                if(temperatureUnit === "K"){
                    limitCorrectUnit = Math.round((limit+273.15) *100)/100;
                }

                if(limiteType === 1) {
                    return name + " is larger than " + limitCorrectUnit;
                }else if (limiteType === 2) {
                    return name + " is lower than " + limitCorrectUnit;
                }
            }else{
                if(limiteType === 1) {
                    return name + " is larger than " + limit;
                }else if (limiteType === 2){
                    return name + " is lower than " + limit;
                }else {
                    return "The device don't send points since " + limit + " minutes";
                }
            }
        }
    }

    //activate or deactivate the mission supervision
    const handleModifyMissionSupervision = (supervision) => {
        let active = 1;
        if(supervision.actif === 1){
            active = 2;
        }
        //generate operation code
        const operation = mqttRequests.generateOperationCode("editMissionSupervision");

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idMissionSupervision":"' + supervision.idMissionSupervision
            + '", "idGroupNotification":"' + supervision.idGroupNotification + '", "actif":"' + active + '"}';
        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/editMissionSupervision");

        //publish a demand to add a user
        mqttRequests.publish(mqttClient, "alarm/editMissionSupervision", data);

        //rerender data, if not, we don't see the deletion
        setReshow(!reshow);

        //set the message for the popup
        setMessagePopupDialog("Supervision successfully edited");
    }

    const selectDeviceToSelect = (devicesSelect) => {

        console.log(devicesSelect);

        devicesSelect.forEach((device) => {

            let deviceSelected = null;
            devices.forEach((element) => {
                if (element.idDevice === device.value) {
                    deviceSelected = element;
                }
            });
            console.log(deviceSelected)
            if(deviceSelected !== null){
                device.key = deviceSelected.customName + ' (' + deviceSelected.identifier + ')';
            }

        }) ;

        return devicesSelect
    }

    /*      Mission status:
            1 = en préparation
            2 = en cours
            3 = terminé
            4 = archivé */

    return(
        <div>
            <h2 className="baseTitle">Mission: {mission.nameMission}</h2>
            <div className="missionDivTxt">
                <p className="missionText">Description: {mission.descrMission}</p>
                {mission.previousMission !== mission.idMission ?
                    <p className="missionText">Previous Mission: {previousMission.nameMission}</p>
                    :
                    null
                }
                {mission.dateStart !== 0 ?
                    <p className="missionText">Start date: {moment(new Date(mission.dateStart*1000)).format("MMMM DD YYYY")}</p>
                    :
                    <p className="missionText">Start date not defined yet</p>
                }
                {mission.dateStop !== 0 ?
                    <p className="missionText">Stop date: {moment(new Date(mission.dateStop*1000)).format("MMMM DD YYYY")}</p>
                    :
                    <p className="missionText">Stop date not defined yet</p>
                }
                <p className="missionText">State: {displayState(mission.stateMission)}</p>
            </div>
            {mission.userRights !==2?
                mission.stateMission === 1?
                    <button className="missionButtonLeft" onClick={() => manageDialog(mission.stateMission)}>Start Mission</button>
                    :
                    mission.stateMission === 2 ?
                        <button className="missionButtonLeft" onClick={() => manageDialog(mission.stateMission)}>Stop Mission</button>
                        :
                        mission.stateMission === 3 ?
                            <button className="missionButtonLeft" onClick={() => manageDialog(mission.stateMission)}>Archive Mission</button>
                            :
                            null
                :
                null
            }

            <div className="missionDivText">
                <h3 className="missionSubTitle">Group users:</h3>
                {groupMission.length!==0?
                    groupMission.map(function (group, id) {
                        return (
                            <div key={id} className="missionDivTxtFlexbox">
                                <div className="missionDivGroups">
                                    <h4 className="missionText">Name: {displayGroupUserName(group.idGroupUser)}</h4>
                                    <p className="missionText">Rights: {group.typeAuthorization===1?"Read + write":"Read only"}</p>
                                </div>
                                {mission.userRights !==2?
                                    <IconButton sx={{ color: red[500], backgroundColor: red[100], borderRadius: '20%', width: 40, height: 40 }} onClick={() => handleDeleteGroup(group.idGroupUser)}>
                                        <CloseRoundedIcon/>
                                    </IconButton>
                                    :
                                    null
                                }

                            </div>
                        )
                    })
                    :
                    <p className="missionText">No group currently has rights to the mission</p>
                }
                {mission.userRights !==2?
                    <button onClick={manageOpenAddGroup}>Add group user</button>
                    :
                    null
                }
            </div>

            <div className="missionDivTxt">
                <h3 className="missionSubTitle">Devices:</h3>
                {groupDeviceMission.length!==0?
                    groupDeviceMission.map(function (device, id) {
                        return(
                            <div key={id} className="missionDivTxtFlexbox">
                                <div className="missionDivGroups">
                                    {device.idGroupDevice !== "null"?
                                        displayGroupDevice(device.idGroupDevice)
                                        :
                                        displayDevice(device.idDevice)
                                    }
                                </div>
                                {groupDeviceMission.length!==1 && mission.stateMission === 1 ?
                                    mission.userRights !==2?
                                            <IconButton sx={{ color: red[500], backgroundColor: red[100], borderRadius: '20%', width: 40, height: 40 }} onClick={() => handleDeleteGroupDevice(device.idDeviceMission)}>
                                                <CloseRoundedIcon/>
                                            </IconButton>
                                            :
                                            null
                                    :
                                    null
                                }
                            </div>
                        )
                    })
                    :
                    <p className="missionText">No devices currently added to the mission</p>
                }
                {mission.userRights !==2?
                    <button onClick={() => setAddDeviceOpen(true)}>Add device</button>
                    :
                    null
                }
            </div>

            <div className="missionDivText">
                <h3 className="missionSubTitle">Kinetics:</h3>
                {kineticsMission.length!==0?
                    kineticsMission.map(function (kinetic, id) {
                        return (
                            <div key={id} className="missionDivTxtFlexbox">
                                <div className="missionDivGroups">
                                    <h4 className="missionText">Name: {displayKineticsName(kinetic.idKinetics)}</h4>
                                </div>
                                {mission.stateMission === 1 ?
                                    mission.userRights !==2?
                                        <IconButton sx={{ color: red[500], backgroundColor: red[100], borderRadius: '20%', width: 40, height: 40 }} onClick={() => handleDeleteKinetics(kinetic.idKineticsMission)}>
                                            <CloseRoundedIcon/>
                                        </IconButton>
                                        :
                                        null


                                    :
                                    null
                                }
                            </div>
                        )
                    })
                    :
                    <p className="missionText">No kinetics are currently assigned to the mission</p>
                }
                {mission.userRights !==2?
                    <button onClick={manageOpenAddKinetic}>Add kinetics</button>
                    :
                    null
                }
            </div>

            <div>
                <h3 className="missionSubTitle">Supervisions:</h3>
                {missionSupervisions.length!==0?
                    missionSupervisions.map(function (supervision, id) {
                        return (
                            <div key={id} className="missionDivTxtFlexbox">
                                <div className="missionDivGroups">
                                    <h4 className="missionText">On device: {displayNameDevice(supervision.idDevice)}</h4>
                                    {supervision.gatewayIdentifier === "mv" || supervision.gatewayIdentifier === "external" || supervision.gatewayIdentifier === "gateway"?
                                        <p className="missionText">on the gateway</p>
                                        :
                                        supervision.gatewayIdentifier !== ""?
                                            <p className="missionText">device connected: {displayNameDevicefromIdentifier(supervision.gatewayIdentifier)}</p>
                                            :
                                            null
                                    }
                                    {supervision.idKinetics !== ""?
                                        <p className="missionText">kinetic: {displayKineticsName(supervision.idKinetics)}</p>
                                        :
                                        null
                                    }
                                    <p className="missionText">notification: {supervision.severity === 1 ? "warning" : "critical"}, to {getGroupNotifNameFromId(supervision.idGroupNotification)}</p>
                                    <p className="missionText">When: {DefineWhenAlarm(supervision.idDatatype, supervision.limiteType, supervision.limite, supervision.gatewayIdentifier)}</p>
                                    <div className="missionFlexboxDiv">
                                        <p className="missionText">Active: {supervision.actif === 1 ? "Yes" : <span style={{color:"red"}}>No</span>}</p>
                                        {mission.userRights !==2?
                                            supervision.actif === 1?
                                                    <button className="missionButton" onClick={() => handleModifyMissionSupervision(supervision)}>Deactivate</button>
                                                    :
                                                    <button className="missionButton" onClick={() => handleModifyMissionSupervision(supervision)}>Activate</button>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                {mission.stateMission === 1 ?
                                    mission.userRights !==2?
                                            <IconButton sx={{ color: red[500], backgroundColor: red[100], borderRadius: '20%', width: 40, height: 40 }} onClick={() => handleDeleteMissionSupervision(supervision.idMissionSupervision)}>
                                                <CloseRoundedIcon/>
                                            </IconButton>
                                            :
                                            null
                                    :
                                    null
                                }
                            </div>
                        )
                    })
                    :
                    <p className="missionText">No supervision assigned to the mission</p>
                }
                {mission.userRights !==2?
                    <button onClick={handleAddSupervisions}>Add Supervisions</button>
                    :
                    null
                }
            </div>

            <div className="missionBackButton">
                <button onClick={() => navigate("/missions")}>Back</button>
            </div>
            <Snackbar open={startSuccessOpen} autoHideDuration={4000} onClose={() => setStartSuccessOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">{messagePopupDialog}</Alert>
            </Snackbar>
            <Snackbar open={errorNoDeviceOpen} autoHideDuration={5000} onClose={() => setErrorNoDeviceOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Assign at least one device to this mission to start it</Alert>
            </Snackbar>
            <Snackbar open={errorTwoOpen} autoHideDuration={4000} onClose={() => setErrorTwoOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Something went wrong, try again later</Alert>
            </Snackbar>
            <Snackbar open={allGroupInMissionError} autoHideDuration={4000} onClose={() => setAllGroupInMissionError(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="warning">All groups already have rights for this mission</Alert>
            </Snackbar>
            <Snackbar open={allKineticsInMissionError} autoHideDuration={4000} onClose={() => setAllKineticsInMissionError(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="warning">All kinetics already assigned to this mission</Alert>
            </Snackbar>
            <Snackbar open={groupNotificationDontExistError} autoHideDuration={4000} onClose={() => setGroupNotificationDontExistError(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="warning">A group notification must exist to create a supervision</Alert>
            </Snackbar>
            <Snackbar open={noDeviceInMissionError} autoHideDuration={4000} onClose={() => setNoDeviceInMissionError(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="warning">A device must be added in this mission to create a supervision</Alert>
            </Snackbar>
            <Snackbar open={addSupervisionOpen} autoHideDuration={4000} onClose={() => setAddSupervisionOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Mission supervision successfully added</Alert>
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure to " + messagePopupDialog + " this mission ?"}
                </DialogTitle>
                <DialogActions>
                    <button className="userDialogButton" onClick={() => setDialogOpen(false)}>No</button>
                    <button className="userDialogButton" onClick={manageStateMission}>Yes</button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={addGroupOpen}
                onClose={() => setAddGroupOpen(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add new group in the mission"}
                </DialogTitle>
                <Formik
                    initialValues={addGroupMissionInitialValues}
                    validationSchema={addGroupMissionValidationSchema}
                    onSubmit={addGroupMission}
                >
                    {formik => {
                        return(
                            <Form>
                                <FormikControl control="select" label="Group: " name="groupUser" options={groupUsersSelect}/>
                                <FormikControl control="select" label="Type of authorization: " name="typeAuthorization" options={selectTypeAuthorization}/>
                                <DialogActions>
                                    <button className="userDialogButton" type="button" onClick={() => setAddGroupOpen(false)}>Cancel</button>
                                    <button className="userDialogButton" type="submit">Add</button>
                                </DialogActions>
                            </Form>
                        )
                    }}
                </Formik>
            </Dialog>
            <Dialog
                open={addDeviceOpen}
                onClose={() => setAddDeviceOpen(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add new device in the mission"}
                </DialogTitle>
                <Formik
                    initialValues={addGroupDeviceMissionInitialValues}
                    validationSchema={addGroupDeviceMissionValidationSchema}
                    onSubmit={addGroupDeviceMission}
                >
                    {() => {
                        return(
                            <Form>
                                <FormikControl control="radio" label="What do you want to add ?" name="dialogRadioValue" options={dialogRadioOptions} onChangeAction={setDeviceChoiceRadio}/>
                                {deviceChoiceRadio === "groupDevice" ?
                                    <FormikControl control="select" label="Group of device: " name="idGroupDevice" options={groupDeviceSelect}/>
                                    :
                                    <FormikControl control="select" label="Device: " name="idDevice" options={selectDeviceToSelect(devicesSelect)}/>
                                }
                                <DialogActions>
                                    <button className="userDialogButton" type="button" onClick={handleCancelAddGroupDevice}>Cancel</button>
                                    <button className="userDialogButton" type="submit">Add</button>
                                </DialogActions>
                            </Form>
                        )
                    }}
                </Formik>
            </Dialog>
            <Dialog
                open={addKineticOpen}
                onClose={() => setAddKineticOpen(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add new kinetic to the mission"}
                </DialogTitle>
                <Formik
                    initialValues={addKineticInitialValues}
                    validationSchema={addKineticValidationSchema}
                    onSubmit={addKineticMission}
                >
                    {() => {
                        return(
                            <Form onChange={handleOnChange}>
                                <FormikControl control="select" label="Kinetic: " name="kinetic" options={kineticsSelect}/>
                                <FormikControl control="textarea" label="Description: " name="description" />
                                <FormikControl control="input" type="number" label="Alpha aging 1: " name="alphaAeging1"/>
                                {alphaaeging2Display ?
                                    <FormikControl control="input" type="number" label="Alpha aging 2: " name="alphaAeging2"/>
                                    :
                                    null
                                }
                                <DialogActions>
                                    <button className="userDialogButton" type="button" onClick={() => setAddKineticOpen(false)}>Cancel</button>
                                    <button className="userDialogButton" type="submit">Add</button>
                                </DialogActions>
                            </Form>
                        )
                    }}
                </Formik>
            </Dialog>
        </div>
    )
}

export default ManageMissions;