import React, {useContext, useEffect, useState} from 'react';
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {Alert, Dialog, DialogActions, DialogTitle, Snackbar} from "@mui/material";
import EditDevice from "./EditDevice";
import Accordion from 'react-bootstrap/Accordion';
import "./Devices.scss";
import AddDevice from "./AddDevice";
import AddProfile from "./AddProfile";
import EditProfile from "./EditProfile";


//the page witch display all the devices of an account
function DeviceList(props) {

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('deviceList');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/")
            setMenuClicked("Home");
        }, false);
    }, [navigate, setMenuClicked]);

    //the idAccount for mqtt
    const {idAccount, idUser, idRight} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //if user have rights
    const [deviceRight, setDeviceRight] = useState(true);

    //set variable to get the data
    const [devices, setDevices] = useState([]);
    const [profiles, setProfiles] = useState([]);

    //dialog open
    const [dialogOpen, setDialogOpen] = useState(false);
    //id profile for deletion
    const [idProfileDeletion, setIdProfileDeletion] = useState("");

    //variable to store the data types
    const [allDataType, setAllDataType] = useState([]);
    //variable for display edit success message
    const [deviceEdited, setDeviceEdited] = useState(false);
    const [profileEdited, setProfileEdited] = useState(false);
    const [deviceDeleted, setDeviceDeleted] = useState(false);
    const [errDelete, setErrDelete] = useState(false);
    const [errOneOpen, setErrOneOpen] = useState(false);
    const [errOneOpenAdd, setErrOneOpenAdd] = useState(false);
    const [errTwoOpen, setErrTwoOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [successOpenAddProfile, setSuccessOpenAddProfile] = useState(false);
    const [errOneOpenAddProfile, setErrOneOpenAddProfile]= useState(false);
    const [errOneOpenEditProfile, setErrOneOpenEditProfile] = useState(false);
    const [errOneOpenProfile, setErrOneOpenProfile] = useState(false);

    const [reshow, setReshow] = useState(false);

    const [edit, setEdit] = useState('');
    const [editProfile, setEditProfile] = useState('');
    const [deviceAdd, setDeviceAdd] = useState(false);
    const [profileAdd, setProfileAdd] = useState(false);

    //variable for the add select values
    const[modelOptions, setModelOptions] = useState([]);

    //determines if the user have rights to add/modify devices
    useEffect(() => {
        if(idUser !== ""){
            if(idRight !== null){
                try{
                    setDeviceRight(idRight.includes("02112221623298373533"));
                } catch (error) {
                    console.error(error);
                    setDeviceRight(false);
                }
            }
        }
    }, [idRight, idUser]);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //subscribe to the channel to wait for the mqtt response (get all the devices for the current account)
            mqttRequests.subscribe(mqttClient, "device/list/" + idAccount);

            //get the dataTypes
            //generate operation code
            let operation = mqttRequests.generateOperationCode("getDataType");
            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getDataType");
            //publish a demand to get the data types
            mqttRequests.publish(mqttClient, "device/getDataType", data);

            //demand to get the devices for the second time when user go in the page
            operation = mqttRequests.generateOperationCode("getAllDevices");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getAllDevices");
            mqttRequests.publish(mqttClient, "device/getAllDevices", data);

            //ask for profile
            operation = mqttRequests.generateOperationCode("getProfile");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getProfile");
            mqttRequests.publish(mqttClient, "profile/getProfile", data);

            //ask for model device (add)
            operation = mqttRequests.generateOperationCode("getModelDevice");
            data = '{"operation":"' + operation + '", "source":"' + source + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getModelDevice");
            mqttRequests.publish(mqttClient, "device/getModelDevice", data);

            //read incoming message and set the variables
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //the list of all devices in the db
                if(jsonParsed.devices){
                    setDevices(jsonParsed.devices);
                //the list of all different datatype in the db with id and name
                }else if(jsonParsed.idDataType){
                    refactorData(jsonParsed);
                //list of all profiles
                }else if(jsonParsed.profiles){
                    setProfiles(jsonParsed.profiles);
                //result to get the model
                }else if(jsonParsed.model) {
                    refactorDataModel(jsonParsed);
                }else if(jsonParsed.idDevice){
                    if(jsonParsed.idDevice===-2){
                        //display error -2 popup
                        setErrTwoOpen(true);
                    } else {
                        if(jsonParsed.idDevice===-1){
                            //display error -1 popup
                            setErrOneOpenAdd(true);
                        }else {
                            //display success popup
                            setSuccessOpen(true);
                        }
                    }
                }else if(jsonParsed.operation.includes("addProfile")){
                    if(jsonParsed.result === 1){
                        setSuccessOpenAddProfile(true);
                    }else if(jsonParsed.result === -1){
                        setErrOneOpenAddProfile(true);
                    }else {
                        setErrTwoOpen(true);
                    }
                }else if(jsonParsed.operation.includes("editProfile")) {
                    switch (jsonParsed.result){
                        case 1:
                            setEditProfile('');
                            setProfileEdited(true);
                            break;
                        case -1:
                            //display error popup
                            setErrOneOpenProfile(true);
                            break;
                        case -2:
                            //display error popup
                            setErrTwoOpen(true);
                            break;
                        default :
                            break;
                    }
                }else if(jsonParsed.operation.includes("editDevice")){
                    switch (jsonParsed.result){
                        case 1:
                            setDeviceEdited(true);
                            setEdit('');
                            break;
                        case -1:
                            //display error popup
                            setErrOneOpen(true);
                            break;
                        case -2:
                            //display error popup
                            setErrTwoOpen(true);
                            break;
                        default :
                            break;
                    }
                }

            })
        }
    },[source, idAccount, mqttClient, reshow]);

    //transform 2 separates arrays in 1 object with all the values to make it easier to render
    const refactorData = (data) => {
        setAllDataType(data.idDataType.map((id, index_value) =>{
            return {
                idDataType: data.idDataType[index_value],
                description: data.description[index_value],
            }
        }));
    };

    //transform 2 separates arrays in 1 object for the select
    const refactorDataModel = (data) => {
        let tempObject = [{key:"Select", value: ""}]
        let temp = data.idModelDevice.map((id, index_value) =>{
            return {
                key: data.model[index_value],
                value: data.idModelDevice[index_value],
            }
        });

        //hide real name of the devices
        let tempWithoutRealName = temp.map((element) => {
            switch (element.key){
                case "Efento NB-IoT":
                    return {
                        key: "t",
                        value: element.value,
                    }
                case "Efento bluetooth":
                    return {
                        key: "o",
                        value: element.value,
                    }
                case "Efento current":
                    return {
                        key: "o ct",
                        value: element.value,
                    }
                case "TMT250":
                    return {
                        key: 250,
                        value: element.value,
                    }
                case "Ruuvitag":
                    return {
                        key: "g",
                        value: element.value,
                    }
                case "FMC920":
                    return {
                        key: "c",
                        value: element.value,
                    }
                case "Eye":
                    return {
                        key: "e",
                        value: element.value,
                    }
                case "Efento NB-IoT co2":
                    return {
                        key: "t co2",
                        value: element.value,
                    }
                case "Efento NB-IoT Voc":
                    return {
                        key: "t voc",
                        value: element.value,
                    }
                default:
                    return {
                        key: element.key,
                        value: element.value,
                    }
            }
        });

        setModelOptions([...tempObject, ...tempWithoutRealName]);
    };

    //action done by the edit button
    const handleButtonEdit = (device) => {
        //store the device in the local storage to recover it in the edit page
        localStorage.setItem("deviceEdit", JSON.stringify(device));
        setEdit(device.identifier);
    }

    //send the user in the edit or view data page for device
    const handleButtonViewData = (device) => {
        //store the device in the local storage to recover it in the edit page
        localStorage.setItem("device", JSON.stringify(device));
        navigate('/deviceData');
    }

    const handleButtonParameters = (device) => {
        localStorage.setItem("deviceParam", JSON.stringify(device));
        navigate('/parameters');
    }

    //send the user in the edit or view data page for profile
    const handleButtonClickEditProfile = (profile) => {
        //store the device in the local storage to recover it in the edit page
        localStorage.setItem("editProfile", JSON.stringify(profile));
        setEditProfile(profile.nameProfile);

    }

    //send the user in the edit or view data page for profile
    const handleButtonClickViewDataProfile = (profile) => {
        //store the device in the local storage to recover it in the edit page
        localStorage.setItem("profile", JSON.stringify(profile));
        navigate('/profileData');
    }

    //send the description of the datatype by the id
    const dataTypeToDisplay = (datatype) => {
        //find the right data to display by searching in the table with all the dataTypes (allDataType)
        let response =  "";
        allDataType.forEach((element) => {
            if (element.idDataType === datatype){
                    response = element.description;
            }
        })
        return response;
    }

    //open the dialog to delete a profile
    const handleDeleteProfile = (idProfile) => {
        setIdProfileDeletion(idProfile);
        setDialogOpen(true);
    }

    //delete a profile
    const realDeleteProfile = () => {
        //close the dialog
        setDialogOpen(false);

        //generate operation code
        const operation = mqttRequests.generateOperationCode("deleteProfile");
        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idProfile":"' + idProfileDeletion + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/deleteProfile");
        //publish a demand to delete a profile
        mqttRequests.publish(mqttClient, "profile/deleteProfile", data);

        //rerender data, if not, we don't see the deletion
        setReshow(!reshow);
    }

    return(
        <div className="baseMainDiv">
            <h2 className="baseTitle">All devices</h2>

            <div className="baseDivAccordion">
                <Accordion alwaysOpen>
                    {devices.sort((a,b) => a.customName > b.customName ? 1 : -1,).map((device, id) => {
                        return(
                            <div key={id}>
                                <Accordion.Item eventKey={id}>
                                    <Accordion.Header>{device.customName + " (" + device.identifier + ")"}</Accordion.Header>
                                    <Accordion.Body className="baseAccordionBody">
                                        <table className="baseTableNoBorder">
                                            <tbody>
                                            <tr className="baseTr">
                                                <td className="deviceBoldSpan">Name:</td>
                                                <td className="baseTd">{device.customName}</td>
                                            </tr>
                                            <tr className="baseTr">
                                                <td className="deviceBoldSpan">Description:</td>
                                                <td className="baseTd">{device.descrDevice !== "" ? device.descrDevice : "-"}</td>
                                            </tr>
                                            <tr className="baseTr">
                                                <td className="deviceBoldSpan">Identifier:</td>
                                                <td className="baseTd">{device.identifier}</td>
                                            </tr>
                                            {device.firstPoint === -1 || device.firstPoint === '-1' ?
                                                <tr className="baseTr">
                                                    <td className="deviceBoldSpan">Points:</td>
                                                    <td className="baseTd">No point for the moment</td>
                                                </tr>
                                                :
                                                <>
                                                    <tr className="baseTr">
                                                        <td className="deviceBoldSpan">First point:</td>
                                                        <td className="baseTd">{moment(device.firstPoint * 1000).format('MMMM D YYYY, HH:mm')}</td>
                                                    </tr>
                                                    <tr className="baseTr">
                                                        <td className="deviceBoldSpan">Last point:</td>
                                                        <td className="baseTd">{moment(device.lastPoint * 1000).format('MMMM D YYYY, HH:mm')}</td>
                                                    </tr>
                                                </>
                                            }
                                            {device.dataType.map((datatype, id) => {
                                                return(
                                                    <tr key={id}>
                                                        {id === 0 ?
                                                            <td className="deviceBoldSpan">Data types:</td>
                                                            :
                                                            <td className="deviceBoldSpan"></td>
                                                        }
                                                        <td className="baseTd">- {dataTypeToDisplay(datatype)}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td className="deviceBoldSpan"></td>
                                                <td>
                                                    {deviceRight ?
                                                        <button className="devicesButton"
                                                                onClick={() => handleButtonEdit(device)}>edit
                                                        </button>
                                                        :
                                                        null
                                                    }
                                                    {device.firstPoint === -1 || device.firstPoint === '-1' ?
                                                        null
                                                        :
                                                        <button className="devicesButton"
                                                                onClick={() => handleButtonViewData(device)}>View data
                                                        </button>
                                                    }
                                                    {device.manufacturer === "Teltonika" ?
                                                        <button className="devicesButton" onClick={() => handleButtonParameters(device)}>Parameters</button>
                                                        :
                                                        null
                                                    }
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        {edit === device.identifier ?
                                            <div className="deviceEdit">
                                                <EditDevice setEdit={setEdit}/>
                                            </div>
                                            :
                                            null
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </div>
                        )
                    })}
                </Accordion>
                {deviceRight ?
                    <button className="deviceButtonAdd" onClick={() => setDeviceAdd(true)}>Add new Device</button>
                    :
                    null
                }
                {deviceAdd ?
                    <AddDevice setDeviceAdd={setDeviceAdd} modelOptions={modelOptions}/>
                    :
                    null
                }
            </div>

            <h2 className="baseTitle">All Profiles</h2>
            <div className="baseDivAccordion">
                <Accordion alwaysOpen>
                    {profiles.sort((a,b) => a.nameProfile > b.nameProfile ? 1 : -1,).map((profile, id) => {
                        return(
                            <div key={id}>
                                <Accordion.Item eventKey={id}>
                                    <Accordion.Header>{profile.nameProfile}</Accordion.Header>
                                    <Accordion.Body className="baseAccordionBody">
                                        <table className="baseTableNoBorder">
                                            <tbody>
                                            <tr className="baseTr">
                                                <td className="deviceBoldSpan">Name:</td>
                                                <td className="baseTd">{profile.nameProfile}</td>
                                            </tr>
                                            <tr className="baseTr">
                                                <td className="deviceBoldSpan">Description:</td>
                                                <td className="baseTd">{profile.descrProfile !== "" ? profile.descrProfile : "-"}</td>
                                            </tr>
                                            <tr className="baseTr">
                                                <td className="deviceBoldSpan">First point:</td>
                                                <td className="baseTd">{moment(new Date(profile.startDate*1000)).format('MMMM D YYYY')}</td>
                                            </tr>
                                            {profile.datatype.map((datatype, id) => {
                                                return(
                                                    <tr key={id}>
                                                        {id === 0 ?
                                                            <td className="deviceBoldSpan">Data types:</td>
                                                            :
                                                            <td className="deviceBoldSpan"></td>
                                                        }
                                                        <td className="baseTd">- {dataTypeToDisplay(datatype)}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td className="deviceBoldSpan"></td>
                                                <td>
                                                    {deviceRight ?
                                                        <button className="devicesButton"
                                                                onClick={() => handleButtonClickEditProfile(profile)}>edit
                                                        </button>
                                                        :
                                                        null
                                                    }
                                                    <button className="devicesButton"
                                                            onClick={() => handleButtonClickViewDataProfile(profile)}>View data
                                                    </button>
                                                    {deviceRight ?
                                                        <button className="deviceButtonDelete"
                                                                onClick={() => handleDeleteProfile(profile.idProfile)}>Delete
                                                        </button>
                                                        :
                                                        null
                                                    }
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        {editProfile === profile.nameProfile ?
                                            <div className="deviceEdit">
                                                <EditProfile setEditProfile={setEditProfile}/>
                                            </div>
                                            :
                                            null
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </div>
                        )
                    })}
                </Accordion>
                {deviceRight ?
                    <button className="deviceButtonAdd" onClick={() => setProfileAdd(true)}>Add profile</button>
                    :
                    null
                }
                {profileAdd ?
                    <AddProfile setProfileAdd={setProfileAdd}/>
                    :
                    null
                }
            </div>
            <Snackbar open={deviceEdited} autoHideDuration={4000} onClose={() => setDeviceEdited(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Device successfully edited</Alert>
            </Snackbar>
            <Snackbar open={profileEdited} autoHideDuration={4000} onClose={() => setProfileEdited(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Profile successfully edited</Alert>
            </Snackbar>
            <Snackbar open={deviceDeleted} autoHideDuration={4000} onClose={() => setDeviceDeleted(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Profile successfully deleted</Alert>
            </Snackbar>
            <Snackbar open={errDelete} autoHideDuration={4000} onClose={() => setErrDelete(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Something went wrong</Alert>
            </Snackbar>
            <Snackbar open={errOneOpen} autoHideDuration={4000} onClose={() => setErrOneOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Device not found</Alert>
            </Snackbar>
            <Snackbar open={errOneOpenProfile} autoHideDuration={4000} onClose={() => setErrOneOpenProfile(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Profile not found</Alert>
            </Snackbar>
            <Snackbar open={errOneOpenAdd} autoHideDuration={4000} onClose={() => setErrOneOpenAdd(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - A device with this identifier already exists</Alert>
            </Snackbar>
            <Snackbar open={errTwoOpen} autoHideDuration={4000} onClose={() => setErrTwoOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - something went wrong, try again later</Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={4000} onClose={() => setSuccessOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Device successfully added</Alert>
            </Snackbar>
            <Snackbar open={successOpenAddProfile} autoHideDuration={4000} onClose={() => setSuccessOpenAddProfile(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Profile successfully added</Alert>
            </Snackbar>
            <Snackbar open={errOneOpenAddProfile} autoHideDuration={4000} onClose={() => setErrOneOpenAddProfile(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Name already exist</Alert>
            </Snackbar>
            <Snackbar open={errOneOpenEditProfile} autoHideDuration={4000} onClose={() => setErrOneOpenEditProfile(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Profile name already exists</Alert>
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title" className="deviceDialog">
                    {"Are you sure to delete this profile ?"}
                </DialogTitle>
                <DialogActions className="deviceDialog">
                    <button className="devicesButton" onClick={() => setDialogOpen(false)}>No</button>
                    <button className="devicesButton" onClick={realDeleteProfile}>Yes</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DeviceList;