import React, {useContext, useEffect, useState} from 'react';
import {
    CartesianGrid,
    Legend,
    ReferenceArea,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import * as Yup from "yup";
import mqttRequests from "../../Utils/mqttRequests";
import {Form, Formik} from "formik";
import FormikControl from "../FormTemplate/FormikControl";
import moment from "moment";
import {
    Alert,
    Slider,
    Snackbar
} from "@mui/material";
import {MqttClientContext} from "../../context/MqttClientContext";
import "./Devices.scss";
import {useNavigate} from "react-router-dom";
import {SettingsContext} from "../../context/SettingsContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import TemperatureYes from "../../assets/TemperatureYes.png";
import TemperatureNo from "../../assets/TemperatureNo.png";
import HumidityYes from "../../assets/HumidityYes.png";
import HumidityNo from "../../assets/HumidityNo.png";
import CurrentYes from "../../assets/CurrentYes.png";
import CurrentNo from "../../assets/CurrentNo.png";
import PressureYes from "../../assets/PressureYes.png";
import PressureNo from "../../assets/PressureNo.png";
import MovementYes from "../../assets/MovementYes.png";
import MovementNo from "../../assets/MovementNo.png";
import AccelerationYes from "../../assets/AccelerationYes.png";
import AccelerationNo from "../../assets/AccelerationNo.png";
import CrashYes from "../../assets/CrashYes.png";
import CrashNo from "../../assets/CrashNo.png";
import BatteryYes from "../../assets/BatteryYes.png";
import BatteryNo from "../../assets/BatteryNo.png";
import CoordinatesYes from "../../assets/CoordinatesYes.png";
import CoordinatesNo from "../../assets/CoordinatesNo.png";
import LineYes from "../../assets/LinesYes.png";
import LineNo from "../../assets/LinesNo.png";
import DotsYes from "../../assets/DotsYes.png";
import DotsNo from "../../assets/DotsNo.png";

//the page to display device data
function GroupDeviceData(props) {

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('getGroupDevice');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/getGroupDevice")
        }, false);
    }, [navigate]);

    //variables for display
    const [chartHidden, setChartHidden] = useState(true);
    const [errMessage, setErrMessage] = useState(false);
    const [errMessageDevice, setErrMessageDevice] = useState(false);

    //the number of points display in the chart (1/1 1/2 1/3...) 1/3 -> 3
    const [numberReduced, setNumberReduce] = useState(1);

    //variables for time
    const [unitTime, setUnitTime] = useState("sec");
    //for the seconds of the last point
    const [maxTime, setMaxTime] = useState(0);

    //variables for temperature
    const [temperature, setTemperature] = useState([]);
    const [temperatureDisplayed, setTemperatureDisplayed] = useState([]);
    //for teltonika
    const [temperature2, setTemperature2] = useState([]);
    const [temperature2Displayed, setTemperature2Displayed] = useState([]);
    const [temperature3, setTemperature3] = useState([]);
    const [temperature3Displayed, setTemperature3Displayed] = useState([]);
    const [temperature4, setTemperature4] = useState([]);
    const [temperature4Displayed, setTemperature4Displayed] = useState([]);
    //labels for temperature
    const [labelTemperature, setLabelTemperature] = useState("");
    const [labelTemperature2, setLabelTemperature2] = useState("");
    const [labelTemperature3, setLabelTemperature3] = useState("");
    const [labelTemperature4, setLabelTemperature4] = useState("");

    //variables for humidity
    const [humidity, setHumidity] = useState([]);
    const [humidityDisplayed, setHumidityDisplayed] = useState([]);
    //for teltonika
    const [humidity2, setHumidity2] = useState([]);
    const [humidity2Displayed, setHumidity2Displayed] = useState([]);
    const [humidity3, setHumidity3] = useState([]);
    const [humidity3Displayed, setHumidity3Displayed] = useState([]);
    const [humidity4, setHumidity4] = useState([]);
    const [humidity4Displayed, setHumidity4Displayed] = useState([]);
    //labels for humidity
    const [labelHumidity, setLabelHumidity] = useState("");
    const [labelHumidity2, setLabelHumidity2] = useState("");
    const [labelHumidity3, setLabelHumidity3] = useState("");
    const [labelHumidity4, setLabelHumidity4] = useState("");

    //variables for movement
    const [movement, setMovement] = useState([]);
    const [movementDisplayed, setMovementDisplayed] = useState([]);
    //for teltonika
    const [movement2, setMovement2] = useState([]);
    const [movement2Displayed, setMovement2Displayed] = useState([]);
    const [movement3, setMovement3] = useState([]);
    const [movement3Displayed, setMovement3Displayed] = useState([]);
    const [movement4, setMovement4] = useState([]);
    const [movement4Displayed, setMovement4Displayed] = useState([]);
    //label for movement
    const [labelMovement, setLabelMovement] = useState("");
    const [labelMovement2, setLabelMovement2] = useState("");
    const [labelMovement3, setLabelMovement3] = useState("");
    const [labelMovement4, setLabelMovement4] = useState("");

    //variables for atmospheric pressure
    const [atmosphericPressure, setAtmosphericPressure] = useState([]);
    const [atmosphericPressureDisplayed, setAtmosphericPressureDisplayed] = useState([]);
    //for teltonika
    const [atmosphericPressure2, setAtmosphericPressure2] = useState([]);
    const [atmosphericPressure2Displayed, setAtmosphericPressure2Displayed] = useState([]);
    const [atmosphericPressure3, setAtmosphericPressure3] = useState([]);
    const [atmosphericPressure3Displayed, setAtmosphericPressure3Displayed] = useState([]);
    const [atmosphericPressure4, setAtmosphericPressure4] = useState([]);
    const [atmosphericPressure4Displayed, setAtmosphericPressure4Displayed] = useState([]);
    //labels for atmospheric pressure
    const [labelAtmosphericPressure, setLabelAtmosphericPressure] = useState("");
    const [labelAtmosphericPressure2, setLabelAtmosphericPressure2] = useState("");
    const [labelAtmosphericPressure3, setLabelAtmosphericPressure3] = useState("");
    const [labelAtmosphericPressure4, setLabelAtmosphericPressure4] = useState("");

    //variables for current1
    const [current1, setCurrent1] = useState([]);
    const [current1Displayed, setCurrent1Displayed] = useState([]);
    //for teltonika
    const [current12, setCurrent12] = useState([]);
    const [current12Displayed, setCurrent12Displayed] = useState([]);
    const [current13, setCurrent13] = useState([]);
    const [current13Displayed, setCurrent13Displayed] = useState([]);
    const [current14, setCurrent14] = useState([]);
    const [current14Displayed, setCurrent14Displayed] = useState([]);
    //labels for atmospheric pressure
    const [labelCurrent1, setLabelCurrent1] = useState("");
    const [labelCurrent12, setLabelCurrent12] = useState("");
    const [labelCurrent13, setLabelCurrent13] = useState("");
    const [labelCurrent14, setLabelCurrent14] = useState("");

    //variables for current1
    const [current2, setCurrent2] = useState([]);
    const [current2Displayed, setCurrent2Displayed] = useState([]);
    //for teltonika
    const [current22, setCurrent22] = useState([]);
    const [current22Displayed, setCurrent22Displayed] = useState([]);
    const [current23, setCurrent23] = useState([]);
    const [current23Displayed, setCurrent23Displayed] = useState([]);
    const [current24, setCurrent24] = useState([]);
    const [current24Displayed, setCurrent24Displayed] = useState([]);
    //labels for atmospheric pressure
    const [labelCurrent2, setLabelCurrent2] = useState("");
    const [labelCurrent22, setLabelCurrent22] = useState("");
    const [labelCurrent23, setLabelCurrent23] = useState("");
    const [labelCurrent24, setLabelCurrent24] = useState("");

    //variables for battery
    const [batteryPercent, setBatteryPercent] = useState([]);
    const [batteryPercentDisplayed, setBatteryPercentDisplayed] = useState([]);
    const [batteryMv, setBatteryMv] = useState([]);
    const [batteryMvDisplayed, setBatteryMvDisplayed] = useState([]);
    const [batteryPercent2, setBatteryPercent2] = useState([]);
    const [batteryPercent2Displayed, setBatteryPercent2Displayed] = useState([]);
    const [batteryMv2, setBatteryMv2] = useState([]);
    const [batteryMv2Displayed, setBatteryMv2Displayed] = useState([]);
    const [batteryPercent3, setBatteryPercent3] = useState([]);
    const [batteryPercent3Displayed, setBatteryPercent3Displayed] = useState([]);
    const [batteryMv3, setBatteryMv3] = useState([]);
    const [batteryMv3Displayed, setBatteryMv3Displayed] = useState([]);
    const [batteryPercent4, setBatteryPercent4] = useState([]);
    const [batteryPercent4Displayed, setBatteryPercent4Displayed] = useState([]);
    const [batteryMv4, setBatteryMv4] = useState([]);
    const [batteryMv4Displayed, setBatteryMv4Displayed] = useState([]);
    //labels for battery
    const [labelBattery, setLabelBattery] = useState("");
    const [labelBattery2, setLabelBattery2] = useState("");
    const [labelBattery3, setLabelBattery3] = useState("");
    const [labelBattery4, setLabelBattery4] = useState("");

    //variables for Acceleration x
    const [accelerationX, setAccelerationX] = useState([]);
    const [accelerationXDisplayed, setAccelerationXDisplayed] = useState([]);
    //for teltonika
    const [accelerationX2, setAccelerationX2] = useState([]);
    const [accelerationX2Displayed, setAccelerationX2Displayed] = useState([]);
    const [accelerationX3, setAccelerationX3] = useState([]);
    const [accelerationX3Displayed, setAccelerationX3Displayed] = useState([]);
    const [accelerationX4, setAccelerationX4] = useState([]);
    const [accelerationX4Displayed, setAccelerationX4Displayed] = useState([]);
    //labels for Acceleration x
    const [labelAccelerationX, setLabelAccelerationX] = useState("");
    const [labelAccelerationX2, setLabelAccelerationX2] = useState("");
    const [labelAccelerationX3, setLabelAccelerationX3] = useState("");
    const [labelAccelerationX4, setLabelAccelerationX4] = useState("");

    //variables for Acceleration y
    const [accelerationY, setAccelerationY] = useState([]);
    const [accelerationYDisplayed, setAccelerationYDisplayed] = useState([]);
    //for teltonika
    const [accelerationY2, setAccelerationY2] = useState([]);
    const [accelerationY2Displayed, setAccelerationY2Displayed] = useState([]);
    const [accelerationY3, setAccelerationY3] = useState([]);
    const [accelerationY3Displayed, setAccelerationY3Displayed] = useState([]);
    const [accelerationY4, setAccelerationY4] = useState([]);
    const [accelerationY4Displayed, setAccelerationY4Displayed] = useState([]);
    //labels for Acceleration y
    const [labelAccelerationY, setLabelAccelerationY] = useState("");
    const [labelAccelerationY2, setLabelAccelerationY2] = useState("");
    const [labelAccelerationY3, setLabelAccelerationY3] = useState("");
    const [labelAccelerationY4, setLabelAccelerationY4] = useState("");

    //variables for Acceleration z
    const [accelerationZ, setAccelerationZ] = useState([]);
    const [accelerationZDisplayed, setAccelerationZDisplayed] = useState([]);
    //for teltonika
    const [accelerationZ2, setAccelerationZ2] = useState([]);
    const [accelerationZ2Displayed, setAccelerationZ2Displayed] = useState([]);
    const [accelerationZ3, setAccelerationZ3] = useState([]);
    const [accelerationZ3Displayed, setAccelerationZ3Displayed] = useState([]);
    const [accelerationZ4, setAccelerationZ4] = useState([]);
    const [accelerationZ4Displayed, setAccelerationZ4Displayed] = useState([]);
    //labels for Acceleration z
    const [labelAccelerationZ, setLabelAccelerationZ] = useState("");
    const [labelAccelerationZ2, setLabelAccelerationZ2] = useState("");
    const [labelAccelerationZ3, setLabelAccelerationZ3] = useState("");
    const [labelAccelerationZ4, setLabelAccelerationZ4] = useState("");

    //variables to choose to display or not Teltonika device values (TH -> temperature humidity, M -> movement, Atm -> atmPressure, A -> Acceleration, B -> battery)
    const [TH1Display, setTH1Display] = useState(true);
    const [TH2Display, setTH2Display] = useState(true);
    const [TH3Display, setTH3Display] = useState(true);
    const [TH4Display, setTH4Display] = useState(true);
    const [M1Display, setM1Display] = useState(true);
    const [M2Display, setM2Display] = useState(true);
    const [M3Display, setM3Display] = useState(true);
    const [M4Display, setM4Display] = useState(true);
    const [Atm1Display, setAtm1Display] = useState(true);
    const [Atm2Display, setAtm2Display] = useState(true);
    const [Atm3Display, setAtm3Display] = useState(true);
    const [Atm4Display, setAtm4Display] = useState(true);
    const [Cu1Display, setCu1Display] = useState(true);
    const [Cu2Display, setCu2Display] = useState(true);
    const [Cu3Display, setCu3Display] = useState(true);
    const [Cu4Display, setCu4Display] = useState(true);
    const [A1Display, setA1Display] = useState(true);
    const [A2Display, setA2Display] = useState(true);
    const [A3Display, setA3Display] = useState(true);
    const [A4Display, setA4Display] = useState(true);
    const [B1Display, setB1Display] = useState(true);
    const [B2Display, setB2Display] = useState(true);
    const [B3Display, setB3Display] = useState(true);
    const [B4Display, setB4Display] = useState(true);

    //variables for checkboxes
    //for line and dots display
    const [lineRender, setLineRender] = useState(true);
    const [shapeRender, setShapeRender] = useState(true);
    //for data display
    const [temperatureCheckbox, setTemperatureCheckbox] = useState(true);
    const [humidityCheckbox, setHumidityCheckbox] = useState(true);
    const [currentCheckbox, setCurrentCheckbox] = useState(true);
    const [movementCheckbox, setMovementCheckbox] = useState(false);
    const [atmPressureCheckbox, setAtmPressureCheckbox] = useState(false);
    const [accelerationCheckbox, setAccelerationCheckbox] = useState(false);
    const [batteryCheckbox, setBatteryCheckbox] = useState(false);

    //variables to select min and max for y-axis
    const [boundsBatteryPercentYAxis, setBoundsBatteryPercentYAxis] = useState([0, 100]);
    const [boundsBatteryMvYAxis, setBoundsBatteryMvYAxis] = useState([dataMin => Math.ceil(dataMin - 100), dataMax => Math.floor(dataMax + 100)]);
    const [boundsTemperatureYAxis, setBoundsTemperatureYAxis] = useState([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
    const [boundsHumidityYAxis, setBoundsHumidityYAxis] = useState([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
    const [boundsAtmPressureYAxis, setBoundsAtmPressureYAxis] = useState([dataMin => Math.ceil(dataMin - 20), dataMax => Math.floor(dataMax + 20)]);
    const [boundsAccelerationYAxis, setBoundsAccelerationYAxis] = useState([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);

    //variables to display min and max for y-axis
    const [displayBoundsBatteryMv, setDisplayBoundsBatteryMv] = useState([0, 0]);
    const [displayBoundsTemperature, setDisplayBoundsTemperature] = useState([0, 0]);
    const [displayBoundsHumidity, setDisplayBoundsHumidity] = useState([0, 0]);
    const [displayBoundsAtmPressure, setDisplayBoundsAtmPressure] = useState([0, 0]);
    const [displayBoundsAcceleration, setDisplayBoundsAcceleration] = useState([0, 0]);

    //variables to save min and max display for y-axis
    const [saveBoundsBatteryMv, setSaveBoundsBattery] = useState([0, 0]);
    const [saveBoundsTemperature, setSaveBoundsTemperature] = useState([0, 0]);
    const [saveBoundsHumidity, setSaveBoundsHumidity] = useState([0, 0]);
    const [saveBoundsAtmPressure, setSaveBoundsAtmPressure] = useState([0, 0]);
    const [saveBoundsAcceleration, setSaveBoundsAcceleration] = useState([0, 0]);

    //the devices
    const [devices, setDevices] = useState([]);

    const [historyTotalTime, setHistoryTotalTime] = useState(0);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);
    const {idAccount} = useContext(UserInfoContext);

    //the context for temperature unit
    const {temperatureUnit} = useContext(SettingsContext);

    //variables to zoom
    const [stateZoom, setStateZoom] = useState({
        left: 'dataMin',
        right: dataMax => Math.ceil(dataMax),
        refAreaLeft: '',
        refAreaRight: '',
        animation: true,
    });

    //get the device
    let group = JSON.parse(localStorage.getItem("groupData"));
    let groupNameData = localStorage.getItem("groupNameData");

    //the initial values for formik
    const initialValues = {
        beginning: moment(new Date()).format('YYYY-MM-DD'),
        end: moment(new Date()).format('YYYY-MM-DD'),
    };

    //set the display temperature value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
            if(temperatureDisplayed.length !== 0){
                temperatureDisplayed.forEach((element) => {
                    if (element.temperature < min) {
                        min = element.temperature;
                    }
                    if (element.temperature > max) {
                        max = element.temperature
                    }
                })
            }
            if(temperature2Displayed.length !== 0){
                temperature2Displayed.forEach((element) => {
                    if (element.temperature < min) {
                        min = element.temperature;
                    }
                    if (element.temperature > max) {
                        max = element.temperature
                    }
                })
            }
            if(temperature3Displayed.length !== 0){
                temperature3Displayed.forEach((element) => {
                    if (element.temperature < min) {
                        min = element.temperature;
                    }
                    if (element.temperature > max) {
                        max = element.temperature
                    }
                })
            }
            if(temperature4Displayed.length !== 0) {
                temperature4Displayed.forEach((element) => {
                    if (element.temperature < min) {
                        min = element.temperature;
                    }
                    if (element.temperature > max) {
                        max = element.temperature
                    }
                })
            }
        setDisplayBoundsTemperature([Math.ceil(min - 1), Math.floor(max + 1)]);
        setSaveBoundsTemperature([Math.ceil(min - 1), Math.floor(max + 1)]);
    }, [temperatureDisplayed, temperature2Displayed, temperature3Displayed, temperature4Displayed]);

    //set the display humidity value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        if(humidityDisplayed.length !== 0){
            humidityDisplayed.forEach((element) => {
                if (element.humidity < min) {
                    min = element.humidity;
                }
                if (element.humidity > max) {
                    max = element.humidity
                }
            })
        }
        if(humidity2Displayed.length !== 0){
            humidity2Displayed.forEach((element) => {
                if (element.humidity < min) {
                    min = element.humidity;
                }
                if (element.humidity > max) {
                    max = element.humidity
                }
            })
        }
        if(humidity3Displayed.length !== 0){
            humidity3Displayed.forEach((element) => {
                if (element.humidity < min) {
                    min = element.humidity;
                }
                if (element.humidity > max) {
                    max = element.humidity
                }
            })
        }
        if(humidity4Displayed.length !== 0){
            humidity4Displayed.forEach((element) => {
                if (element.humidity < min) {
                    min = element.humidity;
                }
                if (element.humidity > max) {
                    max = element.humidity
                }
            })
        }
        setDisplayBoundsHumidity([Math.ceil(min - 1), Math.floor(max + 1)]);
        setSaveBoundsHumidity([Math.ceil(min - 1), Math.floor(max + 1)]);
    }, [humidityDisplayed, humidity2Displayed, humidity3Displayed, humidity4Displayed]);

    //set the display atmPressure value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        if (atmosphericPressureDisplayed.length !== 0) {
            atmosphericPressureDisplayed.forEach((element) => {
                if (element.atmPressure < min) {
                    min = element.atmPressure;
                }
                if (element.atmPressure > max) {
                    max = element.atmPressure
                }
            })
        }
        if (atmosphericPressure2Displayed.length !== 0) {
            atmosphericPressure2Displayed.forEach((element) => {
                if (element.atmPressure < min) {
                    min = element.atmPressure;
                }
                if (element.atmPressure > max) {
                    max = element.atmPressure
                }
            })
        }
        if (atmosphericPressure3Displayed.length !== 0) {
            atmosphericPressure3Displayed.forEach((element) => {
                if (element.atmPressure < min) {
                    min = element.atmPressure;
                }
                if (element.atmPressure > max) {
                    max = element.atmPressure
                }
            })
        }
        if (atmosphericPressure4Displayed.length !== 0) {
            atmosphericPressure4Displayed.forEach((element) => {
                if (element.atmPressure < min) {
                    min = element.atmPressure;
                }
                if (element.atmPressure > max) {
                    max = element.atmPressure
                }
            })
        }
        setDisplayBoundsAtmPressure([min - 20, max + 20]);
        setSaveBoundsAtmPressure([min - 20, max + 20]);
    }, [atmosphericPressureDisplayed, atmosphericPressure2Displayed, atmosphericPressure3Displayed, atmosphericPressure4Displayed]);

    //set the display acceleration X value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        if(accelerationXDisplayed.length !== 0) {
            accelerationXDisplayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        if(accelerationYDisplayed.length !== 0) {
            accelerationYDisplayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        if(accelerationZDisplayed.length !== 0) {
            accelerationZDisplayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        if(accelerationX2Displayed.length !== 0) {
            accelerationX2Displayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        if(accelerationY2Displayed.length !== 0) {
            accelerationY2Displayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        if(accelerationZ2Displayed.length !== 0) {
            accelerationZ2Displayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        if(accelerationX3Displayed.length !== 0) {
            accelerationX3Displayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        if(accelerationY3Displayed.length !== 0) {
            accelerationY3Displayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        if(accelerationZ3Displayed.length !== 0) {
            accelerationZ3Displayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        if(accelerationX4Displayed.length !== 0) {
            accelerationX4Displayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        if(accelerationY4Displayed.length !== 0) {
            accelerationY4Displayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        if(accelerationZ4Displayed.length !== 0) {
            accelerationZ4Displayed.forEach((element) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
            })
        }
        setDisplayBoundsAcceleration([Math.ceil(min - 1), Math.floor(max + 1)]);
        setSaveBoundsAcceleration([Math.ceil(min - 1), Math.floor(max + 1)]);
    }, [accelerationXDisplayed, accelerationX2Displayed, accelerationX3Displayed, accelerationX4Displayed, accelerationYDisplayed,
        accelerationY2Displayed, accelerationY3Displayed, accelerationY4Displayed, accelerationZDisplayed, accelerationZ2Displayed,
        accelerationZ3Displayed, accelerationZ4Displayed]);

    //set the display battery mv value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        if (batteryMvDisplayed.length !== 0) {
            batteryMvDisplayed.forEach((element, index) => {
                if (element.batteryMv < min) {
                    min = element.batteryMv;
                }
                if (element.batteryMv > max) {
                    max = element.batteryMv
                }
            })
        }
        if (batteryMv2Displayed.length !== 0) {
            batteryMv2Displayed.forEach((element, index) => {
                if (element.batteryMv < min) {
                    min = element.batteryMv;
                }
                if (element.batteryMv > max) {
                    max = element.batteryMv
                }
            })
        }
        if (batteryMv3Displayed.length !== 0) {
            batteryMv3Displayed.forEach((element, index) => {
                if (element.batteryMv < min) {
                    min = element.batteryMv;
                }
                if (element.batteryMv > max) {
                    max = element.batteryMv
                }
            })
        }
        if (batteryMv4Displayed.length !== 0) {
            batteryMv4Displayed.forEach((element, index) => {
                if (element.batteryMv < min) {
                    min = element.batteryMv;
                }
                if (element.batteryMv > max) {
                    max = element.batteryMv
                }
            })
        }
        setDisplayBoundsBatteryMv([min - 100, max + 100]);
        setSaveBoundsBattery([min - 100, max + 100]);
    }, [batteryMvDisplayed, batteryMv2Displayed, batteryMv3Displayed, batteryMv4Displayed]);

    //set the total time, the max time and the unit time
    useEffect(() => {
        //find the latest data
        let totalTime = Math.round(stateZoom.right - stateZoom.left);

        if (!isNaN(totalTime)) {
            //choose the unit for the time depending on the duration of the data (data during how much time)
            switch (true) {
                //less than 2 min - unit in seconds
                case (totalTime < 120):
                    setUnitTime("min");
                    break;
                //less than 5h - unit in minutes
                case (totalTime < 18000):
                    setUnitTime("min");
                    break;
                //less than 5 days - unit in hours
                case (totalTime < 432000):
                    setUnitTime("h");
                    break;
                //less than 180 days - unit in days
                case (totalTime < 15552000):
                    setUnitTime("days");
                    break;
                //less than 3 years - unit in month
                case (totalTime < 94608000):
                    setUnitTime("month");
                    break;
                //more than 3 years - unit in year
                case (totalTime > 9460800):
                    setUnitTime("year");
                    break;
                default:
                    break;
            }
            //update the maxTime;
            setMaxTime(totalTime);
        }

    }, [stateZoom.left, stateZoom.right]);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //get the dataTypes
            //generate operation code
            let operation = mqttRequests.generateOperationCode("getAllDevices");
            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getAllDevices");
            mqttRequests.publish(mqttClient, "device/getAllDevices", data);

            //read incoming message and set the variables
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //the list of all devices in the db
                if(jsonParsed.devices){
                    setDevices(jsonParsed.devices);
                    //the list of all different datatype in the db with id and name
                }else if (jsonParsed.data) {
                    //set the real timestamp of the first point
                    manageData(jsonParsed.data, jsonParsed.firstPoint);
                    //display the chart
                    setChartHidden(false);
                }

            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[source, idAccount, mqttClient]);

    //return labels for the xAxis of all charts displaying the time in duration and in days/month/year
    function CustomizedTick(props) {
        const {x, y, payload} = props;
        //find the duration between the first point and now

        //set the actual time in date format
        let displayDate = new Date(payload.value);
        //display the infos differently according to the duration of the data asked
        switch (true) {
            //less than 5 days : days + hours
            case (maxTime < 432000):
                displayDate = moment(displayDate).format('MM/DD-HH:mm');
                break;
            //less than 4 month : days
            case (maxTime < 10512008):
                displayDate = moment(displayDate).format('MM/DD');
                break;
            //less than 2.5 years : month + year
            case (maxTime < 78840000):
                displayDate = moment(displayDate).format('MM-YYYY');
                break;
            //2.5 years or more : year
            case (maxTime >= 78840000):
                displayDate = moment(displayDate).format('YYYY');
                break;
            default:
                break;
        }

        //return a value in duration and a value in date format
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} fill="#666">
                    <tspan textAnchor="middle" x="0" dy="20">
                        {displayDate}
                    </tspan>
                </text>
            </g>
        );
    }

    //returns the customized data when a user pass the mouse in a point in the chart
    const CustomTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            //if the user is on a point -> render the date formatted and the info (humidity, temperature...)
            return (
                <div className="deviceCustomTooltip">
                    <p>Time: {moment(new Date(payload[0].payload.timestamp)).format('YYYY-MM-DD HH:mm')}</p>
                    <p>{`${Object.keys(payload[0].payload)[1]}: ${Object.values(payload[0].payload)[1] + payload[1].unit}`}</p>
                </div>
            );
        } else {
            return null;
        }
    }

    //to ask mqtt to give points between time in the form
    const submitMethod = (value) => {
        //check if the beginning date is before the end date
        if (new Date(value.end) - new Date(value.beginning) < 0) {
            setErrMessage(true)
        } else {

            //ask for data
            //generate operation code
            const operation = mqttRequests.generateOperationCode("dataAsked");

            //get the start of the day in our timezone
            let beginDate = new Date(value.beginning).getTime() / 1000 + new Date().getTimezoneOffset() * 60;
            //get the end of the day in our timezone (get the start of the day + 86399 which goes to 23h59 59sec the same day)
            let finishDate = (new Date(value.end).getTime() / 1000) + new Date().getTimezoneOffset() * 60 + 86399;

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/dataAsked");

            let count = 0;
            group.forEach((element) => {
                count  = count + 1;
                if(count <= 4){
                    let idModel = "";
                    let datatypes = [];
                    devices.forEach((device) => {
                        if (device.identifier === element){
                            datatypes = device.dataType;
                            idModel = device.idModel;
                        }
                    })
                    //create json to publish
                    const data = '{"operation":"' + operation + '", "source":"' + source + '", "identifier":"' + element + '",' +
                        ' "from":"' + beginDate + '", "to":"' + finishDate + '", "idModel":"' + idModel + '", "dataType":[' + datatypes + '], "sortType":0}';

                    //publish a demand to get the data from a device
                    mqttRequests.publish(mqttClient, "data/dataAsked", data);
                }
            });

        }
    };

    //manage data
    const manageData = (data, firstPoint) => {

        //reset the slider change
        setNumberReduce(1);

        if(data.time.length !== 0){
            //find the latest data
            let totalTime = data.time[data.time.length - 1];
            setHistoryTotalTime(totalTime);

            let divisionNumber = 1;
            //choose the unit for the time depending on the duration of the data (data during how much time)
            switch (true) {
                //less than 2 min - unit in seconds
                case (totalTime < 120):
                    break;
                //less than 5h - unit in minutes
                case (totalTime < 18000):
                    setUnitTime("min");
                    divisionNumber = 60;
                    break;
                //less than 5 days - unit in hours
                case (totalTime < 432000):
                    setUnitTime("h");
                    divisionNumber = 3600;
                    break;
                //less than 180 days - unit in days
                case (totalTime < 15552000):
                    setUnitTime("days");
                    divisionNumber = 86400;
                    break;
                //less than 3 years - unit in month
                case (totalTime < 94608000):
                    setUnitTime("month");
                    divisionNumber = 2628002;
                    break;
                //more than 3 years - unit in year
                case (totalTime > 9460800):
                    setUnitTime("year");
                    divisionNumber = 31536000;
                    break;
                default:
                    break;
            }

            //set the max timestamp
            setMaxTime(data.time[data.time.length - 1]);

            //call the method to manage temperature and humidity
            setTemperatureHumidity(data, divisionNumber, firstPoint);

            //call method to manage atmospheric pressure
            setAtmPressure(data, divisionNumber, firstPoint);

            //call method to manage movement
            setMovementData(data, divisionNumber, firstPoint);

            //call method to manage acceleration
            setAccelerationData(data, divisionNumber, firstPoint);

            //call method to manage current
            setCurrentData(data, divisionNumber, firstPoint);

            //call method to manage battery
            setBatteryData(data, divisionNumber, firstPoint);
        }
    }

    //manage temperature and humidity if the device is not a teltonika
    const setTemperatureHumidity = (data, divisionNumber, firstPoint) => {
        //set the name of the content inside data
        let humidityVariable = Object.keys(data).filter(name => name.includes("Humidity_"));
        let temperatureVariable = Object.keys(data).filter(name => name.includes("Temperature_"));
        if(temperatureVariable.length !== 0){
            humidityVariable = humidityVariable[0];
            temperatureVariable = temperatureVariable[0];

            let deviceName = temperatureVariable.substring(12);

            //set the variable "temperature" with a table of all temperatures and time
            let tempTemperature = data[temperatureVariable].map((id, index_value) => {
                //return the correct temperature depending on the unit in the settings
                if (temperatureUnit === "C") {
                    return {
                        timestamp: (data["time"][index_value] + firstPoint) * 1000,
                        temperature: data[temperatureVariable][index_value],
                    }
                } else if (temperatureUnit === "F") {
                    return {
                        timestamp: (data["time"][index_value] + firstPoint) * 1000,
                        temperature: Math.round((((data[temperatureVariable][index_value] * 9 / 5) + 32) * 100) / 100),
                    }
                } else {
                    return {
                        timestamp: (data["time"][index_value] + firstPoint) * 1000,
                        temperature: Math.round(((data[temperatureVariable][index_value] + 273.15) * 100) / 100),
                    }
                }
            });

            //set the variable "humidity" with a table of all humidity and time
            let tempHumidity = data[humidityVariable].map((id, index_value) => {
                return {
                    timestamp: (data["time"][index_value] + firstPoint) * 1000,
                    humidity: data[humidityVariable][index_value],
                }
            });

            //if less than 500 points, display all points, if not, display max 500 points
            let numberReduce = 1;
            if (tempTemperature.length < 100) {
                if(group[0] === deviceName){
                    setTemperatureDisplayed(tempTemperature);
                    setHumidityDisplayed(tempHumidity);
                } else if(group[1] === deviceName) {
                    setTemperature2Displayed(tempTemperature);
                    setHumidity2Displayed(tempHumidity);
                } else if(group[2] === deviceName) {
                    setTemperature3Displayed(tempTemperature);
                    setHumidity3Displayed(tempHumidity);
                } else if(group[3] === deviceName) {
                    setTemperature4Displayed(tempTemperature);
                    setHumidity4Displayed(tempHumidity);
                }
            } else {
                if (tempTemperature.length / 100 <= 100) {
                    numberReduce = Math.ceil(tempTemperature.length / 100);
                } else {
                    numberReduce = 100;
                }
                setNumberReduce(numberReduce);
            }

            //set temperature and humidity = all points
            if(group[0] === deviceName){
                setTemperature(tempTemperature);
                setHumidity(tempHumidity);
                displayLessPointsTemperatureHumidity(tempTemperature, tempHumidity, numberReduce, setTemperatureDisplayed, setHumidityDisplayed);
                setLabelTemperature(deviceName);
                setLabelHumidity(deviceName);
            } else if(group[1] === deviceName) {
                setTemperature2(tempTemperature);
                setHumidity2(tempHumidity);
                displayLessPointsTemperatureHumidity(tempTemperature, tempHumidity, numberReduce, setTemperature2Displayed, setHumidity2Displayed);
                setLabelTemperature2(deviceName);
                setLabelHumidity2(deviceName);
            } else if(group[2] === deviceName) {
                setTemperature3(tempTemperature);
                setHumidity3(tempHumidity);
                displayLessPointsTemperatureHumidity(tempTemperature, tempHumidity, numberReduce, setTemperature3Displayed, setHumidity3Displayed);
                setLabelTemperature3(deviceName);
                setLabelHumidity3(deviceName);
            } else if(group[3] === deviceName) {
                setTemperature4(tempTemperature);
                setHumidity4(tempHumidity);
                displayLessPointsTemperatureHumidity(tempTemperature, tempHumidity, numberReduce, setTemperature4Displayed, setHumidity4Displayed);
                setLabelTemperature4(deviceName);
                setLabelHumidity4(deviceName);
            }
        }
    }

    //manage atmospheric pressure
    const setAtmPressure = (data, divisionNumber, firstPoint) => {

        //select all the atmospheric pressure
        let atmVariable = Object.keys(data).filter(name => name.includes("AtmPressure"));

        if(atmVariable.length !== 0){
            atmVariable = atmVariable[0];
            let deviceName = atmVariable.substring(12);

            //set the variable "atm pressure" with a table of all temperatures and time
            let tempAtm = data[atmVariable].map((id, index_value) => {
                return {
                    timestamp: (data["time"][index_value] + firstPoint) * 1000,
                    atmPressure: data[atmVariable][index_value],
                }
            });

            //if less than 500 points, display all points, if not, display max 500 points
            let numberReduce = 1;
            if (tempAtm.length < 100) {
                if(group[0] === deviceName){
                    setAtmosphericPressureDisplayed(tempAtm);
                } else if(group[1] === deviceName) {
                    setAtmosphericPressure2Displayed(tempAtm);
                } else if(group[2] === deviceName) {
                    setAtmosphericPressure3Displayed(tempAtm);
                } else if(group[3] === deviceName) {
                    setAtmosphericPressure4Displayed(tempAtm);
                }
            } else {
                if (tempAtm.length / 100 <= 100) {
                    numberReduce = Math.ceil(tempAtm.length / 100);
                } else {
                    numberReduce = 100;
                }
                setNumberReduce(numberReduce);
            }

            //set temperature and humidity = all points
            if(group[0] === deviceName){
                setAtmosphericPressure(tempAtm);
                displayLessPointsAtmPressure(tempAtm, numberReduce, setAtmosphericPressureDisplayed);
                setLabelAtmosphericPressure(deviceName);
            } else if(group[1] === deviceName) {
                setAtmosphericPressure2(tempAtm);
                displayLessPointsAtmPressure(tempAtm, numberReduce, setAtmosphericPressure2Displayed);
                setLabelAtmosphericPressure2(deviceName);
            } else if(group[2] === deviceName) {
                setAtmosphericPressure3(tempAtm);
                displayLessPointsAtmPressure(tempAtm, numberReduce, setAtmosphericPressure3Displayed);
                setLabelAtmosphericPressure3(deviceName);
            } else if(group[3] === deviceName) {
                setAtmosphericPressure4(tempAtm);
                displayLessPointsAtmPressure(tempAtm, numberReduce, setAtmosphericPressure4Displayed);
                setLabelAtmosphericPressure4(deviceName);
            }
        }
    }

    //manage atmospheric pressure
    const setCurrentData = (data, divisionNumber, firstPoint) => {

        //select all the atmospheric pressure
        let current1Variable = Object.keys(data).filter(name => name.includes("Current1"));
        let current2Variable = Object.keys(data).filter(name => name.includes("Current2"));

        if(current1Variable.length !== 0){

            current1Variable = current1Variable[0];
            current2Variable = current2Variable[0];
            //console.log(firstPoint)

            let deviceName = current1Variable.substring(8);

            //set the variable "atm pressure" with a table of all temperatures and time
            let tempCurrent1 = data[current1Variable].map((id, index_value) => {
                return {
                    timestamp: (data["time"][index_value] + firstPoint) * 1000,
                    current: data[current1Variable][index_value],
                }
            });

            console.log(tempCurrent1)
            //set the variable "atm pressure" with a table of all temperatures and time
            let tempCurrent2 = data[current2Variable].map((id, index_value) => {
                return {
                    timestamp: (data["time"][index_value] + firstPoint) * 1000,
                    current: data[current2Variable][index_value],
                }
            });

            //if less than 500 points, display all points, if not, display max 500 points
            let numberReduce = 1;
            if (tempCurrent1.length < 100) {
                if(group[0] === deviceName){
                    setCurrent1Displayed(tempCurrent1);
                    setCurrent2Displayed(tempCurrent2);
                } else if(group[1] === deviceName) {
                    setCurrent12Displayed(tempCurrent1);
                    setCurrent22Displayed(tempCurrent2);
                } else if(group[2] === deviceName) {
                    setCurrent13Displayed(tempCurrent1);
                    setCurrent23Displayed(tempCurrent2);
                } else if(group[3] === deviceName) {
                    setCurrent14Displayed(tempCurrent1);
                    setCurrent24Displayed(tempCurrent2);
                }
            } else {
                if (tempCurrent1.length / 100 <= 100) {
                    numberReduce = Math.ceil(tempCurrent1.length / 100);
                } else {
                    numberReduce = 100;
                }
                setNumberReduce(numberReduce);
            }

            //set temperature and humidity = all points
            if(group[0] === deviceName){
                setCurrent1(tempCurrent1);
                displayLessPointsCurrent(tempCurrent1, numberReduce, setCurrent1Displayed);
                setLabelCurrent1(deviceName);
                setCurrent2(tempCurrent2);
                displayLessPointsCurrent(tempCurrent2, numberReduce, setCurrent2Displayed);
                setLabelCurrent2(deviceName);
            } else if(group[1] === deviceName) {
                setCurrent12(tempCurrent1);
                displayLessPointsCurrent(tempCurrent1, numberReduce, setCurrent12Displayed);
                setLabelCurrent12(deviceName);
                setCurrent22(tempCurrent2);
                displayLessPointsCurrent(tempCurrent2, numberReduce, setCurrent22Displayed);
                setLabelCurrent22(deviceName);
            } else if(group[2] === deviceName) {
                setCurrent13(tempCurrent1);
                displayLessPointsCurrent(tempCurrent1, numberReduce, setCurrent13Displayed);
                setLabelCurrent13(deviceName);
                setCurrent23(tempCurrent2);
                displayLessPointsCurrent(tempCurrent2, numberReduce, setCurrent23Displayed);
                setLabelCurrent23(deviceName);
            } else if(group[3] === deviceName) {
                setCurrent14(tempCurrent1);
                displayLessPointsCurrent(tempCurrent1, numberReduce, setCurrent14Displayed);
                setLabelCurrent14(deviceName);
                setCurrent24(tempCurrent2);
                displayLessPointsCurrent(tempCurrent2, numberReduce, setCurrent24Displayed);
                setLabelCurrent24(deviceName);
            }
        }
    }

    //manage movement
    const setMovementData = (data, divisionNumber, firstPoint) => {
        //select all the movements
        let movementVariable = Object.keys(data).filter(name => name.includes("Movement"));
        if(movementVariable.length !== 0){
            movementVariable = movementVariable[0];

            let deviceName = movementVariable.substring(9);

            //set the variable "atm pressure" with a table of all temperatures and time
            let tempMovement = data[movementVariable].map((id, index_value) => {
                if (index_value === 0) {
                    return {
                        timestamp: (data["time"][index_value] + firstPoint) * 1000,
                        movement: 0,
                    }
                } else {
                    return {
                        timestamp: (data["time"][index_value] + firstPoint) * 1000,
                        movement: Math.abs((data[movementVariable][index_value] - data[movementVariable][index_value - 1]) / (data["time"][index_value] - data["time"][index_value - 1])),
                    }
                }
            });

            //if less than 500 points, display all points, if not, display max 500 points
            let numberReduce = 1;
            if (tempMovement.length < 100) {
                if(group[0] === deviceName){
                    setMovementDisplayed(tempMovement);
                } else if(group[1] === deviceName) {
                    setMovement2Displayed(tempMovement);
                } else if(group[2] === deviceName) {
                    setMovement3Displayed(tempMovement);
                } else if(group[3] === deviceName) {
                    setMovement4Displayed(tempMovement);
                }
            } else {
                if (tempMovement.length / 100 <= 100) {
                    numberReduce = Math.ceil(tempMovement.length / 100);
                } else {
                    numberReduce = 100;
                }
                setNumberReduce(numberReduce);
            }

            //set temperature and humidity = all points
            if(group[0] === deviceName){
                setMovement(tempMovement);
                displayLessPointsMovement(tempMovement, numberReduce, setMovementDisplayed);
                setLabelMovement(deviceName);
            } else if(group[1] === deviceName) {
                setMovement2(tempMovement);
                displayLessPointsMovement(tempMovement, numberReduce, setMovement2Displayed);
                setLabelMovement2(deviceName);
            } else if(group[2] === deviceName) {
                setMovement3(tempMovement);
                displayLessPointsMovement(tempMovement, numberReduce, setMovement3Displayed);
                setLabelMovement3(deviceName);
            } else if(group[3] === deviceName) {
                setMovement4(tempMovement);
                displayLessPointsMovement(tempMovement, numberReduce, setMovement4Displayed);
                setLabelMovement4(deviceName);
            }
        }
    }

    //manage acceleration
    const setAccelerationData = (data, divisionNumber, firstPoint) => {
        //select all the accelerations
        let accelerationsXVariable = Object.keys(data).filter(name => name.includes("AccelerationX"));
        let accelerationsYVariable = Object.keys(data).filter(name => name.includes("AccelerationY"));
        let accelerationsZVariable = Object.keys(data).filter(name => name.includes("AccelerationZ"));
        if(accelerationsXVariable.length !== 0){
            accelerationsXVariable = accelerationsXVariable[0];
            accelerationsYVariable = accelerationsYVariable[0];
            accelerationsZVariable = accelerationsZVariable[0];

            let deviceName = accelerationsXVariable.substring(14);

            //set the variable "atm pressure" with a table of all temperatures and time
            let tempAccelerationX = data[accelerationsXVariable].map((id, index_value) => {
                return {
                    timestamp: (data["time"][index_value] + firstPoint) * 1000,
                    acceleration: data[accelerationsXVariable][index_value],
                }
            });
            //set the variable "atm pressure" with a table of all temperatures and time
            let tempAccelerationY = data[accelerationsYVariable].map((id, index_value) => {
                return {
                    timestamp: (data["time"][index_value] + firstPoint) * 1000,
                    acceleration: data[accelerationsYVariable][index_value],
                }
            });
            //set the variable "atm pressure" with a table of all temperatures and time
            let tempAccelerationZ = data[accelerationsZVariable].map((id, index_value) => {
                return {
                    timestamp: (data["time"][index_value] + firstPoint) * 1000,
                    acceleration: data[accelerationsZVariable][index_value],
                }
            });

            //if less than 500 points, display all points, if not, display max 500 points
            let numberReduce = 1;
            if (tempAccelerationX.length < 100) {
                if(group[0] === deviceName){
                    setAccelerationXDisplayed(tempAccelerationX);
                    setAccelerationYDisplayed(tempAccelerationY);
                    setAccelerationZDisplayed(tempAccelerationZ);
                } else if(group[1] === deviceName) {
                    setAccelerationX2Displayed(tempAccelerationX);
                    setAccelerationY2Displayed(tempAccelerationY);
                    setAccelerationZ2Displayed(tempAccelerationZ);
                } else if(group[2] === deviceName) {
                    setAccelerationX3Displayed(tempAccelerationX);
                    setAccelerationY3Displayed(tempAccelerationY);
                    setAccelerationZ3Displayed(tempAccelerationZ);
                } else if(group[3] === deviceName) {
                    setAccelerationX4Displayed(tempAccelerationX);
                    setAccelerationY4Displayed(tempAccelerationY);
                    setAccelerationZ4Displayed(tempAccelerationZ);
                }
            } else {
                if (tempAccelerationX.length / 100 <= 100) {
                    numberReduce = Math.ceil(tempAccelerationX.length / 100);
                } else {
                    numberReduce = 100;
                }
                setNumberReduce(numberReduce);
            }

            //set temperature and humidity = all points
            if(group[0] === deviceName){
                setAccelerationX(tempAccelerationX);
                setAccelerationY(tempAccelerationY);
                setAccelerationZ(tempAccelerationZ);
                displayLessPointsAcceleration(tempAccelerationX, numberReduce, setAccelerationXDisplayed);
                displayLessPointsAcceleration(tempAccelerationY, numberReduce, setAccelerationYDisplayed);
                displayLessPointsAcceleration(tempAccelerationZ, numberReduce, setAccelerationZDisplayed);
                setLabelAccelerationX(deviceName);
                setLabelAccelerationY(deviceName);
                setLabelAccelerationZ(deviceName);
            } else if(group[1] === deviceName) {
                setAccelerationX2(tempAccelerationX);
                setAccelerationY2(tempAccelerationY);
                setAccelerationZ2(tempAccelerationZ);
                displayLessPointsAcceleration(tempAccelerationX, numberReduce, setAccelerationX2Displayed);
                displayLessPointsAcceleration(tempAccelerationY, numberReduce, setAccelerationY2Displayed);
                displayLessPointsAcceleration(tempAccelerationZ, numberReduce, setAccelerationZ2Displayed);
                setLabelAccelerationX2(deviceName);
                setLabelAccelerationY2(deviceName);
                setLabelAccelerationZ2(deviceName);
            } else if(group[2] === deviceName) {
                setAccelerationX3(tempAccelerationX);
                setAccelerationY3(tempAccelerationY);
                setAccelerationZ3(tempAccelerationZ);
                displayLessPointsAcceleration(tempAccelerationX, numberReduce, setAccelerationX3Displayed);
                displayLessPointsAcceleration(tempAccelerationY, numberReduce, setAccelerationY3Displayed);
                displayLessPointsAcceleration(tempAccelerationZ, numberReduce, setAccelerationZ3Displayed);
                setLabelAccelerationX3(deviceName);
                setLabelAccelerationY3(deviceName);
                setLabelAccelerationZ3(deviceName);
            } else if(group[3] === deviceName) {
                setAccelerationX4(tempAccelerationX);
                setAccelerationY4(tempAccelerationY);
                setAccelerationZ4(tempAccelerationZ);
                displayLessPointsAcceleration(tempAccelerationX, numberReduce, setAccelerationX4Displayed);
                displayLessPointsAcceleration(tempAccelerationY, numberReduce, setAccelerationY4Displayed);
                displayLessPointsAcceleration(tempAccelerationZ, numberReduce, setAccelerationZ4Displayed);
                setLabelAccelerationX4(deviceName);
                setLabelAccelerationY4(deviceName);
                setLabelAccelerationZ4(deviceName);
            }
        }
    }

    //manage battery
    const setBatteryData = (data, divisionNumber, firstPoint) => {
        //select all the batteries
        let batteryVariable = Object.keys(data).filter(name => name.includes("Battery"));
        if(batteryVariable.length !== 0){

            batteryVariable = batteryVariable[0];
            let deviceName = batteryVariable.substring(8);

            //set the variable "atm pressure" with a table of all temperatures and time
            let tempBattery = data[batteryVariable].map((id, index_value) => {
                if (deviceName.substring(0,3) === "282") {
                    return {
                        timestamp: (data["time"][index_value] + firstPoint) * 1000,
                        batteryPercent: data[batteryVariable][index_value] *100,
                    }
                    //else -> battery is in % (effento)
                } else {
                    return {
                        timestamp: (data["time"][index_value] + firstPoint) * 1000,
                        batteryMv: data[batteryVariable][index_value],
                    }
                }
            });

            if (deviceName.substring(0,3) === "282") {
                //if less than 500 points, display all points, if not, display max 500 points
                let numberReduce = 1;
                if (tempBattery.length < 500) {
                    if(group[0] === deviceName){
                        setBatteryPercentDisplayed(tempBattery);
                    } else if(group[1] === deviceName) {
                        setBatteryPercent2Displayed(tempBattery);
                    } else if(group[2] === deviceName) {
                        setBatteryPercent3Displayed(tempBattery);
                    } else if(group[3] === deviceName) {
                        setBatteryPercent4Displayed(tempBattery);
                    }
                } else {
                    if (tempBattery.length / 100 <= 100) {
                        numberReduce = Math.ceil(tempBattery.length / 100);
                    } else {
                        numberReduce = 100;
                    }
                    setNumberReduce(numberReduce);
                }

                //set temperature and humidity = all points
                if(group[0] === deviceName){
                    setBatteryPercent(tempBattery);
                    displayLessPointsBattery(tempBattery, numberReduce, setBatteryPercentDisplayed, "percent");
                    setLabelBattery(deviceName);
                } else if(group[1] === deviceName) {
                    setBatteryPercent2(tempBattery);
                    displayLessPointsBattery(tempBattery, numberReduce, setBatteryPercent2Displayed, "percent");
                    setLabelBattery2(deviceName);
                } else if(group[2] === deviceName) {
                    setBatteryPercent3(tempBattery);
                    displayLessPointsBattery(tempBattery, numberReduce, setBatteryPercent3Displayed, "percent");
                    setLabelBattery3(deviceName);
                } else if(group[3] === deviceName) {
                    setBatteryPercent4(tempBattery);
                    displayLessPointsBattery(tempBattery, numberReduce, setBatteryPercent4Displayed, "percent");
                    setLabelBattery4(deviceName);
                }
            } else {
                //if less than 500 points, display all points, if not, display max 500 points
                let numberReduce = 1;
                if (tempBattery.length < 100) {
                    if(group[0] === deviceName){
                        setBatteryMvDisplayed(tempBattery);
                    } else if(group[1] === deviceName) {
                        setBatteryMv2Displayed(tempBattery);
                    } else if(group[2] === deviceName) {
                        setBatteryMv3Displayed(tempBattery);
                    } else if(group[3] === deviceName) {
                        setBatteryMv4Displayed(tempBattery);
                    }
                } else {
                    if (tempBattery.length / 100 <= 100) {
                        numberReduce = Math.ceil(tempBattery.length / 100);
                    } else {
                        numberReduce = 100;
                    }
                    setNumberReduce(numberReduce);
                }

                //set temperature and humidity = all points
                if(group[0] === deviceName){
                    setBatteryMv(tempBattery);
                    displayLessPointsBattery(tempBattery, numberReduce, setBatteryMvDisplayed, "mv");
                    setLabelBattery(deviceName);
                } else if(group[1] === deviceName) {
                    setBatteryMv2(tempBattery);
                    displayLessPointsBattery(tempBattery, numberReduce, setBatteryMv2Displayed, "mv");
                    setLabelBattery2(deviceName);
                } else if(group[2] === deviceName) {
                    setBatteryMv3(tempBattery);
                    displayLessPointsBattery(tempBattery, numberReduce, setBatteryMv3Displayed, "mv");
                    setLabelBattery3(deviceName);
                } else if(group[3] === deviceName) {
                    setBatteryMv4(tempBattery);
                    displayLessPointsBattery(tempBattery, numberReduce, setBatteryMv4Displayed, "mv");
                    setLabelBattery4(deviceName);
                }
            }
        }
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for temperature and humidity
    const displayLessPointsTemperatureHumidity = (temperatureTable, humidityTable, number, actionTemperature, actionHumidity) => {
        if(temperatureTable.length !== 0){
            //reduce the number of points for temperature
            let modulo = -1;
            let tempTemperature = temperatureTable.map((temp) => {
                modulo++;
                if (modulo % number === 0) {
                    return {
                        timestamp: temp.timestamp,
                        temperature: temp.temperature,
                    }
                }
                return undefined;
            });
            //actionTemperature = setTemperatureDisplayed, we remove the undefined data
            actionTemperature(tempTemperature.filter(element => element !== undefined));

            //reduce the number of points for humidity
            let moduloHumidity = -1;
            let tempHumidity = humidityTable.map((humi) => {
                moduloHumidity++;
                if (moduloHumidity % number === 0) {
                    return {
                        timestamp: humi.timestamp,
                        humidity: humi.humidity,
                    }
                }
                return undefined;
            });
            //actionHumidity = setHumidityDisplayed, we remove the undefined data
            actionHumidity(tempHumidity.filter(element => element !== undefined));
        }
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for atmospheric pressure
    const displayLessPointsAtmPressure = (atmPressureTable, number, actionAtmPressure) => {
        if(atmPressureTable.length !== 0){
            //reduce the number of points for atmospheric pressure
            let modulo = -1;
            let tempAtmPressure = atmPressureTable.map((temp) => {
                modulo++;
                if (modulo % number === 0) {
                    return {
                        timestamp: temp.timestamp,
                        atmPressure: temp.atmPressure,
                    }
                }
                return undefined;
            });
            //actionAtmPressure = setAtmosphericPressureDisplayed, we remove undefined values
            actionAtmPressure(tempAtmPressure.filter(element => element !== undefined));
        }
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for current
    const displayLessPointsCurrent = (currentTable, number, actionCurrent) => {
        if(currentTable.length !== 0){
            //reduce the number of points for atmospheric pressure
            let modulo = -1;
            let tempCurrent = currentTable.map((temp) => {
                modulo++;
                if (modulo % number === 0) {
                    return {
                        timestamp: temp.timestamp,
                        current: temp.current,
                    }
                }
                return undefined;
            });
            //actionAtmPressure = setAtmosphericPressureDisplayed, we remove undefined values
            actionCurrent(tempCurrent.filter(element => element !== undefined));
        }
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for movement
    const displayLessPointsMovement = (movementTable, number, actionMovement) => {
        if(movementTable.length !== 0){
            //reduce the number of points for movement
            let modulo = -1;
            let tempMovement = movementTable.map((temp) => {
                modulo++;
                if (modulo % number === 0) {
                    return {
                        timestamp: temp.timestamp,
                        movement: temp.movement,
                    }
                }
                return undefined;
            });
            //actionTemperature = setTemperatureDisplayed, we remove undefined values
            actionMovement(tempMovement.filter(element => element !== undefined));
        }
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for acceleration
    const displayLessPointsAcceleration = (accelerationTable, number, actionAcceleration) => {
        if(accelerationTable.length !== 0){
            //reduce the number of points for movement
            let modulo = -1;
            let tempAcceleration = accelerationTable.map((temp) => {
                modulo++;
                if (modulo % number === 0) {
                    return {
                        timestamp: temp.timestamp,
                        acceleration: temp.acceleration,
                    }
                }
                return undefined;
            });
            //actionAcceleration = setAcceleration..., we remove undefined values
            actionAcceleration(tempAcceleration.filter(element => element !== undefined));
        }
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for battery
    const displayLessPointsBattery = (batteryTable, number, actionBattery, type) => {
        if(batteryTable.length !== 0){
            //reduce the number of points for battery
            let modulo = -1;
            let tempBattery = batteryTable.map((temp) => {
                modulo++;
                if (modulo % number === 0) {
                    if (type === "percent") {
                        return {
                            timestamp: temp.timestamp,
                            batteryPercent: temp.batteryPercent,
                        }
                    } else if (type === "mv")
                        return {
                            timestamp: temp.timestamp,
                            batteryMv: temp.batteryMv,
                        }
                }
                return undefined;
            });
            //actionTemperature = setTemperatureDisplayed, we remove undefined values
            actionBattery(tempBattery.filter(element => element !== undefined));
        }
    }

    //actions when the user change the slider (modify the display of the slider)
    const handleSliderChange = (event, newValue) => {
        setNumberReduce(newValue);
    }

    //action when the user release the slider (modify the number of points in variables)
    const handleSliderChangeCommited = (event, newValue) => {
        if(temperature.length !== 0) {
            displayLessPointsTemperatureHumidity(temperature, humidity, newValue, setTemperatureDisplayed, setHumidityDisplayed);
        }
        if(atmosphericPressure.length !== 0) {
            displayLessPointsAtmPressure(atmosphericPressure, newValue, setAtmosphericPressureDisplayed);
        }
        if(movement.length !== 0){
            displayLessPointsMovement(movement, newValue, setMovementDisplayed);
        }
        if(accelerationX.length !== 0){
            displayLessPointsAcceleration(accelerationX, newValue, setAccelerationXDisplayed);
            displayLessPointsAcceleration(accelerationY, newValue, setAccelerationYDisplayed);
            displayLessPointsAcceleration(accelerationZ, newValue, setAccelerationZDisplayed);
        }
        if(batteryMv.length !== 0){
            displayLessPointsBattery(batteryMv, newValue, setBatteryMvDisplayed, "mv");
        }
        if(batteryPercent.length !== 0){
            displayLessPointsBattery(batteryPercent, newValue, setBatteryPercentDisplayed, "percent");
        }
        if(temperature2.length !== 0){
            displayLessPointsTemperatureHumidity(temperature2, humidity2, newValue, setTemperature2Displayed, setHumidity2Displayed);
        }
        if(atmosphericPressure2.length !== 0) {
            displayLessPointsAtmPressure(atmosphericPressure2, newValue, setAtmosphericPressure2Displayed);
        }
        if(movement2.length !== 0){
            displayLessPointsMovement(movement2, newValue, setMovement2Displayed);
        }
        if(accelerationX2.length !== 0){
            displayLessPointsAcceleration(accelerationX2, newValue, setAccelerationX2Displayed);
            displayLessPointsAcceleration(accelerationY2, newValue, setAccelerationY2Displayed);
            displayLessPointsAcceleration(accelerationZ2, newValue, setAccelerationZ2Displayed);
        }
        if(batteryMv2.length !== 0){
            displayLessPointsBattery(batteryMv2, newValue, setBatteryMv2Displayed, "mv");
        }
        if(batteryPercent2.length !== 0){
            displayLessPointsBattery(batteryPercent2, newValue, setBatteryPercent2Displayed, "percent");
        }
        if(temperature3.length !== 0){
            displayLessPointsTemperatureHumidity(temperature3, humidity3, newValue, setTemperature3Displayed, setHumidity3Displayed);
        }
        if(atmosphericPressure3.length !== 0) {
            displayLessPointsAtmPressure(atmosphericPressure3, newValue, setAtmosphericPressure3Displayed);
        }
        if(movement3.length !== 0){
            displayLessPointsMovement(movement3, newValue, setMovement3Displayed);
        }
        if(accelerationX3.length !== 0){
            displayLessPointsAcceleration(accelerationX3, newValue, setAccelerationX3Displayed);
            displayLessPointsAcceleration(accelerationY3, newValue, setAccelerationY3Displayed);
            displayLessPointsAcceleration(accelerationZ3, newValue, setAccelerationZ3Displayed);
        }
        if(batteryMv3.length !== 0){
            displayLessPointsBattery(batteryMv3, newValue, setBatteryMv3Displayed, "mv");
        }
        if(batteryPercent3.length !== 0){
            displayLessPointsBattery(batteryPercent3, newValue, setBatteryPercent3Displayed, "percent");
        }
        if(temperature4.length !== 0){
            displayLessPointsTemperatureHumidity(temperature4, humidity4, newValue, setTemperature4Displayed, setHumidity4Displayed);
        }
        if(atmosphericPressure4.length !== 0) {
            displayLessPointsAtmPressure(atmosphericPressure4, newValue, setAtmosphericPressure4Displayed);
        }
        if(movement4.length !== 0){
            displayLessPointsMovement(movement4, newValue, setMovement4Displayed);
        }
        if(accelerationX4.length !== 0){
            displayLessPointsAcceleration(accelerationX4, newValue, setAccelerationX4Displayed);
            displayLessPointsAcceleration(accelerationY4, newValue, setAccelerationY4Displayed);
            displayLessPointsAcceleration(accelerationZ4, newValue, setAccelerationZ4Displayed);
        }
        if(batteryMv4.length !== 0){
            displayLessPointsBattery(batteryMv4, newValue, setBatteryMv4Displayed, "mv");
        }
        if(batteryPercent4.length !== 0){
            displayLessPointsBattery(batteryPercent4, newValue, setBatteryPercent4Displayed, "percent");
        }
        if(current1.length !== 0){
            displayLessPointsCurrent(current1, newValue, setCurrent1Displayed);
            displayLessPointsCurrent(current2, newValue, setCurrent2Displayed);
        }
        if(current12.length !== 0){
            displayLessPointsCurrent(current12, newValue, setCurrent12Displayed);
            displayLessPointsCurrent(current22, newValue, setCurrent22Displayed);

        }
        if(current13.length !== 0){
            displayLessPointsCurrent(current13, newValue, setCurrent13Displayed);
            displayLessPointsCurrent(current23, newValue, setCurrent23Displayed);

        }
        if(current14.length !== 0){
            displayLessPointsCurrent(current14, newValue, setCurrent14Displayed);
            displayLessPointsCurrent(current24, newValue, setCurrent24Displayed);

        }
    }

    //method to zoom, activate when the user release the click
    const zoom = () => {
        //recover refAreaLeft and refAreaRight from the state
        let {refAreaLeft, refAreaRight} = stateZoom;

        //if no ref right or the 2 are the same (user made a simple click on the chart), don't zoom and reset the refs
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaLeft: '',
                refAreaRight: '',
            }))
            return;
        }

        try {
            // set the right and the left area in the correct order (if the user select from right to left)
            if (refAreaLeft > refAreaRight) {
                [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
            }

            //select the first element and the last element which must be displayed
            let startElement = -1;
            let endElement = -1;
            if(temperatureDisplayed.length !== 0){
                temperatureDisplayed.forEach((element, index) => {
                    if (startElement === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement = index;
                        }
                    }
                    if (endElement === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement = index;
                        }
                    }
                })
            }else if(current1Displayed.length !== 0) {
                current1Displayed.forEach((element, index) => {
                    if (startElement === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement = index;
                        }
                    }
                    if (endElement === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement = index;
                        }
                    }
                })
            }

            let startElement2 = -1;
            let endElement2 = -1;
            if(temperature2Displayed.length !== 0){
                temperature2Displayed.forEach((element, index) => {
                    if (startElement2 === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement2 = index;
                        }
                    }
                    if (endElement2 === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement2 = index;
                        }
                    }
                })
            }else if(current12Displayed.length !== 0) {
                current12Displayed.forEach((element, index) => {
                    if (startElement2 === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement2 = index;
                        }
                    }
                    if (endElement2 === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement2 = index;
                        }
                    }
                })
            }

            let startElement3 = -1;
            let endElement3 = -1;
            if(temperature3Displayed.length !== 0){
                temperature3Displayed.forEach((element, index) => {
                    if (startElement3 === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement3 = index;
                        }
                    }
                    if (endElement3 === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement3 = index;
                        }
                    }
                })
            }else if(current13Displayed.length !== 0) {
                current13Displayed.forEach((element, index) => {
                    if (startElement3 === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement3 = index;
                        }
                    }
                    if (endElement3 === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement3 = index;
                        }
                    }
                })
            }

            let startElement4 = -1;
            let endElement4 = -1;
            if(temperature4Displayed.length !== 0){
                temperature4Displayed.forEach((element, index) => {
                    if (startElement4 === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement4 = index;
                        }
                    }
                    if (endElement4 === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement4 = index;
                        }
                    }
                })
            }else if(current14Displayed.length !== 0) {
                current14Displayed.forEach((element, index) => {
                    if (startElement4 === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement4 = index;
                        }
                    }
                    if (endElement4 === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement4 = index;
                        }
                    }
                })
            }

            //restrain the number of data in the variables displayed temperature and humidity
            setTemperatureDisplayed(temperatureDisplayed.slice(startElement, endElement));
            setHumidityDisplayed(humidityDisplayed.slice(startElement, endElement));
            setTemperature2Displayed(temperature2Displayed.slice(startElement2, endElement2));
            setTemperature3Displayed(temperature3Displayed.slice(startElement3, endElement3));
            setTemperature4Displayed(temperature4Displayed.slice(startElement4, endElement4));
            setHumidity2Displayed(humidity2Displayed.slice(startElement2, endElement2));
            setHumidity3Displayed(humidity3Displayed.slice(startElement3, endElement3));
            setHumidity4Displayed(humidity4Displayed.slice(startElement4, endElement4));
            //restrain the number of data in the variables displayed movement
            setMovementDisplayed(movementDisplayed.slice(startElement, endElement));
            setMovement2Displayed(movement2Displayed.slice(startElement2, endElement2));
            setMovement3Displayed(movement3Displayed.slice(startElement3, endElement3));
            setMovement4Displayed(movement4Displayed.slice(startElement4, endElement4));
            //restrain the number of data in the variables displayed atmospheric pressure
            setAtmosphericPressureDisplayed(atmosphericPressureDisplayed.slice(startElement, endElement));
            setAtmosphericPressure2Displayed(atmosphericPressure2Displayed.slice(startElement2, endElement2));
            setAtmosphericPressure3Displayed(atmosphericPressure3Displayed.slice(startElement3, endElement3));
            setAtmosphericPressure4Displayed(atmosphericPressure4Displayed.slice(startElement4, endElement4));
            //restrain the number of data in the variables displayed acceleration
            setAccelerationXDisplayed(accelerationXDisplayed.slice(startElement, endElement));
            setAccelerationYDisplayed(accelerationYDisplayed.slice(startElement, endElement));
            setAccelerationZDisplayed(accelerationZDisplayed.slice(startElement, endElement));
            setAccelerationX2Displayed(accelerationX2Displayed.slice(startElement2, endElement2));
            setAccelerationX3Displayed(accelerationX3Displayed.slice(startElement3, endElement3));
            setAccelerationX4Displayed(accelerationX4Displayed.slice(startElement4, endElement4));
            setAccelerationY2Displayed(accelerationY2Displayed.slice(startElement2, endElement2));
            setAccelerationY3Displayed(accelerationY3Displayed.slice(startElement3, endElement3));
            setAccelerationY4Displayed(accelerationY4Displayed.slice(startElement4, endElement4));
            setAccelerationZ2Displayed(accelerationZ2Displayed.slice(startElement2, endElement2));
            setAccelerationZ3Displayed(accelerationZ3Displayed.slice(startElement3, endElement3));
            setAccelerationZ4Displayed(accelerationZ4Displayed.slice(startElement4, endElement4));
            //restrain the number of data in the variables displayed battery
            setBatteryMvDisplayed(batteryMvDisplayed.slice(startElement, endElement));
            setBatteryPercentDisplayed(batteryPercentDisplayed.slice(startElement, endElement));
            setBatteryMv2Displayed(batteryMv2Displayed.slice(startElement2, endElement2));
            setBatteryMv3Displayed(batteryMv3Displayed.slice(startElement3, endElement3));
            setBatteryMv4Displayed(batteryMv4Displayed.slice(startElement4, endElement4));
            setBatteryPercent2Displayed(batteryPercent2Displayed.slice(startElement2, endElement2));
            setBatteryPercent3Displayed(batteryPercent3Displayed.slice(startElement3, endElement3));
            setBatteryPercent4Displayed(batteryPercent4Displayed.slice(startElement4, endElement4));
            setCurrent1Displayed(current1Displayed.slice(startElement, endElement));
            setCurrent12Displayed(current12Displayed.slice(startElement2, endElement2));
            setCurrent13Displayed(current13Displayed.slice(startElement3, endElement3));
            setCurrent14Displayed(current14Displayed.slice(startElement4, endElement4));
            setCurrent2Displayed(current2Displayed.slice(startElement, endElement));
            setCurrent22Displayed(current22Displayed.slice(startElement2, endElement2));
            setCurrent23Displayed(current23Displayed.slice(startElement3, endElement3));
            setCurrent24Displayed(current24Displayed.slice(startElement4, endElement4));

            //set the new state of zoom
            setStateZoom({
                refAreaLeft: '',
                refAreaRight: '',
                left: refAreaLeft,
                right: refAreaRight,
                animation: true,
            });
            //in case of error, display it in the console
        } catch (err) {
            console.error(err);
        }
    }

    //method to zoom out, reset the values at as they were before the zoom
    const zoomOut = () => {
        //reset the values for temperature and humidity
        displayLessPointsTemperatureHumidity(temperature, humidity, numberReduced, setTemperatureDisplayed, setHumidityDisplayed);
        displayLessPointsTemperatureHumidity(temperature2, humidity2, numberReduced, setTemperature2Displayed, setHumidity2Displayed);
        displayLessPointsTemperatureHumidity(temperature3, humidity3, numberReduced, setTemperature3Displayed, setHumidity3Displayed);
        displayLessPointsTemperatureHumidity(temperature4, humidity4, numberReduced, setTemperature4Displayed, setHumidity4Displayed);
        //reset the values for movement
        displayLessPointsMovement(movement, numberReduced, setMovementDisplayed);
        displayLessPointsMovement(movement2, numberReduced, setMovement2Displayed);
        displayLessPointsMovement(movement3, numberReduced, setMovement3Displayed);
        displayLessPointsMovement(movement4, numberReduced, setMovement4Displayed);
        //reset the values for atmospheric pressure
        displayLessPointsAtmPressure(atmosphericPressure, numberReduced, setAtmosphericPressureDisplayed);
        displayLessPointsAtmPressure(atmosphericPressure2, numberReduced, setAtmosphericPressure2Displayed);
        displayLessPointsAtmPressure(atmosphericPressure3, numberReduced, setAtmosphericPressure3Displayed);
        displayLessPointsAtmPressure(atmosphericPressure4, numberReduced, setAtmosphericPressure4Displayed);
        //reset the values for acceleration
        displayLessPointsAcceleration(accelerationX, numberReduced, setAccelerationXDisplayed);
        displayLessPointsAcceleration(accelerationY, numberReduced, setAccelerationYDisplayed);
        displayLessPointsAcceleration(accelerationZ, numberReduced, setAccelerationZDisplayed);
        displayLessPointsAcceleration(accelerationX2, numberReduced, setAccelerationX2Displayed);
        displayLessPointsAcceleration(accelerationX3, numberReduced, setAccelerationX3Displayed);
        displayLessPointsAcceleration(accelerationX4, numberReduced, setAccelerationX4Displayed);
        displayLessPointsAcceleration(accelerationY2, numberReduced, setAccelerationY2Displayed);
        displayLessPointsAcceleration(accelerationY3, numberReduced, setAccelerationY3Displayed);
        displayLessPointsAcceleration(accelerationY4, numberReduced, setAccelerationY4Displayed);
        displayLessPointsAcceleration(accelerationZ2, numberReduced, setAccelerationZ2Displayed);
        displayLessPointsAcceleration(accelerationZ3, numberReduced, setAccelerationZ3Displayed);
        displayLessPointsAcceleration(accelerationZ4, numberReduced, setAccelerationZ4Displayed);
        //reset the values for battery
        displayLessPointsBattery(batteryMv, numberReduced, setBatteryMvDisplayed, "mv");
        displayLessPointsBattery(batteryMv2, numberReduced, setBatteryMv2Displayed, "mv");
        displayLessPointsBattery(batteryMv3, numberReduced, setBatteryMv3Displayed, "mv");
        displayLessPointsBattery(batteryMv4, numberReduced, setBatteryMv4Displayed, "mv");
        displayLessPointsBattery(batteryPercent, numberReduced, setBatteryPercentDisplayed, "percent");
        displayLessPointsBattery(batteryPercent2, numberReduced, setBatteryPercent2Displayed, "percent");
        displayLessPointsBattery(batteryPercent3, numberReduced, setBatteryPercent3Displayed, "percent");
        displayLessPointsBattery(batteryPercent4, numberReduced, setBatteryPercent4Displayed, "percent");
        //reset the value for current
        displayLessPointsCurrent(current1, numberReduced, setCurrent1Displayed);
        displayLessPointsCurrent(current12, numberReduced, setCurrent12Displayed);
        displayLessPointsCurrent(current13, numberReduced, setCurrent13Displayed);
        displayLessPointsCurrent(current14, numberReduced, setCurrent14Displayed);
        displayLessPointsCurrent(current2, numberReduced, setCurrent2Displayed);
        displayLessPointsCurrent(current22, numberReduced, setCurrent22Displayed);
        displayLessPointsCurrent(current23, numberReduced, setCurrent23Displayed);
        displayLessPointsCurrent(current24, numberReduced, setCurrent24Displayed);

        //reset the total time
        let totalTime = historyTotalTime;

        //reset the division number
        //choose the unit for the time depending on the duration of the data (data during how much time)
        switch (true) {
            //less than 2 min - unit in seconds
            case (totalTime < 120):
                setUnitTime("min");
                break;
            //less than 5h - unit in minutes
            case (totalTime < 18000):
                setUnitTime("min");
                break;
            //less than 5 days - unit in hours
            case (totalTime < 432000):
                setUnitTime("h");
                break;
            //less than 180 days - unit in days
            case (totalTime < 15552000):
                setUnitTime("days");
                break;
            //less than 3 years - unit in month
            case (totalTime < 94608000):
                setUnitTime("month");
                break;
            //more than 3 years - unit in year
            case (totalTime > 9460800):
                setUnitTime("year");
                break;
            default:
                break;
        }
        //update the maxTime;
        setMaxTime(totalTime);

        //reset the state
        setStateZoom({
            left: 'dataMin',
            right: dataMax => Math.ceil(dataMax),
            refAreaLeft: '',
            refAreaRight: '',
            animation: true,
        });
    }

    //activate when a user click down, set the state refAreaLeft
    const handleMouseDown = e => {
        if (e !== null) {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaLeft: e.xValue,
            }))
        }
    }

    //activate when a user move the mouse in the plot, set the state refAreaRight
    const handleMouseMove = e => {
        if (e !== null) {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaRight: e.xValue,
            }))
        }
    }

    //function to reset axis for temperature
    const resetYAxisTemperature = () => {
        setBoundsTemperatureYAxis([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
        setDisplayBoundsTemperature(saveBoundsTemperature);
    }

    //function to reset axis for humidity
    const resetYAxisHumidity = () => {
        setBoundsHumidityYAxis([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
        setDisplayBoundsHumidity(saveBoundsHumidity);
    }

    //function to reset axis for atmosphericPressure
    const resetYAxisAtmPressure = () => {
        setBoundsAtmPressureYAxis([dataMin => Math.ceil(dataMin - 20), dataMax => Math.floor(dataMax + 20)]);
        setDisplayBoundsAtmPressure(saveBoundsAtmPressure);
    }

    //function to reset axis for acceleration
    const resetYAxisAcceleration = () => {
        setBoundsAccelerationYAxis([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
        setDisplayBoundsAcceleration(saveBoundsAcceleration);
    }

    //function to reset axis for battery %
    const resetYAxisBatteryPercent = () => {
        setBoundsBatteryPercentYAxis([0, 100]);
    }

    //function to reset axis for battery mV
    const resetYAxisBatteryMv = () => {
        setBoundsBatteryMvYAxis([dataMin => Math.ceil(dataMin - 100), dataMax => Math.floor(dataMax + 100)]);
        setDisplayBoundsBatteryMv(saveBoundsBatteryMv)
    }

    //changes when user modify the form
    const handleFormBoundsBatteryMvFirst = e => {
        setBoundsBatteryMvYAxis([parseInt(e.target.value), boundsBatteryMvYAxis[1]]);
        setDisplayBoundsBatteryMv([parseInt(e.target.value), displayBoundsBatteryMv[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsBatteryMvLast = e => {
        setBoundsBatteryMvYAxis([boundsBatteryMvYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsBatteryMv([displayBoundsBatteryMv[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsTemperatureFirst = e => {
        setBoundsTemperatureYAxis([parseInt(e.target.value), boundsTemperatureYAxis[1]]);
        setDisplayBoundsTemperature([parseInt(e.target.value), displayBoundsTemperature[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsTemperatureLast = e => {
        setBoundsTemperatureYAxis([boundsTemperatureYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsTemperature([displayBoundsTemperature[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsHumidityFirst = e => {
        setBoundsHumidityYAxis([parseInt(e.target.value), boundsHumidityYAxis[1]]);
        setDisplayBoundsHumidity([parseInt(e.target.value), displayBoundsHumidity[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsHumidityLast = e => {
        setBoundsHumidityYAxis([boundsHumidityYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsHumidity([displayBoundsHumidity[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsAtmPressureFirst = e => {
        setBoundsAtmPressureYAxis([parseInt(e.target.value), boundsAtmPressureYAxis[1]]);
        setDisplayBoundsAtmPressure([parseInt(e.target.value), displayBoundsAtmPressure[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsAtmPressureLast = e => {
        setBoundsAtmPressureYAxis([boundsAtmPressureYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsAtmPressure([displayBoundsAtmPressure[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsAccelerationFirst = e => {
        setBoundsAccelerationYAxis([parseInt(e.target.value), boundsAccelerationYAxis[1]]);
        setDisplayBoundsAcceleration([parseInt(e.target.value), displayBoundsAcceleration[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsAccelerationLast = e => {
        setBoundsAccelerationYAxis([boundsAccelerationYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsAcceleration([displayBoundsAcceleration[0], parseInt(e.target.value)]);
    }

    const nameFromIdentifier = (id) => {
        let chosenDevice = '';
        devices.forEach((element) => {
            if(element.identifier === id){
                chosenDevice = element;
            }
        });
        if(chosenDevice !== ''){
            return chosenDevice.customName;
        }else {
            return id;
        }
    }

    const labelFromIdentifier = (id) => {
        let chosenDevice = '';
        devices.forEach((element) => {
            if(element.identifier === id){
                chosenDevice = element;
            }
        });
        if(chosenDevice !== ''){
            return chosenDevice.customName + '(' + id + ')';
        }else {
            return id;
        }
    }

    //display the form, the button to download, the charts and the map
    return (
        <div className="baseMainDiv">
            <h2 className="baseTitle">Data from group: {groupNameData}</h2>
            <div className="devicesFlexboxDiv">
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object({})}
                    onSubmit={submitMethod}
                >
                    {() => {
                        return (
                            <Form>
                                <div className="devicesDivTxt">
                                    <FormikControl control="input" type="date" label="From: " name="beginning"/>
                                    <FormikControl control="input" type="date" label="To: " name="end"/>
                                </div>
                                <button type="submit" className="devicesButton">Validate</button>
                            </Form>
                        )
                    }}
                </Formik>
                {!chartHidden ?
                    <div className="devicesFlexboxDivLeftDisplay">
                        {temperature.length !== 0 || temperature2.length !== 0 || temperature3.length !== 0 || temperature4.length!== 0 ?
                            temperatureCheckbox ?
                                <img className="devicesimg" src={TemperatureYes} onClick={() => setTemperatureCheckbox(!temperatureCheckbox)} alt="Temperature" style={{color:"#06c"}}/>
                                :
                                <img className="devicesimg" src={TemperatureNo} onClick={() => setTemperatureCheckbox(!temperatureCheckbox)} alt="Temperature"/>
                            :
                            null
                        }
                        {humidity.length !== 0 || humidity2.length !== 0 || humidity3.length !== 0 || humidity4.length!== 0 ?
                            humidityCheckbox ?
                                <img className="devicesimg" src={HumidityYes} onClick={() => setHumidityCheckbox(!humidityCheckbox)} alt="Humidity" style={{color:"#06c"}}/>
                                :
                                <img className="devicesimg" src={HumidityNo} onClick={() => setHumidityCheckbox(!humidityCheckbox)} alt="Humidity"/>
                            :
                            null
                        }
                        {current1.length !== 0 || current12.length !== 0 || current13.length !== 0 || current14.length !== 0 ?
                            currentCheckbox ?
                                <img className="devicesimg" src={CurrentYes} onClick={() => setCurrentCheckbox(!currentCheckbox)} alt="Current" style={{color:"#06c"}}/>
                                :
                                <img className="devicesimg" src={CurrentNo} onClick={() => setCurrentCheckbox(!currentCheckbox)} alt="Current"/>
                            :
                            null
                        }
                        {atmosphericPressure.length !== 0 || atmosphericPressure2.length !== 0 || atmosphericPressure3.length !== 0 || atmosphericPressure4.length !== 0 ?
                            atmPressureCheckbox ?
                                <img className="devicesimg" src={PressureYes} onClick={() => setAtmPressureCheckbox(!atmPressureCheckbox)} alt="Atm. Pressure" style={{color:"#06c"}}/>
                                :
                                <img className="devicesimg" src={PressureNo} onClick={() => setAtmPressureCheckbox(!atmPressureCheckbox)} alt="Atm. Pressure"/>
                            :
                            null
                        }
                        {movement.length !== 0 || movement2.length !== 0 || movement3.length !== 0 || movement4.length !== 0 ?
                            movementCheckbox ?
                                <img className="devicesimg" src={MovementYes} onClick={() => setMovementCheckbox(!movementCheckbox)} alt="Movement" style={{color:"#06c"}}/>
                                :
                                <img className="devicesimg" src={MovementNo} onClick={() => setMovementCheckbox(!movementCheckbox)} alt="Movement"/>
                            :
                            null
                        }
                        {accelerationX.length !== 0 || accelerationX2.length !== 0 || accelerationX3.length !== 0 || accelerationX4.length !== 0 ?
                            accelerationCheckbox ?
                                <img className="devicesimg" src={AccelerationYes} onClick={() => setAccelerationCheckbox(!accelerationCheckbox)} alt="Acceleration" style={{color:"#06c"}}/>
                                :
                                <img className="devicesimg" src={AccelerationNo} onClick={() => setAccelerationCheckbox(!accelerationCheckbox)} alt="Acceleration"/>
                            :
                            null
                        }
                        {batteryCheckbox ?
                            <img className="devicesimg" src={BatteryYes} onClick={() => setBatteryCheckbox(!batteryCheckbox)} alt="Battery" style={{color:"#06c"}}/>
                            :
                            <img className="devicesimg" src={BatteryNo} onClick={() => setBatteryCheckbox(!batteryCheckbox)} alt="Battery"/>
                        }
                    </div>
                    :
                    <div className="devicesFlexboxDivLeftDisplayGroup"></div>
                }
            </div>
            <div hidden={chartHidden}>
                <div className="devicesFlexboxDivCenter">
                    {lineRender ?
                        <img src={LineYes} onClick={() => setLineRender(!lineRender)} alt="Lines" style={{color:"#06c"}}/>
                        :
                        <img src={LineNo} onClick={() => setLineRender(!lineRender)} alt="Lines"/>
                    }
                    {shapeRender ?
                        <img src={DotsYes} onClick={() => setShapeRender(!shapeRender)} alt="Dots" style={{color:"#06c"}}/>
                        :
                        <img src={DotsNo} onClick={() => setShapeRender(!shapeRender)} alt="Dots"/>
                    }
                </div>
                <div className="devicesFlexboxDivSlider">
                    <p>How many points do you want to render: 1/</p>
                    <Slider style={{width: 300, marginTop: 14, marginLeft: 15}} value={numberReduced} step={1}
                            min={1} max={100} valueLabelDisplay={"on"} track={false} onChange={handleSliderChange}
                            onChangeCommitted={handleSliderChangeCommited}/>
                </div>
                {(temperatureCheckbox || humidityCheckbox) && (temperature.length !== 0 || temperature2.length !== 0 || temperature3.length !== 0 || temperature4.length!== 0) ?
                    <>
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                            <div className="devicesFlexboxDivLeft">
                                <p className="devicesFlexTextBoundsYaxis">Temperature bounds:</p>
                                <input type='number' value={displayBoundsTemperature[0]}
                                       onChange={handleFormBoundsTemperatureFirst} className="devicesInput"/>
                                <input type='number' value={displayBoundsTemperature[1]}
                                       onChange={handleFormBoundsTemperatureLast} className="devicesInput"/>
                                <button className="devicesButton" onClick={resetYAxisTemperature}>Reset</button>
                            </div>
                            <div className="devicesFlexboxDivLeft">
                                <p className="devicesFlexTextBoundsYaxis">Humidity bounds:</p>
                                <input type='number' value={displayBoundsHumidity[0]}
                                       onChange={handleFormBoundsHumidityFirst} className="devicesInput"/>
                                <input type='number' value={displayBoundsHumidity[1]}
                                       onChange={handleFormBoundsHumidityLast} className="devicesInput"/>
                                <button className="devicesButton" onClick={resetYAxisHumidity}>Reset</button>
                            </div>
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 20,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time"
                                       height={50} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                <YAxis className="deviceRechartsUserSelection" dataKey="temperature" type="number"
                                       name="temperature" unit={temperatureUnit === "C" ? "°C" : temperatureUnit === "F" ? "°F" : "K"}
                                       domain={boundsTemperatureYAxis} allowDataOverflow={true}
                                       label={temperatureUnit === "C"?
                                           {
                                               value: "Temperature (°C)",
                                               angle: -90,
                                               dy: 50,
                                               dx: -25,
                                               position: "insideLeft",
                                               fill: "#f00"
                                           }
                                           :
                                           temperatureUnit === "F"?
                                               {
                                                   value: "Temperature (°F)",
                                                   angle: -90,
                                                   dy: 50,
                                                   dx: -25,
                                                   position: "insideLeft",
                                                   fill: "#f00"
                                               }
                                               :
                                               {
                                                   value: "Temperature (K)",
                                                   angle: -90,
                                                   dy: 50,
                                                   dx: -25,
                                                   position: "insideLeft",
                                                   fill: "#f00"
                                               }
                                       } yAxisId="left" stroke="#f00"/>
                                <YAxis className="deviceRechartsUserSelection" dataKey="humidity" type="number"
                                       name="humidity" unit="%"
                                       domain={boundsHumidityYAxis} allowDataOverflow={true}
                                       label={{
                                           value: "Humidity (%)",
                                           angle: -90,
                                           dy: -35,
                                           dx: 10,
                                           position: "insideRight",
                                           fill: "#090"
                                       }} yAxisId="right" orientation="right" stroke="#090"/>
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {temperature.length !== 0 && TH1Display ?
                                    <>
                                        {temperatureCheckbox ?
                                            <Scatter name={labelFromIdentifier(labelTemperature)} data={temperatureDisplayed}
                                                     line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                                     yAxisId="left" fill="#f00"/>
                                            :
                                            null
                                        }
                                        {humidityCheckbox ?
                                            <Scatter name={labelFromIdentifier(labelHumidity)} data={humidityDisplayed}
                                                     line={{stroke: '#090', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                                     yAxisId="right" fill="#090"/>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {temperature2.length !== 0 && TH2Display?
                                    <>
                                        {temperatureCheckbox ?
                                            <Scatter name={labelFromIdentifier(labelTemperature2)} data={temperature2Displayed}
                                                     line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                     yAxisId="left" fill="#f00"/>
                                            :
                                            null
                                        }
                                        {humidityCheckbox ?
                                            <Scatter name={labelFromIdentifier(labelHumidity2)} data={humidity2Displayed}
                                                     line={{stroke: '#090', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                     yAxisId="right" fill="#090"/>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {temperature3.length !== 0 && TH3Display ?
                                    <>
                                        {temperatureCheckbox ?
                                            <Scatter name={labelFromIdentifier(labelTemperature3)} data={temperature3Displayed}
                                                     line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "triangle" : null}
                                                     legendType="triangle"
                                                     yAxisId="left" fill="#f00"/>
                                            :
                                            null
                                        }
                                        {humidityCheckbox ?
                                            <Scatter name={labelFromIdentifier(labelHumidity3)} data={humidity3Displayed}
                                                     line={{stroke: '#090', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "triangle" : null}
                                                     legendType="triangle"
                                                     yAxisId="right" fill="#090"/>
                                            :
                                            null
                                        }

                                    </>
                                    :
                                    null
                                }
                                {temperature4.length !== 0 && TH4Display ?
                                    <>
                                        {temperatureCheckbox ?
                                            <Scatter name={labelFromIdentifier(labelTemperature4)} data={temperature4Displayed}
                                                     line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "square" : null} legendType="square"
                                                     yAxisId="left" fill="#f00"/>
                                            :
                                            null
                                        }
                                        {humidityCheckbox ?
                                            <Scatter name={labelFromIdentifier(labelHumidity4)} data={humidity4Displayed}
                                                     line={{stroke: '#090', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "square" : null} legendType="square"
                                                     yAxisId="right" fill="#090"/>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ? (
                                    <ReferenceArea yAxisId="left" x1={stateZoom.refAreaLeft} x2={stateZoom.refAreaRight}
                                                   strokeOpacity={0.3}/>
                                ) : null}
                            </ScatterChart>
                        </ResponsiveContainer>
                            <div className="devicesFlexboxDivCenter">
                                <p className="devicePaddingTop">Devices displayed: </p>
                                {temperature.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceT1" name="device1"
                                               onClick={() => setTH1Display(!TH1Display)} checked={TH1Display}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(labelTemperature)}</p>
                                    </div>
                                    :
                                    null
                                }
                                {temperature2.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceT2" name="device2"
                                               onClick={() => setTH2Display(!TH2Display)} checked={TH2Display}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(labelTemperature2)}</p>
                                    </div>
                                    :
                                    null
                                }
                                {temperature3.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceT3" name="device3"
                                               onClick={() => setTH3Display(!TH3Display)} checked={TH3Display}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(labelTemperature3)}</p>
                                    </div>
                                    :
                                    null
                                }
                                {temperature4.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceT4" name="device4"
                                               onClick={() => setTH4Display(!TH4Display)} checked={TH4Display}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(labelTemperature4)}</p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                    </>
                    :
                    null
                }
                {currentCheckbox && (current1.length !== 0 || current12.length !== 0 || current13.length !== 0 || current14.length!== 0)?
                    <>
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                        </div>
                        <div className="devicesLegendAcceleration">
                            <span style={{color: "#000"}}>Current 1</span>
                            <span style={{color: "#aaa"}}>Current 2</span>
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                {currentCheckbox ?
                                    <YAxis className="deviceRechartsUserSelection" dataKey="current"
                                           type="number" name="Current" domain={['dataMin', 'dataMax']}
                                           yAxisId="right" stroke="#000" unit="mA"
                                           label={{
                                               value: "Current (mA)",
                                               angle: -90,
                                               dy: -45,
                                               dx: -85,
                                               position: "insideRight",
                                               fill: "#000"
                                           }} allowDataOverflow={true}/>
                                    :
                                    null
                                }
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {currentCheckbox ?
                                    <>
                                        {Cu1Display && current1Displayed.length !== 0 ?
                                            <>
                                                <Scatter name={labelFromIdentifier(labelCurrent1)}
                                                         data={current1Displayed}
                                                         line={{stroke: '#000', strokeWidth: lineRender ? 2 : 0}}
                                                         shape={shapeRender ? "circle" : null} legendType="circle"
                                                         yAxisId="right" fill="#000"/>
                                                <Scatter name={labelFromIdentifier(labelCurrent2)}
                                                         data={current2Displayed}
                                                         line={{stroke: '#aaa', strokeWidth: lineRender ? 2 : 0}}
                                                         shape={shapeRender ? "circle" : null} legendType="circle"
                                                         yAxisId="right" fill="#aaa"/>
                                            </>
                                            :
                                            null
                                        }
                                        {Cu2Display && current12Displayed.length !== 0 ?
                                            <>
                                                <Scatter name={labelFromIdentifier(labelCurrent12)}
                                                         data={current12Displayed} line={{
                                                    stroke: '#000',
                                                    strokeWidth: lineRender ? 2 : 0
                                                }} shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                         yAxisId="right" fill="#000"/>
                                                <Scatter name={labelFromIdentifier(labelCurrent22)}
                                                         data={current22Displayed} line={{
                                                    stroke: '#aaa',
                                                    strokeWidth: lineRender ? 2 : 0
                                                }} shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                         yAxisId="right" fill="#aaa"/>
                                            </>
                                            :
                                            null
                                        }
                                        {Cu3Display && current13Displayed.length !== 0 ?
                                            <>
                                                <Scatter name={labelFromIdentifier(labelCurrent13)}
                                                         data={current13Displayed} line={{
                                                    stroke: '#000',
                                                    strokeWidth: lineRender ? 2 : 0
                                                }} shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                         yAxisId="right" fill="#000"/>
                                                <Scatter name={labelFromIdentifier(labelCurrent23)}
                                                         data={current23Displayed} line={{
                                                    stroke: '#aaa',
                                                    strokeWidth: lineRender ? 2 : 0
                                                }} shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                         yAxisId="right" fill="#aaa"/>
                                            </>
                                            :
                                            null
                                        }
                                        {Cu4Display && current14Displayed.length !== 0 ?
                                            <>
                                                <Scatter name={labelFromIdentifier(labelCurrent14)}
                                                         data={current14Displayed} line={{
                                                    stroke: '#000',
                                                    strokeWidth: lineRender ? 2 : 0
                                                }} shape={shapeRender ? "square" : null} legendType="square"
                                                         yAxisId="right" fill="#000"/>
                                                <Scatter name={labelFromIdentifier(labelCurrent24)}
                                                         data={current24Displayed} line={{
                                                    stroke: '#aaa',
                                                    strokeWidth: lineRender ? 2 : 0
                                                }} shape={shapeRender ? "square" : null} legendType="square"
                                                         yAxisId="right" fill="#aaa"/>
                                            </>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea yAxisId="right" x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    : null}
                            </ScatterChart>
                        </ResponsiveContainer>
                        <div className="devicesFlexboxDivCenter">
                            <p className="devicePaddingTop">Devices displayed: </p>
                            {current1.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM1" name="device1"
                                           onClick={() => setCu1Display(!Cu1Display)} checked={Cu1Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelCurrent1)}</p>
                                </div>
                                :
                                null
                            }
                            {current12.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM2" name="device2"
                                           onClick={() => setCu2Display(!Cu2Display)} checked={Cu2Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelCurrent12)}</p>
                                </div>
                                :
                                null
                            }
                            {current13.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM3" name="device3"
                                           onClick={() => setCu3Display(!Cu3Display)} checked={Cu3Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelCurrent13)}</p>
                                </div>
                                :
                                null
                            }
                            {current14.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM4" name="device4"
                                           onClick={() => setCu4Display(!Cu4Display)} checked={Cu4Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelCurrent14)}</p>
                                </div>
                                :
                                null
                            }
                        </div>
                    </>
                    :
                    null
                }
                {atmPressureCheckbox ?
                    <>
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                            {atmPressureCheckbox ?
                                <div className="devicesFlexboxDivLeft">
                                    <p className="devicesFlexTextBoundsYaxis">Atmospheric pressure bounds:</p>
                                    <input type='number' value={displayBoundsAtmPressure[0]}
                                           onChange={handleFormBoundsAtmPressureFirst} className="devicesInput"/>
                                    <input type='number' value={displayBoundsAtmPressure[1]}
                                           onChange={handleFormBoundsAtmPressureLast} className="devicesInput"/>
                                    <button className="devicesButton" onClick={resetYAxisAtmPressure}>Reset</button>
                                </div>
                                :
                                null
                            }
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                {atmPressureCheckbox ?
                                    <YAxis className="deviceRechartsUserSelection" dataKey="atmPressure"
                                           type="number" name="atmPressure" domain={boundsAtmPressureYAxis}
                                           yAxisId="right" stroke="#ff8c00" unit="Pa"
                                           label={{
                                               value: "Atmospheric pressure (Pa)",
                                               angle: -90,
                                               dy: -80,
                                               dx: -85,
                                               position: "insideRight",
                                               fill: "#ff8c00"
                                           }} allowDataOverflow={true}/>
                                    :
                                    null
                                }
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {atmPressureCheckbox ?
                                    <>
                                        {Atm1Display && atmosphericPressure.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelAtmosphericPressure)}
                                                     data={atmosphericPressureDisplayed}
                                                     line={{stroke: '#ff8c00', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                                     yAxisId="right" fill="#ff8c00"/>
                                            :
                                            null
                                        }
                                        {Atm2Display && atmosphericPressure2.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelAtmosphericPressure2)}
                                                     data={atmosphericPressure2Displayed} line={{
                                                stroke: '#ff8c00',
                                                strokeWidth: lineRender ? 2 : 0
                                            }} shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                     yAxisId="right" fill="#ff8c00"/>
                                            :
                                            null
                                        }
                                        {Atm3Display && atmosphericPressure3.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelAtmosphericPressure3)}
                                                     data={atmosphericPressure3Displayed} line={{
                                                stroke: '#ff8c00',
                                                strokeWidth: lineRender ? 2 : 0
                                            }} shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                     yAxisId="right" fill="#ff8c00"/>
                                            :
                                            null
                                        }
                                        {Atm4Display && atmosphericPressure4.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelAtmosphericPressure4)}
                                                     data={atmosphericPressure4Displayed} line={{
                                                stroke: '#ff8c00',
                                                strokeWidth: lineRender ? 2 : 0
                                            }} shape={shapeRender ? "square" : null} legendType="square"
                                                     yAxisId="right" fill="#ff8c00"/>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea yAxisId="right" x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    : null}
                            </ScatterChart>
                        </ResponsiveContainer>
                        <div className="devicesFlexboxDivCenter">
                            <p className="devicePaddingTop">Devices displayed: </p>
                            {atmosphericPressure.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM1" name="device1"
                                           onClick={() => setAtm1Display(!Atm1Display)} checked={Atm1Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelAtmosphericPressure)}</p>
                                </div>
                                :
                                null
                            }
                            {atmosphericPressure2.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM2" name="device2"
                                           onClick={() => setAtm2Display(!Atm2Display)} checked={Atm2Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelAtmosphericPressure2)}</p>
                                </div>
                                :
                                null
                            }
                            {atmosphericPressure3.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM3" name="device3"
                                           onClick={() => setAtm3Display(!Atm3Display)} checked={Atm3Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelAtmosphericPressure3)}</p>
                                </div>
                                :
                                null
                            }
                            {atmosphericPressure4.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM4" name="device4"
                                           onClick={() => setAtm4Display(!Atm4Display)} checked={Atm4Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelAtmosphericPressure4)}</p>
                                </div>
                                :
                                null
                            }
                        </div>
                    </>
                    :
                    null
                }
                {movementCheckbox ?
                    <>
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                {movementCheckbox ?
                                    <YAxis className="deviceRechartsUserSelection" dataKey="movement" type="number"
                                           name="movement" yAxisId="left" stroke="#000"
                                           label={{
                                               value: "Movement",
                                               angle: -90,
                                               dy: 35,
                                               dx: -10,
                                               position: "insideLeft",
                                               fill: "#000"
                                           }} allowDataOverflow={true}/>
                                    :
                                    null
                                }
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {movementCheckbox ?
                                    <>
                                        {movement.length !== 0 && M1Display ?
                                            <Scatter name={labelFromIdentifier(labelMovement)} data={movementDisplayed}
                                                     line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                                     yAxisId="left"/>
                                            :
                                            null
                                        }
                                        {movement2.length !== 0 && M2Display ?
                                            <Scatter name={labelFromIdentifier(labelMovement2)} data={movement2Displayed} line={{
                                                stroke: "#000",
                                                strokeWidth: lineRender ? 2 : 0
                                            }} shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                     yAxisId="left"/>
                                            :
                                            null
                                        }
                                        {movement3.length !== 0 && M3Display ?
                                            <Scatter name={labelFromIdentifier(labelMovement3)} data={movement3Displayed} line={{
                                                stroke: "#000",
                                                strokeWidth: lineRender ? 2 : 0
                                            }} shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                     yAxisId="left"/>
                                            :
                                            null
                                        }
                                        {movement4.length !== 0 && M4Display ?
                                            <Scatter name={labelFromIdentifier(labelMovement4)} data={movement4Displayed} line={{
                                                stroke: "#000",
                                                strokeWidth: lineRender ? 2 : 0
                                            }} shape={shapeRender ? "square" : null} legendType="square"
                                                     yAxisId="left"/>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea yAxisId="left" x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    : null}
                            </ScatterChart>
                        </ResponsiveContainer>
                        <div className="devicesFlexboxDivCenter">
                            <p className="devicePaddingTop">Devices displayed: </p>
                            {movement.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM1" name="device1"
                                           onClick={() => setM1Display(!M1Display)} checked={M1Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelMovement)}</p>
                                </div>
                                :
                                null
                            }
                            {movement2.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM2" name="device2"
                                           onClick={() => setM2Display(!M2Display)} checked={M2Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelMovement2)}</p>
                                </div>
                                :
                                null
                            }
                            {movement3.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM3" name="device3"
                                           onClick={() => setM3Display(!M3Display)} checked={M3Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelMovement3)}</p>
                                </div>
                                :
                                null
                            }
                            {movement4.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceM4" name="device4"
                                           onClick={() => setM4Display(!M4Display)} checked={M4Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelMovement4)}</p>
                                </div>
                                :
                                null
                            }
                        </div>
                    </>
                    :
                    null
                }

                {accelerationCheckbox ?
                    <>
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                            {accelerationCheckbox ?
                                <div className="devicesFlexboxDivLeft">
                                    <p className="devicesFlexTextBoundsYaxis">Acceleration bounds:</p>
                                    <input type='number' value={displayBoundsAcceleration[0]}
                                           onChange={handleFormBoundsAccelerationFirst}
                                           className="devicesInput"/>
                                    <input type='number' value={displayBoundsAcceleration[1]}
                                           onChange={handleFormBoundsAccelerationLast}
                                           className="devicesInput"/>

                                    <button className="devicesButton" onClick={resetYAxisAcceleration}>Reset</button>
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className="devicesLegendAcceleration">
                            <span style={{color: "#F00"}}>Acceleration X Axis &nbsp;</span>
                            <span style={{color: "#090"}}>Acceleration Y Axis &nbsp;</span>
                            <span style={{color: "#00B"}}>Acceleration Z Axis</span>
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                <YAxis className="deviceRechartsUserSelection" dataKey="acceleration" type="number"
                                       name="acceleration" stroke="#000" domain={boundsAccelerationYAxis} unit="g"
                                       label={{
                                           value: "Acceleration (g-force)",
                                           angle: -90,
                                           dy: 65,
                                           dx: -15,
                                           position: "insideLeft",
                                           fill: "#000"
                                       }} allowDataOverflow={true}
                                />
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {accelerationXDisplayed.length !== 0 && A1Display ?
                                    <>
                                        <Scatter name={labelFromIdentifier(labelAccelerationX)} data={accelerationXDisplayed}
                                                 line={{stroke: "#F00", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "circle" : null} legendType="circle" fill="#F00"/>
                                        <Scatter name={labelFromIdentifier(labelAccelerationY)} data={accelerationYDisplayed}
                                                 line={{stroke: "#090", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "circle" : null} legendType="circle" fill="#090"/>
                                        <Scatter name={labelFromIdentifier(labelAccelerationZ)} data={accelerationZDisplayed}
                                                 line={{stroke: "#00B", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "circle" : null} legendType="circle" fill="#00B"/>
                                    </>
                                    :
                                    null
                                }
                                {accelerationX2Displayed.length !== 0 && A2Display ?
                                    <>
                                        <Scatter name={labelFromIdentifier(labelAccelerationX2)} data={accelerationX2Displayed}
                                                 line={{stroke: "#F00", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "diamond" : null} legendType="diamond" fill="#F00"/>
                                        <Scatter name={labelFromIdentifier(labelAccelerationY2)} data={accelerationY2Displayed}
                                                 line={{stroke: "#090",strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "diamond" : null} legendType="diamond" fill="#090"/>
                                        <Scatter name={labelFromIdentifier(labelAccelerationZ2)} data={accelerationZ2Displayed}
                                                 line={{stroke: "#00B", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "diamond" : null} legendType="diamond" fill="#00B"/>
                                    </>
                                    :
                                    null
                                }
                                {accelerationX3Displayed.length !== 0 && A3Display ?
                                    <>
                                        <Scatter name={labelFromIdentifier(labelAccelerationX3)} data={accelerationX3Displayed}
                                                 line={{stroke: "#F00", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "triangle" : null} legendType="triangle" fill="#F00"/>
                                        <Scatter name={labelFromIdentifier(labelAccelerationY3)} data={accelerationY3Displayed}
                                                 line={{stroke: "#090", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "triangle" : null} legendType="triangle" fill="#090"/>
                                        <Scatter name={labelFromIdentifier(labelAccelerationZ3)} data={accelerationZ3Displayed}
                                                 line={{stroke: "#00B", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "triangle" : null} legendType="triangle" fill="#00B"/>
                                    </>
                                    :
                                    null
                                }
                                {accelerationX4Displayed.length !== 0 && A4Display ?
                                    <>
                                        <Scatter name={labelFromIdentifier(labelAccelerationX4)} data={accelerationX4Displayed}
                                                 line={{stroke: "#F00", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "square" : null} legendType="square" fill="#F00"/>
                                        <Scatter name={labelFromIdentifier(labelAccelerationY4)} data={accelerationY4Displayed}
                                                 line={{stroke: "#090", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "square" : null} legendType="square" fill="#090"/>
                                        <Scatter name={labelFromIdentifier(labelAccelerationZ4)} data={accelerationZ4Displayed}
                                                 line={{stroke: "#00B", strokeWidth: lineRender ? 2 : 0 }}
                                                 shape={shapeRender ? "square" : null} legendType="square" fill="#00B"/>
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    :
                                    null
                                }
                            </ScatterChart>
                        </ResponsiveContainer>
                        <div className="devicesFlexboxDivCenter">
                            <p className="devicePaddingTop">Devices displayed: </p>
                            {accelerationX.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceA1" name="device1"
                                           onClick={() => setA1Display(!A1Display)} checked={A1Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelAccelerationX)}</p>
                                </div>
                                :
                                null
                            }
                            {accelerationX2.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceA2" name="device2"
                                           onClick={() => setA2Display(!A2Display)} checked={A2Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelAccelerationX2)}</p>
                                </div>
                                :
                                null
                            }
                            {accelerationX3.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceA3" name="device3"
                                           onClick={() => setA3Display(!A3Display)} checked={A3Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelAccelerationX3)}</p>
                                </div>
                                :
                                null
                            }
                            {accelerationX4.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceA4" name="device4"
                                           onClick={() => setA4Display(!A4Display)} checked={A4Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelAccelerationX4)}</p>
                                </div>
                                :
                                null
                            }
                        </div>
                    </>
                    :
                    null
                }
                {batteryCheckbox ?
                    <>
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                            {batteryPercent.length !== 0 ?
                                <div className="devicesFlexboxDivLeft">
                                    <p className="devicesFlexTextBoundsYaxis">Battery percent bounds:</p>
                                    <input type='number' value={boundsBatteryPercentYAxis[0]}
                                           onChange={e => setBoundsBatteryPercentYAxis([parseInt(e.currentTarget.value), boundsBatteryPercentYAxis[1]])}
                                           className="devicesInput"/>
                                    <input type='number' value={boundsBatteryPercentYAxis[1]}
                                           onChange={e => setBoundsBatteryPercentYAxis([boundsBatteryPercentYAxis[0], parseInt(e.currentTarget.value)])}
                                           className="devicesInput"/>
                                    <button className="devicesButton" onClick={resetYAxisBatteryPercent}>Reset
                                    </button>
                                </div>
                                :
                                null
                            }
                            {batteryMv.length !== 0 ?
                                <div className="devicesFlexboxDivLeft">
                                    <p className="devicesFlexTextBoundsYaxis">Battery mV bounds:</p>
                                    <input type='number' value={displayBoundsBatteryMv[0]}
                                           onChange={handleFormBoundsBatteryMvFirst} className="devicesInput"/>
                                    <input type='number' value={displayBoundsBatteryMv[1]}
                                           onChange={handleFormBoundsBatteryMvLast} className="devicesInput"/>
                                    <button className="devicesButton" onClick={resetYAxisBatteryMv}>Reset</button>
                                </div>
                                :
                                null
                            }
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                {batteryPercent.length !== 0 || batteryPercent2.length || batteryPercent3.length || batteryPercent4.length ?
                                    <YAxis className="deviceRechartsUserSelection" dataKey="batteryPercent"
                                           type="number" name="batteryPercent" domain={boundsBatteryPercentYAxis}
                                           yAxisId="left" stroke="#000" unit="%"
                                           label={{
                                               value: "Battery (%)",
                                               angle: -90,
                                               dy: 35,
                                               dx: -10,
                                               position: "insideLeft",
                                               fill: "#000"
                                           }} allowDataOverflow={true}/>
                                    :
                                    null
                                }
                                {batteryMv.length !== 0 || batteryMv2.length !== 0 || batteryMv3.length !== 0 || batteryMv4.length !== 0?
                                    <YAxis className="deviceRechartsUserSelection" dataKey="batteryMv" type="number"
                                           name="batteryMv" domain={boundsBatteryMvYAxis} yAxisId="right"
                                           orientation="right" stroke="#aaa" unit="mV"
                                           label={{
                                               value: "Battery (mV)",
                                               angle: -90,
                                               dy: -80,
                                               dx: 30,
                                               position: "insideRight",
                                               fill: "#aaa"
                                           }} allowDataOverflow={true}/>
                                    :
                                    null
                                }
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {B1Display ?
                                    <>
                                        {batteryPercent.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelBattery)} data={batteryPercentDisplayed}
                                                     line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} yAxisId="left"/>
                                            :
                                            null
                                        }
                                        {batteryMv.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelBattery)} data={batteryMvDisplayed}
                                                     line={{stroke: "#aaa", strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null}
                                                     yAxisId="right" fill="#aaa"/>

                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {B2Display ?
                                    <>
                                        {batteryPercent2.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelBattery2)} data={batteryPercent2Displayed}
                                                     line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "diamond" : null}
                                                     legendType="diamond" yAxisId="left"/>
                                            :
                                            null
                                        }
                                        {batteryMv2.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelBattery2)} data={batteryMv2Displayed}
                                                     line={{stroke: "#aaa", strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                     yAxisId="right" fill="#aaa"/>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {B3Display ?
                                    <>
                                        {batteryPercent3.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelBattery3)} data={batteryPercent3Displayed}
                                                     line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                     yAxisId="left"/>
                                            :
                                            null
                                        }
                                        {batteryMv3.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelBattery3)} data={batteryMv3Displayed}
                                                     line={{stroke: "#aaa", strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                     yAxisId="right" fill="#aaa"/>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {B4Display ?
                                    <>
                                        {batteryPercent4.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelBattery4)} data={batteryPercent4Displayed}
                                                     line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "square" : null} legendType="square"
                                                     yAxisId="left"/>
                                            :
                                            null
                                        }
                                        {batteryMv4.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelBattery4)} data={batteryMv4Displayed}
                                                     line={{stroke: "#aaa", strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "square" : null} legendType="square"
                                                     yAxisId="right" fill="#aaa"/>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    batteryPercent.length !== 0 ?
                                        <ReferenceArea yAxisId="left" x1={stateZoom.refAreaLeft}
                                                       x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                        :
                                        <ReferenceArea yAxisId="right" x1={stateZoom.refAreaLeft}
                                                       x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    :
                                    null
                                }
                            </ScatterChart>
                        </ResponsiveContainer>
                        <div className="devicesFlexboxDivCenter">
                            <p className="devicePaddingTop">Devices displayed: </p>
                            {batteryMv.length !== 0 || batteryPercent.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceB1" name="device1"
                                           onClick={() => setB1Display(!B1Display)} checked={B1Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelBattery)}</p>
                                </div>
                                :
                                null
                            }
                            {batteryMv2.length !== 0 || batteryPercent2.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceB2" name="device2"
                                           onClick={() => setB2Display(!B2Display)} checked={B2Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelBattery2)}</p>
                                </div>
                                :
                                null
                            }
                            {batteryMv3.length !== 0 || batteryPercent3.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceB3" name="device3"
                                           onClick={() => setB3Display(!B3Display)} checked={B3Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelBattery3)}</p>
                                </div>
                                :
                                null
                            }
                            {batteryMv4.length !== 0 || batteryPercent4.length !== 0 ?
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceB4" name="device4"
                                           onClick={() => setB4Display(!B4Display)} checked={B4Display}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(labelBattery4)}</p>
                                </div>
                                :
                                null
                            }
                        </div>
                    </>
                    :
                    null
                }

            </div>
            <div className="DeviceBackButton">
                <button onClick={() => navigate("/getGroupDevice")} className="devicesButton">Back</button>
            </div>
            <Snackbar open={errMessage} autoHideDuration={4000} onClose={() => setErrMessage(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - end date before begin date</Alert>
            </Snackbar>
            <Snackbar open={errMessageDevice} autoHideDuration={4000} onClose={() => setErrMessageDevice(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Select at least 1 device</Alert>
            </Snackbar>
        </div>
    )
}

export default GroupDeviceData;