import React, {useContext, useEffect, useState} from 'react';
import {
    CartesianGrid,
    Legend,
    ReferenceArea,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import * as Yup from "yup";
import mqttRequests from "../../Utils/mqttRequests";
import {Form, Formik} from "formik";
import FormikControl from "../FormTemplate/FormikControl";
import moment from "moment";
import {
    Alert,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slider,
    Snackbar
} from "@mui/material";
import {MqttClientContext} from "../../context/MqttClientContext";
import {MapContainer, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import "./Devices.scss";
import "leaflet/dist/leaflet.css"
import L from 'leaflet';
import mapIcon from "../../assets/icon.png"
import {useNavigate} from "react-router-dom";
import {SettingsContext} from "../../context/SettingsContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import AccelerationNo from '../../assets/AccelerationNo.png';
import AccelerationYes from '../../assets/AccelerationYes.png';
import BatteryNo from '../../assets/BatteryNo.png';
import BatteryYes from '../../assets/BatteryYes.png';
import CoordinatesNo from '../../assets/CoordinatesNo.png';
import CoordinatesYes from '../../assets/CoordinatesYes.png';
import CrashNo from '../../assets/CrashNo.png';
import CrashYes from '../../assets/CrashYes.png';
import HumidityNo from '../../assets/HumidityNo.png';
import HumidityYes from '../../assets/HumidityYes.png';
import MovementNo from '../../assets/MovementNo.png';
import MovementYes from '../../assets/MovementYes.png';
import PressureNo from '../../assets/PressureNo.png';
import PressureYes from '../../assets/PressureYes.png';
import TemperatureNo from '../../assets/TemperatureNo.png';
import TemperatureYes from '../../assets/TemperatureYes.png';
import LineNo from '../../assets/LinesNo.png';
import LineYes from '../../assets/LinesYes.png';
import DotsNo from'../../assets/DotsNo.png';
import DotsYes from '../../assets/DotsYes.png';
import HistoryNo from '../../assets/HistoryNo.png';
import HistoryYes from '../../assets/HistoryYes.png';
import CurrentNo from '../../assets/CurrentNo.png';
import CurrentYes from '../../assets/CurrentYes.png';
import Co2No from '../../assets/Co2No.png';
import Co2Yes from '../../assets/Co2Yes.png';
import VocNo from '../../assets/VocNo.png';
import VocYes from '../../assets/VocYes.png';
import MagnetNo from '../../assets/MagnetNo.png';
import MagnetYes from '../../assets/MagnetYes.png';
import Loading from '../../assets/Loading.png';

//the page to display device data
function DeviceData(props) {

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('deviceList');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/deviceList")
        }, false);
    }, [navigate]);

    //for the loading
    const [loadingOn, setLoadingOn] = useState(false);

    //variables for display
    const [chartHidden, setChartHidden] = useState(true);
    const [errMessage, setErrMessage] = useState(false);

    //the number of points display in the chart (1/1 1/2 1/3...) 1/3 -> 3
    const [numberReduced, setNumberReduce] = useState(1);

    const [crashNumber, setCrashNumber] = useState(NaN);

    //variable to set all points on the device
    const [allPoints, setAllPoints] = useState([]);

    //variables for time
    const [unitTime, setUnitTime] = useState("sec");
    //for the seconds of the last point
    const [maxTime, setMaxTime] = useState(0);
    //variable to have the first point timestamp
    const [firstPointTime, setFirstPointTime] = useState(0);
    //the number by each timestamp must be divided to have the time in min/h/days...
    const [timeDivisionNumber, setTimeDivisionNumber] = useState(1);

    //variables for temperature
    const [temperature, setTemperature] = useState([]);
    const [temperatureDisplayed, setTemperatureDisplayed] = useState([]);
    //for teltonika
    const [temperature2, setTemperature2] = useState([]);
    const [temperature2Displayed, setTemperature2Displayed] = useState([]);
    const [temperature3, setTemperature3] = useState([]);
    const [temperature3Displayed, setTemperature3Displayed] = useState([]);
    const [temperature4, setTemperature4] = useState([]);
    const [temperature4Displayed, setTemperature4Displayed] = useState([]);
    //labels for temperature
    const [labelTemperature, setLabelTemperature] = useState("");
    const [labelTemperature2, setLabelTemperature2] = useState("");
    const [labelTemperature3, setLabelTemperature3] = useState("");
    const [labelTemperature4, setLabelTemperature4] = useState("");

    //variables for humidity
    const [humidity, setHumidity] = useState([]);
    const [humidityDisplayed, setHumidityDisplayed] = useState([]);
    //for teltonika
    const [humidity2, setHumidity2] = useState([]);
    const [humidity2Displayed, setHumidity2Displayed] = useState([]);
    const [humidity3, setHumidity3] = useState([]);
    const [humidity3Displayed, setHumidity3Displayed] = useState([]);
    const [humidity4, setHumidity4] = useState([]);
    const [humidity4Displayed, setHumidity4Displayed] = useState([]);
    //labels for humidity
    const [labelHumidity, setLabelHumidity] = useState("");
    const [labelHumidity2, setLabelHumidity2] = useState("");
    const [labelHumidity3, setLabelHumidity3] = useState("");
    const [labelHumidity4, setLabelHumidity4] = useState("");

    //variables for movement
    const [movement, setMovement] = useState([]);
    const [movementDisplayed, setMovementDisplayed] = useState([]);
    //for teltonika
    const [movement2, setMovement2] = useState([]);
    const [movement2Displayed, setMovement2Displayed] = useState([]);
    const [movement3, setMovement3] = useState([]);
    const [movement3Displayed, setMovement3Displayed] = useState([]);
    const [movement4, setMovement4] = useState([]);
    const [movement4Displayed, setMovement4Displayed] = useState([]);
    //label for movement
    const [labelMovement, setLabelMovement] = useState("");
    const [labelMovement2, setLabelMovement2] = useState("");
    const [labelMovement3, setLabelMovement3] = useState("");
    const [labelMovement4, setLabelMovement4] = useState("");

    //variables for atmospheric pressure
    const [atmosphericPressure, setAtmosphericPressure] = useState([]);
    const [atmosphericPressureDisplayed, setAtmosphericPressureDisplayed] = useState([]);
    //for teltonika
    const [atmosphericPressure2, setAtmosphericPressure2] = useState([]);
    const [atmosphericPressure2Displayed, setAtmosphericPressure2Displayed] = useState([]);
    const [atmosphericPressure3, setAtmosphericPressure3] = useState([]);
    const [atmosphericPressure3Displayed, setAtmosphericPressure3Displayed] = useState([]);
    const [atmosphericPressure4, setAtmosphericPressure4] = useState([]);
    const [atmosphericPressure4Displayed, setAtmosphericPressure4Displayed] = useState([]);
    //labels for atmospheric pressure
    const [labelAtmosphericPressure, setLabelAtmosphericPressure] = useState("");
    const [labelAtmosphericPressure2, setLabelAtmosphericPressure2] = useState("");
    const [labelAtmosphericPressure3, setLabelAtmosphericPressure3] = useState("");
    const [labelAtmosphericPressure4, setLabelAtmosphericPressure4] = useState("");

    //variables for co2
    const [co, setCo] = useState([]);
    const [coDisplayed, setCoDisplayed] = useState([]);
    //for teltonika
    const [co2, setCo2] = useState([]);
    const [co2Displayed, setCo2Displayed] = useState([]);
    const [co3, setCo3] = useState([]);
    const [co3Displayed, setCo3Displayed] = useState([]);
    const [co4, setCo4] = useState([]);
    const [co4Displayed, setCo4Displayed] = useState([]);
    //labels for atmospheric pressure
    const [labelCo, setLabelCo] = useState("");
    const [labelCo2, setLabelCo2] = useState("");
    const [labelCo3, setLabelCo3] = useState("");
    const [labelCo4, setLabelCo4] = useState("");

    //variables for voc
    const [voc, setVoc] = useState([]);
    const [vocDisplayed, setVocDisplayed] = useState([]);
    //for teltonika
    const [voc2, setVoc2] = useState([]);
    const [voc2Displayed, setVoc2Displayed] = useState([]);
    const [voc3, setVoc3] = useState([]);
    const [voc3Displayed, setVoc3Displayed] = useState([]);
    const [voc4, setVoc4] = useState([]);
    const [voc4Displayed, setVoc4Displayed] = useState([]);
    //labels for atmospheric pressure
    const [labelVoc, setLabelVoc] = useState("");
    const [labelVoc2, setLabelVoc2] = useState("");
    const [labelVoc3, setLabelVoc3] = useState("");
    const [labelVoc4, setLabelVoc4] = useState("");

    //variables for current1
    const [current1, setCurrent1] = useState([]);
    const [current1Displayed, setCurrent1Displayed] = useState([]);
    //for teltonika
    const [current12, setCurrent12] = useState([]);
    const [current12Displayed, setCurrent12Displayed] = useState([]);
    const [current13, setCurrent13] = useState([]);
    const [current13Displayed, setCurrent13Displayed] = useState([]);
    const [current14, setCurrent14] = useState([]);
    const [current14Displayed, setCurrent14Displayed] = useState([]);
    //labels for atmospheric pressure
    const [labelCurrent1, setLabelCurrent1] = useState("");
    const [labelCurrent12, setLabelCurrent12] = useState("");
    const [labelCurrent13, setLabelCurrent13] = useState("");
    const [labelCurrent14, setLabelCurrent14] = useState("");

    const [magnet, setMagnet] = useState([]);
    const [magnetDisplayed, setMagnetDisplayed] = useState([]);
    const [labelMagnet, setLabelMagnet] = useState("");

    //variables for current1
    const [current2, setCurrent2] = useState([]);
    const [current2Displayed, setCurrent2Displayed] = useState([]);
    //for teltonika
    const [current22, setCurrent22] = useState([]);
    const [current22Displayed, setCurrent22Displayed] = useState([]);
    const [current23, setCurrent23] = useState([]);
    const [current23Displayed, setCurrent23Displayed] = useState([]);
    const [current24, setCurrent24] = useState([]);
    const [current24Displayed, setCurrent24Displayed] = useState([]);
    //labels for atmospheric pressure
    const [labelCurrent2, setLabelCurrent2] = useState("");
    const [labelCurrent22, setLabelCurrent22] = useState("");
    const [labelCurrent23, setLabelCurrent23] = useState("");
    const [labelCurrent24, setLabelCurrent24] = useState("");

    //variables for battery
    const [batteryPercent, setBatteryPercent] = useState([]);
    const [batteryPercentDisplayed, setBatteryPercentDisplayed] = useState([]);
    const [batteryMv, setBatteryMv] = useState([]);
    const [batteryMvDisplayed, setBatteryMvDisplayed] = useState([]);
    //for teltonika
    const [batteryExternal, setBatteryExternal] = useState([]);
    const [batteryExternalDisplayed, setBatteryExternalDisplayed] = useState([]);
    const [batteryPercent1, setBatteryPercent1] = useState([]);
    const [batteryPercent1Displayed, setBatteryPercent1Displayed] = useState([]);
    const [batteryMv1, setBatteryMv1] = useState([]);
    const [batteryMv1Displayed, setBatteryMv1Displayed] = useState([]);
    const [batteryPercent2, setBatteryPercent2] = useState([]);
    const [batteryPercent2Displayed, setBatteryPercent2Displayed] = useState([]);
    const [batteryMv2, setBatteryMv2] = useState([]);
    const [batteryMv2Displayed, setBatteryMv2Displayed] = useState([]);
    const [batteryPercent3, setBatteryPercent3] = useState([]);
    const [batteryPercent3Displayed, setBatteryPercent3Displayed] = useState([]);
    const [batteryMv3, setBatteryMv3] = useState([]);
    const [batteryMv3Displayed, setBatteryMv3Displayed] = useState([]);
    const [batteryPercent4, setBatteryPercent4] = useState([]);
    const [batteryPercent4Displayed, setBatteryPercent4Displayed] = useState([]);
    const [batteryMv4, setBatteryMv4] = useState([]);
    const [batteryMv4Displayed, setBatteryMv4Displayed] = useState([]);
    //labels for battery
    const [labelBatteryPercent, setLabelBatteryPercent] = useState("");
    const [labelBatteryMv, setLabelBatteryMv] = useState("");
    const [labelBatteryPercent1, setLabelBatteryPercent1] = useState("");
    const [labelBatteryMv1, setLabelBatteryMv1] = useState("");
    const [labelBatteryPercent2, setLabelBatteryPercent2] = useState("");
    const [labelBatteryMv2, setLabelBatteryMv2] = useState("");
    const [labelBatteryPercent3, setLabelBatteryPercent3] = useState("");
    const [labelBatteryMv3, setLabelBatteryMv3] = useState("");
    const [labelBatteryPercent4, setLabelBatteryPercent4] = useState("");
    const [labelBatteryMv4, setLabelBatteryMv4] = useState("");

    //variables for Acceleration x
    const [accelerationX, setAccelerationX] = useState([]);
    const [accelerationXDisplayed, setAccelerationXDisplayed] = useState([]);
    //for teltonika
    const [accelerationX2, setAccelerationX2] = useState([]);
    const [accelerationX2Displayed, setAccelerationX2Displayed] = useState([]);
    const [accelerationX3, setAccelerationX3] = useState([]);
    const [accelerationX3Displayed, setAccelerationX3Displayed] = useState([]);
    const [accelerationX4, setAccelerationX4] = useState([]);
    const [accelerationX4Displayed, setAccelerationX4Displayed] = useState([]);
    //labels for Acceleration x
    const [labelAccelerationX, setLabelAccelerationX] = useState("");
    const [labelAccelerationX2, setLabelAccelerationX2] = useState("");
    const [labelAccelerationX3, setLabelAccelerationX3] = useState("");
    const [labelAccelerationX4, setLabelAccelerationX4] = useState("");

    //variables for Acceleration y
    const [accelerationY, setAccelerationY] = useState([]);
    const [accelerationYDisplayed, setAccelerationYDisplayed] = useState([]);
    //for teltonika
    const [accelerationY2, setAccelerationY2] = useState([]);
    const [accelerationY2Displayed, setAccelerationY2Displayed] = useState([]);
    const [accelerationY3, setAccelerationY3] = useState([]);
    const [accelerationY3Displayed, setAccelerationY3Displayed] = useState([]);
    const [accelerationY4, setAccelerationY4] = useState([]);
    const [accelerationY4Displayed, setAccelerationY4Displayed] = useState([]);
    //labels for Acceleration y
    const [labelAccelerationY, setLabelAccelerationY] = useState("");
    const [labelAccelerationY2, setLabelAccelerationY2] = useState("");
    const [labelAccelerationY3, setLabelAccelerationY3] = useState("");
    const [labelAccelerationY4, setLabelAccelerationY4] = useState("");

    //variables for Acceleration z
    const [accelerationZ, setAccelerationZ] = useState([]);
    const [accelerationZDisplayed, setAccelerationZDisplayed] = useState([]);
    //for teltonika
    const [accelerationZ2, setAccelerationZ2] = useState([]);
    const [accelerationZ2Displayed, setAccelerationZ2Displayed] = useState([]);
    const [accelerationZ3, setAccelerationZ3] = useState([]);
    const [accelerationZ3Displayed, setAccelerationZ3Displayed] = useState([]);
    const [accelerationZ4, setAccelerationZ4] = useState([]);
    const [accelerationZ4Displayed, setAccelerationZ4Displayed] = useState([]);
    //labels for Acceleration z
    const [labelAccelerationZ, setLabelAccelerationZ] = useState("");
    const [labelAccelerationZ2, setLabelAccelerationZ2] = useState("");
    const [labelAccelerationZ3, setLabelAccelerationZ3] = useState("");
    const [labelAccelerationZ4, setLabelAccelerationZ4] = useState("");

    //for acceleration of the teltonika itself
    const [accX, setAccX] = useState([]);
    const [accY, setAccY] = useState([]);
    const [accZ, setAccZ] = useState([]);
    const [accXDisplayed, setAccXDisplayed] = useState([]);
    const [accYDisplayed, setAccYDisplayed] = useState([]);
    const [accZDisplayed, setAccZDisplayed] = useState([]);

    const [allCrash, setAllCrash] = useState([]);
    const [crashDataX, setCrashDataX] = useState([]);
    const [crashDataY, setCrashDataY] = useState([]);
    const [crashDataZ, setCrashDataZ] = useState([]);
    const [maxCrashX, setMaxCrashX] = useState (0);
    const [minCrashX, setMinCrashX] = useState (0);
    const [maxCrashY, setMaxCrashY] = useState (0);
    const [minCrashY, setMinCrashY] = useState (0);
    const [crashErrNoPoints, setCrashErrNoPoints] = useState(false);
    const [crashErrUk, setCrashErrUk] = useState(false);

    //variable to store all the latitudes and the longitude
    const [longLat, setLongLat] = useState([]);
    const [longLatDisplayed, setLongLatDisplayed] = useState([]);
    //variable to the group of current latitudes and longitudes (keep history)
    const [historyLongLat, setHistoryLongLat] = useState([]);
    const [historyLLDisplay, setHistoryLLDisplay] = useState([]);
    //variables to set the current latitude and longitude
    const [longitude, setLongitude] = useState(null);
    const [latitude, setLatitude] = useState(null);

    //variables to choose to display or not Teltonika device values (TH -> temperature humidity, M -> movement, Atm -> atmPressure, A -> Acceleration, B -> battery)
    const [TH1Teltonika, setTH1Teltonika] = useState(true);
    const [TH2Teltonika, setTH2Teltonika] = useState(true);
    const [TH3Teltonika, setTH3Teltonika] = useState(true);
    const [TH4Teltonika, setTH4Teltonika] = useState(true);
    const [M1Teltonika, setM1Teltonika] = useState(true);
    const [M2Teltonika, setM2Teltonika] = useState(true);
    const [M3Teltonika, setM3Teltonika] = useState(true);
    const [M4Teltonika, setM4Teltonika] = useState(true);
    const [Atm1Teltonika, setAtm1Teltonika] = useState(true);
    const [Atm2Teltonika, setAtm2Teltonika] = useState(true);
    const [Atm3Teltonika, setAtm3Teltonika] = useState(true);
    const [Atm4Teltonika, setAtm4Teltonika] = useState(true);
    const [CoTeltonika, setCoTeltonika] = useState(true);
    const [Co2Teltonika, setCo2Teltonika] = useState(true);
    const [Co3Teltonika, setCo3Teltonika] = useState(true);
    const [Co4Teltonika, setCo4Teltonika] = useState(true);
    const [VocTeltonika, setVocTeltonika] = useState(true);
    const [Voc2Teltonika, setVoc2Teltonika] = useState(true);
    const [Voc3Teltonika, setVoc3Teltonika] = useState(true);
    const [Voc4Teltonika, setVoc4Teltonika] = useState(true);
    const [Cu1Teltonika, setCu1Teltonika] = useState(true);
    const [Cu2Teltonika, setCu2Teltonika] = useState(true);
    const [Cu3Teltonika, setCu3Teltonika] = useState(true);
    const [Cu4Teltonika, setCu4Teltonika] = useState(true);
    const [A1Teltonika, setA1Teltonika] = useState(true);
    const [A2Teltonika, setA2Teltonika] = useState(true);
    const [A3Teltonika, setA3Teltonika] = useState(true);
    const [A4Teltonika, setA4Teltonika] = useState(true);
    const [AGTeltonika, setAGTeltonika] = useState(true);
    const [gatewayTeltonika, setGatewayTeltonika] = useState(true);
    const [BExternalTeltonika, setBExternalTeltonika] = useState(true);
    const [B1Teltonika, setB1Teltonika] = useState(true);
    const [B2Teltonika, setB2Teltonika] = useState(true);
    const [B3Teltonika, setB3Teltonika] = useState(true);
    const [B4Teltonika, setB4Teltonika] = useState(true);

    //the devices
    const [devices, setDevices] = useState([]);

    //label of devices teltonika
    const [teltonikaDeviceList, setTeltonikaDeviceList] = useState([]);

    //variables for checkboxes
    //for line and dots display
    const [lineRender, setLineRender] = useState(true);
    const [shapeRender, setShapeRender] = useState(true);
    //for data display
    const [temperatureCheckbox, setTemperatureCheckbox] = useState(true);
    const [humidityCheckbox, setHumidityCheckbox] = useState(true);
    const [currentCheckbox, setCurrentCheckbox] = useState(true);
    const [movementCheckbox, setMovementCheckbox] = useState(false);
    const [atmPressureCheckbox, setAtmPressureCheckbox] = useState(false);
    const [co2Checkbox, setCo2Checkbox] = useState(false);
    const [vocCheckbox, setVocCheckbox] = useState(false);
    const [accelerationCheckbox, setAccelerationCheckbox] = useState(false);
    const [crashCheckbox, setCrashCheckbox] = useState(false);
    const [magnetCheckbox, setMagnetCheckbox] = useState(false);
    const [batteryCheckbox, setBatteryCheckbox] = useState(false);
    const [latLongCheckbox, setLatLongCheckbox] = useState(false);
    //variable for display all history of location or not
    const [coordinateHistory, setCoordinateHistory] = useState(false);

    //variables to select min and max for y-axis
    const [boundsBatteryPercentYAxis, setBoundsBatteryPercentYAxis] = useState([0, 100]);
    const [boundsBatteryMvYAxis, setBoundsBatteryMvYAxis] = useState([dataMin => Math.ceil(dataMin - 100), dataMax => Math.floor(dataMax + 100)]);
    const [boundsTemperatureYAxis, setBoundsTemperatureYAxis] = useState([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
    const [boundsHumidityYAxis, setBoundsHumidityYAxis] = useState([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
    const [boundsAtmPressureYAxis, setBoundsAtmPressureYAxis] = useState([dataMin => Math.ceil(dataMin - 20), dataMax => Math.floor(dataMax + 20)]);
    const [boundsCo2YAxis, setBoundsCo2YAxis] = useState([dataMin => Math.ceil(dataMin - 10), dataMax => Math.floor(dataMax + 10)]);
    const [boundsVocYAxis, setBoundsVocYAxis] = useState([dataMin => Math.ceil(dataMin - 10), dataMax => Math.floor(dataMax + 10)]);
    const [boundsAccelerationYAxis, setBoundsAccelerationYAxis] = useState([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
    const [boundsLatitudeYAxis, setBoundsLatitudeYAxis] = useState([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
    const [boundsLongitudeYAxis, setBoundsLongitudeYAxis] = useState([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);

    //variables to display min and max for y-axis
    const [displayBoundsBatteryMv, setDisplayBoundsBatteryMv] = useState([0, 0]);
    const [displayBoundsTemperature, setDisplayBoundsTemperature] = useState([0, 0]);
    const [displayBoundsHumidity, setDisplayBoundsHumidity] = useState([0, 0]);
    const [displayBoundsAtmPressure, setDisplayBoundsAtmPressure] = useState([0, 0]);
    const [displayBoundsCo2, setDisplayBoundsCo2] = useState([0, 0]);
    const [displayBoundsVoc, setDisplayBoundsVoc] = useState([0, 0]);
    const [displayBoundsAcceleration, setDisplayBoundsAcceleration] = useState([0, 0]);
    const [displayBoundsLatitude, setDisplayBoundsLatitude] = useState([0, 0]);
    const [displayBoundsLongitude, setDisplayBoundsLongitude] = useState([0, 0]);

    //variables to save min and max display for y-axis
    const [saveBoundsBatteryMv, setSaveBoundsBattery] = useState([0, 0]);
    const [saveBoundsTemperature, setSaveBoundsTemperature] = useState([0, 0]);
    const [saveBoundsHumidity, setSaveBoundsHumidity] = useState([0, 0]);
    const [saveBoundsAtmPressure, setSaveBoundsAtmPressure] = useState([0, 0]);
    const [saveBoundsCo2, setSaveBoundsCo2] = useState([0, 0]);
    const [saveBoundsVoc, setSaveBoundsVoc] = useState([0, 0]);
    const [saveBoundsAcceleration, setSaveBoundsAcceleration] = useState([0, 0]);
    const [saveBoundsLatitude, setSaveBoundsLatitude] = useState([0, 0]);
    const [saveBoundsLongitude, setSaveBoundsLongitude] = useState([0, 0]);

    //variable to open the dialog to download data
    const [exportDialog, setExportDialog] = useState(false);
    //variables for the dialog
    const [separator, setSeparator] = useState(" ");
    const [includeIndex, setIncludeIndex] = useState(false);
    const [selectUnitTime, setSelectUnitTime] = useState("sec");
    const [dataTypeSelected, setDataTypeSelected] = useState({
        timeChecked: false,
        temperatureChecked: false,
        humidityChecked: false,
        currentChecked: false,
        movementChecked: false,
        magnetChecked: false,
        atmosphericPressureChecked: false,
        batteryChecked: false,
        latitudeChecked: false,
        longitudeChecked: false,
        co2Checked: false,
        vocChecked: false,
    });
    //variables easy to use from the state just above
    const {
        timeChecked,
        temperatureChecked,
        humidityChecked,
        currentChecked,
        movementChecked,
        magnetChecked,
        atmosphericPressureChecked,
        co2Checked,
        vocChecked,
        batteryChecked,
        latitudeChecked,
        longitudeChecked
    } = dataTypeSelected;

    //variable for the map slider
    const [mapSlider, setMapSlider] = useState([0, 0]);
    const [maxValueMapSlider, setMaxValueMapSlider] = useState(0);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);
    const {idAccount} = useContext(UserInfoContext);

    //the context for temperature unit
    const {temperatureUnit} = useContext(SettingsContext);

    //variables to zoom
    const [stateZoom, setStateZoom] = useState({
        left: 'dataMin',
        right: dataMax => Math.ceil(dataMax),
        refAreaLeft: '',
        refAreaRight: '',
        animation: true,
    });

    //get the device
    let device = JSON.parse(localStorage.getItem("device"));

    //the first and last point received from the device
    let startDate = new Date(device.firstPoint * 1000);
    let endDate = new Date(device.lastPoint * 1000);

    //custom icon for the collector pointer
    const collectorIcon = new L.Icon({
        iconUrl: mapIcon,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [0, -25],
    })

    //the initial values for formik
    const initialValues = {
        beginning: moment(new Date(device.lastPoint * 1000)).format('YYYY-MM-DD'),
        end: moment(new Date(device.lastPoint * 1000)).format('YYYY-MM-DD'),
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        beginning: Yup.date().required("Required")
            .min(moment(startDate).format('YYYY-MM-DD'), "No point before " + moment(startDate).format('DD/MM/YYYY'))
            .max(moment(endDate).format('YYYY-MM-DD'), "No point after " + moment(endDate).format('DD/MM/YYYY')),

        end: Yup.date().required("Required")
            .min(moment(startDate).format('YYYY-MM-DD'), "No point before " + moment(startDate).format('DD/MM/YYYY'))
            .max(moment(endDate).format('YYYY-MM-DD'), "No point after " + moment(endDate).format('DD/MM/YYYY')),
    });

    //return a marker for each point in historyLongLat
    const allHistoryMarker = () => {
        let tempMarkers = []
        if(historyLLDisplay.length !== 0){
            historyLLDisplay.forEach((element, index) => {
                if (element.longitude !== undefined) {
                    tempMarkers.push(<Marker key={index} position={[element.latitude, element.longitude]} icon={collectorIcon}>
                        <Popup>
                            {moment(new Date((firstPointTime + element.timestamp) * 1000)).format('MM/DD-HH:mm')}
                        </Popup>
                    </Marker>);
                }
            })
        }
        return tempMarkers.filter(element => element !== undefined);
    };

    //set the display temperature value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        temperatureDisplayed.forEach((element, index) => {
            if (element.temperature < min) {
                min = element.temperature;
            }
            if (element.temperature > max) {
                max = element.temperature
            }
            if (device.manufacturer === "Teltonika") {
                if (temperature2Displayed[index].temperature < min) {
                    min = temperature2Displayed[index].temperature;
                }
                if (temperature2Displayed[index].temperature > max) {
                    max = temperature2Displayed[index].temperature
                }
                if (temperature3Displayed[index].temperature < min) {
                    min = temperature3Displayed[index].temperature;
                }
                if (temperature3Displayed[index].temperature > max) {
                    max = temperature3Displayed[index].temperature
                }
                if (temperature4Displayed[index].temperature < min) {
                    min = temperature4Displayed[index].temperature;
                }
                if (temperature4Displayed[index].temperature > max) {
                    max = temperature4Displayed[index].temperature
                }
            }
        })

        setDisplayBoundsTemperature([Math.ceil(min - 1), Math.floor(max + 1)]);
        setSaveBoundsTemperature([Math.ceil(min - 1), Math.floor(max + 1)]);
    }, [temperatureDisplayed, temperature2Displayed, temperature3Displayed, temperature4Displayed, device.manufacturer]);

    //set the display humidity value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        humidityDisplayed.forEach((element, index) => {
            if (element.humidity < min) {
                min = element.humidity;
            }
            if (element.humidity > max) {
                max = element.humidity
            }
            if (device.manufacturer === "Teltonika") {
                if (humidity2Displayed[index].humidity < min) {
                    min = humidity2Displayed[index].humidity;
                }
                if (humidity2Displayed[index].humidity > max) {
                    max = humidity2Displayed[index].humidity
                }
                if (humidity3Displayed[index].humidity < min) {
                    min = humidity3Displayed[index].humidity;
                }
                if (humidity3Displayed[index].humidity > max) {
                    max = humidity3Displayed[index].humidity
                }
                if (humidity4Displayed[index].humidity < min) {
                    min = humidity4Displayed[index].humidity;
                }
                if (humidity4Displayed[index].humidity > max) {
                    max = humidity4Displayed[index].humidity
                }
            }

        })
        setDisplayBoundsHumidity([Math.ceil(min - 1), Math.floor(max + 1)]);
        setSaveBoundsHumidity([Math.ceil(min - 1), Math.floor(max + 1)]);
    }, [humidityDisplayed, humidity2Displayed, humidity3Displayed, humidity4Displayed, device.manufacturer]);

    //set the display CO2 value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        if (coDisplayed.length !== 0) {
            coDisplayed.forEach((element) => {
                if (element.co2 < min) {
                    min = element.co2;
                }
                if (element.co2 > max) {
                    max = element.co2
                }

            });
        }
        if (co2Displayed.length !== 0) {
            co2Displayed.forEach((element) => {
                if (element.co2 < min) {
                    min = element.co2;
                }
                if (element.co2 > max) {
                    max = element.co2
                }

            });
        }
        if (co3Displayed.length !== 0) {
            co3Displayed.forEach((element) => {
                if (element.co2 < min) {
                    min = element.co2;
                }
                if (element.co2 > max) {
                    max = element.co2
                }

            });
        }
        if (co4Displayed.length !== 0) {
            co4Displayed.forEach((element) => {
                if (element.co2 < min) {
                    min = element.co2;
                }
                if (element.co2 > max) {
                    max = element.co2
                }

            });
        }
        setDisplayBoundsCo2([min - 20, max + 20]);
        setSaveBoundsCo2([min - 20, max + 20]);
    }, [coDisplayed, co2Displayed, co3Displayed, co4Displayed, device.manufacturer]);

    //set the display voc value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        if (vocDisplayed.length !== 0) {
            vocDisplayed.forEach((element) => {
                if (element.voc < min) {
                    min = element.voc;
                }
                if (element.voc > max) {
                    max = element.voc
                }

            });
        }
        if (voc2Displayed.length !== 0) {
            voc2Displayed.forEach((element) => {
                if (element.voc < min) {
                    min = element.voc;
                }
                if (element.voc > max) {
                    max = element.voc
                }

            });
        }
        if (voc3Displayed.length !== 0) {
            voc3Displayed.forEach((element) => {
                if (element.voc < min) {
                    min = element.voc;
                }
                if (element.voc > max) {
                    max = element.voc
                }

            });
        }
        if (voc4Displayed.length !== 0) {
            voc4Displayed.forEach((element) => {
                if (element.voc < min) {
                    min = element.voc;
                }
                if (element.voc > max) {
                    max = element.voc
                }

            });
        }
        setDisplayBoundsVoc([min - 20, max + 20]);
        setSaveBoundsVoc([min - 20, max + 20]);
    }, [vocDisplayed, voc2Displayed, voc3Displayed, voc4Displayed, device.manufacturer]);

    //set the display atmPressure value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        if (atmosphericPressureDisplayed.length !== 0) {
            atmosphericPressureDisplayed.forEach((element, index) => {
                if (element.atmPressure < min) {
                    min = element.atmPressure;
                }
                if (element.atmPressure > max) {
                    max = element.atmPressure
                }
                if (device.manufacturer === "Teltonika") {
                    if (atmosphericPressure2Displayed.length !== 0 && atmosphericPressure2Displayed.length > index) {
                        if (atmosphericPressure2Displayed[index].atmPressure < min) {
                            min = atmosphericPressure2Displayed[index].atmPressure;
                        }
                        if (atmosphericPressure2Displayed[index].atmPressure > max) {
                            max = atmosphericPressure2Displayed[index].atmPressure
                        }
                    }
                    if (atmosphericPressure3Displayed.length !== 0 && atmosphericPressure3Displayed.length > index) {
                        if (atmosphericPressure3Displayed[index].atmPressure < min) {
                            min = atmosphericPressure3Displayed[index].atmPressure;
                        }
                        if (atmosphericPressure3Displayed[index].atmPressure > max) {
                            max = atmosphericPressure3Displayed[index].atmPressure
                        }
                    }
                    if (atmosphericPressure4Displayed.length !== 0 && atmosphericPressure4Displayed.length > index) {
                        if (atmosphericPressure4Displayed[index].atmPressure < min) {
                            min = atmosphericPressure4Displayed[index].atmPressure;
                        }
                        if (atmosphericPressure4Displayed[index].atmPressure > max) {
                            max = atmosphericPressure4Displayed[index].atmPressure
                        }
                    }

                }

            })
        }
        setDisplayBoundsAtmPressure([min - 20, max + 20]);
        setSaveBoundsAtmPressure([min - 20, max + 20]);
    }, [atmosphericPressureDisplayed, atmosphericPressure2Displayed, atmosphericPressure3Displayed, atmosphericPressure4Displayed, device.manufacturer]);

    //set the display acceleration X value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        accelerationXDisplayed.forEach((element, index) => {
            if (element.acceleration < min) {
                min = element.acceleration;
            }
            if (element.acceleration > max) {
                max = element.acceleration
            }
            if (accelerationYDisplayed[index].acceleration < min) {
                min = accelerationYDisplayed[index].acceleration;
            }
            if (accelerationYDisplayed[index].acceleration > max) {
                max = accelerationYDisplayed[index].acceleration
            }
            if (accelerationZDisplayed[index].acceleration < min) {
                min = accelerationZDisplayed[index].acceleration;
            }
            if (accelerationZDisplayed[index].acceleration > max) {
                max = accelerationZDisplayed[index].acceleration
            }
        })
        if (device.manufacturer === "Teltonika") {
            accelerationX2Displayed.forEach((element, index) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
                if (accelerationY2Displayed[index].acceleration < min) {
                    min = accelerationYDisplayed[index].acceleration;
                }
                if (accelerationY2Displayed[index].acceleration > max) {
                    max = accelerationYDisplayed[index].acceleration
                }
                if (accelerationZ2Displayed[index].acceleration < min) {
                    min = accelerationZDisplayed[index].acceleration;
                }
                if (accelerationZ2Displayed[index].acceleration > max) {
                    max = accelerationZDisplayed[index].acceleration
                }
            })
            accelerationX3Displayed.forEach((element, index) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
                if (accelerationY3Displayed[index].acceleration < min) {
                    min = accelerationYDisplayed[index].acceleration;
                }
                if (accelerationY3Displayed[index].acceleration > max) {
                    max = accelerationYDisplayed[index].acceleration
                }
                if (accelerationZ3Displayed[index].acceleration < min) {
                    min = accelerationZDisplayed[index].acceleration;
                }
                if (accelerationZ3Displayed[index].acceleration > max) {
                    max = accelerationZDisplayed[index].acceleration
                }
            })
            accelerationX4Displayed.forEach((element, index) => {
                if (element.acceleration < min) {
                    min = element.acceleration;
                }
                if (element.acceleration > max) {
                    max = element.acceleration
                }
                if (accelerationY4Displayed[index].acceleration < min) {
                    min = accelerationYDisplayed[index].acceleration;
                }
                if (accelerationY4Displayed[index].acceleration > max) {
                    max = accelerationYDisplayed[index].acceleration
                }
                if (accelerationZ4Displayed[index].acceleration < min) {
                    min = accelerationZDisplayed[index].acceleration;
                }
                if (accelerationZ4Displayed[index].acceleration > max) {
                    max = accelerationZDisplayed[index].acceleration
                }
            })
        }
        setDisplayBoundsAcceleration([Math.ceil(min - 1), Math.floor(max + 1)]);
        setSaveBoundsAcceleration([Math.ceil(min - 1), Math.floor(max + 1)]);
    }, [accelerationXDisplayed, accelerationX2Displayed, accelerationX3Displayed, accelerationX4Displayed, accelerationYDisplayed,
        accelerationY2Displayed, accelerationY3Displayed, accelerationY4Displayed, accelerationZDisplayed, accelerationZ2Displayed,
        accelerationZ3Displayed, accelerationZ4Displayed, device.manufacturer, accXDisplayed, accYDisplayed, accZDisplayed]);

    //set the display battery mv value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        batteryMvDisplayed.forEach((element, index) => {
            if (element.batteryMv < min) {
                min = element.batteryMv;
            }
            if (element.batteryMv > max) {
                max = element.batteryMv
            }
            if (device.manufacturer === "Teltonika") {
                if (batteryExternalDisplayed[index].batteryMv < min) {
                    min = batteryExternalDisplayed[index].batteryMv;
                }
                if (batteryExternalDisplayed[index].batteryMv > max) {
                    max = batteryExternalDisplayed[index].batteryMv
                }

                if(batteryMv1Displayed.length !== 0){
                    if (batteryMv1Displayed[index].batteryMv < min && batteryMv1Displayed[index].batteryMv !== 0) {
                        min = batteryMv1Displayed[index].batteryMv;
                    }
                    if (batteryMv1Displayed[index].batteryMv > max) {
                        max = batteryMv1Displayed[index].batteryMv
                    }
                }

                if(batteryMv2Displayed.length !== 0){
                    if (batteryMv2Displayed[index].batteryMv < min && batteryMv2Displayed[index].batteryMv !== 0) {
                        min = batteryMv2Displayed[index].batteryMv;
                    }
                    if (batteryMv2Displayed[index].batteryMv > max) {
                        max = batteryMv2Displayed[index].batteryMv
                    }
                }

                if(batteryMv3Displayed.length !== 0){
                    if (batteryMv3Displayed[index].batteryMv < min && batteryMv3Displayed[index].batteryMv !== 0) {
                        min = batteryMv3Displayed[index].batteryMv;
                    }
                    if (batteryMv3Displayed[index].batteryMv > max) {
                        max = batteryMv3Displayed[index].batteryMv
                    }
                }

                if(batteryMv4Displayed.length !== 0){
                    if (batteryMv4Displayed[index].batteryMv < min && batteryMv4Displayed[index].batteryMv !== 0) {
                        min = batteryMv4Displayed[index].batteryMv;
                    }
                    if (batteryMv4Displayed[index].batteryMv > max) {
                        max = batteryMv4Displayed[index].batteryMv
                    }
                }
            }
        })
        setDisplayBoundsBatteryMv([min - 100, max + 100]);
        setSaveBoundsBattery([min - 100, max + 100]);
    }, [batteryMvDisplayed, batteryExternalDisplayed, batteryMv1Displayed, batteryMv2Displayed, batteryMv3Displayed, batteryMv4Displayed, device.manufacturer]);

    //set the display latitude and longitude value of the fields for the bounds selecting
    useEffect(() => {
        if (device.manufacturer === "Teltonika") {
            let latMin = 100000;
            let latMax = -100000;
            let longMin = 100000;
            let longMax = -100000;
            longLatDisplayed.forEach((element) => {
                if (element.latitude < latMin) {
                    latMin = element.latitude;
                }
                if (element.latitude > latMax) {
                    latMax = element.latitude
                }
                if (element.longitude < longMin) {
                    longMin = element.longitude;
                }
                if (element.longitude > longMax) {
                    longMax = element.longitude
                }
            })
            setDisplayBoundsLatitude([Math.ceil(latMin - 1), Math.floor(latMax + 1)]);
            setDisplayBoundsLongitude([Math.ceil(longMin - 1), Math.floor(longMax + 1)]);
            setSaveBoundsLatitude([Math.ceil(latMin - 1), Math.floor(latMax + 1)]);
            setSaveBoundsLongitude([Math.ceil(longMin - 1), Math.floor(longMax + 1)]);
        }
    }, [longLatDisplayed, device.manufacturer]);

    //determine witch point to display to prevent multiple marker stacking at the same point
    useEffect(() => {
        if (historyLongLat.length !== 0) {
            //reverse the array to render only the last point and not the first in a place
            let reversedLongLat = [...historyLongLat].reverse();

            let arrayLessPoint = [];

            //for each point in the array, keep it only if the long and the lat is not already displayed
            reversedLongLat.forEach((element) => {
                if (!foundItem(arrayLessPoint, element.longitude * 100, element.latitude * 100)) {
                    if (element.longitude !== 0 && element.latitude !== 0) {
                        arrayLessPoint.push(element);
                    }
                }
            });
            setHistoryLLDisplay(arrayLessPoint);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyLongLat]);

    //set dataLessPoint for teltonika at start
    useEffect(() => {
        if (device.manufacturer === "Teltonika") {
            //if less than 500 points, display all points, if not, display max 500 points
            let length = 0;
            if(temperature.length !== 0){
                length = temperature.length;
            }else if(temperature2.length !== 0){
                length = temperature2.length;
            }else if(temperature3.length !== 0){
                length = temperature3.length;
            }else if(temperature4.length !== 0){
                length = temperature4.length;
            }
            if (length < 200) {
                //set the temperature, humidity for the 4 devices and number reduce
                setTemperatureDisplayed(temperature);
                setTemperature2Displayed(temperature2);
                setTemperature3Displayed(temperature3);
                setTemperature4Displayed(temperature4);
                setHumidityDisplayed(humidity);
                setHumidity2Displayed(humidity2);
                setHumidity3Displayed(humidity3);
                setHumidity4Displayed(humidity4);
                setNumberReduce(1);
            } else {
                //set the data with max 500 points
                let numberReduce;
                if (length / 200 <= 100) {
                    numberReduce = Math.ceil(length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsTemperatureHumidity(temperature, humidity, numberReduce, setTemperatureDisplayed, setHumidityDisplayed);
                displayLessPointsTemperatureHumidity(temperature2, humidity2, numberReduce, setTemperature2Displayed, setHumidity2Displayed);
                displayLessPointsTemperatureHumidity(temperature3, humidity3, numberReduce, setTemperature3Displayed, setHumidity3Displayed);
                displayLessPointsTemperatureHumidity(temperature4, humidity4, numberReduce, setTemperature4Displayed, setHumidity4Displayed);
                setNumberReduce(numberReduce);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [temperature, temperature2, temperature3, temperature4]);

    //set the division number and the max time
    useEffect(() => {
        //find the latest data
        let totalTime = Math.round(stateZoom.right - stateZoom.left);

        if (!isNaN(totalTime)) {
            let divisionNumber = 1;
            //choose the unit for the time depending on the duration of the data (data during how much time)
            switch (true) {
                //less than 2 min - unit in seconds
                case (totalTime < 120):
                    setUnitTime("min");
                    break;
                //less than 5h - unit in minutes
                case (totalTime < 18000):
                    setUnitTime("min");
                    divisionNumber = 60;
                    break;
                //less than 5 days - unit in hours
                case (totalTime < 432000):
                    setUnitTime("h");
                    divisionNumber = 3600;
                    break;
                //less than 180 days - unit in days
                case (totalTime < 15552000):
                    setUnitTime("days");
                    divisionNumber = 86400;
                    break;
                //less than 3 years - unit in month
                case (totalTime < 94608000):
                    setUnitTime("month");
                    divisionNumber = 2628002;
                    break;
                //more than 3 years - unit in year
                case (totalTime > 9460800):
                    setUnitTime("year");
                    divisionNumber = 31536000;
                    break;
                default:
                    break;
            }
            //set variable for divisionNumber
            setTimeDivisionNumber(divisionNumber);
            //update the maxTime;
            setMaxTime(totalTime);
        }
    }, [stateZoom.left, stateZoom.right]);

    //set the historyLongLat for the points on the map
    useEffect(() => {
        if (longLat.length !== 0) {
            //adjust the array with all the element inside the range
            let counter = 0;
            let tempHistory = longLat.map((element) => {
                counter++;
                if (counter >= 0 && counter <= maxValueMapSlider) {
                    return element;
                }
                return undefined;
            });
            tempHistory = tempHistory.filter(element => element !== undefined);

            setHistoryLongLat(tempHistory);
        }
    }, [longLat, maxValueMapSlider]);

    //manage the arrays that are fill and doesn't be
    useEffect(() => {
        if(voc.length !== 0){
            if(voc[0].voc === 0){
                setVoc([]);
                setVocDisplayed([]);
                if(co[0].co2 === 0){
                    setCo([]);
                    setCoDisplayed([]);
                }
            }else {
                setCo([]);
                setCoDisplayed([]);
                setAtmosphericPressure([]);
                setAtmosphericPressureDisplayed([]);
            }
        }
        if(voc2.length !== 0){
            if(voc2[0].voc === 0){
                setVoc2([]);
                setVoc2Displayed([]);
                if(co2[0].co2 === 0){
                    setCo2([]);
                    setCo2Displayed([]);
                }
            }else {
                setCo2([]);
                setCo2Displayed([]);
                setAtmosphericPressure2([]);
                setAtmosphericPressure2Displayed([]);
            }
        }
        if(voc3.length !== 0){
            if(voc3[0].voc === 0){
                setVoc3([]);
                setVoc3Displayed([]);
                if(co3[0].co2 === 0){
                    setCo3([]);
                    setCo3Displayed([]);
                }
            }else {
                setCo3([]);
                setCo3Displayed([]);
                setAtmosphericPressure3([]);
                setAtmosphericPressure3Displayed([]);
            }
        }
        if(voc4.length !== 0){
            if(voc4[0].voc === 0){
                setVoc4([]);
                setVoc4Displayed([]);
                if(co4[0].co2 === 0){
                    setCo4([]);
                    setCo4Displayed([]);
                }
            }else {
                setCo4([]);
                setCo4Displayed([]);
                setAtmosphericPressure4([]);
                setAtmosphericPressure4Displayed([]);
            }
        }
    }, [voc, voc2, voc3, voc4, co, co2, co3, co4]);

    //send true or false depending on if the given table contains element with the given properties long and lat
    const foundItem = (table, long, lat) => {
        //return true if the long and lat given (*100 to have a small margin of error) is already in the table.
        return table.some(element => {
            return !!(Math.round(element.longitude * 100) === Math.round(long) && Math.round(element.latitude * 100) === Math.round(lat));
        });
    }

    //get all the devices
    useEffect(() => {
        if(source !==null){
            //get the devices
            //generate operation code
            let operation = mqttRequests.generateOperationCode("getAllDevices");
            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getAllDevices");
            mqttRequests.publish(mqttClient, "device/getAllDevices", data);

            //read incoming message and send data to manageThem
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                if (jsonParsed.accX) {
                    manageCrashData(jsonParsed);
                }else{
                    //the list of all devices in the db
                    if(jsonParsed.devices) {
                        setDevices(jsonParsed.devices);
                    }
                    //if it's data
                    if (jsonParsed.data) {
                        //set the real timestamp of the first point
                        setLoadingOn(false);
                        setFirstPointTime(jsonParsed.firstPoint);
                        setAllPoints(jsonParsed.data);
                        manageData(jsonParsed.data, jsonParsed.firstPoint);
                        //if it's a teltonika, manage the size of the map slide
                        if (jsonParsed.data.Longitude) {
                            setMapSlider([jsonParsed.data.Longitude.length, jsonParsed.data.Longitude.length]);
                            setMaxValueMapSlider(jsonParsed.data.Longitude.length);
                        }
                        //display the chart
                        setChartHidden(false);
                    }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[source, mqttClient]);

    //return labels for the xAxis of all charts displaying the time in duration and in days/month/year
    function CustomizedTick(props) {
        const {x, y, payload} = props;
        //find the duration between the first point and now
        let timestamp = payload.value;

        //set the actual time in date format
        let displayDate = new Date((firstPointTime + timestamp) * 1000);
        //display the infos differently according to the duration of the data asked
        switch (true) {
            //less than 5 days : days + hours
            case (maxTime < 432000):
                displayDate = moment(displayDate).format('MM/DD-HH:mm');
                break;
            //less than 4 month : days
            case (maxTime < 10512008):
                displayDate = moment(displayDate).format('MM/DD');
                break;
            //less than 2.5 years : month + year
            case (maxTime < 78840000):
                displayDate = moment(displayDate).format('MM-YYYY');
                break;
            //2.5 years or more : year
            case (maxTime >= 78840000):
                displayDate = moment(displayDate).format('YYYY');
                break;
            default:
                break;
        }

        //return a value in duration and a value in date format
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} fill="#666">
                    <tspan textAnchor="middle" x="0">
                        {Math.round(payload.value / timeDivisionNumber)}
                    </tspan>
                    <tspan textAnchor="middle" x="0" dy="20">
                        {displayDate}
                    </tspan>
                </text>
            </g>
        );
    }

    function CustomizedTickCrash(props) {
        const {x, y, payload} = props;
        //find the duration between the first point and now
        let timestamp = payload.value;
        //set the actual time in date format
        let displayDate = moment(new Date((timestamp))).format('MM/DD-HH:mm:ss');

        //return a value in duration and a value in date format
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} fill="#666">
                    <tspan textAnchor="middle" x="0" dy="40">
                        {displayDate}
                    </tspan>
                </text>
            </g>
        );
    }

    //returns the customized data when a user pass the mouse in a point in the chart
    const CustomTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            //if the user is on a point -> render the date formatted and the info (humidity, temperature...)
            return (
                <div className="deviceCustomTooltip">
                    <p>Time: {moment(new Date((payload[0].payload.timestamp + firstPointTime) * 1000)).format('YYYY-MM-DD HH:mm:ss')}</p>
                    <p>{`${Object.keys(payload[0].payload)[2]}: ${Object.values(payload[0].payload)[2] + payload[1].unit}`}</p>
                    {Object.values(payload[0].payload)[3] !== undefined ?
                        <p>{`Gateway: ${Object.values(payload[0].payload)[3]}`}</p>
                        :
                        null
                    }
                </div>
            );
        } else {
            return null;
        }
    }

    const CustomTooltipCrash = ({active, payload}) => {
        if (active && payload && payload.length) {
            //if the user is on a point -> render the date formatted and the info (humidity, temperature...)
            return (
                <div className="deviceCustomTooltip">
                    <p>Time: {moment(new Date(payload[0].payload.timestamp)).format('YYYY-MM-DD HH:mm:ss')}</p>
                    <p>acceleration: {Object.values(payload[0].payload)[1]} g</p>
                </div>
            );
        } else {
            return null;
        }
    }

    //returns the customized data when a user pass the mouse in a point in the chart
    const CustomTooltipForLongLat = ({active, payload}) => {
        if (active && payload && payload.length) {
            //if the user is on a point -> render the date formatted and the info (humidity, temperature...)
            return (
                <div className="deviceCustomTooltip">
                    <p>Time: {moment(new Date((payload[0].payload.timestamp + firstPointTime) * 1000)).format('YYYY-MM-DD HH:mm')}</p>
                    <p>{`${Object.keys(payload[0].payload)[2]}: ${Object.values(payload[0].payload)[2] + payload[1].unit}`}</p>
                    <p>{`${Object.keys(payload[0].payload)[3]}: ${Object.values(payload[0].payload)[3] + payload[1].unit}`}</p>
                </div>
            );
        } else {
            return null;
        }
    }

    //to ask mqtt to give points between time in the form
    const submitMethod = (value) => {
        //check if the beginning date is before the end date
        if (new Date(value.end) - new Date(value.beginning) < 0) {
            setErrMessage(true)
        } else {

            setLoadingOn(true);
            //ask for data
            //generate operation code
            const operation = mqttRequests.generateOperationCode("dataAsked");

            //get the start of the day in our timezone
            let beginDate = new Date(value.beginning).getTime() / 1000 + new Date().getTimezoneOffset() * 60;
            //get the end of the day in our timezone (get the start of the day + 86399 which goes to 23h59 59sec the same day)
            let finishDate = (new Date(value.end).getTime() / 1000) + new Date().getTimezoneOffset() * 60 + 86399;

            //create json to publish
            const data = '{"operation":"' + operation + '", "source":"' + source + '", "identifier":"' + device.identifier + '",' +
                ' "from":"' + beginDate + '", "to":"' + finishDate + '", "idModel":"' + device.idModel + '", "dataType":[' + device.dataType + '], "sortType":0}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/dataAsked");

            //publish a demand to get the data from a device
            mqttRequests.publish(mqttClient, "data/dataAsked", data);
        }
    };

    //manage data
    const manageData = (data, firstPoint) => {
        //reset the slider change
        setNumberReduce(1);
        //find the latest data
        let totalTime = data.time[data.time.length - 1];

        let divisionNumber = 1;
        //choose the unit for the time depending on the duration of the data (data during how much time)
        switch (true) {
            //less than 2 min - unit in seconds
            case (totalTime < 120):
                break;
            //less than 5h - unit in minutes
            case (totalTime < 18000):
                setUnitTime("min");
                setSelectUnitTime("min");
                divisionNumber = 60;
                break;
            //less than 5 days - unit in hours
            case (totalTime < 432000):
                setUnitTime("h");
                setSelectUnitTime("h");
                divisionNumber = 3600;
                break;
            //less than 180 days - unit in days
            case (totalTime < 15552000):
                setUnitTime("days");
                setSelectUnitTime("days");
                divisionNumber = 86400;
                break;
            //less than 3 years - unit in month
            case (totalTime < 94608000):
                setUnitTime("month");
                setSelectUnitTime("month");
                divisionNumber = 2628002;
                break;
            //more than 3 years - unit in year
            case (totalTime > 9460800):
                setUnitTime("year");
                setSelectUnitTime("year");
                divisionNumber = 31536000;
                break;
            default:
                break;
        }
        //set variable for divisionNumber
        setTimeDivisionNumber(divisionNumber);

        //set the max timestamp
        setMaxTime(data.time[data.time.length - 1]);

        //call the method to manage temperature and humidity for teltonika (4 devices) or other (1 device)
        if (device.manufacturer === "Teltonika") {
            setTemperatureHumidityTeltonika(data, divisionNumber);
        } else {
            setTemperatureHumidityNotTeltonika(data, divisionNumber);
        }

        //call method to manage co2
        setCO2Data(data, divisionNumber);

        //call method to manage voc
        setVocData(data, divisionNumber);

        //call method to manage atmospheric pressure
        setAtmPressure(data, divisionNumber);

        //call method to manage movement
        setMovementData(data, divisionNumber);

        //call method to manage acceleration
        setAccelerationData(data, divisionNumber);

        //call method to manage current
        setCurrentData(data, divisionNumber);

        //call method to manage battery
        setBatteryData(data, divisionNumber);

        //call method to manage magnet
        setMagnetData(data, divisionNumber);

        //manage longitude latitude and crash
        if (device.manufacturer === "Teltonika") {
            //set the longitude and latitude
            let tempLongLat = data.Longitude.map((id, index_value) => {
                if (data.Longitude[index_value] !== 0 && data.Latitude[index_value] !== 0) {
                    return {
                        time: data["time"][index_value] / divisionNumber,
                        timestamp: data["time"][index_value],
                        longitude: data.Longitude[index_value],
                        latitude: data.Latitude[index_value]
                    }
                } else {
                    return {
                        time: data["time"][index_value] / divisionNumber,
                        timestamp: data["time"][index_value],
                        longitude: data.Longitude[index_value - 10],
                        latitude: data.Latitude[index_value - 10]
                    }
                }
            });
            setLongLat(tempLongLat.filter(element => element !== undefined));
            //set the longitude and latitude to the last point (the point displayed by default in the map)
            setLongitude(tempLongLat[tempLongLat.length - 1].longitude);
            setLatitude(tempLongLat[tempLongLat.length - 1].latitude);

            //if less than 500 points, display all points, if not, display max 500 points
            if (tempLongLat.length < 200) {
                setLongLatDisplayed(tempLongLat.filter(element => element !== undefined));
            } else {
                let numberReduce;
                if (tempLongLat.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempLongLat.length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsLongLat(tempLongLat.filter(element => element !== undefined), numberReduce);
            }

            //manage the crash
            let tempCrash = data.crash.map((id, index_value) => {
                if (id !== 0) {
                    return {
                        timestamp: data["time"][index_value] + firstPoint,
                        crashNumber: id,
                    }
                } else {
                    return undefined;
                }
            });
            setAllCrash(tempCrash.filter(element => element !== undefined));
        }


    }

    //manage temperature and humidity if the device is a teltonika
    const setTemperatureHumidityTeltonika = (data, divisionNumber) => {
        if(device.dataType.includes("20221222161657076001")){
            //find all the 4 temperature names and humidity names
            let temperatures = Object.keys(data).filter(name => name.includes("Temperature"));
            let humidities = Object.keys(data).filter(name => name.includes("Humidity"));

            //set the label for the temperatures
            setLabelTemperature(temperatures[0].substring(13));
            setLabelTemperature2(temperatures[1].substring(13));
            setLabelTemperature3(temperatures[2].substring(13));
            setLabelTemperature4(temperatures[3].substring(13));
            //set the label for the humidities
            setLabelHumidity(humidities[0].substring(10));
            setLabelHumidity2(humidities[1].substring(10));
            setLabelHumidity3(humidities[2].substring(10));
            setLabelHumidity4(humidities[3].substring(10));

            //set the list of all teltonika device name
            setTeltonikaDeviceList(temperatures.map((element) => {
                return element.substring(13);
            }));

            //set variables for the number of device
            let occurrence = 0;
            //for each 4 temperatures, set it in a variable and define its time and temperature
            temperatures.forEach((temperatureVariable) => {
                let temperaturePoints = data[temperatureVariable].map((id, index_value) => {
                    //return the correct temperature depending on the unit in the settings
                    if (temperatureUnit === "C") {
                        return {
                            time: data["time"][index_value] / divisionNumber,
                            timestamp: data["time"][index_value],
                            temperature: data[temperatureVariable][index_value] > -273.15 ? data[temperatureVariable][index_value] : data[temperatureVariable][0],
                        }
                    } else if (temperatureUnit === "F") {
                        return {
                            time: data["time"][index_value] / divisionNumber,
                            timestamp: data["time"][index_value],
                            temperature: data[temperatureVariable][index_value] > -273.15 ? Math.round((((data[temperatureVariable][index_value] * 9 / 5) + 32) * 100) / 100) : Math.round((((data[temperatureVariable][0] * 9 / 5) + 32) * 100) / 100),
                        }
                    } else {
                        return {
                            time: data["time"][index_value] / divisionNumber,
                            timestamp: data["time"][index_value],
                            temperature: data[temperatureVariable][index_value] > -273.15 ? Math.round(((data[temperatureVariable][index_value] + 273.15) * 100) / 100) : Math.round(((data[temperatureVariable][0] + 273.15) * 100) / 100),
                        }
                    }
                });
                occurrence++;
                //depending on the device, set it in the correct variable

                switch (occurrence) {
                    case 1:
                        if(data[temperatureVariable][0] !== -1000){
                            setTemperature(temperaturePoints.filter(element => element !== undefined));
                        }else{
                            //it's current
                            setCurrentTeltonika(data, divisionNumber, 1);
                        }
                        break;
                    case 2:
                        if(data[temperatureVariable][0] !== -1000) {
                            setTemperature2(temperaturePoints.filter(element => element !== undefined));
                        }else{
                            //it's current
                            setCurrentTeltonika(data, divisionNumber, 2);
                        }
                        break;
                    case 3:
                        if(data[temperatureVariable][0] !== -1000) {
                            setTemperature3(temperaturePoints.filter(element => element !== undefined));
                        }else{
                            //it's current
                            setCurrentTeltonika(data, divisionNumber, 3);
                        }
                        break;
                    case 4:
                        if(data[temperatureVariable][0] !== -1000) {
                            setTemperature4(temperaturePoints.filter(element => element !== undefined));
                        }else{
                            //it's current
                            setCurrentTeltonika(data, divisionNumber, 4);
                        }
                        break;
                    default:
                        break;
                }

            });

            //set variables for the number of device
            occurrence = 0;
            //for each 4 humidities, set it in a variable and define its time and humidity
            humidities.forEach((humidityVariable) => {
                let humidityPoints = data[humidityVariable].map((id, index_value) => {
                    return {
                        time: data["time"][index_value] / divisionNumber,
                        timestamp: data["time"][index_value],
                        humidity: data[humidityVariable][index_value],
                    }
                });
                occurrence++;
                //depending on the device, set it in the correct variable
                switch (occurrence) {
                    case 1:
                        if(data[humidityVariable][0] !== -1000) {
                            setHumidity(humidityPoints);
                        }
                        break;
                    case 2:
                        if(data[humidityVariable][0] !== -1000) {
                            setHumidity2(humidityPoints);
                        }
                        break;
                    case 3:
                        if(data[humidityVariable][0] !== -1000) {
                            setHumidity3(humidityPoints);
                        }
                        break;
                    case 4:
                        if(data[humidityVariable][0] !== -1000) {
                            setHumidity4(humidityPoints);
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }

    //manage temperature and humidity if the device is not a teltonika
    const setTemperatureHumidityNotTeltonika = (data, divisionNumber) => {
        if(device.dataType.includes("20221222161657076001")){
            //set the name of the content inside data
            let humidityVariable = "Humidity_" + device.identifier;
            let temperatureVariable = "Temperature_" + device.identifier;

            let tempTemperature;
                if(data["macGateway"]) {
                    //set the variable "temperature" with a table of all temperatures and time
                    tempTemperature = data[temperatureVariable].map((id, index_value) => {
                        //return the correct temperature depending on the unit in the settings
                        if (temperatureUnit === "C") {
                            return {
                                time: data["time"][index_value] / divisionNumber,
                                timestamp: data["time"][index_value],
                                temperature: data[temperatureVariable][index_value],
                                gateway: data["macGateway"][index_value],
                            }
                        } else if (temperatureUnit === "F") {
                            return {
                                time: data["time"][index_value] / divisionNumber,
                                timestamp: data["time"][index_value],
                                temperature: Math.round((((data[temperatureVariable][index_value] * 9 / 5) + 32) * 100) / 100),
                                gateway: data["macGateway"][index_value],
                            }
                        } else {
                            return {
                                time: data["time"][index_value] / divisionNumber,
                                timestamp: data["time"][index_value],
                                temperature: Math.round(((data[temperatureVariable][index_value] + 273.15) * 100) / 100),
                            }
                        }
                    });
                }else {
                    //set the variable "temperature" with a table of all temperatures and time
                    tempTemperature = data[temperatureVariable].map((id, index_value) => {
                        //return the correct temperature depending on the unit in the settings
                        if (temperatureUnit === "C") {
                            return {
                                time: data["time"][index_value] / divisionNumber,
                                timestamp: data["time"][index_value],
                                temperature: data[temperatureVariable][index_value],
                            }
                        } else if (temperatureUnit === "F") {
                            return {
                                time: data["time"][index_value] / divisionNumber,
                                timestamp: data["time"][index_value],
                                temperature: Math.round((((data[temperatureVariable][index_value] * 9 / 5) + 32) * 100) / 100),
                            }
                        } else {
                            return {
                                time: data["time"][index_value] / divisionNumber,
                                timestamp: data["time"][index_value],
                                temperature: Math.round(((data[temperatureVariable][index_value] + 273.15) * 100) / 100),
                            }
                        }
                    });
                }
            //set temperature = all points
            setTemperature(tempTemperature);

            let tempHumidity;

            if (data["macGateway"]){
                //set the variable "humidity" with a table of all humidity and time
                tempHumidity = data[humidityVariable].map((id, index_value) => {
                    return {
                        time: data["time"][index_value] / divisionNumber,
                        timestamp: data["time"][index_value],
                        humidity: data[humidityVariable][index_value],
                        gateway: data["macGateway"][index_value],
                    }
                });
            } else {
                //set the variable "humidity" with a table of all humidity and time
                tempHumidity = data[humidityVariable].map((id, index_value) => {
                    return {
                        time: data["time"][index_value] / divisionNumber,
                        timestamp: data["time"][index_value],
                        humidity: data[humidityVariable][index_value],
                    }
                });
            }

            //set humidity = all points
            setHumidity(tempHumidity);

            //if less than 500 points, display all points, if not, display max 500 points
            if (tempTemperature.length < 200) {
                setTemperatureDisplayed(tempTemperature);
                setHumidityDisplayed(tempHumidity);
            } else {
                let numberReduce;
                if (tempTemperature.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempTemperature.length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsTemperatureHumidity(tempTemperature, tempHumidity, numberReduce, setTemperatureDisplayed, setHumidityDisplayed);
                setNumberReduce(numberReduce);
            }

            //set labels
            setLabelTemperature("Temperature");
            setLabelHumidity("Humidity");
        }
    }

    //manage Co2
    const setCO2Data = (data, divisionNumber) => {

        //select all the co2
        let co2Data = Object.keys(data).filter(name => name.includes("CO2"));

        //one co2 -> not teltonika
        if (co2Data.length === 1) {
            //set the variable "atmPressure" with a table of all co2 and time
            let tempCo2 = data[co2Data[0]].map((id, index_value) => {
                if (data[co2Data[0]][index_value] !== 0) {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        co2: data[co2Data[0]][index_value],
                    }
                }
                return undefined;
            });

            //if less than 200 points, display all points, if not, display max 200 points
            if (tempCo2.length < 200) {
                setCoDisplayed(tempCo2.filter(element => element !== undefined));
            } else {
                let numberReduce;
                if (tempCo2.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempCo2.length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsCo2(tempCo2.filter(element => element !== undefined), numberReduce, setCoDisplayed);
            }

            //set atmPressure = all points
            setCo(tempCo2.filter(element => element !== undefined));
            //set the labels
            setLabelCo("CO2");

            //co2Data === 4 -> it's a teltonika
        }
    }

    //manage voc
    const setVocData = (data, divisionNumber) => {

        //select all the co2
        let vocData = Object.keys(data).filter(name => name.includes("voc"));

        //one co2 -> not teltonika
        if (vocData.length === 1) {
            //set the variable "vocData" with a table of all voc and time
            let tempVoc = data[vocData[0]].map((id, index_value) => {
                if (data[vocData[0]][index_value] !== 0) {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        voc: data[vocData[0]][index_value],
                    }
                }
                return undefined;
            });

            //if less than 200 points, display all points, if not, display max 200 points
            if (tempVoc.length < 200) {
                setVocDisplayed(tempVoc.filter(element => element !== undefined));
            } else {
                let numberReduce;
                if (tempVoc.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempVoc.length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsVoc(tempVoc.filter(element => element !== undefined), numberReduce, setVocDisplayed);
            }

            //set voc = all points
            setVoc(tempVoc.filter(element => element !== undefined));
            //set the labels
            setLabelVoc("Voc");
        }
    }

    //manage atmospheric pressure
    const setAtmPressure = (data, divisionNumber) => {

        //select all the atmospheric pressure
        let atmPressures = Object.keys(data).filter(name => name.includes("AtmPressure"));

        //one atm pressure -> not teltonika
        if (atmPressures.length === 1) {
            //set the variable "atmPressure" with a table of all atmPressure and time
            let tempAtmPressure = data[atmPressures[0]].map((id, index_value) => {
                if (data[atmPressures[0]][index_value] !== 0) {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        atmPressure: data[atmPressures[0]][index_value],
                    }
                }
                return undefined;
            });

            //if less than 500 points, display all points, if not, display max 500 points
            if (tempAtmPressure.length < 200) {
                setAtmosphericPressureDisplayed(tempAtmPressure.filter(element => element !== undefined));
            } else {
                let numberReduce;
                if (tempAtmPressure.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempAtmPressure.length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsAtmPressure(tempAtmPressure.filter(element => element !== undefined), numberReduce, setAtmosphericPressureDisplayed);
            }

            //set atmPressure = all points
            setAtmosphericPressure(tempAtmPressure.filter(element => element !== undefined));
            //set the labels
            setLabelAtmosphericPressure("Atmospheric pressure");

            //atmPressure === 4 -> it's a teltonika
        } else if (atmPressures.length === 4) {
            //set variables for the number of device
            let occurrence = 0;
            let numberReduce = 1;
            //for each 4 temperatures, set it in a variable and define its time and temperature
            atmPressures.forEach((atmPressureVariable) => {
                let atmPressurePoints = data[atmPressureVariable].map((id, index_value) => {
                    if (data[atmPressureVariable][index_value] !== 0) {
                        return {
                            time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                            timestamp: data["time"][index_value],
                            atmPressure: data[atmPressureVariable][index_value],
                        }
                    }
                    return undefined;
                });
                occurrence++;
                //depending on the device, set it in the correct variable
                switch (occurrence) {
                    case 1:
                        setAtmosphericPressure(atmPressurePoints.filter(element => element !== undefined));
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (atmPressurePoints.length < 200) {
                            setAtmosphericPressureDisplayed(atmPressurePoints.filter(element => element !== undefined));
                        } else {
                            if (atmPressurePoints.length / 200 <= 100) {
                                numberReduce = Math.ceil(atmPressurePoints.length / 200);
                            } else {
                                numberReduce = 100;
                            }
                            displayLessPointsAtmPressure(atmPressurePoints.filter(element => element !== undefined), numberReduce, setAtmosphericPressureDisplayed);
                        }
                        break;
                    case 2:
                        setAtmosphericPressure2(atmPressurePoints.filter(element => element !== undefined));
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (atmPressurePoints.length < 200) {
                            setAtmosphericPressure2Displayed(atmPressurePoints.filter(element => element !== undefined));
                        } else {
                            displayLessPointsAtmPressure(atmPressurePoints.filter(element => element !== undefined), numberReduce, setAtmosphericPressure2Displayed);
                        }
                        break;
                    case 3:
                        setAtmosphericPressure3(atmPressurePoints.filter(element => element !== undefined));
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (atmPressurePoints.length < 200) {
                            setAtmosphericPressure3Displayed(atmPressurePoints.filter(element => element !== undefined));
                        } else {
                            displayLessPointsAtmPressure(atmPressurePoints.filter(element => element !== undefined), numberReduce, setAtmosphericPressure3Displayed);
                        }
                        break;
                    case 4:
                        setAtmosphericPressure4(atmPressurePoints.filter(element => element !== undefined));
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (atmPressurePoints.length < 200) {
                            setAtmosphericPressure4Displayed(atmPressurePoints.filter(element => element !== undefined));
                        } else {
                            displayLessPointsAtmPressure(atmPressurePoints.filter(element => element !== undefined), numberReduce, setAtmosphericPressure4Displayed);
                        }
                        break;
                    default:
                        break;
                }

            });

            //set the labels
            setLabelAtmosphericPressure(atmPressures[0].substring(13));
            setLabelAtmosphericPressure2(atmPressures[1].substring(13));
            setLabelAtmosphericPressure3(atmPressures[2].substring(13));
            setLabelAtmosphericPressure4(atmPressures[3].substring(13));

        }
    }

    //manage movement
    const setMovementData = (data, divisionNumber) => {
        //select all the movements
        let movements = Object.keys(data).filter(name => name.includes("Movement"));

        //one movement -> not teltonika
        if (movements.length === 1) {
            //set the variable "movement" with a table of all movements and time
            let tempMovement = data[movements[0]].map((id, index_value) => {
                if (index_value === 0) {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        movement: 0,
                    }
                } else {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        movement: (data[movements[0]][index_value] - data[movements[0]][index_value - 1]) / (data["time"][index_value] - data["time"][index_value - 1]),
                    }
                }
            });

            //if less than 500 points, display all points, if not, display max 500 points
            if (tempMovement.length < 200) {
                setMovementDisplayed(tempMovement);
            } else {
                let numberReduce;
                if (tempMovement.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempMovement.length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsMovement(tempMovement, numberReduce, setMovementDisplayed);
            }

            //set movements = all points
            setMovement(tempMovement);
            //set the label
            setLabelMovement("Movement");

            //movements === 4 -> it's a teltonika
        } else if (movements.length === 4) {
            //set variables for the number of device
            let occurrence = 0;
            let numberReduce;
            //for each 4 temperatures, set it in a variable and define its time and temperature
            movements.forEach((movementVariable) => {
                let movementPoints = data[movementVariable].map((id, index_value) => {
                    if (index_value === 0) {
                        return {
                            time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                            timestamp: data["time"][index_value],
                            movement: 0,
                        }
                    } else {
                        return {
                            time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                            timestamp: data["time"][index_value],
                            movement: Math.abs((data[movementVariable][index_value] - data[movementVariable][index_value - 1]) / (data["time"][index_value] - data["time"][index_value - 1])),
                        }
                    }
                });
                occurrence++;
                //depending on the device, set it in the correct variable
                switch (occurrence) {
                    case 1:
                        setMovement(movementPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (movementPoints.length < 200) {
                            setMovementDisplayed(movementPoints);
                        } else {
                            if (movementPoints.length / 200 <= 100) {
                                numberReduce = Math.ceil(movementPoints.length / 200);
                            } else {
                                numberReduce = 100;
                            }
                            displayLessPointsMovement(movementPoints, numberReduce, setMovementDisplayed);
                        }
                        break;
                    case 2:
                        setMovement2(movementPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (movementPoints.length < 200) {
                            setMovement2Displayed(movementPoints);
                        } else {
                            displayLessPointsMovement(movementPoints, numberReduce, setMovement2Displayed);
                        }
                        break;
                    case 3:
                        setMovement3(movementPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (movementPoints.length < 200) {
                            setMovement3Displayed(movementPoints);
                        } else {
                            displayLessPointsMovement(movementPoints, numberReduce, setMovement3Displayed);
                        }
                        break;
                    case 4:
                        setMovement4(movementPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (movementPoints.length < 200) {
                            setMovement4Displayed(movementPoints);
                        } else {
                            displayLessPointsMovement(movementPoints, numberReduce, setMovement4Displayed);
                        }
                        break;
                    default:
                        break;
                }
            });
            //set the labels
            setLabelMovement(movements[0].substring(10));
            setLabelMovement2(movements[1].substring(10));
            setLabelMovement3(movements[2].substring(10));
            setLabelMovement4(movements[3].substring(10));

            if(movements[0].substring(10, 13) === "282"){
                setMovement([]);
                setMovementDisplayed([]);
            }
            if(movements[1].substring(10, 13) === "282"){
                setMovement2([]);
                setMovement2Displayed([]);
            }
            if(movements[2].substring(10, 13) === "282"){
                setMovement3([]);
                setMovement3Displayed([]);
            }
            if(movements[3].substring(10, 13) === "282"){
                setMovement4([]);
                setMovement4Displayed([]);
            }
        }
    }

    //manage acceleration
    const setAccelerationData = (data, divisionNumber) => {
        //select all the accelerations
        let accelerationsX = Object.keys(data).filter(name => name.includes("AccelerationX"));
        let accelerationsY = Object.keys(data).filter(name => name.includes("AccelerationY"));
        let accelerationsZ = Object.keys(data).filter(name => name.includes("AccelerationZ"));

        //one acceleration -> not teltonika
        if (accelerationsX.length === 1) {
            //For X
            //set the variable "acceleration" with a table of all accelerations and time
            let tempAccelerationX = data[accelerationsX[0]].map((id, index_value) => {
                return {
                    time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                    timestamp: data["time"][index_value],
                    acceleration: data[accelerationsX[0]][index_value],
                }
            });

            //if less than 500 points, display all points, if not, display max 500 points
            if (tempAccelerationX.length < 200) {
                setAccelerationXDisplayed(tempAccelerationX);
            } else {
                let numberReduce;
                if (tempAccelerationX.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempAccelerationX.length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsAcceleration(tempAccelerationX, numberReduce, setAccelerationXDisplayed);
            }

            //set acceleration = all points
            setAccelerationX(tempAccelerationX);
            //set the label
            setLabelAccelerationX("Acceleration");

            //For Y
            //set the variable "acceleration" with a table of all accelerations and time
            let tempAccelerationY = data[accelerationsY[0]].map((id, index_value) => {
                return {
                    time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                    timestamp: data["time"][index_value],
                    acceleration: data[accelerationsY[0]][index_value],
                }
            });

            //if less than 500 points, display all points, if not, display max 500 points
            if (tempAccelerationY.length < 200) {
                setAccelerationYDisplayed(tempAccelerationY);
            } else {
                let numberReduce;
                if (tempAccelerationY.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempAccelerationY.length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsAcceleration(tempAccelerationY, numberReduce, setAccelerationYDisplayed);
            }
            //set acceleration = all points
            setAccelerationY(tempAccelerationY);
            //set the label
            setLabelAccelerationY("Acceleration");

            //For Z
            //set the variable "acceleration" with a table of all accelerations and time
            let tempAccelerationZ = data[accelerationsZ[0]].map((id, index_value) => {
                return {
                    time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                    timestamp: data["time"][index_value],
                    acceleration: data[accelerationsZ[0]][index_value],
                }
            });

            //if less than 500 points, display all points, if not, display max 500 points
            if (tempAccelerationZ.length < 200) {
                setAccelerationZDisplayed(tempAccelerationZ);
            } else {
                let numberReduce;
                if (tempAccelerationZ.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempAccelerationZ.length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsAcceleration(tempAccelerationZ, numberReduce, setAccelerationZDisplayed);
            }

            //set acceleration = all points
            setAccelerationZ(tempAccelerationZ);
            //set the label
            setLabelAccelerationZ("acceleration");

            //movements === 4 -> it's a teltonika
        } else if (accelerationsX.length === 4) {
            //For X
            //set variables for the number of device
            let occurrence = 0;
            let numberReduce;
            //for each 4 temperatures, set it in a variable and define its time and temperature
            accelerationsX.forEach((accelerationXVariable, index) => {
                let isCO2 = false;
                if(accelerationsX[index].substring(15,18) === "282"){
                    isCO2 = true;
                }
                let accelerationXPoints ;
                let co2Points ;

                if(!isCO2){
                    accelerationXPoints = data[accelerationXVariable].map((id, index_value) => {
                        return {
                            time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                            timestamp: data["time"][index_value],
                            acceleration: data[accelerationXVariable][index_value],
                        }
                    });
                }else {
                    co2Points = data[accelerationXVariable].map((id, index_value) => {
                            return {
                                time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                                timestamp: data["time"][index_value],
                                co2: data[accelerationXVariable][index_value],
                            }
                    });
                }

                occurrence++;
                //depending on the device, set it in the correct variable
                switch (occurrence) {
                    case 1:
                        if(!isCO2){
                            setAccelerationX(accelerationXPoints);
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (accelerationXPoints.length < 200) {
                                setAccelerationXDisplayed(accelerationXPoints);
                            } else {
                                if (accelerationXPoints.length / 200 <= 100) {
                                    numberReduce = Math.ceil(accelerationXPoints.length / 200);
                                } else {
                                    numberReduce = 100;
                                }
                                displayLessPointsAcceleration(accelerationXPoints, numberReduce, setAccelerationXDisplayed);
                            }
                        }else {
                            setCo(co2Points.filter(element => element !== undefined));
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (co2Points.length < 200) {
                                setCoDisplayed(co2Points.filter(element => element !== undefined));
                            } else {
                                if (co2Points.length / 200 <= 100) {
                                    numberReduce = Math.ceil(co2Points.length / 200);
                                } else {
                                    numberReduce = 100;
                                }
                                displayLessPointsCo2(co2Points.filter(element => element !== undefined), numberReduce, setCoDisplayed);
                            }
                            //set the labels
                            setLabelCo(accelerationsX[0].substring(15));
                        }
                        break;
                    case 2:
                        if(!isCO2){
                            setAccelerationX2(accelerationXPoints);
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (accelerationXPoints.length < 200) {
                                setAccelerationX2Displayed(accelerationXPoints);
                            } else {
                                displayLessPointsAcceleration(accelerationXPoints, numberReduce, setAccelerationX2Displayed);
                            }
                        }else {
                            setCo2(co2Points.filter(element => element !== undefined));
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (co2Points.length < 200) {
                                setCo2Displayed(co2Points.filter(element => element !== undefined));
                            } else {
                                displayLessPointsCo2(co2Points.filter(element => element !== undefined), numberReduce, setCo2Displayed);
                            }
                            //set the labels
                            setLabelCo2(accelerationsX[1].substring(15));
                        }
                        break;
                    case 3:
                        if(!isCO2){
                            setAccelerationX3(accelerationXPoints);
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (accelerationXPoints.length < 200) {
                                setAccelerationX3Displayed(accelerationXPoints);
                            } else {
                                displayLessPointsAcceleration(accelerationXPoints, numberReduce, setAccelerationX3Displayed);
                            }
                        }else{
                            setCo3(co2Points.filter(element => element !== undefined));
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (co2Points.length < 200) {
                                setCo3Displayed(co2Points.filter(element => element !== undefined));
                            } else {
                                displayLessPointsCo2(co2Points.filter(element => element !== undefined), numberReduce, setCo3Displayed);
                            }
                            //set the labels
                            setLabelCo3(accelerationsX[2].substring(15));
                        }
                        break;
                    case 4:
                        if(!isCO2){
                            setAccelerationX4(accelerationXPoints);
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (accelerationXPoints.length < 200) {
                                setAccelerationX4Displayed(accelerationXPoints);
                            } else {
                                displayLessPointsAcceleration(accelerationXPoints, numberReduce, setAccelerationX4Displayed);
                            }
                        }else {
                            setCo4(co2Points.filter(element => element !== undefined));
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (co2Points.length < 200) {
                                setCo4Displayed(co2Points.filter(element => element !== undefined));
                            } else {
                                displayLessPointsCo2(co2Points.filter(element => element !== undefined), numberReduce, setCo4Displayed);
                            }
                            //set the labels
                            setLabelCo4(accelerationsX[3].substring(15));
                        }
                        break;
                    default:
                        break;
                }
            });
            //set the labels
            setLabelAccelerationX(accelerationsX[0].substring(15));
            setLabelAccelerationX2(accelerationsX[1].substring(15));
            setLabelAccelerationX3(accelerationsX[2].substring(15));
            setLabelAccelerationX4(accelerationsX[3].substring(15));

            //For Y
            //set variables for the number of device
            occurrence = 0;
            //for each 4 temperatures, set it in a variable and define its time and temperature
            accelerationsY.forEach((accelerationYVariable) => {
                let accelerationYPoints = data[accelerationYVariable].map((id, index_value) => {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        acceleration: data[accelerationYVariable][index_value],
                    }
                });
                occurrence++;
                //depending on the device, set it in the correct variable
                switch (occurrence) {
                    case 1:
                        setAccelerationY(accelerationYPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (accelerationYPoints.length < 200) {
                            setAccelerationYDisplayed(accelerationYPoints);
                        } else {
                            if (accelerationYPoints.length / 200 <= 100) {
                                numberReduce = Math.ceil(accelerationYPoints.length / 200);
                            } else {
                                numberReduce = 100;
                            }
                            displayLessPointsAcceleration(accelerationYPoints, numberReduce, setAccelerationYDisplayed);
                        }
                        break;
                    case 2:
                        setAccelerationY2(accelerationYPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (accelerationYPoints.length < 200) {
                            setAccelerationY2Displayed(accelerationYPoints);
                        } else {
                            displayLessPointsAcceleration(accelerationYPoints, numberReduce, setAccelerationY2Displayed);
                        }
                        break;
                    case 3:
                        setAccelerationY3(accelerationYPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (accelerationYPoints.length < 200) {
                            setAccelerationY3Displayed(accelerationYPoints);
                        } else {
                            displayLessPointsAcceleration(accelerationYPoints, numberReduce, setAccelerationY3Displayed);
                        }
                        break;
                    case 4:
                        setAccelerationY4(accelerationYPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (accelerationYPoints.length < 200) {
                            setAccelerationY4Displayed(accelerationYPoints);
                        } else {
                            displayLessPointsAcceleration(accelerationYPoints, numberReduce, setAccelerationY4Displayed);
                        }
                        break;
                    default:
                        break;
                }
            });
            //set the labels
            setLabelAccelerationY(accelerationsY[0].substring(15));
            setLabelAccelerationY2(accelerationsY[1].substring(15));
            setLabelAccelerationY3(accelerationsY[2].substring(15));
            setLabelAccelerationY4(accelerationsY[3].substring(15));

            //For Z
            //set variables for the number of device
            occurrence = 0;
            //for each 4 temperatures, set it in a variable and define its time and temperature
            accelerationsZ.forEach((accelerationZVariable) => {
                let accelerationZPoints = data[accelerationZVariable].map((id, index_value) => {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        acceleration: data[accelerationZVariable][index_value],
                    }
                });
                occurrence++;
                //depending on the device, set it in the correct variable
                switch (occurrence) {
                    case 1:
                        setAccelerationZ(accelerationZPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (accelerationZPoints.length < 200) {
                            setAccelerationZDisplayed(accelerationZPoints);
                        } else {
                            if (accelerationZPoints.length / 200 <= 100) {
                                numberReduce = Math.ceil(accelerationZPoints.length / 200);
                            } else {
                                numberReduce = 100;
                            }
                            displayLessPointsAcceleration(accelerationZPoints, numberReduce, setAccelerationZDisplayed);
                        }
                        break;
                    case 2:
                        setAccelerationZ2(accelerationZPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (accelerationZPoints.length < 200) {
                            setAccelerationZ2Displayed(accelerationZPoints);
                        } else {
                            displayLessPointsAcceleration(accelerationZPoints, numberReduce, setAccelerationZ2Displayed);
                        }
                        break;
                    case 3:
                        setAccelerationZ3(accelerationZPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (accelerationZPoints.length < 200) {
                            setAccelerationZ3Displayed(accelerationZPoints);
                        } else {
                            displayLessPointsAcceleration(accelerationZPoints, numberReduce, setAccelerationZ3Displayed);
                        }
                        break;
                    case 4:
                        setAccelerationZ4(accelerationZPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (accelerationZPoints.length < 200) {
                            setAccelerationZ4Displayed(accelerationZPoints);
                        } else {
                            displayLessPointsAcceleration(accelerationZPoints, numberReduce, setAccelerationZ4Displayed);
                        }
                        break;
                    default:
                        break;
                }

                //For the teltonika itself
                //set the variable "acceleration" with a table of all accelerations and time
                let tempAccX = data.accX.map((id, index_value) => {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        acceleration: id,
                    }
                });

                //if less than 500 points, display all points, if not, display max 500 points
                if (tempAccX.length < 200) {
                    setAccXDisplayed(tempAccX);
                } else {
                    let numberReduce;
                    if (tempAccX.length / 200 <= 100) {
                        numberReduce = Math.ceil(tempAccX.length / 200);
                    } else {
                        numberReduce = 100;
                    }
                    displayLessPointsAcceleration(tempAccX, numberReduce, setAccXDisplayed);
                }

                //set acceleration = all points
                setAccX(tempAccX);

                //set the variable "acceleration" with a table of all accelerations and time
                let tempAccY = data.accY.map((id, index_value) => {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        acceleration: id,
                    }
                });

                //if less than 500 points, display all points, if not, display max 500 points
                if (tempAccY.length < 200) {
                    setAccYDisplayed(tempAccY);
                } else {
                    let numberReduce;
                    if (tempAccY.length / 200 <= 100) {
                        numberReduce = Math.ceil(tempAccY.length / 200);
                    } else {
                        numberReduce = 100;
                    }
                    displayLessPointsAcceleration(tempAccY, numberReduce, setAccYDisplayed);
                }

                //set acceleration = all points
                setAccY(tempAccY);

                //set the variable "acceleration" with a table of all accelerations and time
                let tempAccZ = data.accZ.map((id, index_value) => {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        acceleration: id,
                    }
                });

                //if less than 500 points, display all points, if not, display max 500 points
                if (tempAccZ.length < 200) {
                    setAccZDisplayed(tempAccZ);
                } else {
                    let numberReduce;
                    if (tempAccZ.length / 200 <= 100) {
                        numberReduce = Math.ceil(tempAccZ.length / 200);
                    } else {
                        numberReduce = 100;
                    }
                    displayLessPointsAcceleration(tempAccZ, numberReduce, setAccZDisplayed);
                }

                //set acceleration = all points
                setAccZ(tempAccZ);

            });
            //set the labels
            setLabelAccelerationZ(accelerationsZ[0].substring(15));
            setLabelAccelerationZ2(accelerationsZ[1].substring(15));
            setLabelAccelerationZ3(accelerationsZ[2].substring(15));
            setLabelAccelerationZ4(accelerationsZ[3].substring(15));
        }
    }

    //manage battery
    const setBatteryData = (data, divisionNumber) => {
        //select all the batteries
        let batteryPercentDevices = Object.keys(data).filter(name => name.includes("BatteryPercent"));
        let batteryMvDevices = Object.keys(data).filter(name => name.includes("BatterymV"));
        let batteryNotTeltonika = "Battery_" + device.identifier;

        //if it's not a teltonika
        if (device.manufacturer !== "Teltonika") {
            //set the variable "battery" with a table of all batteries and time
            let tempBattery = data[batteryNotTeltonika].map((id, index_value) => {
                //if it's a ruuvi -> battery is in mV
                if (device.manufacturer === "Ruuvi" || device.manufacturer === "TeltonikaEye") {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        batteryMv: data[batteryNotTeltonika][index_value],
                    }
                    //else -> battery is in % (effento)
                } else {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        batteryPercent: data[batteryNotTeltonika][index_value] * 100,
                    }
                }
            });
            //if less than 500 points, display all points, if not, display max 500 points
            if (tempBattery.length < 200) {
                if (device.manufacturer === "Ruuvi" || device.manufacturer === "TeltonikaEye") {
                    setBatteryMvDisplayed(tempBattery);
                } else {
                    setBatteryPercentDisplayed(tempBattery);
                }
            } else {
                let numberReduce;
                if (tempBattery.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempBattery.length / 200);
                } else {
                    numberReduce = 100;
                }
                if (device.manufacturer === "Ruuvi" || device.manufacturer === "TeltonikaEye") {
                    displayLessPointsBattery(tempBattery, numberReduce, setBatteryMvDisplayed, "mv");
                } else {
                    displayLessPointsBattery(tempBattery, numberReduce, setBatteryPercentDisplayed, "percent");
                }
            }

            //set battery = all points and set the label
            if (device.manufacturer === "Ruuvi" || device.manufacturer === "TeltonikaEye") {
                setBatteryMv(tempBattery);
                setLabelBatteryMv("battery");
            } else {
                setBatteryPercent(tempBattery);
                setLabelBatteryPercent("battery");
            }

            //it's a teltonika
        } else {
            //set variables to save the max and min battery mv and the number of device
            let occurrence = 0;
            let numberReduce = 1;
            //for each 5 batteries in mV, set it in a variable and define its time and battery
            batteryMvDevices.forEach((batteryVariable, index) => {
                let batteryPoints;
                let vocPoints;
                let isVOC = false;
                if(batteryMvDevices[index].substring(11, 14) === "282" && index !== 0){
                    isVOC = true;
                }
                if (!isVOC){
                    batteryPoints = data[batteryVariable].map((id, index_value) => {
                        return {
                            time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                            timestamp: data["time"][index_value],
                            batteryMv: data[batteryVariable][index_value],
                        }
                    });
                } else{
                    vocPoints = data[batteryVariable].map((id, index_value) => {
                        return {
                            time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                            timestamp: data["time"][index_value],
                            voc: data[batteryVariable][index_value],
                        }
                    });
                }

                occurrence++;
                //depending on the device, set it in the correct variable
                switch (occurrence) {
                    case 1:
                        setBatteryMv(batteryPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (batteryPoints.length < 200) {
                            setBatteryMvDisplayed(batteryPoints);
                        } else {
                            if (batteryPoints.length / 200 <= 100) {
                                numberReduce = Math.ceil(batteryPoints.length / 200);
                            } else {
                                numberReduce = 100;
                            }
                            displayLessPointsBattery(batteryPoints, numberReduce, setBatteryMvDisplayed, "mv");
                        }
                        break;
                    case 2:
                        if(!isVOC) {
                            setBatteryMv1(batteryPoints);
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (batteryPoints.length < 200) {
                                setBatteryMv1Displayed(batteryPoints);
                            } else {
                                displayLessPointsBattery(batteryPoints, numberReduce, setBatteryMv1Displayed, "mv");
                            }

                        }else {
                            setVoc(vocPoints.filter(element => element !== undefined));
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (vocPoints.length < 200) {
                                setVocDisplayed(vocPoints.filter(element => element !== undefined));
                            } else {
                                displayLessPointsVoc(vocPoints.filter(element => element !== undefined), numberReduce, setVocDisplayed);
                            }
                            //set the labels
                            setLabelVoc(batteryMvDevices[1].substring(11));
                        }
                        break;
                    case 3:
                        if(!isVOC){
                            setBatteryMv2(batteryPoints);
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (batteryPoints.length < 200) {
                                setBatteryMv2Displayed(batteryPoints);
                            } else {
                                displayLessPointsBattery(batteryPoints, numberReduce, setBatteryMv2Displayed, "mv");
                            }
                        }else {
                            setVoc2(vocPoints.filter(element => element !== undefined));
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (vocPoints.length < 200) {
                                setVoc2Displayed(vocPoints.filter(element => element !== undefined));
                            } else {
                                displayLessPointsVoc(vocPoints.filter(element => element !== undefined), numberReduce, setVoc2Displayed);
                            }
                            //set the labels
                            setLabelVoc2(batteryMvDevices[2].substring(11));
                        }
                        break;
                    case 4:
                        if(!isVOC){
                            setBatteryMv3(batteryPoints);
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (batteryPoints.length < 200) {
                                setBatteryMv3Displayed(batteryPoints);
                            } else {
                                displayLessPointsBattery(batteryPoints, numberReduce, setBatteryMv3Displayed, "mv");
                            }
                        }else {
                            setVoc3(vocPoints.filter(element => element !== undefined));
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (vocPoints.length < 200) {
                                setVoc3Displayed(vocPoints.filter(element => element !== undefined));
                            } else {
                                displayLessPointsVoc(vocPoints.filter(element => element !== undefined), numberReduce, setVoc3Displayed);
                            }
                            //set the labels
                            setLabelVoc3(batteryMvDevices[3].substring(11));
                        }
                        break;
                    case 5:
                        if(!isVOC){
                            setBatteryMv4(batteryPoints);
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (batteryPoints.length < 200) {
                                setBatteryMv4Displayed(batteryPoints);
                            } else {
                                displayLessPointsBattery(batteryPoints, numberReduce, setBatteryMv4Displayed, "mv");
                            }
                        }else {
                            setVoc4(vocPoints.filter(element => element !== undefined));
                            //if less than 500 points, display all points, if not, display max 500 points
                            if (vocPoints.length < 200) {
                                setVoc4Displayed(vocPoints.filter(element => element !== undefined));
                            } else {
                                displayLessPointsVoc(vocPoints.filter(element => element !== undefined), numberReduce, setVoc4Displayed);
                            }
                            //set the labels
                            setLabelVoc4(batteryMvDevices[4].substring(11));
                        }
                        break;
                    default:
                        break;
                }

            });

            occurrence = 0;
            //for each 5 batteries %, set it in a variable and define its time and battery
            batteryPercentDevices.forEach((batteryVariable) => {
                let batteryPoints = data[batteryVariable].map((id, index_value) => {
                    if (batteryVariable.substring(16, 19) === "282") {
                        return {
                            time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                            timestamp: data["time"][index_value],
                            batteryPercent: (data[batteryVariable][index_value] % 2) * 100,
                        }
                    } else {
                        return {
                            time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                            timestamp: data["time"][index_value],
                            batteryPercent: data[batteryVariable][index_value],
                        }
                    }
                });
                occurrence++;
                //depending on the device, set it in the correct variable
                switch (occurrence) {
                    case 1:
                        setBatteryPercent(batteryPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (batteryPoints.length < 200) {
                            setBatteryPercentDisplayed(batteryPoints);
                        } else {
                            displayLessPointsBattery(batteryPoints, numberReduce, setBatteryPercentDisplayed, "percent");
                        }
                        break;
                    case 2:
                        setBatteryPercent1(batteryPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (batteryPoints.length < 200) {
                            setBatteryPercent1Displayed(batteryPoints);
                        } else {
                            displayLessPointsBattery(batteryPoints, numberReduce, setBatteryPercent1Displayed, "percent");
                        }
                        break;
                    case 3:
                        setBatteryPercent2(batteryPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (batteryPoints.length < 200) {
                            setBatteryPercent2Displayed(batteryPoints);
                        } else {
                            displayLessPointsBattery(batteryPoints, numberReduce, setBatteryPercent2Displayed, "percent");
                        }
                        break;
                    case 4:
                        setBatteryPercent3(batteryPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (batteryPoints.length < 200) {
                            setBatteryPercent3Displayed(batteryPoints);
                        } else {
                            displayLessPointsBattery(batteryPoints, numberReduce, setBatteryPercent3Displayed, "percent");
                        }
                        break;
                    case 5:
                        setBatteryPercent4(batteryPoints);
                        //if less than 500 points, display all points, if not, display max 500 points
                        if (batteryPoints.length < 200) {
                            setBatteryPercent4Displayed(batteryPoints);
                        } else {
                            displayLessPointsBattery(batteryPoints, numberReduce, setBatteryPercent4Displayed, "percent");
                        }
                        break;
                    default:
                        break;
                }
            });

            //set variable for the external battery
            let tempBattery = data.BatteryExternalmV.map((id, index_value) => {
                return {
                    time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                    timestamp: data["time"][index_value],
                    batteryMv: data.BatteryExternalmV[index_value],
                }
            });
            setBatteryExternal(tempBattery);

            //if less than 500 points, display all points, if not, display max 500 points
            if (tempBattery.length < 200) {
                setBatteryExternalDisplayed(tempBattery);
            } else {
                displayLessPointsBattery(tempBattery, numberReduce, setBatteryExternalDisplayed, "mv");
            }
            //set the labels
            setLabelBatteryMv1(batteryMvDevices[1].substring(11));
            setLabelBatteryMv2(batteryMvDevices[2].substring(11));
            setLabelBatteryMv3(batteryMvDevices[3].substring(11));
            setLabelBatteryMv4(batteryMvDevices[4].substring(11));
            setLabelBatteryMv("Gateway");
            setLabelBatteryPercent1(batteryPercentDevices[1].substring(16));
            setLabelBatteryPercent2(batteryPercentDevices[2].substring(16));
            setLabelBatteryPercent3(batteryPercentDevices[3].substring(16));
            setLabelBatteryPercent4(batteryPercentDevices[4].substring(16));
            setLabelBatteryPercent("Gateway");
        }
    }

    //manage current
    const setCurrentData = (data, divisionNumber) => {

        //select all the atmospheric pressure
        let current_1 = Object.keys(data).filter(name => name.includes("Current1"));
        let current_2 = Object.keys(data).filter(name => name.includes("Current2"));

        //one current -> not teltonika
        if (current_1.length === 1) {
            //set the variable "current" with a table of all atmPressure and time
            let tempCurrent1 = data[current_1[0]].map((id, index_value) => {
                if (data[current_1[0]][index_value] !== 0) {
                    return {
                        time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                        timestamp: data["time"][index_value],
                        current: data[current_1[0]][index_value],
                    }
                }
                return undefined;
            });
            let tempCurrent2 = data[current_2[0]].map((id, index_value) => {
                return {
                    time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                    timestamp: data["time"][index_value],
                    current: data[current_2[0]][index_value],
                }
            });

            //if less than 500 points, display all points, if not, display max 500 points
            if (tempCurrent1.length < 200) {
                setCurrent1Displayed(tempCurrent1.filter(element => element !== undefined));
                setCurrent2Displayed(tempCurrent2.filter(element => element !== undefined));
            } else {
                let numberReduce;
                if (tempCurrent1.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempCurrent1.length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsCurrent(tempCurrent1.filter(element => element !== undefined), numberReduce, setCurrent1Displayed);
                displayLessPointsCurrent(tempCurrent2.filter(element => element !== undefined), numberReduce, setCurrent2Displayed);
            }

            //set current = all points
            setCurrent1(tempCurrent1.filter(element => element !== undefined));
            setCurrent2(tempCurrent2.filter(element => element !== undefined));
            //set the labels
            setLabelCurrent1("current 1");
            setLabelCurrent2("current 2");
        }
    }

    //manage current for teltonika
    const setCurrentTeltonika = (data, divisionNumber, noDevice) => {
        //current1 stored in movement -> MovementX_Device
        //current 2 stored in atmPressure -> AtmPressureX_Device
        let current_1 = Object.keys(data).filter(name => name.includes("Movement" + noDevice));
        let current_2 = Object.keys(data).filter(name => name.includes("AtmPressure" + noDevice));
        current_1 = current_1[0];
        current_2 = current_2[0];


        let tempCurrent1 = data[current_1].map((id, index_value) => {
            return {
                time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                timestamp: data["time"][index_value],
                current: data[current_1][index_value],
            }
        });
        let tempCurrent2 = data[current_2].map((id, index_value) => {
            return {
                time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                timestamp: data["time"][index_value],
                current: data[current_2][index_value],
            }
        });

        //if less than 500 points, display all points, if not, display max 500 points
        if (tempCurrent1.length < 200) {
            if(noDevice === 1){
                setCurrent1Displayed(tempCurrent1);
                setCurrent2Displayed(tempCurrent2);
                setCurrent1(tempCurrent1);
                setCurrent2(tempCurrent2);
                setLabelCurrent1(current_1.substring(10));
                setLabelCurrent2(current_2.substring(13));
            }
            if(noDevice === 2){
                setCurrent12Displayed(tempCurrent1);
                setCurrent22Displayed(tempCurrent2);
                setCurrent12(tempCurrent1);
                setCurrent22(tempCurrent2);
                setLabelCurrent12(current_1.substring(10));
                setLabelCurrent22(current_2.substring(13));
            }
            if(noDevice === 3){
                setCurrent13Displayed(tempCurrent1);
                setCurrent23Displayed(tempCurrent2);
                setCurrent13(tempCurrent1);
                setCurrent23(tempCurrent2);
                setLabelCurrent13(current_1.substring(10));
                setLabelCurrent23(current_2.substring(13));
            }
            if(noDevice === 4){
                setCurrent14Displayed(tempCurrent1);
                setCurrent24Displayed(tempCurrent2);
                setCurrent14(tempCurrent1);
                setCurrent24(tempCurrent2);
                setLabelCurrent14(current_1.substring(10));
                setLabelCurrent24(current_2.substring(13));
            }

        } else {
            let numberReduce;
            if (tempCurrent1.length / 200 <= 100) {
                numberReduce = Math.ceil(tempCurrent1.length / 200);
            } else {
                numberReduce = 100;
            }
            if(noDevice === 1){
                displayLessPointsCurrent(tempCurrent1, numberReduce, setCurrent1Displayed);
                displayLessPointsCurrent(tempCurrent2, numberReduce, setCurrent2Displayed);
                setCurrent1(tempCurrent1);
                setCurrent2(tempCurrent2);
                setLabelCurrent1(current_1.substring(10));
                setLabelCurrent2(current_2.substring(13));
            }
            if(noDevice === 2){
                displayLessPointsCurrent(tempCurrent1, numberReduce, setCurrent12Displayed);
                displayLessPointsCurrent(tempCurrent2, numberReduce, setCurrent22Displayed);
                setCurrent12(tempCurrent1);
                setCurrent22(tempCurrent2);
                setLabelCurrent12(current_1.substring(10));
                setLabelCurrent22(current_2.substring(13));
            }
            if(noDevice === 3){
                displayLessPointsCurrent(tempCurrent1, numberReduce, setCurrent13Displayed);
                displayLessPointsCurrent(tempCurrent2, numberReduce, setCurrent23Displayed);
                setCurrent13(tempCurrent1);
                setCurrent23(tempCurrent2);
                setLabelCurrent13(current_1.substring(10));
                setLabelCurrent23(current_2.substring(13));
            }
            if(noDevice === 4){
                displayLessPointsCurrent(tempCurrent1, numberReduce, setCurrent14Displayed);
                displayLessPointsCurrent(tempCurrent2, numberReduce, setCurrent24Displayed);
                setCurrent14(tempCurrent1);
                setCurrent24(tempCurrent2);
                setLabelCurrent14(current_1.substring(10));
                setLabelCurrent24(current_2.substring(13));
            }
        }
    }

    //manage atmospheric pressure
    const setMagnetData = (data, divisionNumber) => {

        //select all the atmospheric pressure
        let magnetVariable = Object.keys(data).filter(name => name.includes("Magnet"));
        if(magnetVariable.length !== 0){
            magnetVariable = magnetVariable[0];

            let tempMagnet = data[magnetVariable].map((id, index_value) => {
                return {
                    time: Number((data["time"][index_value] / divisionNumber).toFixed(2)),
                    timestamp: data["time"][index_value],
                    magnet: data[magnetVariable][index_value],
                }
            });

            //if less than 500 points, display all points, if not, display max 500 points
            if (tempMagnet.length < 200) {
                setMagnetDisplayed(tempMagnet);
            } else {
                let numberReduce;
                if (tempMagnet.length / 200 <= 100) {
                    numberReduce = Math.ceil(tempMagnet.length / 200);
                } else {
                    numberReduce = 100;
                }
                displayLessPointsMagnet(tempMagnet, numberReduce);
            }

            //set current = all points
            setMagnet(tempMagnet);
            //set the labels
            setLabelMagnet("magnet");
        }
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for temperature and humidity
    const displayLessPointsTemperatureHumidity = (temperatureTable, humidityTable, number, actionTemperature, actionHumidity) => {
        if(temperatureTable.length !== 0){
            //reduce the number of points for temperature
            let modulo = -1;
            let tempTemperature = temperatureTable.map((temp) => {
                modulo++;
                if (modulo % number === 0) {
                    return {
                        time: temp.time,
                        timestamp: temp.timestamp,
                        temperature: temp.temperature,
                    }
                }
                return undefined;
            });
            //actionTemperature = setTemperatureDisplayed, we remove the undefined data
            actionTemperature(tempTemperature.filter(element => element !== undefined));

            //reduce the number of points for humidity
            let moduloHumidity = -1;
            let tempHumidity = humidityTable.map((humi) => {
                moduloHumidity++;
                if (moduloHumidity % number === 0) {
                    return {
                        time: humi.time,
                        timestamp: humi.timestamp,
                        humidity: humi.humidity,
                    }
                }
                return undefined;
            });
            //actionHumidity = setHumidityDisplayed, we remove the undefined data
            actionHumidity(tempHumidity.filter(element => element !== undefined));
        }
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for Co2
    const displayLessPointsCo2 = (co2Table, number, actionCo2) => {
        //reduce the number of points for Co2
        let modulo = -1;
        let tempCo2 = co2Table.map((temp) => {
            modulo++;
            if (modulo % number === 0) {
                return {
                    time: temp.time,
                    timestamp: temp.timestamp,
                    co2: temp.co2,
                }
            }
            return undefined;
        });
        //actionCo2 = setCo2Displayed, we remove undefined values
        actionCo2(tempCo2.filter(element => element !== undefined));
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for voc
    const displayLessPointsVoc = (vocTable, number, actionVoc) => {
        //reduce the number of points for atmospheric pressure
        let modulo = -1;
        let tempVoc = vocTable.map((temp) => {
            modulo++;
            if (modulo % number === 0) {
                return {
                    time: temp.time,
                    timestamp: temp.timestamp,
                    voc: temp.voc,
                }
            }
            return undefined;
        });
        //actionVoc = setVocDisplayed, we remove undefined values
        actionVoc(tempVoc.filter(element => element !== undefined));
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for atmospheric pressure
    const displayLessPointsAtmPressure = (atmPressureTable, number, actionAtmPressure) => {
        //reduce the number of points for atmospheric pressure
        let modulo = -1;
        let tempAtmPressure = atmPressureTable.map((temp) => {
            modulo++;
            if (modulo % number === 0) {
                return {
                    time: temp.time,
                    timestamp: temp.timestamp,
                    atmPressure: temp.atmPressure,
                }
            }
            return undefined;
        });
        //actionAtmPressure = setAtmosphericPressureDisplayed, we remove undefined values
        actionAtmPressure(tempAtmPressure.filter(element => element !== undefined));
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for movement
    const displayLessPointsMovement = (movementTable, number, actionMovement) => {
        //reduce the number of points for movement
        let modulo = -1;
        let tempMovement = movementTable.map((temp) => {
            modulo++;
            if (modulo % number === 0) {
                return {
                    time: temp.time,
                    timestamp: temp.timestamp,
                    movement: temp.movement,
                }
            }
            return undefined;
        });
        //actionTemperature = setTemperatureDisplayed, we remove undefined values
        actionMovement(tempMovement.filter(element => element !== undefined));
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for acceleration
    const displayLessPointsAcceleration = (accelerationTable, number, actionAcceleration) => {
        //reduce the number of points for movement
        let modulo = -1;
        let tempAcceleration = accelerationTable.map((temp) => {
            modulo++;
            if (modulo % number === 0) {
                return {
                    time: temp.time,
                    timestamp: temp.timestamp,
                    acceleration: temp.acceleration,
                }
            }
            return undefined;
        });
        //actionAcceleration = setAcceleration..., we remove undefined values
        actionAcceleration(tempAcceleration.filter(element => element !== undefined));
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for battery
    const displayLessPointsBattery = (batteryTable, number, actionBattery, type) => {
        //reduce the number of points for battery
        let modulo = -1;
        let tempBattery = batteryTable.map((temp) => {
            modulo++;
            if (modulo % number === 0) {
                if (type === "percent") {
                    return {
                        time: temp.time,
                        timestamp: temp.timestamp,
                        batteryPercent: temp.batteryPercent,
                    }
                } else if (type === "mv")
                    return {
                        time: temp.time,
                        timestamp: temp.timestamp,
                        batteryMv: temp.batteryMv,
                    }
            }
            return undefined;
        });
        //actionTemperature = setTemperatureDisplayed, we remove undefined values
        actionBattery(tempBattery.filter(element => element !== undefined));
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for longitude and latitude
    const displayLessPointsLongLat = (longLatTable, number) => {
        //reduce the number of points for battery
        let modulo = -1;
        let tempLongLat = longLatTable.map((temp) => {
            modulo++;
            if (modulo % number === 0) {
                return {
                    time: temp.time,
                    timestamp: temp.timestamp,
                    longitude: temp.longitude,
                    latitude: temp.latitude,
                }
            }
            return undefined;
        });
        //actionTemperature = setTemperatureDisplayed, we remove undefined values
        setLongLatDisplayed(tempLongLat.filter(element => element !== undefined));
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for current
    const displayLessPointsCurrent = (currentTable, number, actionCurrent) => {
        //reduce the number of points for atmospheric pressure
        let modulo = -1;
        let tempCurrent = currentTable.map((temp) => {
            modulo++;
            if (modulo % number === 0) {
                return {
                    time: temp.time,
                    timestamp: temp.timestamp,
                    current: temp.current,
                }
            }
            return undefined;
        });
        //actionAtmPressure = setAtmosphericPressureDisplayed, we remove undefined values
        actionCurrent(tempCurrent.filter(element => element !== undefined));
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for magnet
    const displayLessPointsMagnet = (magnetTable, number) => {
        //reduce the number of points for atmospheric pressure
        let modulo = -1;
        let tempMagnet = magnetTable.map((temp) => {
            modulo++;
            if (modulo % number === 0) {
                return {
                    time: temp.time,
                    timestamp: temp.timestamp,
                    magnet: temp.magnet,
                }
            }
            return undefined;
        });
        //actionAtmPressure = setAtmosphericPressureDisplayed, we remove undefined values
        setMagnetDisplayed(tempMagnet.filter(element => element !== undefined));
    }

    //actions when the user change the slider (modify the display of the slider)
    const handleSliderChange = (event, newValue) => {
        setNumberReduce(newValue);
    }

    //action when the user release the slider (modify the number of points in variables)
    const handleSliderChangeCommited = (event, newValue) => {
        displayLessPointsTemperatureHumidity(temperature, humidity, newValue, setTemperatureDisplayed, setHumidityDisplayed);
        displayLessPointsAtmPressure(atmosphericPressure, newValue, setAtmosphericPressureDisplayed);
        displayLessPointsMovement(movement, newValue, setMovementDisplayed);
        displayLessPointsAcceleration(accelerationX, newValue, setAccelerationXDisplayed);
        displayLessPointsAcceleration(accelerationY, newValue, setAccelerationYDisplayed);
        displayLessPointsAcceleration(accelerationZ, newValue, setAccelerationZDisplayed);
        displayLessPointsBattery(batteryMv, newValue, setBatteryMvDisplayed, "mv");
        displayLessPointsBattery(batteryPercent, newValue, setBatteryPercentDisplayed, "percent");
        displayLessPointsLongLat(longLat, newValue);
        displayLessPointsCurrent(current1,newValue, setCurrent1Displayed);
        displayLessPointsCurrent(current2,newValue, setCurrent2Displayed);
        displayLessPointsMagnet(magnet, newValue);
        displayLessPointsCo2(co, newValue, setCoDisplayed);
        displayLessPointsVoc(voc, newValue, setVocDisplayed);
        //if it's a teltonika, set the 3 other devices too
        if (device.manufacturer === "Teltonika") {
            displayLessPointsTemperatureHumidity(temperature2, humidity2, newValue, setTemperature2Displayed, setHumidity2Displayed);
            displayLessPointsTemperatureHumidity(temperature3, humidity3, newValue, setTemperature3Displayed, setHumidity3Displayed);
            displayLessPointsTemperatureHumidity(temperature4, humidity4, newValue, setTemperature4Displayed, setHumidity4Displayed);
            displayLessPointsAtmPressure(atmosphericPressure2, newValue, setAtmosphericPressure2Displayed);
            displayLessPointsAtmPressure(atmosphericPressure3, newValue, setAtmosphericPressure3Displayed);
            displayLessPointsAtmPressure(atmosphericPressure4, newValue, setAtmosphericPressure4Displayed);
            displayLessPointsMovement(movement2, newValue, setMovement2Displayed);
            displayLessPointsMovement(movement3, newValue, setMovement3Displayed);
            displayLessPointsMovement(movement4, newValue, setMovement4Displayed);
            displayLessPointsAcceleration(accelerationX2, newValue, setAccelerationX2Displayed);
            displayLessPointsAcceleration(accelerationY2, newValue, setAccelerationY2Displayed);
            displayLessPointsAcceleration(accelerationZ2, newValue, setAccelerationZ2Displayed);
            displayLessPointsAcceleration(accelerationX3, newValue, setAccelerationX3Displayed);
            displayLessPointsAcceleration(accelerationY3, newValue, setAccelerationY3Displayed);
            displayLessPointsAcceleration(accelerationZ3, newValue, setAccelerationZ3Displayed);
            displayLessPointsAcceleration(accelerationX4, newValue, setAccelerationX4Displayed);
            displayLessPointsAcceleration(accelerationY4, newValue, setAccelerationY4Displayed);
            displayLessPointsAcceleration(accelerationZ4, newValue, setAccelerationZ4Displayed);
            displayLessPointsAcceleration(accX, newValue, setAccXDisplayed);
            displayLessPointsAcceleration(accY, newValue, setAccYDisplayed);
            displayLessPointsAcceleration(accZ, newValue, setAccZDisplayed);
            displayLessPointsBattery(batteryExternal, newValue, setBatteryExternalDisplayed, "mv");
            displayLessPointsBattery(batteryMv1, newValue, setBatteryMv1Displayed, "mv");
            displayLessPointsBattery(batteryMv2, newValue, setBatteryMv2Displayed, "mv");
            displayLessPointsBattery(batteryMv3, newValue, setBatteryMv3Displayed, "mv");
            displayLessPointsBattery(batteryMv4, newValue, setBatteryMv4Displayed, "mv");
            displayLessPointsBattery(batteryPercent1, newValue, setBatteryPercent1Displayed, "percent");
            displayLessPointsBattery(batteryPercent2, newValue, setBatteryPercent2Displayed, "percent");
            displayLessPointsBattery(batteryPercent3, newValue, setBatteryPercent3Displayed, "percent");
            displayLessPointsBattery(batteryPercent4, newValue, setBatteryPercent4Displayed, "percent");
            displayLessPointsCurrent(current12,newValue, setCurrent12Displayed);
            displayLessPointsCurrent(current22,newValue, setCurrent22Displayed);
            displayLessPointsCurrent(current13,newValue, setCurrent13Displayed);
            displayLessPointsCurrent(current23,newValue, setCurrent23Displayed);
            displayLessPointsCurrent(current14,newValue, setCurrent14Displayed);
            displayLessPointsCurrent(current24,newValue, setCurrent24Displayed);
            displayLessPointsCo2(co2, newValue, setCo2Displayed);
            displayLessPointsCo2(co3, newValue, setCo3Displayed);
            displayLessPointsCo2(co4, newValue, setCo4Displayed);
            displayLessPointsVoc(voc2, newValue, setVoc2Displayed);
            displayLessPointsVoc(voc3, newValue, setVoc3Displayed);
            displayLessPointsVoc(voc4, newValue, setVoc4Displayed);
        }
    }

    //when the user change the slider in the map -> modify the display of the slider
    const handleSliderMap = (event, newValue) => {

        //if we display the slider
        if (coordinateHistory) {
            setMapSlider(newValue);
            //we send only one coordinate 2 times, so the 2 ranges move at the same time, it's like a non range slider
        } else {
            if (newValue[0] < mapSlider[0]) {
                setMapSlider([newValue[0], newValue[0]]);
            } else {
                setMapSlider([newValue[1], newValue[1]]);
            }
        }
    }

    //modify the map when the user release the slider
    const handleSliderMapComitted = (event, newValue) => {
        //adjust the longitude and latitude
        setLongitude(longLat[newValue[1] - 1].longitude);
        setLatitude(longLat[newValue[1] - 1].latitude);

        //adjust the array with all the element inside the range
        let counter = 0;
        let tempHistory = longLat.map((element) => {
            counter++;
            if (counter >= newValue[0] && counter <= newValue[1]) {
                return element;
            }
            return undefined;
        });
        tempHistory = tempHistory.filter(element => element !== undefined);

        setHistoryLongLat(tempHistory);
    }

    //manage the checkbox to keep location history
    const handleKeepHistory = () => {
        //check or uncheck the box
        setCoordinateHistory(!coordinateHistory);
        //change the slider to set the 2 points at the bigger
        setMapSlider([mapSlider[1], mapSlider[1]]);
        //set the history to only the last point
        let tempHistory = [longLat[mapSlider[1] - 1]];
        setHistoryLongLat(tempHistory);
    }

    //manage the downloads
    const downloadFile = () => {
        //create csv file
        let index = "";
        let content = "";
        //index
        if (includeIndex) {
            index = "Index" + separator;
        }
        //add time
        if (dataTypeSelected.timeChecked) {
            content = content + "Time(" + selectUnitTime + ")";
        }
        //add temperature
        if (dataTypeSelected.temperatureChecked) {
            if (device.manufacturer === "Teltonika") {
                let temperatures = Object.keys(allPoints).filter(name => name.includes("Temperature"));

                temperatures.forEach((element) => {
                    if (content === "") {
                        content = element;
                    } else {
                        content = content + separator + element;
                    }
                });
            } else {
                if (content === "") {
                    content = "Temperature(°C)";
                } else {
                    content = content + separator + "Temperature(°C)";
                }
            }
        }
        //add humidity
        if (dataTypeSelected.humidityChecked) {
            if (device.manufacturer === "Teltonika") {
                let humidities = Object.keys(allPoints).filter(name => name.includes("Humidity"));

                humidities.forEach((element) => {
                    if (content === "") {
                        content = element;
                    } else {
                        content = content + separator + element;
                    }
                })
            } else {
                if (content === "") {
                    content = "Humidity(%)";
                } else {
                    content = content + separator + "Humidity(%)";
                }
            }

        }
        //add current
        if(dataTypeSelected.currentChecked){
            if (content === "") {
                content = "Current(mA)";
            } else {
                content = content + separator + "Current(mA)";
            }
        }
        //add co2
        if(dataTypeSelected.co2Checked){
            if (content === "") {
                content = "CO2(PPM)";
            } else {
                content = content + separator + "CO2(PPM)";
            }
        }
        //add voc
        if(dataTypeSelected.vocChecked){
            if (content === "") {
                content = "Voc(IAQ)";
            } else {
                content = content + separator + "Voc(IAQ)";
            }
        }
        //add movement
        if (dataTypeSelected.movementChecked) {
            if (device.manufacturer === "Teltonika") {
                let movements = Object.keys(allPoints).filter(name => name.includes("Movement"));

                movements.forEach((element) => {
                    if (content === "") {
                        content = element;
                    } else {
                        content = content + separator + element;
                    }
                })
            } else {
                if (content === "") {
                    content = "Movement";
                } else {
                    content = content + separator + "Movement";
                }
            }
        }
        //add magnet
        if (dataTypeSelected.magnetChecked){
            if (content === "") {
                content = "Magnet";
            } else {
                content = content + separator + "Magnet";
            }
        }
        //add atmospheric pressure
        if (dataTypeSelected.atmosphericPressureChecked) {
            if (device.manufacturer === "Teltonika") {
                let atmPressures = Object.keys(allPoints).filter(name => name.includes("AtmPressure"));

                atmPressures.forEach((element) => {
                    if (content === "") {
                        content = element;
                    } else {
                        content = content + separator + element;
                    }
                })
            } else {
                if (content === "") {
                    content = "Atmospheric_pressure(Pa)";
                } else {
                    content = content + separator + "Atmospheric_pressure(Pa)";
                }
            }
        }
        //add acceleration
        if (dataTypeSelected.accelerationChecked) {
            if (device.manufacturer === "Teltonika") {
                let accelerationsX = Object.keys(allPoints).filter(name => name.includes("AccelerationX"));
                let accelerationsY = Object.keys(allPoints).filter(name => name.includes("AccelerationY"));
                let accelerationsZ = Object.keys(allPoints).filter(name => name.includes("AccelerationZ"));

                accelerationsX.forEach((element) => {
                    if (content === "") {
                        content = element;
                    } else {
                        content = content + separator + element;
                    }
                })
                accelerationsY.forEach((element) => {
                    content = content + separator + element;
                })
                accelerationsZ.forEach((element) => {
                    content = content + separator + element;
                })
                //add acceleration
                content = content + separator + "Acceleration_X_gateway(g-force)" + separator + "Acceleration_Y_gateway(g-force)" + separator + "Acceleration_Z_gateway(g-force)";
            } else {
                if (content === "") {
                    content = "Acceleration_X(g-force)" + separator + "Acceleration_Y(g-force)" + separator + "Acceleration_Z(g-force)";
                } else {
                    content = content + separator + "Acceleration_X(g-force)" + separator + "Acceleration_Y(g-force)" + separator + "Acceleration_Z(g-force)";
                }
            }
        }
        //add battery
        if (dataTypeSelected.batteryChecked) {
            if (device.manufacturer === "Teltonika") {
                let batteries = Object.keys(allPoints).filter(name => name.includes("Battery"));

                batteries.forEach((element) => {
                    if (content === "") {
                        content = element;
                    } else {
                        content = content + separator + element;
                    }
                })
            } else {
                if (content === "") {
                    if (batteryPercent.length !== 0) {
                        content = "Battery(%)";
                    } else {
                        content = "Battery(mV)";
                    }
                } else {
                    if (batteryPercent.length !== 0) {
                        content = content + separator + "Battery(%)";
                    } else {
                        content = content + separator + "Battery(mV)";
                    }

                }
            }
        }
        //add latitude
        if (dataTypeSelected.latitudeChecked) {
            if (content === "") {
                content = "Latitude";
            } else {
                content = content + separator + "Latitude";
            }
        }
        //add longitude
        if (dataTypeSelected.longitudeChecked) {
            if (content === "") {
                content = "Longitude";
            } else {
                content = content + separator + "Longitude";
            }
        }

        //create head
        let data = index + content + '\n'

        //creating body
        let bodyData = [];
        //create lines
        //iterate one lime per points
        if(temperature.length !== 0){
            temperature.forEach((element, index_value) => {
                let dataToInsert = [];
                //add index
                if (includeIndex) {
                    dataToInsert = dataToInsert.concat(index_value + 1);
                }
                //add time
                if (dataTypeSelected.timeChecked) {
                    switch (selectUnitTime) {
                        case "sec":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp).toFixed(2));
                            break;
                        case "min":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp / 60).toFixed(2));
                            break;
                        case "h":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp / 3600).toFixed(2));
                            break;
                        case "days":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp / 86400).toFixed(2));
                            break;
                        case "month":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp / 2628002).toFixed(2));
                            break;
                        case "year":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp / 31536000).toFixed(2));
                            break;
                        default:
                            break;
                    }
                }
                //add temperature
                if (dataTypeSelected.temperatureChecked) {
                    if (device.manufacturer === "Teltonika") {
                        let temperatures = Object.keys(allPoints).filter(name => name.includes("Temperature"));

                        temperatures.forEach((category, index) => {
                            switch (index) {
                                case 0:
                                    dataToInsert = dataToInsert.concat(temperature[index_value].temperature);
                                    break;
                                case 1:
                                    dataToInsert = dataToInsert.concat(temperature2[index_value].temperature);
                                    break;
                                case 2:
                                    dataToInsert = dataToInsert.concat(temperature3[index_value].temperature);
                                    break;
                                case 3:
                                    dataToInsert = dataToInsert.concat(temperature4[index_value].temperature);
                                    break;
                                default:
                                    break;
                            }
                        });
                    } else {
                        dataToInsert = dataToInsert.concat(element.temperature);
                    }
                }
                //add humidity
                if (dataTypeSelected.humidityChecked) {
                    if (device.manufacturer === "Teltonika") {
                        let humidities = Object.keys(allPoints).filter(name => name.includes("Humidity"));

                        humidities.forEach((category, index) => {
                            switch (index) {
                                case 0:
                                    dataToInsert = dataToInsert.concat(humidity[index_value].humidity);
                                    break;
                                case 1:
                                    dataToInsert = dataToInsert.concat(humidity2[index_value].humidity);
                                    break;
                                case 2:
                                    dataToInsert = dataToInsert.concat(humidity3[index_value].humidity);
                                    break;
                                case 3:
                                    dataToInsert = dataToInsert.concat(humidity4[index_value].humidity);
                                    break;
                                default:
                                    break;
                            }
                        });
                    } else {
                        dataToInsert = dataToInsert.concat(humidity[index_value].humidity);
                    }
                }
                //add current
                if(dataTypeSelected.currentChecked){
                    dataToInsert = dataToInsert.concat(current1[index_value].current);
                    dataToInsert = dataToInsert.concat(current2[index_value].current);
                }
                //add CO2
                if (dataTypeSelected.co2Checked) {
                    if (device.manufacturer === "Teltonika") {
                        let co2Points = Object.keys(allPoints).filter(name => name.includes("co2"));

                        co2Points.forEach((category, index) => {
                            switch (index) {
                                case 0:
                                    dataToInsert = dataToInsert.concat(co[index_value].co2);
                                    break;
                                case 1:
                                    dataToInsert = dataToInsert.concat(co2[index_value].co2);
                                    break;
                                case 2:
                                    dataToInsert = dataToInsert.concat(co3[index_value].co2);
                                    break;
                                case 3:
                                    dataToInsert = dataToInsert.concat(co4[index_value].co2);
                                    break;
                                default:
                                    break;
                            }
                        });
                    } else {
                        dataToInsert = dataToInsert.concat(co[index_value].co2);
                    }
                }
                //add voc
                if (dataTypeSelected.vocChecked) {
                    if (device.manufacturer === "Teltonika") {
                        let vocPoints = Object.keys(allPoints).filter(name => name.includes("voc"));

                        vocPoints.forEach((category, index) => {
                            switch (index) {
                                case 0:
                                    dataToInsert = dataToInsert.concat(voc[index_value].voc);
                                    break;
                                case 1:
                                    dataToInsert = dataToInsert.concat(voc2[index_value].voc);
                                    break;
                                case 2:
                                    dataToInsert = dataToInsert.concat(voc3[index_value].voc);
                                    break;
                                case 3:
                                    dataToInsert = dataToInsert.concat(voc4[index_value].voc);
                                    break;
                                default:
                                    break;
                            }
                        });
                    } else {
                        dataToInsert = dataToInsert.concat(co[index_value].voc);
                    }
                }
                //add movement
                if (dataTypeSelected.movementChecked) {
                    if (device.manufacturer === "Teltonika") {
                        let movements = Object.keys(allPoints).filter(name => name.includes("Movement"));

                        movements.forEach((category, index) => {
                            switch (index) {
                                case 0:
                                    dataToInsert = dataToInsert.concat(movement[index_value].movement);
                                    break;
                                case 1:
                                    dataToInsert = dataToInsert.concat(movement2[index_value].movement);
                                    break;
                                case 2:
                                    dataToInsert = dataToInsert.concat(movement3[index_value].movement);
                                    break;
                                case 3:
                                    dataToInsert = dataToInsert.concat(movement4[index_value].movement);
                                    break;
                                default:
                                    break;
                            }
                        });
                    } else {
                        dataToInsert = dataToInsert.concat(movement[index_value].movement);
                    }
                }
                //add magnet
                if (dataTypeSelected.magnetChecked){
                    dataToInsert = dataToInsert.concat(magnet[index_value].magnet);
                }
                //add atmospheric pressure
                if (dataTypeSelected.atmosphericPressureChecked) {
                    if (device.manufacturer === "Teltonika") {
                        let atmPressures = Object.keys(allPoints).filter(name => name.includes("AtmPressure"));

                        atmPressures.forEach((category, index) => {
                            switch (index) {
                                case 0:
                                    dataToInsert = dataToInsert.concat(atmosphericPressure[index_value].atmPressure);
                                    break;
                                case 1:
                                    dataToInsert = dataToInsert.concat(atmosphericPressure2[index_value].atmPressure);
                                    break;
                                case 2:
                                    dataToInsert = dataToInsert.concat(atmosphericPressure3[index_value].atmPressure);
                                    break;
                                case 3:
                                    dataToInsert = dataToInsert.concat(atmosphericPressure4[index_value].atmPressure);
                                    break;
                                default:
                                    break;
                            }
                        });
                    } else {
                        dataToInsert = dataToInsert.concat(atmosphericPressure[index_value].atmPressure);
                    }
                }
                //add acceleration
                if (dataTypeSelected.accelerationChecked) {
                    if (device.manufacturer === "Teltonika") {
                        let accelerationsX = Object.keys(allPoints).filter(name => name.includes("AccelerationX"));
                        let accelerationsY = Object.keys(allPoints).filter(name => name.includes("AccelerationY"));
                        let accelerationsZ = Object.keys(allPoints).filter(name => name.includes("AccelerationZ"));

                        accelerationsX.forEach((category, index) => {
                            switch (index) {
                                case 0:
                                    dataToInsert = dataToInsert.concat(accelerationX[index_value].acceleration);
                                    break;
                                case 1:
                                    dataToInsert = dataToInsert.concat(accelerationX2[index_value].acceleration);
                                    break;
                                case 2:
                                    dataToInsert = dataToInsert.concat(accelerationX3[index_value].acceleration);
                                    break;
                                case 3:
                                    dataToInsert = dataToInsert.concat(accelerationX4[index_value].acceleration);
                                    break;
                                default:
                                    break;
                            }
                        });

                        accelerationsY.forEach((category, index) => {
                            switch (index) {
                                case 0:
                                    dataToInsert = dataToInsert.concat(accelerationY[index_value].acceleration);
                                    break;
                                case 1:
                                    dataToInsert = dataToInsert.concat(accelerationY2[index_value].acceleration);
                                    break;
                                case 2:
                                    dataToInsert = dataToInsert.concat(accelerationY3[index_value].acceleration);
                                    break;
                                case 3:
                                    dataToInsert = dataToInsert.concat(accelerationY4[index_value].acceleration);
                                    break;
                                default:
                                    break;
                            }
                        });

                        accelerationsZ.forEach((category, index) => {
                            switch (index) {
                                case 0:
                                    dataToInsert = dataToInsert.concat(accelerationZ[index_value].acceleration);
                                    break;
                                case 1:
                                    dataToInsert = dataToInsert.concat(accelerationZ2[index_value].acceleration);
                                    break;
                                case 2:
                                    dataToInsert = dataToInsert.concat(accelerationZ3[index_value].acceleration);
                                    break;
                                case 3:
                                    dataToInsert = dataToInsert.concat(accelerationZ4[index_value].acceleration);
                                    break;
                                default:
                                    break;
                            }
                        });
                        dataToInsert = dataToInsert.concat(accX[index_value].acceleration);
                        dataToInsert = dataToInsert.concat(accY[index_value].acceleration);
                        dataToInsert = dataToInsert.concat(accZ[index_value].acceleration);
                    } else {
                        dataToInsert = dataToInsert.concat(accelerationX[index_value].acceleration);
                        dataToInsert = dataToInsert.concat(accelerationY[index_value].acceleration);
                        dataToInsert = dataToInsert.concat(accelerationZ[index_value].acceleration);
                    }
                }
                //add battery
                if (dataTypeSelected.batteryChecked) {
                    if (device.manufacturer === "Teltonika") {
                        let batteries = Object.keys(allPoints).filter(name => name.includes("Battery"));

                        batteries.forEach((category, index) => {
                            switch (index) {
                                case 0:
                                    dataToInsert = dataToInsert.concat(batteryPercent[index_value].batteryPercent / 100);
                                    break;
                                case 1:
                                    dataToInsert = dataToInsert.concat(batteryMv[index_value].batteryMv);
                                    break;
                                case 2:
                                    dataToInsert = dataToInsert.concat(batteryExternal[index_value].batteryMv);
                                    break;
                                case 3:
                                    dataToInsert = dataToInsert.concat(batteryPercent1[index_value].batteryPercent);
                                    break;
                                case 4:
                                    dataToInsert = dataToInsert.concat(batteryPercent2[index_value].batteryPercent);
                                    break;
                                case 5:
                                    dataToInsert = dataToInsert.concat(batteryPercent3[index_value].batteryPercent);
                                    break;
                                case 6:
                                    dataToInsert = dataToInsert.concat(batteryPercent4[index_value].batteryPercent);
                                    break;
                                case 7:
                                    dataToInsert = dataToInsert.concat(batteryMv1[index_value].batteryMv);
                                    break;
                                case 8:
                                    dataToInsert = dataToInsert.concat(batteryMv2[index_value].batteryMv);
                                    break;
                                case 9:
                                    dataToInsert = dataToInsert.concat(batteryMv3[index_value].batteryMv);
                                    break;
                                case 10:
                                    dataToInsert = dataToInsert.concat(batteryMv4[index_value].batteryMv);
                                    break;
                                default:
                                    break;
                            }
                        });
                    } else {
                        if (batteryPercent.length !== 0) {
                            dataToInsert = dataToInsert.concat(batteryPercent[index_value].batteryPercent / 100);
                        } else {
                            dataToInsert = dataToInsert.concat(batteryMv[index_value].batteryMv);
                        }
                    }
                }
                //add latitude
                if (dataTypeSelected.latitudeChecked) {
                    dataToInsert = dataToInsert.concat(longLat[index_value].latitude);
                }
                //add longitude
                if (dataTypeSelected.longitudeChecked) {
                    dataToInsert = dataToInsert.concat(longLat[index_value].longitude);
                }
                bodyData = bodyData.concat({dataToInsert});

            })
        }else {
            current1.forEach((element, index_value) => {
                let dataToInsert = [];
                //add index
                if (includeIndex) {
                    dataToInsert = dataToInsert.concat(index_value + 1);
                }
                //add time
                if (dataTypeSelected.timeChecked) {
                    switch (selectUnitTime) {
                        case "sec":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp).toFixed(2));
                            break;
                        case "min":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp / 60).toFixed(2));
                            break;
                        case "h":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp / 3600).toFixed(2));
                            break;
                        case "days":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp / 86400).toFixed(2));
                            break;
                        case "month":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp / 2628002).toFixed(2));
                            break;
                        case "year":
                            dataToInsert = dataToInsert.concat(Number(element.timestamp / 31536000).toFixed(2));
                            break;
                        default:
                            break;
                    }
                }
                //add current
                if(dataTypeSelected.currentChecked){
                    dataToInsert = dataToInsert.concat(current1[index_value].current);
                    dataToInsert = dataToInsert.concat(current2[index_value].current);
                }
                //add battery
                if (dataTypeSelected.batteryChecked) {
                    dataToInsert = dataToInsert.concat(batteryPercent[index_value].batteryPercent / 100);
                }
                bodyData = bodyData.concat({dataToInsert});
            })
        }

        //merge heading with the data
        bodyData.forEach(function (row) {
            data += row.dataToInsert.join(separator);
            data += "\n";
        });

        //creating the real file and download it in the download folder
        const blob = new Blob([data], {type: "text/csv"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "points.txt"
        link.href = url;
        link.click();

        setExportDialog(false);
    }

    //manage the separator radio group in the dialog
    const handleSeparatorChange = (event) => {
        setSeparator(event.target.value);
    }

    //manage the checkbox group for data selection in the dialog
    const handleTypeDataChange = (event) => {
        setDataTypeSelected({
            ...dataTypeSelected,
            [event.target.name]: event.target.checked,
        });
    };

    //manage the "select" of time unit in the dialog
    const handleSelectChange = (event) => {
        setSelectUnitTime(event.target.value);
    }

    //manage the display of the slider of the map
    const handleValueLabelFormat = (value) => {

        //from an index, select the real time and return it in the wanted format
        if (temperature.length !== 0) {
            let timestamp = temperature[value - 1].timestamp;

            let dayDate = new Date((firstPointTime + timestamp) * 1000);

            return moment(dayDate).format('MM/DD-HH:mm');
        }
        if (current1.length !== 0) {
            let timestamp = current1[value - 1].timestamp;

            let dayDate = new Date((firstPointTime + timestamp) * 1000);

            return moment(dayDate).format('MM/DD-HH:mm');
        }
        return value;
    }

    //method to zoom, activate when the user release the click
    const zoom = () => {
        //recover refAreaLeft and refAreaRight from the state
        let {refAreaLeft, refAreaRight} = stateZoom;

        //if no ref right or the 2 are the same (user made a simple click on the chart), don't zoom and reset the refs
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaLeft: '',
                refAreaRight: '',
            }))
            return;
        }

        try {
            // set the right and the left area in the correct order (if the user select from right to left)
            if (refAreaLeft > refAreaRight) {
                [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
            }

            //select the first element and the last element which must be displayed
            let startElement = -1;
            let endElement = -1;
            if (temperatureDisplayed.length !== 0){
                temperatureDisplayed.forEach((element, index) => {
                    if (startElement === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement = index;
                        }
                    }
                    if (endElement === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement = index;
                        }
                    }
                })
            }else{
                current1Displayed.forEach((element, index) => {
                    if (startElement === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement = index;
                        }
                    }
                    if (endElement === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement = index;
                        }
                    }
                })
            }

            if(device.dataType.includes("20221222161657076001")){
                //restrain the number of data in the variables displayed temperature and humidity
                setTemperatureDisplayed(temperatureDisplayed.slice(startElement, endElement));
                setHumidityDisplayed(humidityDisplayed.slice(startElement, endElement));
                if (device.manufacturer === "Teltonika") {
                    setTemperature2Displayed(temperature2Displayed.slice(startElement, endElement));
                    setTemperature3Displayed(temperature3Displayed.slice(startElement, endElement));
                    setTemperature4Displayed(temperature4Displayed.slice(startElement, endElement));
                    setHumidity2Displayed(humidity2Displayed.slice(startElement, endElement));
                    setHumidity3Displayed(humidity3Displayed.slice(startElement, endElement));
                    setHumidity4Displayed(humidity4Displayed.slice(startElement, endElement));
                }
            }
            //restrain the number of data in the variables displayed movement
            if (device.dataType.includes("20230126103350301002")) {
                setMovementDisplayed(movementDisplayed.slice(startElement, endElement));
                if (device.manufacturer === "Teltonika") {
                    setMovement2Displayed(movement2Displayed.slice(startElement, endElement));
                    setMovement3Displayed(movement3Displayed.slice(startElement, endElement));
                    setMovement4Displayed(movement4Displayed.slice(startElement, endElement));
                }
            }
            //restrain the number of data in the variables displayed co2
            if (device.dataType.includes("20230126103350301112")) {
                setCoDisplayed(coDisplayed.slice(startElement, endElement));
                if (device.manufacturer === "Teltonika") {
                    setCo2Displayed(co2Displayed.slice(startElement, endElement));
                    setCo3Displayed(co3Displayed.slice(startElement, endElement));
                    setCo4Displayed(co4Displayed.slice(startElement, endElement));
                }
            }
            //restrain the number of data in the variables displayed voc
            if (device.dataType.includes("20230126103350301113")) {
                setVocDisplayed(vocDisplayed.slice(startElement, endElement));
                if (device.manufacturer === "Teltonika") {
                    setVoc2Displayed(voc2Displayed.slice(startElement, endElement));
                    setVoc3Displayed(voc3Displayed.slice(startElement, endElement));
                    setVoc4Displayed(voc4Displayed.slice(startElement, endElement));
                }
            }
            //restrain the number of data in the variables displayed atmospheric pressure
            if (device.dataType.includes("20230123154617221001")) {
                setAtmosphericPressureDisplayed(atmosphericPressureDisplayed.slice(startElement, endElement));
                if (device.manufacturer === "Teltonika") {
                    setAtmosphericPressure2Displayed(atmosphericPressure2Displayed.slice(startElement, endElement));
                    setAtmosphericPressure3Displayed(atmosphericPressure3Displayed.slice(startElement, endElement));
                    setAtmosphericPressure4Displayed(atmosphericPressure4Displayed.slice(startElement, endElement));
                }
            }
            //restrain the number of data in the variables displayed acceleration
            if (device.dataType.includes("20230126103350301004")) {
                setAccelerationXDisplayed(accelerationXDisplayed.slice(startElement, endElement));
                setAccelerationYDisplayed(accelerationYDisplayed.slice(startElement, endElement));
                setAccelerationZDisplayed(accelerationZDisplayed.slice(startElement, endElement));
                if (device.manufacturer === "Teltonika") {
                    setAccelerationX2Displayed(accelerationX2Displayed.slice(startElement, endElement));
                    setAccelerationX3Displayed(accelerationX3Displayed.slice(startElement, endElement));
                    setAccelerationX4Displayed(accelerationX4Displayed.slice(startElement, endElement));
                    setAccelerationY2Displayed(accelerationY2Displayed.slice(startElement, endElement));
                    setAccelerationY3Displayed(accelerationY3Displayed.slice(startElement, endElement));
                    setAccelerationY4Displayed(accelerationY4Displayed.slice(startElement, endElement));
                    setAccelerationZ2Displayed(accelerationZ2Displayed.slice(startElement, endElement));
                    setAccelerationZ3Displayed(accelerationZ3Displayed.slice(startElement, endElement));
                    setAccelerationZ4Displayed(accelerationZ4Displayed.slice(startElement, endElement));
                    setAccXDisplayed(accXDisplayed.slice(startElement, endElement));
                    setAccYDisplayed(accYDisplayed.slice(startElement, endElement));
                    setAccZDisplayed(accZDisplayed.slice(startElement, endElement));
                }
            }
            //restrain the number of data in the variables displayed battery
            if (device.manufacturer === "Ruuvi") {
                setBatteryMvDisplayed(batteryMvDisplayed.slice(startElement, endElement));
            }
            if (device.manufacturer === "Efento") {
                setBatteryPercentDisplayed(batteryPercentDisplayed.slice(startElement, endElement));
            }

            if(device.manufacturer === "TeltonikaEye"){
                setMagnetDisplayed(magnetDisplayed.slice(startElement, endElement));
                setBatteryMvDisplayed(batteryMvDisplayed.slice(startElement, endElement));
            }
            if (device.manufacturer === "Teltonika") {
                setBatteryMvDisplayed(batteryMvDisplayed.slice(startElement, endElement));
                setBatteryPercentDisplayed(batteryPercentDisplayed.slice(startElement, endElement));
                setBatteryExternalDisplayed(batteryExternalDisplayed.slice(startElement, endElement));
                setBatteryMv1Displayed(batteryMv1Displayed.slice(startElement, endElement));
                setBatteryMv2Displayed(batteryMv2Displayed.slice(startElement, endElement));
                setBatteryMv3Displayed(batteryMv3Displayed.slice(startElement, endElement));
                setBatteryMv4Displayed(batteryMv4Displayed.slice(startElement, endElement));
                setBatteryPercent1Displayed(batteryPercent1Displayed.slice(startElement, endElement));
                setBatteryPercent2Displayed(batteryPercent2Displayed.slice(startElement, endElement));
                setBatteryPercent3Displayed(batteryPercent3Displayed.slice(startElement, endElement));
                setBatteryPercent4Displayed(batteryPercent4Displayed.slice(startElement, endElement));
            }
            //restrain the number of data in the variables displayed longitude and latitude
            if (device.dataType.includes("20221222161814823007")) {
                setLongLatDisplayed(longLatDisplayed.slice(startElement, endElement));
            }

            //restrain the number of data in the variables displayed current
            if (current1.length !== 0) {
                setCurrent1Displayed(current1Displayed.slice(startElement, endElement));
                setCurrent2Displayed(current2Displayed.slice(startElement, endElement));
            }
            if (current12.length !== 0) {
                setCurrent12Displayed(current12Displayed.slice(startElement, endElement));
                setCurrent22Displayed(current22Displayed.slice(startElement, endElement));
            }
            if (current13.length !== 0) {
                setCurrent13Displayed(current13Displayed.slice(startElement, endElement));
                setCurrent23Displayed(current23Displayed.slice(startElement, endElement));
            }
            if (current14.length !== 0) {
                setCurrent14Displayed(current14Displayed.slice(startElement, endElement));
                setCurrent24Displayed(current24Displayed.slice(startElement, endElement));
            }

            //set the new state of zoom
            setStateZoom({
                refAreaLeft: '',
                refAreaRight: '',
                left: refAreaLeft,
                right: refAreaRight,
                animation: true,
            });
            //in case of error, display it in the console
        } catch (err) {
            console.error(err);
        }
    }

    //method to zoom out, reset the values at as they were before the zoom
    const zoomOut = () => {
        if (device.dataType.includes("20221222161657076001")) {
            //reset the values for temperature and humidity
            displayLessPointsTemperatureHumidity(temperature, humidity, numberReduced, setTemperatureDisplayed, setHumidityDisplayed);
            if (device.manufacturer === "Teltonika") {
                displayLessPointsTemperatureHumidity(temperature2, humidity2, numberReduced, setTemperature2Displayed, setHumidity2Displayed);
                displayLessPointsTemperatureHumidity(temperature3, humidity3, numberReduced, setTemperature3Displayed, setHumidity3Displayed);
                displayLessPointsTemperatureHumidity(temperature4, humidity4, numberReduced, setTemperature4Displayed, setHumidity4Displayed);
            }
        }
        //reset the values for movement
        if (device.dataType.includes("20230126103350301002")) {
            displayLessPointsMovement(movement, numberReduced, setMovementDisplayed);
            if (device.manufacturer === "Teltonika") {
                displayLessPointsMovement(movement2, numberReduced, setMovement2Displayed);
                displayLessPointsMovement(movement3, numberReduced, setMovement3Displayed);
                displayLessPointsMovement(movement4, numberReduced, setMovement4Displayed);
            }
        }
        //reset the values for Co2
        if (device.dataType.includes("20230126103350301112")) {
            displayLessPointsCo2(co, numberReduced, setCoDisplayed);
            if (device.manufacturer === "Teltonika") {
                displayLessPointsCo2(co2, numberReduced, setCo2Displayed);
                displayLessPointsCo2(co3, numberReduced, setCo3Displayed);
                displayLessPointsCo2(co4, numberReduced, setCo4Displayed);
            }
        }
        //reset the values for Voc
        if (device.dataType.includes("20230126103350301113")) {
            displayLessPointsVoc(voc, numberReduced, setVocDisplayed);
            if (device.manufacturer === "Teltonika") {
                displayLessPointsVoc(voc2, numberReduced, setVoc2Displayed);
                displayLessPointsVoc(voc3, numberReduced, setVoc3Displayed);
                displayLessPointsVoc(voc4, numberReduced, setVoc4Displayed);
            }
        }
        //reset the values for atmospheric pressure
        if (device.dataType.includes("20230123154617221001")) {
            displayLessPointsAtmPressure(atmosphericPressure, numberReduced, setAtmosphericPressureDisplayed);
            if (device.manufacturer === "Teltonika") {
                displayLessPointsAtmPressure(atmosphericPressure2, numberReduced, setAtmosphericPressure2Displayed);
                displayLessPointsAtmPressure(atmosphericPressure3, numberReduced, setAtmosphericPressure3Displayed);
                displayLessPointsAtmPressure(atmosphericPressure4, numberReduced, setAtmosphericPressure4Displayed);
            }
        }
        //reset the values for acceleration
        if (device.dataType.includes("20230126103350301004")) {
            displayLessPointsAcceleration(accelerationX, numberReduced, setAccelerationXDisplayed);
            displayLessPointsAcceleration(accelerationY, numberReduced, setAccelerationYDisplayed);
            displayLessPointsAcceleration(accelerationZ, numberReduced, setAccelerationZDisplayed);
            if (device.manufacturer === "Teltonika") {
                displayLessPointsAcceleration(accelerationX2, numberReduced, setAccelerationX2Displayed);
                displayLessPointsAcceleration(accelerationX3, numberReduced, setAccelerationX3Displayed);
                displayLessPointsAcceleration(accelerationX4, numberReduced, setAccelerationX4Displayed);
                displayLessPointsAcceleration(accelerationY2, numberReduced, setAccelerationY2Displayed);
                displayLessPointsAcceleration(accelerationY3, numberReduced, setAccelerationY3Displayed);
                displayLessPointsAcceleration(accelerationY4, numberReduced, setAccelerationY4Displayed);
                displayLessPointsAcceleration(accelerationZ2, numberReduced, setAccelerationZ2Displayed);
                displayLessPointsAcceleration(accelerationZ3, numberReduced, setAccelerationZ3Displayed);
                displayLessPointsAcceleration(accelerationZ4, numberReduced, setAccelerationZ4Displayed);
                displayLessPointsAcceleration(accX, numberReduced, setAccXDisplayed);
                displayLessPointsAcceleration(accY, numberReduced, setAccYDisplayed);
                displayLessPointsAcceleration(accZ, numberReduced, setAccZDisplayed);
            }
        }
        //reset the values for battery
        if (device.manufacturer === "Ruuvi") {
            displayLessPointsBattery(batteryMv, numberReduced, setBatteryMvDisplayed, "mv");
        }
        if (device.manufacturer === "Efento") {
            displayLessPointsBattery(batteryPercent, numberReduced, setBatteryPercentDisplayed, "percent");
        }
        if (device.manufacturer === "TeltonikaEye") {
            displayLessPointsMagnet(magnet, numberReduced);
            displayLessPointsBattery(batteryMv, numberReduced, setBatteryMvDisplayed, "mv");
        }
        if (device.manufacturer === "Teltonika") {
            displayLessPointsBattery(batteryMv, numberReduced, setBatteryMvDisplayed, "mv");
            displayLessPointsBattery(batteryPercent, numberReduced, setBatteryPercentDisplayed, "percent");
            displayLessPointsBattery(batteryExternal, numberReduced, setBatteryExternalDisplayed, "mv");
            displayLessPointsBattery(batteryMv1, numberReduced, setBatteryMv1Displayed, "mv");
            displayLessPointsBattery(batteryMv2, numberReduced, setBatteryMv2Displayed, "mv");
            displayLessPointsBattery(batteryMv3, numberReduced, setBatteryMv3Displayed, "mv");
            displayLessPointsBattery(batteryMv4, numberReduced, setBatteryMv4Displayed, "mv");
            displayLessPointsBattery(batteryPercent1, numberReduced, setBatteryPercent1Displayed, "percent");
            displayLessPointsBattery(batteryPercent2, numberReduced, setBatteryPercent2Displayed, "percent");
            displayLessPointsBattery(batteryPercent3, numberReduced, setBatteryPercent3Displayed, "percent");
            displayLessPointsBattery(batteryPercent4, numberReduced, setBatteryPercent4Displayed, "percent");
        }
        //reset the values for longitude and latitude
        if (device.dataType.includes("20221222161814823007")) {
            displayLessPointsLongLat(longLat, numberReduced);
        }

        if (current1.length !== 0) {
            displayLessPointsCurrent(current1, numberReduced, setCurrent1Displayed);
            displayLessPointsCurrent(current2, numberReduced, setCurrent2Displayed);
        }
        if (current12.length !== 0) {
            displayLessPointsCurrent(current12, numberReduced, setCurrent12Displayed);
            displayLessPointsCurrent(current22, numberReduced, setCurrent22Displayed);
        }
        if (current13.length !== 0) {
            displayLessPointsCurrent(current13, numberReduced, setCurrent13Displayed);
            displayLessPointsCurrent(current23, numberReduced, setCurrent23Displayed);
        }
        if (current14.length !== 0) {
            displayLessPointsCurrent(current14, numberReduced, setCurrent14Displayed);
            displayLessPointsCurrent(current24, numberReduced, setCurrent24Displayed);
        }

        //reset the total time
        let totalTime;
        if (temperature.length !== 0) {
            totalTime = temperature[temperature.length - 1].timestamp;
        }else {
            totalTime = current1[current1.length - 1].timestamp;
        }

        //reset the division number
        let divisionNumber = 1;
        //choose the unit for the time depending on the duration of the data (data during how much time)
        switch (true) {
            //less than 2 min - unit in seconds
            case (totalTime < 120):
                setUnitTime("min");
                break;
            //less than 5h - unit in minutes
            case (totalTime < 18000):
                setUnitTime("min");
                divisionNumber = 60;
                break;
            //less than 5 days - unit in hours
            case (totalTime < 432000):
                setUnitTime("h");
                divisionNumber = 3600;
                break;
            //less than 180 days - unit in days
            case (totalTime < 15552000):
                setUnitTime("days");
                divisionNumber = 86400;
                break;
            //less than 3 years - unit in month
            case (totalTime < 94608000):
                setUnitTime("month");
                divisionNumber = 2628002;
                break;
            //more than 3 years - unit in year
            case (totalTime > 9460800):
                setUnitTime("year");
                divisionNumber = 31536000;
                break;
            default:
                break;
        }
        //update the divisionNumber
        setTimeDivisionNumber(divisionNumber);
        //update the maxTime;
        setMaxTime(totalTime);

        //reset the state
        setStateZoom({
            left: 'dataMin',
            right: dataMax => Math.ceil(dataMax),
            refAreaLeft: '',
            refAreaRight: '',
            animation: true,
        });
    }

    //activate when a user click down, set the state refAreaLeft
    const handleMouseDown = e => {
        if (e !== null) {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaLeft: e.xValue,
            }))
        }
    }

    //activate when a user move the mouse in the plot, set the state refAreaRight
    const handleMouseMove = e => {
        if (e !== null) {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaRight: e.xValue,
            }))
        }
    }

    //select if the Y axis of battery percent must be rendered or not
    const displayAxisPercentBattery = () => {
        if (device.manufacturer !== "Teltonika") {
            return batteryPercent.length !== 0;
        } else {
            let response = false;

            if (gatewayTeltonika) {
                response = true;
            }
            if (B1Teltonika && labelBatteryPercent1.substring(0,3) === "282") {
                response = true;
            }
            if (B2Teltonika && labelBatteryPercent2.substring(0,3) === "282") {
                response = true;
            }
            if (B3Teltonika && labelBatteryPercent3.substring(0,3) === "282") {
                response = true;
            }
            if (B4Teltonika && labelBatteryPercent4.substring(0,3) === "282") {
                response = true;
            }
            return response
        }

    }

    //function to reset axis for temperature
    const resetYAxisTemperature = () => {
        setBoundsTemperatureYAxis([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
        setDisplayBoundsTemperature(saveBoundsTemperature);
    }

    //function to reset axis for humidity
    const resetYAxisHumidity = () => {
        setBoundsHumidityYAxis([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
        setDisplayBoundsHumidity(saveBoundsHumidity);
    }

    //function to reset axis for atmosphericPressure
    const resetYAxisAtmPressure = () => {
        setBoundsAtmPressureYAxis([dataMin => Math.ceil(dataMin - 20), dataMax => Math.floor(dataMax + 20)]);
        setDisplayBoundsAtmPressure(saveBoundsAtmPressure);
    }

    //function to reset axis for CO2
    const resetYAxisCo2 = () => {
        setBoundsCo2YAxis([dataMin => Math.ceil(dataMin - 10), dataMax => Math.floor(dataMax + 10)]);
        setDisplayBoundsCo2(saveBoundsCo2);
    }

    //function to reset axis for CO2
    const resetYAxisVoc = () => {
        setBoundsVocYAxis([dataMin => Math.ceil(dataMin - 10), dataMax => Math.floor(dataMax + 10)]);
        setDisplayBoundsVoc(saveBoundsVoc);
    }

    //function to reset axis for acceleration
    const resetYAxisAcceleration = () => {
        setBoundsAccelerationYAxis([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
        setDisplayBoundsAcceleration(saveBoundsAcceleration);
    }

    //function to reset axis for Longitude
    const resetYAxisLongitude = () => {
        setBoundsLongitudeYAxis([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
        setDisplayBoundsLongitude(saveBoundsLongitude)
    }

    //function to reset axis for Latitude
    const resetYAxisLatitude = () => {
        setBoundsLatitudeYAxis([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
        setDisplayBoundsLatitude(saveBoundsLatitude)
    }

    //function to reset axis for battery %
    const resetYAxisBatteryPercent = () => {
        setBoundsBatteryPercentYAxis([0, 100]);
    }

    //function to reset axis for battery mV
    const resetYAxisBatteryMv = () => {
        setBoundsBatteryMvYAxis([dataMin => Math.ceil(dataMin - 100), dataMax => Math.floor(dataMax + 100)]);
        setDisplayBoundsBatteryMv(saveBoundsBatteryMv)
    }

    //changes when user modify the form
    const handleFormBoundsBatteryPercentFirst = e => {
        setBoundsBatteryMvYAxis([parseInt(e.target.value), boundsBatteryMvYAxis[1]]);
        setDisplayBoundsBatteryMv([parseInt(e.target.value), displayBoundsBatteryMv[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsBatteryPercentLast = e => {
        setBoundsBatteryMvYAxis([boundsBatteryMvYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsBatteryMv([displayBoundsBatteryMv[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsTemperatureFirst = e => {
        setBoundsTemperatureYAxis([parseInt(e.target.value), boundsTemperatureYAxis[1]]);
        setDisplayBoundsTemperature([parseInt(e.target.value), displayBoundsTemperature[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsTemperatureLast = e => {
        setBoundsTemperatureYAxis([boundsTemperatureYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsTemperature([displayBoundsTemperature[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsHumidityFirst = e => {
        setBoundsHumidityYAxis([parseInt(e.target.value), boundsHumidityYAxis[1]]);
        setDisplayBoundsHumidity([parseInt(e.target.value), displayBoundsHumidity[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsHumidityLast = e => {
        setBoundsHumidityYAxis([boundsHumidityYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsHumidity([displayBoundsHumidity[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsAtmPressureFirst = e => {
        setBoundsAtmPressureYAxis([parseInt(e.target.value), boundsAtmPressureYAxis[1]]);
        setDisplayBoundsAtmPressure([parseInt(e.target.value), displayBoundsAtmPressure[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsAtmPressureLast = e => {
        setBoundsAtmPressureYAxis([boundsAtmPressureYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsAtmPressure([displayBoundsAtmPressure[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsCo2First = e => {
        setBoundsCo2YAxis([parseInt(e.target.value), boundsCo2YAxis[1]]);
        setDisplayBoundsCo2([parseInt(e.target.value), displayBoundsCo2[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsCo2Last = e => {
        setBoundsCo2YAxis([boundsCo2YAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsCo2([displayBoundsCo2[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsVocFirst = e => {
        setBoundsVocYAxis([parseInt(e.target.value), boundsVocYAxis[1]]);
        setDisplayBoundsVoc([parseInt(e.target.value), displayBoundsVoc[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsVocLast = e => {
        setBoundsVocYAxis([boundsVocYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsVoc([displayBoundsVoc[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsAccelerationFirst = e => {
        setBoundsAccelerationYAxis([parseInt(e.target.value), boundsAccelerationYAxis[1]]);
        setDisplayBoundsAcceleration([parseInt(e.target.value), displayBoundsAcceleration[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsAccelerationLast = e => {
        setBoundsAccelerationYAxis([boundsAccelerationYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsAcceleration([displayBoundsAcceleration[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsLatitudeFirst = e => {
        setBoundsLatitudeYAxis([parseInt(e.target.value), boundsLatitudeYAxis[1]]);
        setDisplayBoundsLatitude([parseInt(e.target.value), displayBoundsLatitude[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsLatitudeLast = e => {
        setBoundsLatitudeYAxis([boundsLatitudeYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsLatitude([displayBoundsLatitude[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsLongitudeFirst = e => {
        setBoundsLongitudeYAxis([parseInt(e.target.value), boundsLongitudeYAxis[1]]);
        setDisplayBoundsLongitude([parseInt(e.target.value), displayBoundsLongitude[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsLongitudeLast = e => {
        setBoundsLongitudeYAxis([boundsLongitudeYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsLongitude([displayBoundsLongitude[0], parseInt(e.target.value)]);
    }

    const handleChange = (event) => {
        setCrashNumber(event.target.value);
        setCrashDataX([]);
        setCrashDataY([]);
        setCrashDataZ([]);
        setCrashErrUk(false);
        setCrashErrNoPoints(false);

        //ask for data (crash)
        //generate operation code
        const operation = mqttRequests.generateOperationCode("getCrashData");

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "identifier":"' + device.identifier + '",' +
            ' "crash":"' + event.target.labels[0].id + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getCrashData");

        //publish a demand to get the data from a device
        mqttRequests.publish(mqttClient, "data/getCrashData", data);
    }

    const manageCrashData = (data) => {
        //get data from crash
        if(data.result === -1){
            //pas de points pour ce crash
            setCrashErrNoPoints(true);
        }else if(data.result === -2){
            //problems
            setCrashErrUk(true);
        }else {
            //display data
            let tempMin = 10;
            let tempMax = -10;
            let tempCrashX = data.time.map((element, id) => {
                if(parseFloat(data.accX[id]) < parseFloat(tempMin)){
                    tempMin = data.accX[id]
                }
                if(parseFloat(data.accX[id]) > parseFloat(tempMax)){
                    tempMax = data.accX[id]
                }
                return {
                    timestamp: parseFloat(element) + parseFloat(data.firstPoint*1000),
                    acceleration: data.accX[id],
                }
            })
            setCrashDataX(tempCrashX);
            let tempCrashY = data.time.map((element, id) => {
                if(parseFloat(data.accY[id]) < parseFloat(tempMin)){
                    tempMin = data.accY[id]
                }
                if(parseFloat(data.accY[id]) > parseFloat(tempMax)){
                    tempMax = data.accY[id]
                }
                return {
                    timestamp: parseFloat(element) + parseFloat(data.firstPoint*1000),
                    acceleration: data.accY[id],
                }
            })
            setCrashDataY(tempCrashY);
            let tempCrashZ = data.time.map((element, id) => {
                if(parseFloat(data.accZ[id]) < parseFloat(tempMin)){
                    tempMin = data.accZ[id];
                }
                if(parseFloat(data.accZ[id]) > parseFloat(tempMax)){
                    tempMax = data.accZ[id]
                }
                return {
                    timestamp: parseFloat(element) + parseFloat(data.firstPoint*1000),
                    acceleration: data.accZ[id],
                }
            })
            setCrashDataZ(tempCrashZ);
            setMaxCrashX(tempCrashX[tempCrashX.length-1].timestamp);
            setMinCrashX(tempCrashX[0].timestamp);

            setMaxCrashY(Math.ceil((parseFloat(tempMax) + 0.2)*10)/10);
            setMinCrashY(Math.floor((tempMin - 0.2)*10)/10);
        }
    }

    const nameFromIdentifier = (id) => {
        let chosenDevice = '';
        devices.forEach((element) => {
            if(element.identifier === id){
                chosenDevice = element;
            }
        });
        if(chosenDevice !== ''){
            return chosenDevice.customName;
        }else {
            return id;
        }
    }

    const labelFromIdentifier = (id) => {
        let chosenDevice = '';
        devices.forEach((element) => {
            if(element.identifier === id){
                chosenDevice = element;
            }
        });
        if(chosenDevice !== ''){
            return chosenDevice.customName + '(' + id + ')';
        }else {
            return id;
        }
    }

    //display the form, the button to download, the charts and the map
    return (
        <div className="baseMainDiv">
            <h2 className="baseTitle">Data from {device.customName} ({device.identifier})</h2>
            {device.firstPoint === -1 || device.firstPoint === '-1' ?
                <h2>No data from for the moment</h2>
                :
                <div className="devicesFlexboxDiv">
                    <div className="deviceDivMargin">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={submitMethod}
                        >
                            {() => {
                                return (
                                    <Form>
                                        <div className="devicesDivTxt">
                                            <FormikControl control="input" type="date" label="From: " name="beginning"
                                                           min={moment(startDate).format('YYYY-MM-DD')}
                                                           max={moment(endDate).format('YYYY-MM-DD')}/>
                                            <FormikControl control="input" type="date" label="To: " name="end"
                                                           min={moment(startDate).format('YYYY-MM-DD')}
                                                           max={moment(endDate).format('YYYY-MM-DD')}/>
                                        </div>
                                        <button type="submit" id="devicePaddingLeft" className="devicesButton">Validate</button>
                                        {!chartHidden?
                                            <button type="button" className="devicesButton" onClick={() => setExportDialog(true)}>Export points</button>
                                            :
                                            null
                                        }
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                    {!chartHidden ?
                        <div className="devicesFlexboxDivLeftDisplay">
                            {device.dataType.includes("20221222161657076001") ?
                                temperatureCheckbox ?
                                    <img className="devicesimg" src={TemperatureYes} onClick={() => setTemperatureCheckbox(!temperatureCheckbox)} alt="Temperature" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={TemperatureNo} onClick={() => setTemperatureCheckbox(!temperatureCheckbox)} alt="Temperature"/>
                                :
                                null
                            }
                            {device.dataType.includes("20221222161803478003") ?
                                humidityCheckbox ?
                                    <img className="devicesimg" src={HumidityYes} onClick={() => setHumidityCheckbox(!humidityCheckbox)} alt="Humidity" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={HumidityNo} onClick={() => setHumidityCheckbox(!humidityCheckbox)} alt="Humidity"/>
                                :
                                null
                            }
                            {device.dataType.includes("20230126103350301111") || current1.length !== 0 || current12.length !== 0 || current13.length !== 0 || current14.length !== 0?
                                currentCheckbox ?
                                    <img className="devicesimg" src={CurrentYes} onClick={() => setCurrentCheckbox(!currentCheckbox)} alt="Current" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={CurrentNo} onClick={() => setCurrentCheckbox(!currentCheckbox)} alt="Current"/>
                                :
                                null
                            }
                            {device.dataType.includes("20230123154617221001") && (atmosphericPressure.length !== 0 || atmosphericPressure2.length !== 0 || atmosphericPressure3.length !== 0 || atmosphericPressure4.length !== 0) ?
                                atmPressureCheckbox ?
                                    <img className="devicesimg" src={PressureYes} onClick={() => setAtmPressureCheckbox(!atmPressureCheckbox)} alt="Atm. Pressure" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={PressureNo} onClick={() => setAtmPressureCheckbox(!atmPressureCheckbox)} alt="Atm. Pressure"/>
                                :
                                null
                            }
                            {device.dataType.includes("20230126103350301112") && (co.length !== 0 || co2.length !== 0 || co3.length !== 0 || co4.length !== 0) ?
                                co2Checkbox ?
                                    <img className="devicesimg" src={Co2Yes} onClick={() => setCo2Checkbox(!co2Checkbox)} alt="CO2" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={Co2No} onClick={() => setCo2Checkbox(!co2Checkbox)} alt="CO2"/>
                                :
                                null
                            }
                            {device.dataType.includes("20230126103350301113") && (voc.length !== 0 || voc2.length !== 0 || voc3.length !== 0 || voc4.length !== 0) ?
                                vocCheckbox ?
                                    <img className="devicesimg" src={VocYes} onClick={() => setVocCheckbox(!vocCheckbox)} alt="VOC" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={VocNo} onClick={() => setVocCheckbox(!vocCheckbox)} alt="VOC"/>
                                :
                                null
                            }
                            {device.dataType.includes("20230126103350301002") && (movement.length !== 0 || movement2.length !== 0 || movement3.length !== 0 || movement4.length !== 0) ?
                                movementCheckbox ?
                                    <img className="devicesimg" src={MovementYes} onClick={() => setMovementCheckbox(!movementCheckbox)} alt="Movement" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={MovementNo} onClick={() => setMovementCheckbox(!movementCheckbox)} alt="Movement"/>
                                :
                                null
                            }
                            {device.dataType.includes("20230126103350301004") && (accelerationX.length !== 0 || accelerationX2.length !== 0 || accelerationX3.length !== 0 || accelerationX4.length !== 0) ?
                                accelerationCheckbox ?
                                    <img className="devicesimg" src={AccelerationYes} onClick={() => setAccelerationCheckbox(!accelerationCheckbox)} alt="Acceleration" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={AccelerationNo} onClick={() => setAccelerationCheckbox(!accelerationCheckbox)} alt="Acceleration"/>
                                :
                                null
                            }
                            {device.dataType.includes("20230126103350301008") && allCrash.length !== 0 ?
                                crashCheckbox ?
                                    <img className="devicesimg" src={CrashYes} onClick={() => setCrashCheckbox(!crashCheckbox)} alt="Crash" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={CrashNo} onClick={() => setCrashCheckbox(!crashCheckbox)} alt="Crash"/>
                                :
                                null
                            }
                            {device.dataType.includes("20230126103350301123") && magnet.length !== 0 ?
                                magnetCheckbox ?
                                    <img className="devicesimg" src={MagnetYes} onClick={() => setMagnetCheckbox(!magnetCheckbox)} alt="Magnet" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={MagnetNo} onClick={() => setMagnetCheckbox(!magnetCheckbox)} alt="Magnet"/>
                                :
                                null
                            }
                            {batteryMv.length !== 0 || batteryPercent.length !== 0 ?
                                batteryCheckbox ?
                                    <img className="devicesimg" src={BatteryYes} onClick={() => setBatteryCheckbox(!batteryCheckbox)} alt="Battery" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={BatteryNo} onClick={() => setBatteryCheckbox(!batteryCheckbox)} alt="Battery"/>
                                :
                                null
                            }
                            {device.dataType.includes("20221222161814823007") && longLat.length !== 0 ?
                                latLongCheckbox ?
                                    <img className="devicesimg" src={CoordinatesYes} onClick={() => setLatLongCheckbox(!latLongCheckbox)} alt="Coordinates" style={{color:"#06c"}}/>
                                    :
                                    <img className="devicesimg" src={CoordinatesNo} onClick={() => setLatLongCheckbox(!latLongCheckbox)} alt="Coordinates"/>
                                :
                                null
                            }
                        </div>
                        :
                        <div className="devicesFlexboxDivLeftDisplay"></div>
                    }

                </div>
            }
            {loadingOn ?
                <img src={Loading} className="missionimgLoading" style={{ userSelect: "none" }}/>
                :
                null
            }
            <div hidden={chartHidden}>
                <div className="devicesFlexboxDivCenter">
                    {lineRender ?
                        <img className="devicesimg" src={LineYes} onClick={() => setLineRender(!lineRender)} alt="Lines" style={{color:"#06c"}}/>
                        :
                        <img className="devicesimg" src={LineNo} onClick={() => setLineRender(!lineRender)} alt="Lines"/>
                    }
                    {shapeRender ?
                        <img className="devicesimg" src={DotsYes} onClick={() => setShapeRender(!shapeRender)} alt="Dots" style={{color:"#06c"}}/>
                        :
                        <img className="devicesimg" src={DotsNo} onClick={() => setShapeRender(!shapeRender)} alt="Dots"/>
                    }
                </div>
                <div className="devicesFlexboxDivSlider">
                    <p>How many points do you want to render: 1/</p>
                    <Slider style={{width: 300, marginTop: 14, marginLeft: 15}} value={numberReduced} step={1}
                            min={1} max={100} valueLabelDisplay={"on"} track={false} onChange={handleSliderChange}
                            onChangeCommitted={handleSliderChangeCommited}/>
                </div>
                {device.dataType.includes("20221222161657076001") && (temperatureCheckbox || humidityCheckbox) ?
                    <div className="deviceUnderline">
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                            <div className="devicesFlexboxDivLeft">
                                <p className="devicesFlexTextBoundsYaxis">Temperature bounds:</p>
                                <input type='number' value={displayBoundsTemperature[0]}
                                       onChange={handleFormBoundsTemperatureFirst} className="devicesInput"/>
                                <input type='number' value={displayBoundsTemperature[1]}
                                       onChange={handleFormBoundsTemperatureLast} className="devicesInput"/>
                                <button className="devicesButton" onClick={resetYAxisTemperature}>Reset</button>
                            </div>
                            <div className="devicesFlexboxDivLeft">
                                <p className="devicesFlexTextBoundsYaxis">Humidity bounds:</p>
                                <input type='number' value={displayBoundsHumidity[0]}
                                       onChange={handleFormBoundsHumidityFirst} className="devicesInput"/>
                                <input type='number' value={displayBoundsHumidity[1]}
                                       onChange={handleFormBoundsHumidityLast} className="devicesInput"/>
                                <button className="devicesButton" onClick={resetYAxisHumidity}>Reset</button>
                            </div>
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 20,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time"
                                       height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                <YAxis className="deviceRechartsUserSelection" dataKey="temperature" type="number"
                                       name="temperature" unit={temperatureUnit === "C" ? "°C" : temperatureUnit === "F" ? "°F" : "K"}
                                       domain={boundsTemperatureYAxis} allowDataOverflow={true}
                                       label={temperatureUnit === "C"?
                                           {
                                               value: "Temperature (°C)",
                                               angle: -90,
                                               dy: 50,
                                               dx: -25,
                                               position: "insideLeft",
                                               fill: "#f00"
                                           }
                                           :
                                           temperatureUnit === "F"?
                                               {
                                                   value: "Temperature (°F)",
                                                   angle: -90,
                                                   dy: 50,
                                                   dx: -25,
                                                   position: "insideLeft",
                                                   fill: "#f00"
                                               }
                                               :
                                               {
                                                   value: "Temperature (K)",
                                                   angle: -90,
                                                   dy: 50,
                                                   dx: -25,
                                                   position: "insideLeft",
                                                   fill: "#f00"
                                               }
                                       } yAxisId="left" stroke="#f00"/>
                                <YAxis className="deviceRechartsUserSelection" dataKey="humidity" type="number"
                                       name="humidity" unit="%"
                                       domain={boundsHumidityYAxis} allowDataOverflow={true}
                                       label={{
                                           value: "Humidity (%)",
                                           angle: -90,
                                           dy: -35,
                                           dx: 10,
                                           position: "insideRight",
                                           fill: "#090"
                                       }} yAxisId="right" orientation="right" stroke="#090"/>
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {TH1Teltonika && labelTemperature !== "0" && temperature.length !== 0 ?
                                    <>
                                        {temperatureCheckbox ?
                                            <Scatter name={labelFromIdentifier(labelTemperature)} data={temperatureDisplayed}
                                                     line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                                     yAxisId="left" fill="#f00"/>
                                            :
                                            null
                                        }
                                        {humidityCheckbox ?
                                            <Scatter name={labelFromIdentifier(labelHumidity)} data={humidityDisplayed}
                                                     line={{stroke: '#090', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                                     yAxisId="right" fill="#090"/>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }

                                {device.manufacturer === "Teltonika" ?
                                    <>
                                        {TH2Teltonika && labelTemperature2 !== "0" && temperature2.length !== 0 ?
                                            <>
                                                {temperatureCheckbox ?
                                                    <Scatter name={labelFromIdentifier(labelTemperature2)} data={temperature2Displayed}
                                                             line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                                             shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                             yAxisId="left" fill="#f00"/>
                                                    :
                                                    null
                                                }
                                                {humidityCheckbox ?
                                                    <Scatter name={labelFromIdentifier(labelHumidity2)} data={humidity2Displayed}
                                                             line={{stroke: '#090', strokeWidth: lineRender ? 2 : 0}}
                                                             shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                             yAxisId="right" fill="#090"/>
                                                    :
                                                    null
                                                }
                                            </>
                                            :
                                            null
                                        }
                                        {TH3Teltonika && labelTemperature3 !== "0" && temperature3.length !== 0 ?
                                            <>
                                                {temperatureCheckbox ?
                                                    <Scatter name={labelFromIdentifier(labelTemperature3)} data={temperature3Displayed}
                                                             line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                                             shape={shapeRender ? "triangle" : null}
                                                             legendType="triangle"
                                                             yAxisId="left" fill="#f00"/>
                                                    :
                                                    null
                                                }
                                                {humidityCheckbox ?
                                                    <Scatter name={labelFromIdentifier(labelHumidity3)} data={humidity3Displayed}
                                                             line={{stroke: '#090', strokeWidth: lineRender ? 2 : 0}}
                                                             shape={shapeRender ? "triangle" : null}
                                                             legendType="triangle"
                                                             yAxisId="right" fill="#090"/>
                                                    :
                                                    null
                                                }

                                            </>
                                            :
                                            null
                                        }
                                        {TH4Teltonika && labelTemperature4 !== "0" && temperature4.length !== 0 ?
                                            <>
                                                {temperatureCheckbox ?
                                                    <Scatter name={labelFromIdentifier(labelTemperature4)} data={temperature4Displayed}
                                                             line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                                             shape={shapeRender ? "square" : null} legendType="square"
                                                             yAxisId="left" fill="#f00"/>
                                                    :
                                                    null
                                                }
                                                {humidityCheckbox ?
                                                    <Scatter name={labelFromIdentifier(labelHumidity4)} data={humidity4Displayed}
                                                             line={{stroke: '#090', strokeWidth: lineRender ? 2 : 0}}
                                                             shape={shapeRender ? "square" : null} legendType="square"
                                                             yAxisId="right" fill="#090"/>
                                                    :
                                                    null
                                                }


                                            </>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ? (
                                    <ReferenceArea yAxisId="left" x1={stateZoom.refAreaLeft} x2={stateZoom.refAreaRight}
                                                   strokeOpacity={0.3}/>
                                ) : null}
                            </ScatterChart>
                        </ResponsiveContainer>
                        {device.manufacturer === "Teltonika" ?
                            <div className="devicesFlexboxDivCenter">
                                <p className="devicePaddingTop">Devices displayed: </p>
                                {teltonikaDeviceList[0] !== "0" && temperature.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceT1" name="device1"
                                           onClick={() => setTH1Teltonika(!TH1Teltonika)} checked={TH1Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[0])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[1] !== "0" && temperature2.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceT2" name="device2"
                                           onClick={() => setTH2Teltonika(!TH2Teltonika)} checked={TH2Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[1])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[2] !== "0" && temperature3.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceT3" name="device3"
                                           onClick={() => setTH3Teltonika(!TH3Teltonika)} checked={TH3Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[2])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[3] !== "0" && temperature4.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceT4" name="device4"
                                           onClick={() => setTH4Teltonika(!TH4Teltonika)} checked={TH4Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[3])}</p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
                }
                {currentCheckbox && current1.length !== 0 ?
                    <div className="deviceUnderline">
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                        </div>
                        {device.manufacturer === "Teltonika" ?
                            <div className="devicesLegendAcceleration">
                                <span style={{color: "#000"}}>Current 1</span>
                                <span style={{color: "#aaa"}}>Current 2</span>
                            </div>
                            :
                            null
                        }
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                <YAxis className="deviceRechartsUserSelection" dataKey="current"
                                       type="number" name="Current" domain={['dataMin', 'dataMax']}
                                       yAxisId="right" stroke="#000" unit="mA"
                                       label={{
                                           value: "Current (mA)",
                                           angle: -90,
                                           dy: -45,
                                           dx: -85,
                                           position: "insideRight",
                                           fill: "#000"
                                       }} allowDataOverflow={true}/>
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                <>
                                    {Cu1Teltonika && labelCurrent1 !== "0" && current1.length !== 0 ?
                                        <>
                                            <Scatter name={labelCurrent1}
                                                     data={current1Displayed}
                                                     line={{stroke: '#000', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                                     yAxisId="right" fill="#000"/>
                                            <Scatter name={labelCurrent2}
                                                     data={current2Displayed}
                                                     line={{stroke: '#aaa', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                                     yAxisId="right" fill="#aaa"/>
                                        </>
                                        :
                                        null
                                    }
                                    {device.manufacturer === "Teltonika" ?
                                        <>
                                            {Cu2Teltonika && labelCurrent12 !== "0" && current12.length !== 0 ?
                                                <>
                                                    <Scatter name={labelCurrent12}
                                                             data={current12Displayed} line={{
                                                        stroke: '#000',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                             yAxisId="right" fill="#000"/>
                                                    <Scatter name={labelCurrent22}
                                                             data={current22Displayed} line={{
                                                        stroke: '#aaa',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                             yAxisId="right" fill="#aaa"/>
                                                </>
                                                :
                                                null
                                            }
                                            {Cu3Teltonika && labelCurrent13 !== "0" && current13.length !== 0 ?
                                                <>
                                                    <Scatter name={labelCurrent13}
                                                             data={current13Displayed} line={{
                                                        stroke: '#000',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                             yAxisId="right" fill="#000"/>
                                                    <Scatter name={labelCurrent23}
                                                             data={current23Displayed} line={{
                                                        stroke: '#aaa',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                             yAxisId="right" fill="#aaa"/>
                                                </>
                                                :
                                                null
                                            }
                                            {Cu4Teltonika && labelCurrent14 !== "0" && current14.length !== 0 ?
                                                <>
                                                    <Scatter name={labelCurrent14}
                                                             data={current14Displayed} line={{
                                                        stroke: '#000',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "square" : null} legendType="square"
                                                             yAxisId="right" fill="#000"/>
                                                    <Scatter name={labelCurrent24}
                                                             data={current24Displayed} line={{
                                                        stroke: '#aaa',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "square" : null} legendType="square"
                                                             yAxisId="right" fill="#aaa"/>
                                                </>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }
                                </>
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea yAxisId="right" x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    : null}
                            </ScatterChart>
                        </ResponsiveContainer>
                        {device.manufacturer === "Teltonika" ?
                            <div className="devicesFlexboxDivCenter">
                                <p className="devicePaddingTop">Devices displayed: </p>
                                {teltonikaDeviceList[0] !== "0" && current1.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM1" name="device1"
                                               onClick={() => setCu1Teltonika(!Cu1Teltonika)} checked={Cu1Teltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[0])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[1] !== "0" && current12.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM2" name="device2"
                                               onClick={() => setCu2Teltonika(!Cu2Teltonika)} checked={Cu2Teltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[1])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[2] !== "0" && current13.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM3" name="device3"
                                               onClick={() => setCu3Teltonika(!Cu3Teltonika)} checked={Cu3Teltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[2])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[3] !== "0" && current14.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM4" name="device4"
                                               onClick={() => setCu4Teltonika(!Cu4Teltonika)} checked={Cu4Teltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[3])}</p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
                }
                {atmPressureCheckbox ?
                    <div className="deviceUnderline">
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                            {atmPressureCheckbox ?
                                <div className="devicesFlexboxDivLeft">
                                    <p className="devicesFlexTextBoundsYaxis">Atmospheric pressure bounds:</p>
                                    <input type='number' value={displayBoundsAtmPressure[0]}
                                           onChange={handleFormBoundsAtmPressureFirst} className="devicesInput"/>
                                    <input type='number' value={displayBoundsAtmPressure[1]}
                                           onChange={handleFormBoundsAtmPressureLast} className="devicesInput"/>
                                    <button className="devicesButton" onClick={resetYAxisAtmPressure}>Reset</button>
                                </div>
                                :
                                null
                            }
                        </div>
                            <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                                <ScatterChart
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={zoom}
                                    margin={{
                                        top: 20,
                                        right: 40,
                                        bottom: 10,
                                        left: 45
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                           name="time" height={50} label={{
                                        value: "Time (" + unitTime + ")",
                                        position: "insideBottomLeft",
                                        dx: 20,
                                        dy: -20
                                    }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                    {atmPressureCheckbox ?
                                        <YAxis className="deviceRechartsUserSelection" dataKey="atmPressure"
                                               type="number" name="atmPressure" domain={boundsAtmPressureYAxis}
                                               yAxisId="right" stroke="#ff8c00" unit="Pa"
                                               label={{
                                                   value: "Atmospheric pressure (Pa)",
                                                   angle: -90,
                                                   dy: -80,
                                                   dx: -85,
                                                   position: "insideRight",
                                                   fill: "#ff8c00"
                                               }} allowDataOverflow={true}/>
                                        :
                                        null
                                    }
                                    <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                    <Legend/>
                                    {atmPressureCheckbox ?
                                        <>
                                            {Atm1Teltonika && labelAtmosphericPressure !== "0" && atmosphericPressure.length !== 0 && current1.length === 0 ?
                                                <Scatter name={labelFromIdentifier(labelAtmosphericPressure)}
                                                         data={atmosphericPressureDisplayed}
                                                         line={{stroke: '#ff8c00', strokeWidth: lineRender ? 2 : 0}}
                                                         shape={shapeRender ? "circle" : null} legendType="circle"
                                                         yAxisId="right" fill="#ff8c00"/>
                                                :
                                                null
                                            }
                                            {device.manufacturer === "Teltonika" ?
                                                <>
                                                    {Atm2Teltonika && labelAtmosphericPressure2 !== "0" && atmosphericPressure2.length !== 0 && current12.length === 0 ?
                                                        <Scatter name={labelFromIdentifier(labelAtmosphericPressure2)}
                                                                 data={atmosphericPressure2Displayed} line={{
                                                            stroke: '#ff8c00',
                                                            strokeWidth: lineRender ? 2 : 0
                                                        }} shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                                 yAxisId="right" fill="#ff8c00"/>
                                                        :
                                                        null
                                                    }
                                                    {Atm3Teltonika && labelAtmosphericPressure3 !== "0" && atmosphericPressure3.length !== 0 && current13.length === 0 ?
                                                        <Scatter name={labelFromIdentifier(labelAtmosphericPressure3)}
                                                                 data={atmosphericPressure3Displayed} line={{
                                                            stroke: '#ff8c00',
                                                            strokeWidth: lineRender ? 2 : 0
                                                        }} shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                                 yAxisId="right" fill="#ff8c00"/>
                                                        :
                                                        null
                                                    }
                                                    {Atm4Teltonika && labelAtmosphericPressure4 !== "0" && atmosphericPressure4.length !== 0 && current14.length === 0 ?
                                                        <Scatter name={labelFromIdentifier(labelAtmosphericPressure4)}
                                                                 data={atmosphericPressure4Displayed} line={{
                                                            stroke: '#ff8c00',
                                                            strokeWidth: lineRender ? 2 : 0
                                                        }} shape={shapeRender ? "square" : null} legendType="square"
                                                                 yAxisId="right" fill="#ff8c00"/>
                                                        :
                                                        null
                                                    }
                                                </>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }
                                    {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                        <ReferenceArea yAxisId="right" x1={stateZoom.refAreaLeft}
                                                       x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                        : null}
                                </ScatterChart>
                            </ResponsiveContainer>
                            {device.manufacturer === "Teltonika" ?
                                <div className="devicesFlexboxDivCenter">
                                    <p className="devicePaddingTop">Devices displayed: </p>
                                    {teltonikaDeviceList[0] !== "0" && atmosphericPressure.length !== 0 && current1.length === 0 ?
                                        <div className="devicesFlexboxDiv">
                                            <input className="deviceCheckbox" type="checkbox" id="deviceM1" name="device1"
                                               onClick={() => setAtm1Teltonika(!Atm1Teltonika)} checked={Atm1Teltonika}
                                               readOnly="yes"/>
                                            <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[0])}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    {teltonikaDeviceList[1] !== "0" && atmosphericPressure2.length !== 0 && current12.length === 0 ?
                                        <div className="devicesFlexboxDiv">
                                            <input className="deviceCheckbox" type="checkbox" id="deviceM2" name="device2"
                                               onClick={() => setAtm2Teltonika(!Atm2Teltonika)} checked={Atm2Teltonika}
                                               readOnly="yes"/>
                                            <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[1])}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    {teltonikaDeviceList[2] !== "0" && atmosphericPressure3.length !== 0 && current13.length === 0 ?
                                        <div className="devicesFlexboxDiv">
                                            <input className="deviceCheckbox" type="checkbox" id="deviceM3" name="device3"
                                               onClick={() => setAtm3Teltonika(!Atm3Teltonika)} checked={Atm3Teltonika}
                                               readOnly="yes"/>
                                            <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[2])}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    {teltonikaDeviceList[3] !== "0" && atmosphericPressure4.length !== 0 && current14.length === 0 ?
                                        <div className="devicesFlexboxDiv">
                                            <input className="deviceCheckbox" type="checkbox" id="deviceM4" name="device4"
                                               onClick={() => setAtm4Teltonika(!Atm4Teltonika)} checked={Atm4Teltonika}
                                               readOnly="yes"/>
                                            <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[3])}</p>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }
                    </div>
                    :
                    null
                }
                {co2Checkbox ?
                    <div className="deviceUnderline">
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                            {co2Checkbox ?
                                <div className="devicesFlexboxDivLeft">
                                    <p className="devicesFlexTextBoundsYaxis">CO2 bounds:</p>
                                    <input type='number' value={displayBoundsCo2[0]}
                                           onChange={handleFormBoundsCo2First} className="devicesInput"/>
                                    <input type='number' value={displayBoundsCo2[1]}
                                           onChange={handleFormBoundsCo2Last} className="devicesInput"/>
                                    <button className="devicesButton" onClick={resetYAxisCo2}>Reset</button>
                                </div>
                                :
                                null
                            }
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                {co2Checkbox ?
                                    <YAxis className="deviceRechartsUserSelection" dataKey="co2"
                                           type="number" name="co2" domain={boundsCo2YAxis}
                                           yAxisId="right" stroke="#36f" unit="PPM"
                                           label={{
                                               value: "CO2 (PPM)",
                                               angle: -90,
                                               dy: -20,
                                               dx: -85,
                                               position: "insideRight",
                                               fill: "#36f"
                                           }} allowDataOverflow={true}/>
                                    :
                                    null
                                }
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {co2Checkbox ?
                                    <>
                                        {CoTeltonika && labelCo !== "0" && co.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelCo)}
                                                     data={coDisplayed}
                                                     line={{stroke: '#36f', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                                     yAxisId="right" fill="#36f"/>
                                            :
                                            null
                                        }
                                        {device.manufacturer === "Teltonika" ?
                                            <>
                                                {Co2Teltonika && labelCo2 !== "0" && co2.length !== 0?
                                                    <Scatter name={labelFromIdentifier(labelCo2)}
                                                             data={co2Displayed} line={{
                                                        stroke: '#36f',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                             yAxisId="right" fill="#36f"/>
                                                    :
                                                    null
                                                }
                                                {Co3Teltonika && labelCo3 !== "0" && co3.length !== 0?
                                                    <Scatter name={labelFromIdentifier(labelCo3)}
                                                             data={co3Displayed} line={{
                                                        stroke: '#36f',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                             yAxisId="right" fill="#36f"/>
                                                    :
                                                    null
                                                }
                                                {Co4Teltonika && labelCo4 !== "0" && co4.length !== 0?
                                                    <Scatter name={labelFromIdentifier(labelCo4)}
                                                             data={co4Displayed} line={{
                                                        stroke: '#36f',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "square" : null} legendType="square"
                                                             yAxisId="right" fill="#36f"/>
                                                    :
                                                    null
                                                }
                                            </>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea yAxisId="right" x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    : null}
                            </ScatterChart>
                        </ResponsiveContainer>
                        {device.manufacturer === "Teltonika" ?
                            <div className="devicesFlexboxDivCenter">
                                <p className="devicePaddingTop">Devices displayed: </p>
                                {teltonikaDeviceList[0] !== "0" && co.length !== 0?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM1" name="device1"
                                               onClick={() => setCoTeltonika(!CoTeltonika)} checked={CoTeltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[0])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[1] !== "0" && co2.length !== 0?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM2" name="device2"
                                               onClick={() => setCo2Teltonika(!Co2Teltonika)} checked={Co2Teltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[1])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[2] !== "0" && co3.length !== 0?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM3" name="device3"
                                               onClick={() => setCo3Teltonika(!Co3Teltonika)} checked={Co3Teltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[2])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[3] !== "0" && co4.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM4" name="device4"
                                               onClick={() => setCo4Teltonika(!Co4Teltonika)} checked={Co4Teltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[3])}</p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
                }
                {vocCheckbox ?
                    <div className="deviceUnderline">
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                            {vocCheckbox ?
                                <div className="devicesFlexboxDivLeft">
                                    <p className="devicesFlexTextBoundsYaxis">VOC bounds:</p>
                                    <input type='number' value={displayBoundsVoc[0]}
                                           onChange={handleFormBoundsVocFirst} className="devicesInput"/>
                                    <input type='number' value={displayBoundsVoc[1]}
                                           onChange={handleFormBoundsVocLast} className="devicesInput"/>
                                    <button className="devicesButton" onClick={resetYAxisVoc}>Reset</button>
                                </div>
                                :
                                null
                            }
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                {vocCheckbox ?
                                    <YAxis className="deviceRechartsUserSelection" dataKey="voc"
                                           type="number" name="voc" domain={boundsVocYAxis}
                                           yAxisId="right" stroke="#36f" unit="IAQ"
                                           label={{
                                               value: "VOC (IAQ)",
                                               angle: -90,
                                               dy: -10,
                                               dx: -85,
                                               position: "insideRight",
                                               fill: "#36f"
                                           }} allowDataOverflow={true}/>
                                    :
                                    null
                                }
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {vocCheckbox ?
                                    <>
                                        {VocTeltonika && labelVoc !== "0" && voc.length !== 0 ?
                                            <Scatter name={labelFromIdentifier(labelVoc)}
                                                     data={vocDisplayed}
                                                     line={{stroke: '#36f', strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                                     yAxisId="right" fill="#36f"/>
                                            :
                                            null
                                        }
                                        {device.manufacturer === "Teltonika" ?
                                            <>
                                                {Voc2Teltonika && labelVoc2 !== "0" && voc2.length !== 0?
                                                    <Scatter name={labelFromIdentifier(labelVoc2)}
                                                             data={voc2Displayed} line={{
                                                        stroke: '#36f',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                             yAxisId="right" fill="#36f"/>
                                                    :
                                                    null
                                                }
                                                {Voc3Teltonika && labelVoc3 !== "0" && voc3.length !== 0?
                                                    <Scatter name={labelFromIdentifier(labelVoc3)}
                                                             data={voc3Displayed} line={{
                                                        stroke: '#36f',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                             yAxisId="right" fill="#36f"/>
                                                    :
                                                    null
                                                }
                                                {Voc4Teltonika && labelVoc4 !== "0" && voc4.length !== 0?
                                                    <Scatter name={labelFromIdentifier(labelVoc4)}
                                                             data={voc4Displayed} line={{
                                                        stroke: '#36f',
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "square" : null} legendType="square"
                                                             yAxisId="right" fill="#36f"/>
                                                    :
                                                    null
                                                }
                                            </>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea yAxisId="right" x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    : null}
                            </ScatterChart>
                        </ResponsiveContainer>
                        {device.manufacturer === "Teltonika" ?
                            <div className="devicesFlexboxDivCenter">
                                <p className="devicePaddingTop">Devices displayed: </p>
                                {teltonikaDeviceList[0] !== "0" && voc.length !== 0?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM1" name="device1"
                                               onClick={() => setVocTeltonika(!VocTeltonika)} checked={VocTeltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[0])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[1] !== "0" && voc2.length !== 0?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM2" name="device2"
                                               onClick={() => setVoc2Teltonika(!Voc2Teltonika)} checked={Voc2Teltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[1])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[2] !== "0" && voc3.length !== 0?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM3" name="device3"
                                               onClick={() => setVoc3Teltonika(!Voc3Teltonika)} checked={Voc3Teltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[2])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[3] !== "0" && voc4.length !== 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM4" name="device4"
                                               onClick={() => setVoc4Teltonika(!Voc4Teltonika)} checked={Voc4Teltonika}
                                               readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[3])}</p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
                }
                {movementCheckbox ?
                    <div className="deviceUnderline">
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                {movementCheckbox ?
                                    <YAxis className="deviceRechartsUserSelection" dataKey="movement" type="number"
                                           name="movement" yAxisId="left" stroke="#000"
                                           label={{
                                               value: "Movement",
                                               angle: -90,
                                               dy: 35,
                                               dx: -10,
                                               position: "insideLeft",
                                               fill: "#000"
                                           }} allowDataOverflow={true}/>
                                    :
                                    null
                                }
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {movementCheckbox ?
                                    <>
                                        {movement.length !== 0 && M1Teltonika && labelMovement !== "0" && movement.length !== 0 && current1.length === 0 ?
                                            <Scatter name={labelFromIdentifier(labelMovement)} data={movementDisplayed}
                                                     line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                                     yAxisId="left"/>
                                            :
                                            null
                                        }
                                        {device.manufacturer === "Teltonika" ?
                                            <>
                                                {M2Teltonika && labelMovement2 !== "0" && movement2.length !== 0 && current12.length === 0 ?
                                                    <Scatter name={labelFromIdentifier(labelMovement2)} data={movement2Displayed} line={{
                                                        stroke: "#000",
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                             yAxisId="left"/>
                                                    :
                                                    null
                                                }
                                                {M3Teltonika && labelMovement3 !== "0" && movement3.length !== 0 && current13.length === 0 ?
                                                    <Scatter name={labelFromIdentifier(labelMovement3)} data={movement3Displayed} line={{
                                                        stroke: "#000",
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                             yAxisId="left"/>
                                                    :
                                                    null
                                                }
                                                {M4Teltonika && labelMovement4 !== "0" && movement4.length !== 0 && current14.length === 0 ?
                                                    <Scatter name={labelFromIdentifier(labelMovement4)} data={movement4Displayed} line={{
                                                        stroke: "#000",
                                                        strokeWidth: lineRender ? 2 : 0
                                                    }} shape={shapeRender ? "square" : null} legendType="square"
                                                             yAxisId="left"/>
                                                    :
                                                    null
                                                }
                                            </>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea yAxisId="left" x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    : null}
                            </ScatterChart>
                        </ResponsiveContainer>
                        {device.manufacturer === "Teltonika" ?
                            <div className="devicesFlexboxDivCenter">
                                <p className="devicePaddingTop">Devices displayed: </p>
                                {teltonikaDeviceList[0] !== "0" && movement.length !== 0 && current1.length === 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM1" name="device1"
                                           onClick={() => setM1Teltonika(!M1Teltonika)} checked={M1Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[0])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[1] !== "0" && movement2.length !== 0 && current12.length === 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM2" name="device2"
                                           onClick={() => setM2Teltonika(!M2Teltonika)} checked={M2Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[1])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[2] !== "0" && movement3.length !== 0 && current13.length === 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM3" name="device3"
                                           onClick={() => setM3Teltonika(!M3Teltonika)} checked={M3Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[2])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[3] !== "0" && movement4.length !== 0 && current14.length === 0 ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceM4" name="device4"
                                           onClick={() => setM4Teltonika(!M4Teltonika)} checked={M4Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[3])}</p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
                }
                {latLongCheckbox ?
                    <div className="deviceUnderline">
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                            {latLongCheckbox ?
                                <>
                                    <div className="devicesFlexboxDivLeft">
                                        <p className="devicesFlexTextBoundsYaxis">Latitude bounds:</p>
                                        <input type='number' value={displayBoundsLatitude[0]}
                                               onChange={handleFormBoundsLatitudeFirst} className="devicesInput"/>
                                        <input type='number' value={displayBoundsLatitude[1]}
                                               onChange={handleFormBoundsLatitudeLast} className="devicesInput"/>

                                        <button className="devicesButton" onClick={resetYAxisLatitude}>Reset
                                        </button>
                                    </div>
                                    <div className="devicesFlexboxDivLeft">
                                        <p className="devicesFlexTextBoundsYaxis">Longitude bounds:</p>
                                        <input type='number' value={displayBoundsLongitude[0]}
                                               onChange={handleFormBoundsLongitudeFirst} className="devicesInput"/>
                                        <input type='number' value={displayBoundsLongitude[1]}
                                               onChange={handleFormBoundsLongitudeLast} className="devicesInput"/>
                                        <button className="devicesButton" onClick={resetYAxisLongitude}>Reset
                                        </button>
                                    </div>
                                </>
                                :
                                null
                            }
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                {latLongCheckbox ?
                                    <>
                                        <YAxis className="deviceAxisLatitude" dataKey="latitude" type="number"
                                               name="latitude" domain={boundsLatitudeYAxis} yAxisId="rightLat"
                                               orientation="right" stroke="#aaa" unit="°"
                                               label={{
                                                   value: "Latitude (°)",
                                                   angle: -90,
                                                   dy: -30,
                                                   dx: -10,
                                                   position: "insideRight",
                                                   fill: "#aaa"
                                               }} allowDecimals={false} allowDataOverflow={true}/>
                                        <YAxis className="deviceAxisLongitude" dataKey="longitude" type="number"
                                               name="longitude" domain={boundsLongitudeYAxis} yAxisId="leftLong"
                                               stroke="#000" unit="°"
                                               label={{
                                                   value: "Longitude (°)",
                                                   angle: -90,
                                                   dy: -30,
                                                   dx: -45,
                                                   position: "insideRight",
                                                   fill: "#000"
                                               }} allowDecimals={false} allowDataOverflow={true}/>
                                    </>
                                    :
                                    null
                                }
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltipForLongLat/>}/>
                                <Legend/>
                                {latLongCheckbox ?
                                    <>
                                        <Scatter name="latitude" data={longLatDisplayed}
                                                 line={{stroke: "#aaa", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "circle" : null} legendType="circle"
                                                 yAxisId="rightLat" fill="#aaa"/>
                                        <Scatter name="longitude" data={longLatDisplayed}
                                                 line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                 yAxisId="leftLong" fill="#000"/>
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea yAxisId="rightLat" x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    : null}
                            </ScatterChart>
                        </ResponsiveContainer>
                    </div>
                    : null
                }

                {accelerationCheckbox ?
                    <div className="deviceUnderline">
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                            {accelerationCheckbox ?
                                <div className="devicesFlexboxDivLeft">
                                    <p className="devicesFlexTextBoundsYaxis">Acceleration bounds:</p>
                                    <input type='number' value={displayBoundsAcceleration[0]}
                                           onChange={handleFormBoundsAccelerationFirst}
                                           className="devicesInput"/>
                                    <input type='number' value={displayBoundsAcceleration[1]}
                                           onChange={handleFormBoundsAccelerationLast}
                                           className="devicesInput"/>

                                    <button className="devicesButton" onClick={resetYAxisAcceleration}>Reset</button>
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className="devicesLegendAcceleration">
                            <span style={{color: "#F00"}}>Acceleration X Axis &nbsp;</span>
                            <span style={{color: "#090"}}>Acceleration Y Axis &nbsp;</span>
                            <span style={{color: "#00B"}}>Acceleration Z Axis</span>
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                <YAxis className="deviceRechartsUserSelection" dataKey="acceleration" type="number"
                                       name="acceleration" stroke="#000" domain={boundsAccelerationYAxis} unit="g"
                                       label={{
                                           value: "Acceleration (g-force)",
                                           angle: -90,
                                           dy: 65,
                                           dx: -15,
                                           position: "insideLeft",
                                           fill: "#000"
                                       }} allowDataOverflow={true}
                                />
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {AGTeltonika && accX.length !== 0 && device.manufacturer === "Teltonika" && (accX[0].acceleration !== 0 && accY[0].acceleration !== 0 && accZ[0].acceleration !== 0)?
                                    <>
                                        <Scatter name={"gateway"} data={accXDisplayed}
                                                 line={{
                                                     stroke: "#F00",
                                                     strokeWidth: lineRender ? 2 : 0
                                                 }}
                                                 shape={shapeRender ? "circle" : null} legendType="circle"
                                                 fill="#F00"/>
                                        <Scatter name={"gateway"} data={accYDisplayed}
                                                 line={{
                                                     stroke: "#090",
                                                     strokeWidth: lineRender ? 2 : 0
                                                 }}
                                                 shape={shapeRender ? "circle" : null} legendType="circle"
                                                 fill="#090"/>
                                        <Scatter name={"gateway"} data={accZDisplayed}
                                                 line={{
                                                     stroke: "#00B",
                                                     strokeWidth: lineRender ? 2 : 0
                                                 }}
                                                 shape={shapeRender ? "circle" : null} legendType="circle"
                                                 fill="#00B"/>
                                    </>

                                    :
                                    null
                                }
                                {accelerationX.length !== 0 && A1Teltonika && labelAccelerationX !== "0" && (accelerationX[0].acceleration !== 0 && accelerationY[0].acceleration !== 0 && accelerationZ[0].acceleration !== 0) ?
                                    <>
                                        <Scatter name={labelFromIdentifier(labelAccelerationX)} data={accelerationXDisplayed}
                                                 line={{stroke: "#F00", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "diamond" : null} legendType="diamond" fill="#F00"/>
                                        <Scatter name={labelFromIdentifier(labelAccelerationY)} data={accelerationYDisplayed}
                                                 line={{stroke: "#090", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "diamond" : null} legendType="diamond" fill="#090"/>
                                        <Scatter name={labelFromIdentifier(labelAccelerationZ)} data={accelerationZDisplayed}
                                                 line={{stroke: "#00B", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "diamond" : null} legendType="diamond" fill="#00B"/>
                                    </>
                                    :
                                    null
                                }
                                {device.manufacturer === "Teltonika" ?
                                    <>
                                        {A2Teltonika && labelAccelerationX2 !== "0" && accelerationX2.length !== 0 && (accelerationX2[0].acceleration !== 0 && accelerationY2[0].acceleration !== 0 && accelerationZ2[0].acceleration !== 0) ?
                                            <>
                                                <Scatter name={labelFromIdentifier(labelAccelerationX2)} data={accelerationX2Displayed}
                                                         line={{
                                                             stroke: "#F00",
                                                             strokeWidth: lineRender ? 2 : 0
                                                         }}
                                                         shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                         fill="#F00"/>
                                                <Scatter name={labelFromIdentifier(labelAccelerationY2)} data={accelerationY2Displayed}
                                                         line={{
                                                             stroke: "#090",
                                                             strokeWidth: lineRender ? 2 : 0
                                                         }}
                                                         shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                         fill="#090"/>
                                                <Scatter name={labelFromIdentifier(labelAccelerationZ2)} data={accelerationZ2Displayed}
                                                         line={{
                                                             stroke: "#00B",
                                                             strokeWidth: lineRender ? 2 : 0
                                                         }}
                                                         shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                         fill="#00B"/>
                                            </>

                                            :
                                            null
                                        }
                                        {A3Teltonika && labelAccelerationX3 !== "0" && accelerationX3.length !== 0 && (accelerationX3[0].acceleration !== 0 && accelerationY3[0].acceleration !== 0 && accelerationZ3[0].acceleration !== 0) ?
                                            <>
                                                <Scatter name={labelFromIdentifier(labelAccelerationX3)} data={accelerationX3Displayed}
                                                         line={{
                                                             stroke: "#F00",
                                                             strokeWidth: lineRender ? 2 : 0
                                                         }}
                                                         shape={shapeRender ? "square" : null} legendType="square"
                                                         fill="#F00"/>
                                                <Scatter name={labelFromIdentifier(labelAccelerationY3)} data={accelerationY3Displayed}
                                                         line={{
                                                             stroke: "#090",
                                                             strokeWidth: lineRender ? 2 : 0
                                                         }}
                                                         shape={shapeRender ? "square" : null} legendType="square"
                                                         fill="#090"/>
                                                <Scatter name={labelFromIdentifier(labelAccelerationZ3)} data={accelerationZ3Displayed}
                                                         line={{
                                                             stroke: "#00B",
                                                             strokeWidth: lineRender ? 2 : 0
                                                         }}
                                                         shape={shapeRender ? "square" : null} legendType="square"
                                                         fill="#00B"/>
                                            </>
                                            :
                                            null
                                        }
                                        {A4Teltonika && labelAccelerationX4 !== "0" && accelerationX4.length !== 0 && (accelerationX4[0].acceleration !== 0 && accelerationY4[0].acceleration !== 0 && accelerationZ4[0].acceleration !== 0) ?
                                            <>
                                                <Scatter name={labelFromIdentifier(labelAccelerationX4)} data={accelerationX4Displayed}
                                                         line={{
                                                             stroke: "#F00",
                                                             strokeWidth: lineRender ? 2 : 0
                                                         }}
                                                         shape={shapeRender ? "wye" : null} legendType="wye"
                                                         fill="#F00"/>
                                                <Scatter name={labelFromIdentifier(labelAccelerationY4)} data={accelerationY4Displayed}
                                                         line={{
                                                             stroke: "#090",
                                                             strokeWidth: lineRender ? 2 : 0
                                                         }}
                                                         shape={shapeRender ? "wye" : null} legendType="wye"
                                                         fill="#090"/>
                                                <Scatter name={labelFromIdentifier(labelAccelerationZ4)} data={accelerationZ4Displayed}
                                                         line={{
                                                             stroke: "#00B",
                                                             strokeWidth: lineRender ? 2 : 0
                                                         }}
                                                         shape={shapeRender ? "wye" : null} legendType="wye"
                                                         fill="#00B"/>
                                            </>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    :
                                    null
                                }
                            </ScatterChart>
                        </ResponsiveContainer>
                        {device.manufacturer === "Teltonika" ?
                            <div className="devicesFlexboxDivCenter">
                                <p className="devicePaddingTop">Devices displayed: </p>
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="deviceAG" name="deviceG"
                                           onClick={() => setAGTeltonika(!AGTeltonika)} checked={AGTeltonika}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">Gateway</p>
                                </div>
                                {teltonikaDeviceList[0] !== "0" && accelerationX.length !== 0 && (accelerationX[0].acceleration !== 0 && accelerationY[0].acceleration !== 0 && accelerationZ[0].acceleration !== 0) ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceA1" name="device1"
                                           onClick={() => setA1Teltonika(!A1Teltonika)} checked={A1Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[0])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[1] !== "0" && accelerationX2.length !== 0 && (accelerationX2[0].acceleration !== 0 && accelerationY2[0].acceleration !== 0 && accelerationZ2[0].acceleration !== 0) ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceA2" name="device2"
                                           onClick={() => setA2Teltonika(!A2Teltonika)} checked={A2Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[1])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[2] !== "0" && accelerationX3.length !== 0 && (accelerationX3[0].acceleration !== 0 && accelerationY3[0].acceleration !== 0 && accelerationZ3[0].acceleration !== 0) ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceA3" name="device3"
                                           onClick={() => setA3Teltonika(!A3Teltonika)} checked={A3Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[2])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[3] !== "0" && accelerationX4.length !== 0 && (accelerationX4[0].acceleration !== 0 && accelerationY4[0].acceleration !== 0 && accelerationZ4[0].acceleration !== 0) ?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceA4" name="device4"
                                           onClick={() => setA4Teltonika(!A4Teltonika)} checked={A4Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[3])}</p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                    : null
                }
                {crashCheckbox ?
                    <div className="deviceUnderline">
                        <div>
                            <p className="devicesTitleCrash">Crash</p>
                            <RadioGroup
                                className="missionText"
                                name="crashs"
                                value={crashNumber}
                                onChange={handleChange}
                                row
                            >
                                {allCrash.map((crash, id) => {
                                    return(
                                        <>
                                            <FormControlLabel id={crash.timestamp} value={id} control={<Radio sx={{'&, &.Mui-checked': {color: '#000000',},}}/>} label={moment(new Date((crash.timestamp*1000))).format('YYYY MM/DD-HH:mm:ss')} />
                                            {//moment(new Date(crash.timestamp*1000)).format('YYYY MM/DD HH:mm')
                                            }
                                        </>
                                    )
                                })}
                            </RadioGroup>
                        </div>
                        {crashErrNoPoints?
                            <p className="devicesTextError">Error - No data for this point</p>
                            :
                            null
                        }
                        {crashErrUk?
                            <p className="devicesTextError">Unknown error : try again later</p>
                            :
                            null
                        }
                        {crashDataX.length !== 0 ?
                            //display data crash
                            <>
                                <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                                    <ScatterChart
                                        margin={{
                                            top: 20,
                                            right: 40,
                                            bottom: 10,
                                            left: 45
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                               name="time" height={50} label={{
                                            value: "Time (s)",
                                            position: "insideBottomLeft",
                                            dx: 20,
                                            dy: -20
                                        }} domain={[minCrashX, maxCrashX]} tick={<CustomizedTickCrash/>}/>
                                        <YAxis className="deviceRechartsUserSelection" dataKey="acceleration" type="number"
                                               name="acceleration" stroke="#000" domain={[minCrashY, maxCrashY]} unit="g"
                                               label={{
                                                   value: "Acceleration (g-force)",
                                                   angle: -90,
                                                   dy: 65,
                                                   dx: -15,
                                                   position: "insideLeft",
                                                   fill: "#000"
                                               }} allowDataOverflow={true}
                                        />
                                        <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltipCrash/>}/>
                                        <Legend/>
                                        <Scatter name={"acceleration X"} data={crashDataX}
                                                 line={{stroke: "#F00", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "circle" : null} legendType="circle"
                                                 fill="#F00"/>
                                        <Scatter name={"acceleration Y"} data={crashDataY}
                                                 line={{stroke: "#090", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "circle" : null} legendType="circle"
                                                 fill="#090"/>
                                        <Scatter name={"acceleration Z"} data={crashDataZ}
                                                 line={{stroke: "#00B", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "circle" : null} legendType="circle"
                                                 fill="#00B"/>
                                    </ScatterChart>
                                </ResponsiveContainer>
                            </>
                            :
                            null
                        }
                    </div>
                    :
                    null

                }
                {magnetCheckbox ?
                    <div className="deviceUnderline">
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                {magnetCheckbox ?
                                    <YAxis className="deviceRechartsUserSelection" dataKey="magnet" type="number"
                                           name="Magnet" yAxisId="left" stroke="#000" domain={[0,1]}
                                           label={{
                                               value: "Magnet",
                                               angle: -90,
                                               dy: 35,
                                               dx: -10,
                                               position: "insideLeft",
                                               fill: "#000"
                                           }} allowDataOverflow={true}/>
                                    :
                                    null
                                }
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                <Scatter name={labelMagnet} data={magnetDisplayed}
                                         line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                         shape={shapeRender ? "circle" : null} legendType="circle"
                                         yAxisId="left"/>
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea yAxisId="left" x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    : null}
                            </ScatterChart>
                        </ResponsiveContainer>
                    </div>
                    :
                    null
                }
                {batteryCheckbox ?
                    <div className="deviceUnderline">
                        <div className="devicesFlexboxDivSpaceEvenly">
                            <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                            {displayAxisPercentBattery() ?
                                <div className="devicesFlexboxDivLeft">
                                    <p className="devicesFlexTextBoundsYaxis">Battery percent bounds:</p>
                                    <input type='number' value={boundsBatteryPercentYAxis[0]}
                                           onChange={e => setBoundsBatteryPercentYAxis([parseInt(e.currentTarget.value), boundsBatteryPercentYAxis[1]])}
                                           className="devicesInput"/>
                                    <input type='number' value={boundsBatteryPercentYAxis[1]}
                                           onChange={e => setBoundsBatteryPercentYAxis([boundsBatteryPercentYAxis[0], parseInt(e.currentTarget.value)])}
                                           className="devicesInput"/>
                                    <button className="devicesButton" onClick={resetYAxisBatteryPercent}>Reset
                                    </button>
                                </div>
                                :
                                null
                            }
                            {batteryMv.length !== 0 ?
                                <div className="devicesFlexboxDivLeft">
                                    <p className="devicesFlexTextBoundsYaxis">Battery mV bounds:</p>
                                    <input type='number' value={displayBoundsBatteryMv[0]}
                                           onChange={handleFormBoundsBatteryPercentFirst} className="devicesInput"/>
                                    <input type='number' value={displayBoundsBatteryMv[1]}
                                           onChange={handleFormBoundsBatteryPercentLast} className="devicesInput"/>
                                    <button className="devicesButton" onClick={resetYAxisBatteryMv}>Reset</button>
                                </div>
                                :
                                null
                            }
                        </div>
                        <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                            <ScatterChart
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={zoom}
                                margin={{
                                    top: 20,
                                    right: 40,
                                    bottom: 10,
                                    left: 45
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                       name="time" height={50} label={{
                                    value: "Time (" + unitTime + ")",
                                    position: "insideBottomLeft",
                                    dx: 20,
                                    dy: -20
                                }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                                {displayAxisPercentBattery() ?
                                    <YAxis className="deviceRechartsUserSelection" dataKey="batteryPercent"
                                           type="number" name="batteryPercent" domain={boundsBatteryPercentYAxis}
                                           yAxisId="left" stroke="#000" unit="%"
                                           label={{
                                               value: "Battery (%)",
                                               angle: -90,
                                               dy: 35,
                                               dx: -10,
                                               position: "insideLeft",
                                               fill: "#000"
                                           }} allowDataOverflow={true}/>
                                    :
                                    null
                                }
                                {batteryMv.length !== 0 ?
                                    <YAxis className="deviceRechartsUserSelection" dataKey="batteryMv" type="number"
                                           name="batteryMv" domain={boundsBatteryMvYAxis} yAxisId="right"
                                           orientation="right" stroke="#aaa" unit="mV"
                                           label={{
                                               value: "Battery (mV)",
                                               angle: -90,
                                               dy: -80,
                                               dx: 30,
                                               position: "insideRight",
                                               fill: "#aaa"
                                           }} allowDataOverflow={true}/>
                                    :
                                    null
                                }
                                <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                                <Legend/>
                                {device.manufacturer === "Teltonika" && BExternalTeltonika ?
                                    <Scatter name={"external battery (Mv)"} data={batteryExternalDisplayed}
                                             line={{stroke: "#909", strokeWidth: lineRender ? 2 : 0}}
                                             shape={shapeRender ? "circle" : null} legendType="circle"
                                             yAxisId="right" fill="#909"/>
                                    :
                                    null
                                }
                                {batteryPercent.length !== 0 && gatewayTeltonika ?
                                    <Scatter name={labelFromIdentifier(labelBatteryPercent)} data={batteryPercentDisplayed}
                                             line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                             shape={shapeRender ? "circle" : null} legendType="circle"
                                             yAxisId="left"/>
                                    :
                                    null
                                }
                                {batteryMv.length !== 0 && gatewayTeltonika ?
                                    <Scatter name={labelFromIdentifier(labelBatteryMv)} data={batteryMvDisplayed}
                                             line={{stroke: "#aaa", strokeWidth: lineRender ? 2 : 0}}
                                             shape={shapeRender ? "circle" : null} legendType="circle"
                                             yAxisId="right" fill="#aaa"/>
                                    :
                                    null
                                }
                                {device.manufacturer === "Teltonika" ?
                                    <>
                                        {B1Teltonika && labelBatteryPercent1 !== "0" ?
                                            labelBatteryPercent1.substring(0, 3) === "282" ?
                                                <Scatter name={labelFromIdentifier(labelBatteryPercent1)} data={batteryPercent1Displayed}
                                                         line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                                         shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                         yAxisId="left"/>
                                                :
                                                <Scatter name={labelFromIdentifier(labelBatteryMv1)} data={batteryMv1Displayed}
                                                         line={{stroke: "#aaa", strokeWidth: lineRender ? 2 : 0}}
                                                         shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                         yAxisId="right" fill="#aaa"/>
                                            :
                                            null
                                        }
                                        {B2Teltonika && labelBatteryPercent2 !== "0" ?
                                            labelBatteryPercent2.substring(0, 3) === "282" ?
                                                <Scatter name={labelFromIdentifier(labelBatteryPercent2)} data={batteryPercent2Displayed}
                                                         line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                                         shape={shapeRender ? "triangle" : null}
                                                         legendType="triangle" yAxisId="left"/>
                                                :
                                                <Scatter name={labelFromIdentifier(labelBatteryMv2)} data={batteryMv2Displayed}
                                                         line={{stroke: "#aaa", strokeWidth: lineRender ? 2 : 0}}
                                                         shape={shapeRender ? "triangle" : null}
                                                         legendType="triangle" yAxisId="right" fill="#aaa"/>
                                            :
                                            null
                                        }
                                        {B3Teltonika && labelBatteryPercent3 !== "0" ?
                                            labelBatteryPercent3.substring(0, 3) === "282" ?
                                                <Scatter name={labelFromIdentifier(labelBatteryPercent3)} data={batteryPercent3Displayed}
                                                         line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                                         shape={shapeRender ? "square" : null} legendType="square"
                                                         yAxisId="left"/>
                                                :
                                                <Scatter name={labelFromIdentifier(labelBatteryMv3)} data={batteryMv3Displayed}
                                                         line={{stroke: "#aaa", strokeWidth: lineRender ? 2 : 0}}
                                                         shape={shapeRender ? "square" : null} legendType="square"
                                                         yAxisId="right" fill="#aaa"/>
                                            :
                                            null
                                        }
                                        {B4Teltonika && labelBatteryPercent4 !== "0" ?
                                            labelBatteryPercent4.substring(0, 3) === "282" ?
                                                <Scatter name={labelFromIdentifier(labelBatteryPercent4)} data={batteryPercent4Displayed}
                                                         line={{stroke: "#000", strokeWidth: lineRender ? 2 : 0}}
                                                         shape={shapeRender ? "wye" : null} legendType="wye"
                                                         yAxisId="left"/>
                                                :
                                                <Scatter name={labelFromIdentifier(labelBatteryMv4)} data={batteryMv4Displayed}
                                                         line={{stroke: "#aaa", strokeWidth: lineRender ? 2 : 0}}
                                                         shape={shapeRender ? "wye" : null} legendType="wye"
                                                         yAxisId="right" fill="#aaa"/>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    batteryPercent.length !== 0 ?
                                        <ReferenceArea yAxisId="left" x1={stateZoom.refAreaLeft}
                                                       x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                        :
                                        <ReferenceArea yAxisId="right" x1={stateZoom.refAreaLeft}
                                                       x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                    :
                                    null
                                }
                            </ScatterChart>
                        </ResponsiveContainer>
                        {device.manufacturer === "Teltonika" ?
                            <div className="devicesFlexboxDivCenter">
                                <p className="devicePaddingTop">Devices displayed: </p>
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="gateway" name="gateway"
                                           onClick={() => setGatewayTeltonika(!gatewayTeltonika)}
                                           checked={gatewayTeltonika} readOnly="yes"/>
                                    <p className="deviceDisplayedText">Gateway</p>
                                </div>
                                <div className="devicesFlexboxDiv">
                                    <input className="deviceCheckbox" type="checkbox" id="external" name="external"
                                           onClick={() => setBExternalTeltonika(!BExternalTeltonika)}
                                           checked={BExternalTeltonika} readOnly="yes"/>
                                    <p className="deviceDisplayedText">External</p>
                                </div>
                                {teltonikaDeviceList[0] !== "0"?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceB1" name="device1"
                                           onClick={() => setB1Teltonika(!B1Teltonika)} checked={B1Teltonika}
                                           readOnly="yes"/>
                                    <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[0])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[1] !== "0"?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceB2" name="device2"
                                           onClick={() => setB2Teltonika(!B2Teltonika)} checked={B2Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[1])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[2] !== "0"?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceB3" name="device3"
                                           onClick={() => setB3Teltonika(!B3Teltonika)} checked={B3Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[2])}</p>
                                    </div>
                                    :
                                    null
                                }
                                {teltonikaDeviceList[3] !== "0"?
                                    <div className="devicesFlexboxDiv">
                                        <input className="deviceCheckbox" type="checkbox" id="deviceB4" name="device4"
                                           onClick={() => setB4Teltonika(!B4Teltonika)} checked={B4Teltonika}
                                           readOnly="yes"/>
                                        <p className="deviceDisplayedText">{nameFromIdentifier(teltonikaDeviceList[3])}</p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
                }

                {latLongCheckbox && latitude !== null ?
                    <div className="deviceMapDiv">
                        <MapContainer center={[latitude, longitude]} zoom={14} classList="map">
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {coordinateHistory ?
                                allHistoryMarker()
                                :
                                <Marker key={latitude} position={[latitude, longitude]} icon={collectorIcon}>
                                    <Popup>
                                        {temperature.length !== 0 ?
                                            moment(new Date((firstPointTime + temperature[mapSlider[1] - 1].timestamp) * 1000)).format('MM/DD-HH:mm')
                                        :
                                            moment(new Date((firstPointTime + current1[mapSlider[1] - 1].timestamp) * 1000)).format('MM/DD-HH:mm')
                                        }
                                    </Popup>
                                </Marker>
                            }
                            <MyComponent longitude={longitude} latitude={latitude} longLat={longLat} firstPointTime={firstPointTime} setLatitude={setLatitude}
                                         setCoordinateHistory={setCoordinateHistory} collectorIcon={collectorIcon} setLongitude={setLongitude}
                                         setMapSlider={setMapSlider} maxValueMapSlider={maxValueMapSlider} setHistoryLLDisplay={setHistoryLLDisplay}/>
                        </MapContainer>
                        <Slider style={{width: "80%", marginTop: 14, maxWidth: 1000}} value={mapSlider} step={1} min={1}
                                max={maxValueMapSlider} valueLabelDisplay={"on"} track={"normal"}
                                onChange={handleSliderMap} onChangeCommitted={handleSliderMapComitted}
                                valueLabelFormat={value => handleValueLabelFormat(value)}/>
                        <div className="devicesFlexboxDivCenter">
                            {coordinateHistory?
                                <img className="devicesimg" src={HistoryYes} onClick={handleKeepHistory} alt="Keep History" style={{color:"#06c"}}/>
                                :
                                <img className="devicesimg" src={HistoryNo} onClick={handleKeepHistory} alt="Keep History"/>
                            }
                        </div>
                    </div>

                    :
                    null
                }
            </div>
            <div className="DeviceBackButton">
                <button className="devicesButton" onClick={() => navigate("/deviceList")}>Back</button>
            </div>
            <Snackbar open={errMessage} autoHideDuration={4000} onClose={() => setErrMessage(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - end date before begin date</Alert>
            </Snackbar>
            <Dialog open={exportDialog} onClose={() => setExportDialog(false)}>
                <DialogTitle>Download points</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select parameters:</DialogContentText>
                    <div className="devicesFlexboxDiv">
                        <FormControl component="fieldset">
                            <FormLabel>TypeData</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={timeChecked} onChange={handleTypeDataChange}
                                                       name="timeChecked"/>} label="Time"/>
                                {device.dataType.includes("20221222161657076001") ?
                                    <FormControlLabel
                                        control={<Checkbox checked={temperatureChecked} onChange={handleTypeDataChange}
                                                           name="temperatureChecked"/>} label="Temperature"/>
                                    :
                                    null
                                }
                                {device.dataType.includes("20221222161803478003") ?
                                    <FormControlLabel
                                        control={<Checkbox checked={humidityChecked} onChange={handleTypeDataChange}
                                                           name="humidityChecked"/>} label="Humidity"/>
                                    :
                                    null
                                }
                                {device.dataType.includes("20230126103350301111") ?
                                    <FormControlLabel
                                        control={<Checkbox checked={currentChecked} onChange={handleTypeDataChange}
                                                           name="currentChecked"/>} label="Current"/>
                                    :
                                    null
                                }
                                {device.dataType.includes("20230126103350301002") ?
                                    <FormControlLabel
                                        control={<Checkbox checked={movementChecked} onChange={handleTypeDataChange}
                                                           name="movementChecked"/>} label="Movement"/>
                                    :
                                    null
                                }
                                {device.dataType.includes("20230126103350301123") ?
                                    <FormControlLabel control={<Checkbox checked={magnetChecked}
                                                                         onChange={handleTypeDataChange}
                                                                         name="magnetChecked"/>}
                                                      label="Magnet"/>
                                    :
                                    null
                                }
                                {device.dataType.includes("20230123154617221001") ?
                                    <FormControlLabel control={<Checkbox checked={atmosphericPressureChecked}
                                                                         onChange={handleTypeDataChange}
                                                                         name="atmosphericPressureChecked"/>}
                                                      label="Atmospheric pressure"/>
                                    :
                                    null
                                }
                                {device.dataType.includes("20230126103350301112") ?
                                    <FormControlLabel control={<Checkbox checked={co2Checked}
                                                                         onChange={handleTypeDataChange}
                                                                         name="co2Checked"/>}
                                                      label="CO2"/>
                                    :
                                    null
                                }
                                {device.dataType.includes("20230126103350301113") ?
                                    <FormControlLabel control={<Checkbox checked={vocChecked}
                                                                         onChange={handleTypeDataChange}
                                                                         name="vocChecked"/>}
                                                      label="VOC"/>
                                    :
                                    null
                                }
                                <FormControlLabel
                                    control={<Checkbox checked={batteryChecked} onChange={handleTypeDataChange}
                                                       name="batteryChecked"/>} label="Battery"/>
                                {device.dataType.includes("20221222161814823007") ?
                                    <>
                                        <FormControlLabel control={<Checkbox checked={latitudeChecked}
                                                                             onChange={handleTypeDataChange}
                                                                             name="latitudeChecked"/>}
                                                          label="Latitude"/>
                                        <FormControlLabel control={<Checkbox checked={longitudeChecked}
                                                                             onChange={handleTypeDataChange}
                                                                             name="longitudeChecked"/>}
                                                          label="Longitude"/>
                                    </>
                                    :
                                    null
                                }
                            </FormGroup>
                        </FormControl>
                        <div>
                            <FormControl>
                                <FormLabel id="formSeparator">Separator</FormLabel>
                                <RadioGroup
                                    value={separator}
                                    onChange={handleSeparatorChange}
                                >
                                    <FormControlLabel value={" "} control={<Radio/>} label={"space"}/>
                                    <FormControlLabel value={"&nbsp;"} control={<Radio/>} label={"tab"}/>
                                    <FormControlLabel value={","} control={<Radio/>} label={"comma"}/>
                                    <FormControlLabel value={";"} control={<Radio/>} label={"semicolon"}/>
                                </RadioGroup>
                            </FormControl>
                            <div className="devicesFlexboxDivLeft">
                                <input className="deviceCheckbox" type="checkbox" id="index" name="index"
                                       onClick={() => setIncludeIndex(!includeIndex)} checked={includeIndex}
                                       readOnly="yes"/>
                                <p className="deviceText">Include index </p>
                            </div>
                            <FormControl>
                                <InputLabel id="selectUnitTime">Time unit</InputLabel>
                                <Select label="Time unit" value={selectUnitTime} onChange={handleSelectChange}>
                                    <MenuItem value="sec">Seconds</MenuItem>
                                    <MenuItem value="min">Minutes</MenuItem>
                                    <MenuItem value="h">Hours</MenuItem>
                                    <MenuItem value="days">Days</MenuItem>
                                    <MenuItem value="month">Month</MenuItem>
                                    <MenuItem value="year">Years</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <DialogActions>
                        <button className="devicesButton" onClick={() => setExportDialog(false)}>Cancel</button>
                        <button className="devicesButton" onClick={downloadFile}>Validate</button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
}

//button to reset the view on the actual marker
function MyComponent(props) {
    const map = useMap();

    let longLat = props.longLat;
    let setCoordinateHistory = props.setCoordinateHistory;
    let setMapSlider = props.setMapSlider;
    let maxValueMapSlider = props.maxValueMapSlider;
    let setHistoryLLDisplay = props.setHistoryLLDisplay;
    let setLongitude = props.setLongitude;
    let setLatitude = props.setLatitude;

    //send true or false depending on if the given table contains element with the given properties long and lat
    const foundItem = (table, long, lat) => {

        //return true if the long and lat given (*100 to have a small margin of error) is already in the table.
        return table.some(element => {
            return !!(Math.round(element.longitude * 100) === Math.round(long) && Math.round(element.latitude * 100) === Math.round(lat));
        });
    }

    const viewAll = () => {
        let collectorIcon = props.collectorIcon;
        let firstPointTime = props.firstPointTime;

        let reversedLongLat = [...longLat].reverse();
        let arrayLessPoint = [];

        //for each point in the array, keep it only if the long and the lat is not already displayed
        reversedLongLat.forEach((element) => {
            if (!foundItem(arrayLessPoint, element.longitude * 100, element.latitude * 100)) {
                if (element.longitude !== 0 && element.latitude !== 0) {
                    arrayLessPoint.push(element);
                }
            }
        });

        let tempMarkers = [];
        if(arrayLessPoint.length !== 0){
            arrayLessPoint.forEach((element, index) => {
                if (element.longitude !== undefined) {
                    tempMarkers.push(<Marker key={index} position={[element.latitude, element.longitude]} icon={collectorIcon}>
                        <Popup>
                            {moment(new Date((firstPointTime + element.timestamp) * 1000)).format('MM/DD-HH:mm')}
                        </Popup>
                    </Marker>);
                }
            })
        }
        let markers = tempMarkers.filter(element => element !== undefined);
        setHistoryLLDisplay(arrayLessPoint);

        let maxLat = -200;
        let minLat = 200;
        let maxLong = -200;
        let minLong = 200;
        markers.forEach((element)=>{
            if(element.props.position[0] > maxLat){
                maxLat = element.props.position[0];
            }
            if(element.props.position[0] < minLat){
                minLat = element.props.position[0];
            }
            if(element.props.position[1] > maxLong){
                maxLong = element.props.position[1];
            }
            if(element.props.position[1] < minLong){
                minLong = element.props.position[1];
            }
        });

        //choose the zoom
        let zoomLat;
        let zoomLong;
        switch (true){
            case ((maxLat-minLat) < 0.001888):
                zoomLat = 18;
                break;
            case ((maxLat-minLat) < 0.003775):
                zoomLat = 17;
                break;
            case ((maxLat-minLat) < 0.007565):
                zoomLat = 16;
                break;
            case ((maxLat-minLat) < 0.015176):
                zoomLat = 15;
                break;
            case ((maxLat-minLat) < 0.03024):
                zoomLat = 14;
                break;
            case ((maxLat-minLat) < 0.061396):
                zoomLat = 13;
                break;
            case ((maxLat-minLat) < 0.121943):
                zoomLat = 12;
                break;
            case ((maxLat-minLat) < 0.243886):
                zoomLat = 11;
                break;
            case ((maxLat-minLat) < 0.487773):
                zoomLat = 10;
                break;
            case ((maxLat-minLat) < 0.975546):
                zoomLat = 9;
                break;
            case ((maxLat-minLat) < 1.951091):
                zoomLat = 8;
                break;
            case ((maxLat-minLat) < 3.902182):
                zoomLat = 7;
                break;
            case ((maxLat-minLat) < 7.804365):
                zoomLat = 6;
                break;
            case ((maxLat-minLat) < 15.60873):
                zoomLat = 5;
                break;
            case ((maxLat-minLat) < 31.21746):
                zoomLat = 4;
                break;
            case ((maxLat-minLat) < 62.43492):
                zoomLat = 3;
                break;
            case ((maxLat-minLat) < 124.8698):
                zoomLat = 2;
                break;
            default:
                zoomLat = 1;
                break;
        }

        switch (true){
            case ((maxLong-minLong) < 0.006748):
                zoomLong = 18;
                break;
            case ((maxLong-minLong) < 0.013496):
                zoomLong = 17;
                break;
            case ((maxLong-minLong) < 0.027052):
                zoomLong = 16;
                break;
            case ((maxLong-minLong) < 0.05419):
                zoomLong = 15;
                break;
            case ((maxLong-minLong) < 0.108208):
                zoomLong = 14;
                break;
            case ((maxLong-minLong) < 0.217448):
                zoomLong = 13;
                break;
            case ((maxLong-minLong) < 0.434895):
                zoomLong = 12;
                break;
            case ((maxLong-minLong) < 0.869791):
                zoomLong = 11;
                break;
            case ((maxLong-minLong) < 1.739581):
                zoomLong = 10;
                break;
            case ((maxLong-minLong) < 3.479163):
                zoomLong = 9;
                break;
            case ((maxLong-minLong) < 6.958325):
                zoomLong = 8;
                break;
            case ((maxLong-minLong) < 13.91665):
                zoomLong = 7;
                break;
            case ((maxLong-minLong) < 27.8333):
                zoomLong = 6;
                break;
            case ((maxLong-minLong) < 55.6666):
                zoomLong = 5;
                break;
            case ((maxLong-minLong) < 111.3332):
                zoomLong = 4;
                break;
            case ((maxLong-minLong) < 222.6664):
                zoomLong = 3;
                break;
            case ((maxLong-minLong) < 445.3328):
                zoomLong = 2;
                break;
            default:
                zoomLong = 1;
                break;
        }

        let zoom;
        if(zoomLat < zoomLong){
            zoom = zoomLat;
        }else {
            zoom = zoomLong;
        }

        setCoordinateHistory(true);
        setMapSlider([0, maxValueMapSlider]);

        map.setView([minLat+(maxLat-minLat)/2, minLong+(maxLong-minLong)/2], zoom);

    }

    const resetView = () => {
        map.setView([longLat[longLat.length-1].latitude, longLat[longLat.length-1].longitude], 14);
        setLongitude(longLat[longLat.length-1].longitude);
        setLatitude(longLat[longLat.length-1].latitude);
        setCoordinateHistory(false);
        setMapSlider([maxValueMapSlider, maxValueMapSlider]);
    }

    return (
        <div className="deviceResetMap">
            <button className="devicesButtonMap" onClick={resetView}>Reset
                View</button>
            <button className="devicesButtonMap" onClick={viewAll}>View
                All</button>
        </div>
    )
}

export default DeviceData;